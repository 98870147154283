const container = {
    hidden: {
        opacity: 0,
        // y: -200,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0,
            when: "beforeChildren",
            staggerChildren: 0.6,
        },
    },
};
const calculatorSection = {
    hidden: {
        opacity: 0,
        y: 200,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 1.5,
            duration: 1,
            when: "beforeChildren",
            staggerChildren: 0.6,
        },
    },
};

const slideLeft = {
    hidden: {
        x: -250,
        opacity: 0,
    },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            delay: 0.2,
        },
    },
};
const slideRight = {
    hidden: {
        x: 250,
        opacity: 0,
    },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1.6,
            delay: 0.2,
        },
    },
};
const slideUp = {
    hidden: {
        y: 250,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.4,
        },
    },
};
const opacity = {
    hidden: {
        opacity: 0,
        x: 250,
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.2,
        },
    },
};
const bulbImg = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 2,
        },
    },
};

const getStartedItem = {
    hidden: {
        opacity: 0,
        y: 50,
    },
    visible: (i) => ({
        y: 0,
        opacity: 1,
        transition: {
            type: "tween",
            duration: 1,
            delay: 1 * i,
        },
    }),
};

const title = {
    hidden: {
        y: 50,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.8,
            delay: 0.2,
        },
    },
};
export {
    bulbImg,
    calculatorSection,
    container,
    getStartedItem,
    opacity,
    slideLeft,
    slideRight,
    slideUp,
    title,
};
