import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/button/Button";
import { H1 } from "../../components/styles/Typography.styles";
import { LANDING } from "../../constants/routes";
import NotAvailableContainer from "./NotAvailableContainer";

const ButtonStyled = styled(Button)`
    border-radius: 50px;
    margin-top: 100px;
    padding: 1rem 4rem;
`;
export const Status = styled(H1)`
    font-family: "Montserrat", sans-serif;
    font-size: 120px;
    margin-bottom: 10px;

    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: 100px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: 90px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: 80px;
    }
`;
const NotFound = () => {
    const history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: LANDING } };
    return (
        <NotAvailableContainer>
            <Status align="center" color="white" weight="b">
                404
            </Status>
            <H1 align="center" size="xl" weight="b" color="white">
                It seems you got lost
            </H1>

            <ButtonStyled
                weight="bold"
                color="white"
                textTransform="uppercase"
                backgroundColor="secondary"
                type="solid"
                fontSize="xl"
                onClick={() => history.push(from)}
            >
                Go Back
            </ButtonStyled>
        </NotAvailableContainer>
    );
};

export default NotFound;
