import React, { useState } from "react";
import styled from "styled-components";
import { P } from "../styles/Typography.styles";

const TabWrapper = styled.div`
    display: flex;
    border-bottom: 1px solid #ccc;
`;

const TabItem = styled.div`
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: ${(props) => (props.active ? "2px solid #007bff" : "none")};
    font-size:${(props) => props.theme.fontSize.default}
    color: ${(props) => (props.active ? "#007bff" : "#333")};
`;

const TabContent = styled.div`
    display: ${(props) => (props.active ? "block" : "none")};
    padding: 15px;
`;

const Tabs = ({ children, active = 0 }) => {
    const [activeTab, setActiveTab] = useState(active);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <>
            <TabWrapper>
                {children.map((child, index) => (
                    <TabItem
                        key={index}
                        active={activeTab === index}
                        onClick={() => handleTabClick(index)}
                    >
                        <P>{child.props.label}</P>
                    </TabItem>
                ))}
            </TabWrapper>
            {children.map((child, index) => (
                <TabContent key={index} active={activeTab === index}>
                    {child.props.children}
                </TabContent>
            ))}
        </>
    );
};

export const Tab = ({ label, children }) => {
    return <>{children}</>;
};

export default Tabs;
