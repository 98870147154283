import styled from "styled-components";

export const H1 = styled.h1`
    display: ${(props) => (props.isInline ? "inline-block" : "block")};
    font-size: ${(props) =>
        props.size
            ? props.theme.fontSize.smallMobile[props.size]
            : props.theme.fontSize.smallMobile.xxl};
    line-height: ${(props) => props.lineHeight || "1.3"};
    color: ${(props) =>
        props.color
            ? props.theme.color[props.color]
            : props.theme.color.black800};
    opacity: ${(props) => props.opacity || 1};
    text-align: ${(props) => props.align || ""};
    font-family: ${(props) => props.fontFamily || "'Montserrat', sans-serif"};
    font-weight: ${(props) =>
        props.theme.fontWeight[props.weight] || props.theme.fontWeight.b};
    text-decoration: ${(props) => props.decoration || ""};
    letter-spacing: ${(props) => props.letterSpacing || "1"};
    text-transform: ${(props) => props.textTransform || ""};

    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) =>
            props.theme.fontSize.smallMobile[
                props.sMobileSize || props.size || "xxl"
            ]};
        line-height: ${(props) => props.lineHeight || "1.3"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.theme.fontSize.smallTablet[
                props.sTabletSize || props.sMobileSize || props.size || "xxl"
            ]};
        line-height: ${(props) =>
            props.sTabletLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) =>
            props.theme.fontSize.tablet[
                props.sTabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "xxl"
            ]};
        line-height: ${(props) =>
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.theme.fontSize.large[
                props.largeSize ||
                    props.tabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "xxl"
            ]};
        line-height: ${(props) =>
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.theme.fontSize.laptop[
                props.laptopSize ||
                    props.largeSize ||
                    props.tabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "xxl"
            ]};
        line-height: ${(props) =>
            props.laptopLineHeight ||
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
        font-size: ${(props) =>
            props.theme.fontSize.desktop[
                props.desktop ||
                    props.laptopSize ||
                    props.largeSize ||
                    props.tabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "xxl"
            ]};
        line-height: ${(props) =>
            props.desktopLineHeight ||
            props.laptopLineHeight ||
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
`;

export const H2 = styled.h2`
    display: ${(props) => (props.isInline ? "inline-block" : "block")};
    font-size: ${(props) =>
        props.size
            ? props.theme.fontSize.smallMobile[props.size]
            : props.theme.fontSize.smallMobile.xml};
    line-height: ${(props) => props.lineHeight || "1.3"};
    color: ${(props) =>
        props.color
            ? props.theme.color[props.color]
            : props.theme.color.black800};
    opacity: ${(props) => props.opacity || 1};
    text-align: ${(props) => props.align || ""};
    font-family: ${(props) => props.fontFamily || "'Montserrat', sans-serif"};
    font-weight: ${(props) =>
        props.theme.fontWeight[props.weight] || props.theme.fontWeight.b};
    text-decoration: ${(props) => props.decoration || ""};
    letter-spacing: ${(props) => props.letterSpacing || "1"};
    text-transform: ${(props) => props.textTransform || ""};

    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) =>
            props.theme.fontSize.smallMobile[
                props.sMobileSize || props.size || "xml"
            ]};
        line-height: ${(props) => props.lineHeight || "1.3"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.theme.fontSize.smallTablet[
                props.sTabletSize || props.sMobileSize || props.size || "xml"
            ]};
        line-height: ${(props) =>
            props.sTabletLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) =>
            props.theme.fontSize.tablet[
                props.sTabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "xml"
            ]};
        line-height: ${(props) =>
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.theme.fontSize.large[
                props.largeSize ||
                    props.tabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "xml"
            ]};
        line-height: ${(props) =>
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.theme.fontSize.laptop[
                props.laptopSize ||
                    props.largeSize ||
                    props.tabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "xml"
            ]};
        line-height: ${(props) =>
            props.laptopLineHeight ||
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
        font-size: ${(props) =>
            props.theme.fontSize.desktop[
                props.desktopSize ||
                    props.laptopSize ||
                    props.largeSize ||
                    props.tabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "xml"
            ]};
        line-height: ${(props) =>
            props.desktopLineHeight ||
            props.laptopLineHeight ||
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
`;

export const H3 = styled.h3`
    display: ${(props) => (props.isInline ? "inline-block" : "block")};
    font-size: ${(props) =>
        props.size
            ? props.theme.fontSize.smallMobile[props.size]
            : props.theme.fontSize.smallMobile.xl};
    line-height: ${(props) => props.lineHeight || "1.3"};
    color: ${(props) =>
        props.color
            ? props.theme.color[props.color]
            : props.theme.color.black800};
    opacity: ${(props) => props.opacity || 1};
    text-align: ${(props) => props.align || ""};
    font-family: ${(props) => props.fontFamily || "'Montserrat', sans-serif"};
    font-weight: ${(props) =>
        props.theme.fontWeight[props.weight] || props.theme.fontWeight.b};
    text-decoration: ${(props) => props.decoration || ""};
    letter-spacing: ${(props) => props.letterSpacing || "1"};
    text-transform: ${(props) => props.textTransform || ""};
    font-style: ${(props) => props.fontStyle || "normal"};
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) =>
            props.theme.fontSize.smallMobile[
                props.sMobileSize || props.size || "xl"
            ]};
        line-height: ${(props) => props.lineHeight || "1.3"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.theme.fontSize.smallTablet[
                props.sTabletSize || props.sMobileSize || props.size || "xl"
            ]};
        line-height: ${(props) =>
            props.sTabletLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) =>
            props.theme.fontSize.tablet[
                props.sTabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "xl"
            ]};
        line-height: ${(props) =>
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.theme.fontSize.large[
                props.largeSize ||
                    props.tabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "xl"
            ]};
        line-height: ${(props) =>
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.theme.fontSize.laptop[
                props.laptopSize ||
                    props.largeSize ||
                    props.tabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "xl"
            ]};
        line-height: ${(props) =>
            props.laptopLineHeight ||
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
`;

export const H4 = styled.h4`
    display: ${(props) => (props.isInline ? "inline-block" : "block")};
    font-size: ${(props) =>
        props.size
            ? props.theme.fontSize.smallMobile[props.size]
            : props.theme.fontSize.smallMobile.l};
    line-height: ${(props) => props.lineHeight || "1.3"};
    color: ${(props) =>
        props.color
            ? props.theme.color[props.color]
            : props.theme.color.black800};
    opacity: ${(props) => props.opacity || 1};
    text-align: ${(props) => props.align || ""};
    font-family: ${(props) => props.fontFamily || "'Montserrat', sans-serif"};
    font-weight: ${(props) =>
        props.theme.fontWeight[props.weight] || props.theme.fontWeight.b};
    text-decoration: ${(props) => props.decoration || ""};
    letter-spacing: ${(props) => props.letterSpacing || "1"};
    text-transform: ${(props) => props.textTransform || ""};

    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) =>
            props.theme.fontSize.smallMobile[
                props.sMobileSize || props.size || "l"
            ]};
        line-height: ${(props) => props.lineHeight || "1.3"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.theme.fontSize.smallTablet[
                props.sTabletSize || props.sMobileSize || props.size || "l"
            ]};
        line-height: ${(props) =>
            props.sTabletLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) =>
            props.theme.fontSize.tablet[
                props.sTabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "l"
            ]};
        line-height: ${(props) =>
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.theme.fontSize.large[
                props.largeSize ||
                    props.tabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "l"
            ]};
        line-height: ${(props) =>
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.theme.fontSize.laptop[
                props.laptopSize ||
                    props.largeSize ||
                    props.tabletSize ||
                    props.sTabletSize ||
                    props.sMobileSize ||
                    props.size ||
                    "l"
            ]};
        line-height: ${(props) =>
            props.laptopLineHeight ||
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "1.3"};
    }
`;

export const P = styled.p`
    display: ${(props) => (props.isInline ? "inline-block" : "block")};

    font-size: ${(props) =>
        props.xxs
            ? props.theme.fontSize.default[props.xxs]
            : props.theme.fontSize.smallMobile.m};
    line-height: ${(props) => props.lineHeight || "2.4rem"};
    color: ${(props) =>
        props.color
            ? props.theme.color[props.color]
            : props.theme.color.black800};
    opacity: ${(props) => props.opacity || 1};
    text-align: ${(props) => props.align || ""};
    font-family: ${(props) => props.fontFamily || "'Montserrat', sans-serif"};
    font-style: ${(props) => props.fontStyle || "normal"};
    font-weight: ${(props) =>
        props.theme.fontWeight[props.weight] || props.theme.fontWeight.rg};
    text-decoration: ${(props) => props.decoration || ""};
    letter-spacing: ${(props) => props.letterSpacing || "1"};
    text-transform: ${(props) => props.textTransform || ""};

    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) =>
            props.xxs
                ? props.theme.fontSize.default[props.xxs]
                : props.theme.fontSize.smallMobile.m};
        line-height: ${(props) => props.lineHeight || "2.4rem"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) =>
            props.xxs
                ? props.theme.fontSize.default[props.xxs]
                : props.theme.fontSize.mobile.m};
        line-height: ${(props) => props.lineHeight || "2.4rem"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.smallTablet.m};
        line-height: ${(props) =>
            props.sTabletLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "2.4rem"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) =>
            props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.tablet.m};
        line-height: ${(props) =>
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "2.8rem"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.large.m};
        line-height: ${(props) =>
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "2.4rem"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.xlg
                ? props.theme.fontSize.default[props.xlg]
                : props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.laptop.m};
        line-height: ${(props) =>
            props.laptopLineHeight ||
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "2.4rem"};
    }
`;
export const Span = styled.span`
    font-size: ${(props) =>
        props.xxs
            ? props.theme.fontSize.default[props.xxs]
            : props.theme.fontSize.smallMobile.m};
    line-height: ${(props) => props.lineHeight || "2.4rem"};
    color: ${(props) =>
        props.color
            ? props.theme.color[props.color]
            : props.theme.color.black800};
    opacity: ${(props) => props.opacity || 1};
    text-align: ${(props) => props.align || ""};
    font-family: ${(props) => props.fontFamily || "'Montserrat', sans-serif"};
    font-style: ${(props) => props.fontStyle || "normal"};
    font-weight: ${(props) =>
        props.theme.fontWeight[props.weight] || props.theme.fontWeight.rg};
    text-decoration: ${(props) => props.decoration || ""};
    letter-spacing: ${(props) => props.letterSpacing || "1"};
    text-transform: ${(props) => props.textTransform || ""};

    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) =>
            props.xxs
                ? props.theme.fontSize.default[props.xxs]
                : props.theme.fontSize.smallMobile.m};
        line-height: ${(props) => props.lineHeight || "2.4rem"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) =>
            props.xxs
                ? props.theme.fontSize.default[props.xxs]
                : props.theme.fontSize.mobile.m};
        line-height: ${(props) => props.lineHeight || "2.4rem"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.smallTablet.m};
        line-height: ${(props) =>
            props.sTabletLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "2.4rem"};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) =>
            props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.tablet.m};
        line-height: ${(props) =>
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "2.8rem"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.large.m};
        line-height: ${(props) =>
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "2.4rem"};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.xlg
                ? props.theme.fontSize.default[props.xlg]
                : props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.laptop.m};
        line-height: ${(props) =>
            props.laptopLineHeight ||
            props.largeLineHeight ||
            props.tabletLineHeight ||
            props.sMobileLineHeight ||
            props.lineHeight ||
            "2.4rem"};
    }
`;
