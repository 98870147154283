import React, { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Car1 } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";

import ImageTextCard from "../../shared/ui/Cards/ImageTextCard";
const CarouselSection = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const Content = (
        <div className=" mx-2 sm:ml-5">
            <div className="flex flex-col justify-start gap-0.4">
                <P className={"text-secondary text-start uppercase font-thin"}>
                    Hypersrot promise
                </P>
                <H2 className={"text-start"}>Our Mission</H2>
            </div>
            <p
                className={
                    "text-secondary mb-3 text-start text-base sm:text-sm lg:text-4xl 2xl:text-5xl"
                }
            >
                To offset carbon emissions through sustainable Green Energy
                Solutions & overcome the constraints of money, space &
                technology for Green Energy Adoption.
            </p>
        </div>
    );

    const data = [
        {
            id: 1,
            content: Content,
            img: Car1,
        },
        {
            id: 2,
            content: Content,
            img: Car1,
        },
    ];
    const previousSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? data.length - 1 : prev - 1));
    };

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev === data.length - 1 ? 0 : prev + 1));
    };

    return (
        <Section>
            <Container>
                <CarouselComponent
                    selectedItem={currentSlide}
                    onChange={setCurrentSlide}
                    showArrows={false}
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3000}
                    stopOnHover={true}
                    transitionTime={500}
                    swipeable={true}
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={50}
                    emulateTouch={true}
                >
                    {data.map((item, index) => (
                        <div className="border border-opacity-5  [border-image:linear-gradient(21.48deg,_#A4CEFF_17.11%,_#DCDCDC_113.64%)_1]">
                            <ImageTextCard
                                url={item.img}
                                content={item.content}
                                wrapperClass="flex-wrap flex-col-reverse sm:flex-row"
                                width="sm:w-[45%]"
                                contentWidth="sm:w-[50%]"
                                col="flex-col-reverse sm:flex-row"
                            />
                        </div>
                    ))}
                </CarouselComponent>

                <div className="flex items-center gap-1  mt-2 ml-1">
                    <button
                        onClick={previousSlide}
                        className="text-xl xl:text-3xl 2xl:text-5xl"
                    >
                        <IoIosArrowBack />
                    </button>

                    <p>
                        <span className="text-lg xl:text-3xl 2xl:text-5xl">
                            {currentSlide + 1 < 10
                                ? `0${currentSlide + 1}`
                                : currentSlide + 1}
                        </span>{" "}
                        <span className="text-sm xl:text-base 2xl:text-lg">
                            /{" "}
                            {data.length < 10 ? `0${data.length}` : data.length}
                        </span>
                    </p>

                    <button
                        onClick={nextSlide}
                        className="text-xl xl:text-3xl 2xl:text-5xl"
                    >
                        <IoIosArrowForward />
                    </button>
                </div>
            </Container>
        </Section>
    );
};

export default CarouselSection;
