import React, { useCallback } from "react";
import OTPBOX from "react-otp-input";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import theme, { SMALL_MOBILE_BREAKPOINT } from "../../theme";
import Flex from "../flex/Flex";
import { ErrorText } from "../input/Input";
import { P } from "../styles/Typography.styles";

const OtpInput = ({
    label,
    value,
    onChange,
    errorMessage = "",
    size,
    ...rest
}) => {
    const { width } = useWindowDimensions();
    const onChangeHandler = useCallback(
        (value) => {
            onChange({
                target: {
                    value,
                    name: "otp",
                },
            });
        },
        [onChange]
    );

    const otpStyle = {
        lineHeight: "1.6em",
        color: theme.color.black,
        border: `2px solid ${theme.color.lightGrey}`,
        fontSize: theme.fontSize.default.m,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: "400",
        background: theme.color.white,
        borderRadius: "5px",
        margin: "0px 3px 10px 3px",
        width: "40px",
        boxSizing: "border-box",
        maxWidth: "40px",
    };
    const containerStyle = {
        justifyContent: "space-between",
        flexWrap: "wrap",
        boxSizing: "border-box",
    };
    const focusStyle = {
        outlineColor: theme.color.primary,
        border: `1px solid ${theme.color.grey}`,
    };
    return (
        <div>
            {label && (
                <Flex.Container justify="center">
                    <P xxs="s" st="s" color="lightGrey">
                        Enter OTP
                    </P>
                </Flex.Container>
            )}
            <OTPBOX
                value={value}
                onChange={onChangeHandler}
                numInputs={size}
                containerStyle={containerStyle}
                focusStyle={focusStyle}
                inputStyle={{
                    ...otpStyle,
                    width: width > SMALL_MOBILE_BREAKPOINT ? "40px" : "32px",
                    borderColor: errorMessage
                        ? theme.color.error
                        : theme.color.lightGrey,
                }}
                {...rest}
            />

            {errorMessage ? (
                <ErrorText xxs="st" st="xs" align="center">
                    {errorMessage}
                </ErrorText>
            ) : null}
        </div>
    );
};

export default OtpInput;
