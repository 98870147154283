import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import cn from "../../../lib/cn";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import {
    opacity,
    translateContainer,
    wavyHeadings,
} from "../../../shared/animation/constants/landingAnimation";
import SplitText from "../../../shared/animation/typography/SplitText";
import H2 from "../../../shared/typography/H2";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import SwitchingTabs from "../../../shared/ui/tab/SwitchingTabs";
import EnquiryPopUp from "./EnquiryPopUp";
const pricingData = [
    {
        id: 1,
        name: "Monthly savings",
        purchase: "Up to 100%",
        lease: "Up to 25-50%",
        loan: "Up to 50%",
    },
    {
        id: 2,
        name: "Plan duration",
        purchase: "Not Applicable",
        lease: "5 Years",
        loan: "5 Years",
    },
    {
        id: 3,
        name: "Maintenance",
        purchase: "First year",
        lease: "Free for 5 years",
        loan: "First year",
    },
    {
        id: 4,
        name: "Insurance",
        purchase: "Not Applicable",
        lease: "Up to 5 years",
        loan: "Not Applicable",
    },
    {
        id: 5,
        name: "Performance guarantee",
        purchase: "First year",
        lease: "Up to 5 years",
        loan: "First year",
    },
    {
        id: 6,
        name: "Ownership",
        purchase: "From beginning",
        lease: "After 5 years",
        loan: "After 5 years",
    },
];

const PriceSection = () => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const [errors, setErrors] = useState({});
    const [enquiry, setEnquiry] = useState("Looking to Purchase Solar");
    const handleButtonClick = (type) => {
        setErrors({ ...errors, enquiry: "" });
        setEnquiry(type);
        setShowModal((showModal) => !showModal);
    };
    useEffect(() => {
        window.addEventListener("resize", () => {});
        return () => window.removeEventListener("resize", () => {});
    }, []);

    const Card = ({
        title,
        planDuration,
        maintenance,
        insurance,
        performance,
        ownership,
        rateProtection,
        onClick,
        isActive,
    }) => {
        return (
            <div
                className={cn("w-max-[402px] mx-1 p-1.6 rounded-lg shadow-md", {
                    "bg-linear-active": isActive,
                    "bg-linear-inactive": !isActive,
                })}
            >
                <div className="flex justify-between items-center mb-1.6">
                    <p className=" text-sm md:text-lg font-semibold text-primary">
                        {title}
                    </p>
                    {title === "Lease" && (
                        <span className="bg-linear-tag text-primary md:text-tag py-0.4 px-1.6 rounded-full border-b-2 border-blue-500">
                            Popular Deal
                        </span>
                    )}
                </div>
                <hr />
                <div
                    className={cn("flex flex-col justify-stretch", {
                        "mt-0.8": title === "Lease",
                        "mt-1.6": title !== "Lease",
                    })}
                >
                    <table className="w-full text-left text-sm sm:text-lg">
                        <tbody>
                            <tr className="">
                                <td className="pb-3.2   text-secondary">
                                    Monthly savings:
                                </td>
                                <td className="pb-3.2  text-primary font-semibold">
                                    25-50%
                                </td>
                            </tr>
                            <tr className="">
                                <td className="pb-3.2  text-secondary">
                                    Plan duration:
                                </td>
                                <td className="pb-3.2  text-primary font-semibold">
                                    {planDuration}
                                </td>
                            </tr>
                            <tr className="">
                                <td className="pb-3.2  text-secondary">
                                    Maintenance:
                                </td>
                                <td className="pb-3.2  text-primary font-semibold">
                                    {maintenance}
                                </td>
                            </tr>
                            <tr className="">
                                <td className="pb-3.2  text-secondary">
                                    Insurance:
                                </td>
                                <td className="pb-3.2  text-primary font-semibold">
                                    {insurance}
                                </td>
                            </tr>
                            <tr className="">
                                <td className="pb-3.2 text-nowrap  text-secondary">
                                    Performance guarantee:
                                </td>
                                <td className="pb-3.2  text-primary font-semibold">
                                    {performance}
                                </td>
                            </tr>
                            <tr className="">
                                <td className="pb-3.2  text-secondary">
                                    Ownership:
                                </td>
                                <td className="pb-3.2 text-primary sm:text-nowrap font-semibold">
                                    {ownership}
                                </td>
                            </tr>
                            <tr className="">
                                <td className="pb-3.2 text-secondary">
                                    Rate hike protection:
                                </td>
                                <td className="pb-3.2 text-primary font-semibold">
                                    {rateProtection}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Button
                        className="text-base sm:text-xl sm:py-1.2 lg:text-2xl"
                        onClick={onClick}
                    >
                        Choose plan
                    </Button>
                </div>
            </div>
        );
    };

    const handleModal = () => {
        setShowModal((showModal) => !showModal);
    };
    const [activeIndex, setActiveIndex] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const handleTabClick = (index) => {
        setActiveIndex(index);
        setCurrentSlide(index);
    };

    const cardsData = [
        {
            title: "Purchase",
            planDuration: "Immediate",
            maintenance: "None",
            insurance: "Optional",
            performance: "Lifetime",
            ownership: "Immediate",
            rateProtection: "Not applicable",
            onClick: () => handleButtonClick("Looking to Purchase Solar "),
        },
        {
            title: "Lease",
            planDuration: "5-10 Years",
            maintenance: "Free up to 10 years",
            insurance: "Up to 10 years",
            performance: "Up to 10 years",
            ownership: "After 5-10 years",
            rateProtection: "Included",
            onClick: () => handleButtonClick("Looking to Take Solar on Lease"),
        },
        {
            title: "Loan",
            planDuration: "3-7 Years",
            maintenance: "Varies",
            insurance: "Mandatory",
            performance: "Up to loan duration",
            ownership: "After loan completion",
            rateProtection: "Variable",
            onClick: () => handleButtonClick("Looking to Take Solar on Loan"),
        },
    ];

    return (
        <Section className="xl:my-[150px] 2xl:my-[150px]">
            <Container>
                <div className="flex xl:hidden flex-col items-center ">
                    <motion.div
                        initial="initial"
                        whileInView="animate"
                        variants={translateContainer}
                    >
                        <SplitText
                            variants={wavyHeadings}
                            heading="h2"
                            className="font-semibold 2xl:mb-1 xl:pr-4  2xl:pr-6"
                        >
                            Find Your Perfect Plan
                        </SplitText>
                        <motion.div variants={opacity}>
                            <P className="text-secondary xl:text-lg 2xl:text-xl  xl:pr-4 2xl:pr-6">
                                Our customer-first pricing plans help you make
                                an informed decision without any confusion or
                                hidden costs. Our transparent and simple pricing
                                plans ensure you get the services you choose to
                                pay for.
                            </P>
                        </motion.div>
                    </motion.div>
                    <div className="flex items-center justify-center my-3.2">
                        <SwitchingTabs
                            options={["Purchase", "Lease", "Loan"]}
                            currentTab={currentSlide}
                            onTabClick={handleTabClick}
                            border={"!rounded-[3rem] lg:rounded-[3rem]"}
                            bestlabel={true}
                        />
                    </div>
                </div>
                <div className="xl:hidden">
                    <CarouselComponent
                        selectedItem={currentSlide}
                        onChange={setCurrentSlide}
                        showArrows={true}
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        emulateTouch={true}
                        infiniteLoop={true}
                        interval={3000}
                        stopOnHover={true}
                        transitionTime={500}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        swipeable={true}
                        centerMode={true}
                        centerSlidePercentage={
                            document.body.clientWidth < 600 ? 100 : 80
                        }
                    >
                        {cardsData.map((data, index) => (
                            <Card
                                key={index}
                                {...data}
                                isActive={currentSlide === index}
                            />
                        ))}
                    </CarouselComponent>
                </div>

                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className="hidden xl:flex flex-wrap mb-4"
                >
                    <div className="w-[30%] ">
                        <div className="2xl:mb-[1.3rem]  2xl:pb-px  2xl:pt-0">
                            <SplitText
                                variants={wavyHeadings}
                                heading="h2"
                                className="text-8xl font-semibold 2xl:mb-1 xl:pr-4  2xl:pr-6"
                            >
                                Find Your Perfect Plan
                            </SplitText>
                            <motion.div variants={opacity}>
                                <P className="text-secondary xl:text-lg 2xl:text-xl  xl:pr-4 2xl:pr-6">
                                    Our customer-first pricing plans help you
                                    make an informed decision without any
                                    confusion or hidden costs. Our transparent
                                    and simple pricing plans ensure you get the
                                    services you choose to pay for.
                                </P>
                            </motion.div>
                        </div>
                    </div>
                    <motion.div
                        initial={{
                            scale: 1,
                            rotateZ: 0,
                        }}
                        whileHover={{
                            scale: 1.1,
                            rotateZ: "1deg",
                        }}
                        className="xl:w-[23%] 2xl:w-[24%] xl:px-2.4 2xl:px-4 hover:cursor-pointer"
                    >
                        <div className="flex flex-col items-center text-center bg-white  xl:py-4.8 2xl:py-6.2 xl:px-4 2xl:px-5.2 rounded-2 2xl:rounded-2.5 border border-blue-400 ">
                            <H3 className="text-2xl font-bold xl:px-3 2xl:px-4 xl:mt-2.4 2xl:mt-3">
                                Purchase
                            </H3>
                            <H3 className="text-5.8xl font-bold text-gray-800 mb-1">
                                100%
                            </H3>
                            <H3 className="lg:text-xl xl:text-xl 2xl:text-2xl text-nowrap font-bold mb-4 ">
                                Upfront investment
                            </H3>
                            <Button
                                className="w-full flex items-center justify-center"
                                onClick={() =>
                                    handleButtonClick(
                                        "Looking to Purchase Solar"
                                    )
                                }
                            >
                                Choose plan
                            </Button>
                        </div>
                    </motion.div>

                    <div className="xl:w-[23%] 2xl:w-[22%] hover:cursor-pointer">
                        <div className="relative xl:px-3.2 2xl:px-4">
                            <div className="text-center rounded-t-xl  flex flex-col justify-center align-center">
                                <H3 className="font-bold text-white  ">
                                    Lease
                                </H3>
                                <H2 className="text-5.8xl font-bold text-white ">
                                    ZERO
                                </H2>
                                <H3 className="xl:text-2xl text-white  text-nowrap font-bold mb-4 ">
                                    Upfront investment
                                </H3>
                                <Button
                                    variant="secondary"
                                    className="w-full flex items-center justify-center"
                                    onClick={() =>
                                        handleButtonClick(
                                            "Looking to Take Solar on Lease"
                                        )
                                    }
                                >
                                    Choose plan
                                </Button>
                            </div>

                            <div className="absolute rounded-3xl bg-linear-four inset-x-0 xl:h-[1060px] 2xl:h-[1150px] xl:top-[-50px] 2xl:top-[-70px] -z-10"></div>
                            <div className="absolute inset-x-0 xl:h-[45px] 2xl:h-[47px] xl:top-[-95px]  2xl:top-[-117px] -z-10 xl:left-5.2 xl:right-5.2  2xl:left-6.4 2xl:right-6.4 rounded-tl-3xl rounded-tr-3xl bg-linear-border  px-1.6 py-1 rounded-t-2xl shadow-md  border border-blue-500">
                                <P className="xl:text-lg 2xl:text-lg font-bold text-Primary text-center">
                                    Popular Deal
                                </P>
                            </div>
                        </div>
                    </div>

                    <motion.div
                        initial={{
                            scale: 1,
                            rotateZ: 0,
                        }}
                        whileHover={{
                            scale: 1.1,
                            rotateZ: "-1deg",
                        }}
                        className="xl:w-[23%]  2xl:w-[24%] xl:px-2.4 2xl:px-4 hover:cursor-pointer"
                    >
                        <div className="flex flex-col items-center text-center bg-white  xl:py-4.8 2xl:py-6.2 xl:px-4 2xl:px-5.2 rounded-2 2xl:rounded-2.5 border border-blue-400">
                            <H3 className="text-2xl font-bold xl:px-3 2xl:px-4 xl:mt-2.4 2xl:mt-3">
                                Loan
                            </H3>
                            <H3 className="text-5.8xl font-bold text-center text-gray-800 mb-1">
                                30%
                            </H3>
                            <H3 className="lg:text-xl xl:text-xl 2xl:text-2xl text-nowrap font-bold mb-4 ">
                                Upfront investment
                            </H3>
                            <Button
                                className="w-full flex items-center justify-center"
                                onClick={() =>
                                    handleButtonClick(
                                        "Looking to Take Solar on Loan"
                                    )
                                }
                            >
                                Choose plan
                            </Button>
                        </div>
                    </motion.div>
                </motion.div>
                <ul className="hidden xl:flex flex-wrap">
                    {pricingData.map((item, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={cn("  py-3 px-3 w-[30%]", {
                                    "rounded-tl-3xl rounded-bl-3xl":
                                        index % 2 !== 0,

                                    "bg-gray-100": index % 2 === 1,
                                })}
                            >
                                <li>
                                    <P> {item.name}</P>
                                </li>
                            </div>
                            <div
                                className={cn(
                                    " py-3 px-3 text-center xl:w-[23%] 2xl:w-[24%]",
                                    {
                                        "bg-gray-100": index % 2 === 1,
                                    }
                                )}
                            >
                                <li>
                                    <P>{item.purchase}</P>{" "}
                                </li>
                            </div>
                            <div className="py-3 px-3 text-center xl:w-[23%] 2xl:w-[22%]">
                                <li>
                                    <P className="font-medium xl:text-xl 2xl:text-[26px] 2xl:leading-[29px]">
                                        {item.lease}
                                    </P>
                                </li>
                            </div>
                            <div
                                className={cn(
                                    " py-3 px-3 xl:w-[23%] 2xl:w-[24%]  text-center",
                                    {
                                        "rounded-tr-3xl rounded-br-3xl":
                                            index % 2 !== 0,
                                        "bg-gray-100": index % 2 === 1,
                                    }
                                )}
                            >
                                <li>
                                    <P>{item.loan}</P>
                                </li>
                            </div>
                        </React.Fragment>
                    ))}
                </ul>

                <EnquiryPopUp
                    enquiry={enquiry}
                    isOpen={showModal}
                    onClose={handleModal}
                    callback={() => setShowModal(false)}
                />
            </Container>
        </Section>
    );
};

export default PriceSection;
