import React from "react";
import Space from "../../../components/space/Space";
import { Flex } from "../../../components/styles/Elements.styles";
import TopLevelInput from "../TopLevelInput";

const InputRow = ({
    data,
    xxs = "100%",
    st = "45%",
    lg = "45%",
    xl = "30%",
    spacing = ["2rem", "3rem", "3.5rem"],
}) => {
    return (
        <Space
            xxsm={[`0 0 ${spacing[0]}`]}
            stm={[`0 0 ${spacing[1]}`]}
            lgm={`0 0 ${spacing[2]}`}
        >
            <Flex.Container style={{ gap: "5%" }} align="center">
                {data.map((el, i) => (
                    <Flex.Item key={i} xxs={xxs} st={st} lg={lg} xl={xl}>
                        <TopLevelInput
                            title={el.title}
                            value={el.value}
                            disabled={true}
                        />
                    </Flex.Item>
                ))}
            </Flex.Container>
        </Space>
    );
};

export default InputRow;
