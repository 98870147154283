import React from "react";
import styled from "styled-components";
import { bgStars, moon, mountain } from "../../assets";
import Button from "../../components/button/Button";
import Image from "../../components/image/Image";
import { Flex } from "../../components/styles/Elements.styles";
import Text from "../../components/text/Text";

const Container = styled.div`
    padding-top: 0;
    padding-bottom: 0;
    min-height: 100vh;
    background: url(${(props) => props.bg});
    background-position: center top;
    background-size: contain;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Mountain = styled(Image)`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
`;
const Moon = styled(Image)`
    position: absolute;
    top: 20%;
    left: 25%;
    transform: translate(-20%, -25%);
    width: 100px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: 80px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: 70px;
    }
`;
const ButtonStyled = styled(Button)`
    border-radius: 50px;
    margin-top: 100px;
    padding: 1rem 4rem;
`;
const Status = styled(Text)`
    font-family: "Montserrat", sans-serif;
    font-size: 120px;
    margin-bottom: 10px;

    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: 100px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: 90px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: 80px;
    }
`;
const NotAvailableContainer = ({ children }) => {
    return (
        <Container bg={bgStars}>
            <Flex.Container
                style={{
                    flexDirection: "column",
                    align: "center",
                    zIndex: 9,
                }}
            >
                {children}
            </Flex.Container>
            <Mountain src={mountain} alt="mountain" />
            <Moon src={moon} alt="moon" />
        </Container>
    );
};

export default NotAvailableContainer;
