import styled from "styled-components";

export const TableContainer = styled.table`
    border-collapse: collapse;
    width: 100%;
    border-radius: 50px;
    font-family: ${(props) => props.fonFamily || "'Montserrat', sans-serif"};
`;

export const Row = styled.tr``;

export const Header = styled.th`
    border: 1px solid
        ${(props) =>
            props.borderColor
                ? props.theme.color[props.borderColor]
                : props.theme.color.darkGrey};
    text-align: center;
    padding: 10px 3px;
    color: ${(props) =>
        props.color ? props.theme.color[props.color] : props.theme.color.white};
    background: ${(props) =>
        props.bg ? props.theme.color[props.bg] : props.theme.color.secondary};
`;

export const Cell = styled.td`
    border: 1px solid
        ${(props) =>
            props.borderColor
                ? props.theme.color[props.borderColor]
                : props.theme.color.darkGrey};
    text-align: center;
    padding: 10px 3px;
    color: ${(props) =>
        props.color
            ? props.theme.color[props.color]
            : props.theme.color.darkGrey};
    background: ${(props) =>
        props.bg ? props.theme.color[props.bg] : props.theme.color.secondary};
`;

export const TableWrapper = styled.div`
    padding-top: 25px;
    table {
        width: 100%;
        text-align: center;
        background-color: #f5f3f3;
        border-radius: 10px;
        border-collapse: collapse;
        font-family: ${(props) =>
            props.fontFamily || "'Montserrat', sans-serif"};
        thead {
            tr {
                :first-child {
                    th:first-child {
                        border-top-left-radius: 10px;
                    }
                    th:last-child {
                        border-top-right-radius: 10px;
                    }
                }
            }
        }

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
        th {
            border-bottom: none;
            border-top: none;
            color: ${(props) => props.theme.color.white};
            /* background-color: #e0dddd; */
            background-color: ${(props) => props.theme.color.black800};
            :first-child {
                border-left: none;
            }
            :last-child {
                border-right: none;
            }
        }
    }
`;
