const translateContainer = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.1,
            when: "beforeChildren",
            staggerChildren: 0.2,
        },
    },
};

const container = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.1,
            when: "beforeChildren",
            staggerChildren: 0.2,
        },
    },
};

const noStaggerContainer = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.2,
            when: "beforeChildren",
            // staggerChildren: 0.2,
        },
    },
};

const wavyHeadings = {
    initial: { y: "100%" },
    animate: {
        y: 0,
        transition: {
            duration: 0.5,
            type: "tween",
        },
    },
};

const headingsTwo = {
    initial: { y: -20, opacity: 0 },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            type: "tween",
        },
    },
};
const opacity = {
    initial: { opacity: 0 },
    animate: {
        opacity: 1,
        transition: {
            duration: 1.2,
            type: "tween",
        },
    },
};

const slideOpacityLeft = {
    initial: { x: "-100%", opacity: 0 },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            type: "tween",
        },
    },
};
const slideOpacityRight = {
    initial: { x: "100%", opacity: 0 },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1,
            type: "tween",
        },
    },
};
const slideOpacityUp = {
    initial: { y: -100, opacity: 0 },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            type: "tween",
        },
    },
};

const slideOpacityDown = {
    initial: { y: 100, opacity: 0 },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            type: "tween",
        },
    },
};
export {
    container,
    headingsTwo,
    noStaggerContainer,
    opacity,
    slideOpacityDown,
    slideOpacityLeft,
    slideOpacityRight,
    slideOpacityUp,
    translateContainer,
    wavyHeadings,
};
