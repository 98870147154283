import React, { useCallback } from "react";
import "react-calendar/dist/Calendar.css";
import DatePickerBox from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import styled from "styled-components";
import { AlertMessage, ErrorText } from "../input/Input";

const InputWrapper = styled.div`
    margin-bottom: ${(props) => props.marginBottom || ""};
    position: relative;
    box-sizing: border-box;
    border: none;
    font-size: ${(props) => props.theme.fontSize.default.s};
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: ${(props) =>
        props.theme.color[props.color] || props.theme.color.darkGrey};
    .react-date-picker {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        .react-date-picker__calendar {
            z-index: 20;
        }
        .react-date-picker__wrapper {
            box-sizing: border-box;
            background-color: ${(props) => props.theme.color.white};
            border: 2px solid
                ${(props) =>
                    props.isError
                        ? props.theme.color.error
                        : props.theme.color.lightGrey};
            overflow: hidden;
            border-radius: 3px;
            /* padding: 10px 10px; */
            padding: ${(props) =>
                props.size === "s" ? ".7rem 1.8rem" : "1rem 1.8rem"};
            line-height: 1.6em;
            box-shadow: ${(props) =>
                props.shadow ? "rgba(17, 17, 26, 0.20) 0px 0px 12px" : ""};
            .react-date-picker__inputGroup__input {
                color: ${(props) =>
                    props.theme.color[props.color] ||
                    props.theme.color.darkGrey};
            }
        }
    }
`;

const DatePicker = ({
    value,
    onChange,
    errorMessage = "",
    maxDate,
    minDate,
    marginBottom = "0px",
    size = "s",
    errorType = "message",
    name = "dateOfBirth",
    disabled = false,
    showCalender = true,
}) => {
    const onChangeHandler = useCallback(
        (value) => {
            onChange({
                target: {
                    value,
                    name,
                },
            });
        },
        [onChange, name]
    );
    return (
        <InputWrapper
            size={size}
            marginBottom={marginBottom}
            isError={errorMessage}
        >
            <DatePickerBox
                onChange={onChangeHandler}
                value={value}
                shadow
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                format="dd/MM/yyyy"
                yearPlaceholder="yyyy"
                maxDate={maxDate}
                minDate={minDate}
                clearIcon={false}
                disabled={disabled}
                calendarIcon={showCalender}
            />
            {errorMessage && errorType === "alert" ? (
                <AlertMessage xxs="xs" st="xs">
                    {errorMessage}
                </AlertMessage>
            ) : errorMessage ? (
                <ErrorText xxs="xs" st="xs">
                    {errorMessage}
                </ErrorText>
            ) : null}
        </InputWrapper>
    );
};
export default DatePicker;
