import React from "react";
import { MdOutlineArrowOutward } from "react-icons/md";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import cn from "../../lib/cn";
import Container from "../../shared/Container";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";

const VerticalHeroTab = ({ active, tabData, onClick }) => {
    const { width } = useWindowDimensions();
    return (
        <Container className="">
            <div className="flex flex-row-reverse xl:flex-row justify-between items-start box-border">
                <div className="w-[48%] sm:w-[65%] lg:w-[55%] xl:w-[55%] 2xl:w-[55%] 3xl:w-[55%] h-auto box-border">
                    <div
                        style={{
                            backgroundImage: `url(${active.highlightImg.img})`,
                        }}
                        className="relative h-[196px] sm:h-[250px] md:h-[280px] lg:h-[320px] xl:h-[360px] 2xl:h-[409px]  w-full object-cover bg-no-repeat aspect-video 3xl:h-[590px] 3xl:bg-cover 3xl:aspect-auto rounded-2 overflow-hidden"
                    >
                        <div className="absolute inset-x-0 inset-y-0 p-1 lg:p-1.6 xl:p-2 ">
                            <div className="flex flex-col justify-between h-full">
                                <div>
                                    <div className="bg-white inline-block rounded-full py-0.4 xl:py-0.5 px-1.2">
                                        <P className="text-xs xl:text-lg 2xl:text-xl">
                                            {active.tag}
                                        </P>
                                    </div>
                                </div>
                                <div>
                                    <H4 className="text-white xl:text-2xl mb-0.5 xl:mb-0.8 line-clamp-2 ">
                                        {active.tagLine}
                                    </H4>
                                    <div className="flex justify-between items-center">
                                        <P className="text-xs  text-white xl:text-lg">
                                            {active.date}
                                        </P>
                                        <button className=" flex items-center justify-center ">
                                            <div className="border-2 w-[18px] h-[18px] md:w-[25px] md:h-[25px] xl:w-[30px] xl:h-[30px] border-white text-white text-2xl rounded-full flex items-center justify-center hover:cursor-pointer hover:scale-110 duration-200">
                                                <MdOutlineArrowOutward />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[45%] sm:w-[25%] lg:w-[30%]">
                    {tabData.map((item, i) => (
                        <div
                            className={cn(
                                "border-secondary mb-1.6 pb-0.8 xl:py-2 hover:cursor-pointer ",
                                {
                                    "xl:border-b ": i !== tabData.length - 1,
                                    "border-b border-white":
                                        item.id === active.id && width < 1280,
                                    "border-white":
                                        item.id === active.id && width >= 1280,
                                }
                            )}
                        >
                            <P
                                role="button"
                                onClick={() => onClick(item.path)}
                                className={cn(
                                    "text-white hover:font-bold duration-150 inline-block",
                                    {
                                        "font-bold text-base sm:text-xl xl:text-lg 2xl:text-3xl":
                                            active.id === item.id,
                                    }
                                )}
                            >
                                {item.name}
                            </P>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
};

export default VerticalHeroTab;
