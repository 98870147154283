import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { IoMdDownload } from "react-icons/io";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Icon from "../../../components/Icon/Icon";
import Accordion from "../../../components/accordion/Accordion";
import AlertNew from "../../../components/alert/AlertNew";
import Button from "../../../components/button/Button";
import DatePicker from "../../../components/date-picker/DatePicker";
import UploadImage, {
    PlaceHolderPreview,
} from "../../../components/input/UploadImage";
import ModalAlt from "../../../components/modal/ModalAlt";
import RadioInput from "../../../components/radio/RadioInput";
import Space from "../../../components/space/Space";
import Container from "../../../components/styles/Container.styles";
import { Flex, Section } from "../../../components/styles/Elements.styles";
import { H3, H4, P } from "../../../components/styles/Typography.styles";
import {
    CONNECTION_TYPE,
    ERROR_MSG,
    FIVE_YEARS_TERM,
    MAX_BILLING_GAP,
    MAX_NOTES_LENGTH,
    NUMERIC_FIELD,
    PLANT_STATUS,
    PROPOSAL_STATUS,
    REQUIRED_FIELD,
    SURVEY_STATUS,
    TEN_YEARS_TERM,
    YEAR,
} from "../../../constants/common";
import { RESET_LOADER, SET_LOADER } from "../../../constants/contexConstant";
import {
    GET_SURVEY_SITE_API_ENDPOINT,
    SURVEY_PROPOSAL_API_ENDPOINT,
    VIEW_SURVEY_DOCUMENT_API_ENDPOINT,
} from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import {
    handleValidateFiles,
    validSurveyProposalData,
} from "../../../helpers/fieldValidation";
import useNetwork from "../../../hooks/useNetwork";
import request from "../../../services/request";
import theme from "../../../theme";
import DetailsField from "../DetailsField";
import OnboardingContainer from "../OnboardingContainer";
import WrapperSurveySite from "../agent/WrapperSurveySite";

const DOCUMENT_PURPOSE = {
    MOU_DOC: "MOU_DOC",
    PPA_DOC: "PPA_DOC",
    LEAVE_LICENSE_DOC: "LEAVE_LICENSE_DOC",
};

const AdminSurvey = () => {
    const { dispatch } = useContextState();

    const { plantId } = useParams();
    const [getPlantId] = useState(plantId);
    const [siteList, setSiteList] = useState([]);
    const [lightsBills, setLightsBills] = useState([]);
    const [surveyStatus, setSurveyStatus] = useState("");
    const [proposalStatus, setProposalStatus] = useState(null);
    const [plantStatus, setPlantStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [proposalDoc, setProposalDoc] = useState([]);
    const [initialProposal, setInitialProposal] = useState({});
    const [survey, setSurvey] = useState({});

    const {
        data,
        error: plantError,
        loading: plantLoading,
    } = useNetwork(`${GET_SURVEY_SITE_API_ENDPOINT}/${getPlantId}`);

    const [proposal, setProposal] = useState({
        averageMonthlyElectricityBill: "",
        monthlyElectricityUnits: "",
        shadeFreeArea: "",
        tentativeSolarPower: "",
        electricityConnectionType: "",
        proposedSolarCapacity: "",
        billToBePaidFiveYears: "",
        billToBePaidTenYears: "",
        refundableDepositFiveYears: "",
        refundableDepositTenYears: "",
        solarPVModel: "",
        solarInverterModel: "",
        status: "",
        comment: "",
    });

    const [finalProposal, setFinalProposal] = useState({
        finalTerms: "",
        finalMonthlyPayment: "",
        finalRefundableDeposit: "",
        finalSolarCapacity: "",
        firstBillGenerationDate: new Date(),
        firstBillPaymentDate: new Date(),
    });
    useEffect(() => {
        if (data && data?.data?.survey?.siteList) {
            setSiteList(data?.data?.survey?.siteList);
            setLightsBills(data?.data?.survey?.lightsBills);
            setSurveyStatus(data?.data?.survey?.surveyStatus);
            setProposalStatus(data?.data?.survey?.proposalStatus);
            setPlantStatus(data?.data?.survey?.plantStatus);
            setProposalDoc(data?.data?.survey?.proposalDoc);
            setInitialProposal(data?.data?.survey?.proposal);
            setSurvey(data?.data?.survey);
        }
    }, [data]);

    const [formError, setFormError] = useState({});

    let showBillingDate =
        plantStatus === PLANT_STATUS.BANKING &&
        proposalStatus === PROPOSAL_STATUS[2];

    const handleSubmitInitialProposal = useCallback(
        async (e) => {
            e.preventDefault();
            const getValidData = validSurveyProposalData(proposal);
            setFormError(getValidData);

            if (Object.keys(getValidData).length === 0) {
                setLoading(true);
                const body = { ...proposal };
                body.electricityConnectionType =
                    `${body.electricityConnectionType}`
                        .toUpperCase()
                        .replaceAll(" ", "_");

                try {
                    const res = await request.authPost({
                        endpoint: `${SURVEY_PROPOSAL_API_ENDPOINT}/${plantId}`,
                        body,
                    });
                    toast.success(res.message || "Success");
                    setProposalStatus(body.status);
                    setProposal({
                        averageMonthlyElectricityBill: "",
                        monthlyElectricityUnits: "",
                        shadeFreeArea: "",
                        tentativeSolarPower: "",
                        electricityConnectionType: "",
                        proposedSolarCapacity: "",
                        billToBePaidFiveYears: "",
                        billToBePaidTenYears: "",
                        refundableDepositFiveYears: "",
                        refundableDepositTenYears: "",
                        solarPVModel: "",
                        solarInverterModel: "",
                        status: "",
                        comment: "",
                    });
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toast.error(typeof err === "string" ? err : ERROR_MSG);
                }
            }
        },
        [plantId, proposal]
    );
    const [base64Preview, setBase64Preview] = useState("");

    const handleOnPreviewClick = async (val) => {
        try {
            dispatch({ type: SET_LOADER });
            const res = await request.authPost({
                endpoint: VIEW_SURVEY_DOCUMENT_API_ENDPOINT,
                body: {
                    fileName: val,
                },
            });
            dispatch({ type: RESET_LOADER });
            setBase64Preview(res?.data?.image);
        } catch (err) {
            dispatch({ type: RESET_LOADER });
            toast.error(typeof err === "string" ? err : ERROR_MSG);
        }
    };

    const handleDownloadImage = (e) => {
        e.preventDefault();
        if (base64Preview) {
            let el = document.createElement("a");
            el.href = "data:image/png;base64," + base64Preview;
            el.download = `${getPlantId}-${Date.now()}.jpg`;
            el.click();
        }
    };

    // @TODO ==>   Start new ui  Mon Dec  18

    const [finalProposalError, setFinalProposalError] = useState({});
    const handleChange = (name, value, isFinal) => {
        if (isFinal) {
            setFinalProposal({ ...finalProposal, [name]: value });
        } else {
            setProposal({ ...proposal, [name]: value });
        }
    };
    const getMaxAndMinPaymentDate = (isMin) => {
        let billDate = new Date(finalProposal.firstBillGenerationDate);
        if (isMin) {
            billDate.setDate(billDate.getDate() + 7);
            return billDate;
        }
        billDate.setDate(billDate.getDate() + MAX_BILLING_GAP);
        return billDate;
    };

    const validFinalProposal = (values, isDateOnly) => {
        const error = {};
        if (!isDateOnly) {
            if (!values.finalTerms) {
                error.finalTerms = REQUIRED_FIELD;
            }
            if (!values.finalMonthlyPayment) {
                error.finalMonthlyPayment = REQUIRED_FIELD;
            } else if (isNaN(values.finalMonthlyPayment)) {
                error.finalMonthlyPayment = NUMERIC_FIELD;
            }
            if (!values.finalRefundableDeposit) {
                error.finalRefundableDeposit = REQUIRED_FIELD;
            } else if (isNaN(values.finalRefundableDeposit)) {
                error.finalRefundableDeposit = NUMERIC_FIELD;
            }
            if (!values.finalSolarCapacity) {
                error.finalSolarCapacity = REQUIRED_FIELD;
            } else if (isNaN(values.finalSolarCapacity)) {
                error.finalSolarCapacity = NUMERIC_FIELD;
            }
        } else {
            if (!values.firstBillGenerationDate) {
                error.firstBillGenerationDate = REQUIRED_FIELD;
            } else if (
                moment(values.firstBillGenerationDate).isValid() === false
            ) {
                error.firstBillGenerationDate = "Date is invalid!";
            } else {
                const currentMoment = moment().startOf("day").unix();
                // @TODO ==>   Fix how many maximum date user can select for the first payment start: REPLACE 13 Mon Dec  18
                const maxTimeEndDays = moment()
                    .add(MAX_BILLING_GAP, "days")
                    .endOf("day")
                    .unix();
                const userMoment = moment(values.firstBillGenerationDate)
                    .startOf("days")
                    .unix();

                if (currentMoment > userMoment) {
                    error.firstBillGenerationDate = "Past date is invalid!";
                } else if (maxTimeEndDays < userMoment) {
                    error.firstBillGenerationDate = `Date can't be more than ${MAX_BILLING_GAP} days!`;
                }
            }
            if (!values.firstBillPaymentDate) {
                error.firstBillPaymentDate = REQUIRED_FIELD;
            } else if (
                moment(values.firstBillPaymentDate).isValid() === false
            ) {
                error.firstBillPaymentDate = "Date is invalid!";
            } else {
                let currentMoment = moment(values.firstBillGenerationDate)
                    .startOf("day")
                    .unix();
                let maxTimeEndDays = moment(values.firstBillGenerationDate)
                    .add(MAX_BILLING_GAP, "days")
                    .endOf("day")
                    .unix();

                let userMoment = moment(values.firstBillPaymentDate)
                    .startOf("day")
                    .unix();

                let diff = moment(new Date(userMoment * 1000)).diff(
                    new Date(currentMoment * 1000),
                    "days"
                );
                if (currentMoment > userMoment) {
                    error.firstBillPaymentDate = "Past date is invalid!";
                } else if (currentMoment === userMoment) {
                    error.firstBillPaymentDate =
                        "Bill payment date must be grater than bill generation date!";
                } else if (maxTimeEndDays < userMoment) {
                    error.firstBillPaymentDate = `Date can't be more than ${MAX_BILLING_GAP} days!`;
                } else if (diff < 7 || diff > 15) {
                    error.firstBillPaymentDate = `Billing and Payment day gap should be between 7-15`;
                }
            }
        }

        return error;
    };

    const [toUpload, setToUpload] = useState(null);
    const [fileError, setFileError] = useState("");
    const [uploadLoading, setUploadLoading] = useState(false);

    const handleChangeDocuments = (e, name) => {
        let result = handleValidateFiles(e, ["PDF"]);
        // @TODO ==>   Validate Size Tue Dec  19
        if (typeof result === "string") {
            setFileError(result);
        } else {
            setFileError("");
            // @TODO ==>   Show popup Tue Dec  19

            setToUpload({ file: result, purpose: name });
            // onUpload({ document: result, purpose: name, index, id: siteId });
        }
    };

    const handleUploadProposalFile = useCallback(
        async (e) => {
            e.preventDefault();
            setUploadLoading(true);

            if (toUpload?.file && toUpload?.purpose) {
                try {
                    let formData = new FormData();
                    formData.append("document", toUpload?.file);
                    formData.append("plantId", plantId);
                    formData.append("purpose", toUpload?.purpose);

                    let res = await request.authPost({
                        body: formData,
                        endpoint: `${SURVEY_PROPOSAL_API_ENDPOINT}/upload`,
                        isFormData: true,
                    });
                    toast.success(res?.message || "Document has been uploaded");

                    setProposalDoc([...proposalDoc, res?.data?.document]);
                    setToUpload(null);
                    setUploadLoading(false);
                } catch (error) {
                    setUploadLoading(false);
                    setToUpload(null);
                    toast.error(typeof error === "string" ? error : ERROR_MSG);
                }
            } else {
                setUploadLoading(false);
                toast.error("Something went wrong while compressing image");
            }
        },
        [plantId, proposalDoc, toUpload?.file, toUpload?.purpose]
    );

    const showUploadDocInput = () => {
        let ppa = true;
        let license = true;
        let mou = true;
        proposalDoc.forEach((item) => {
            if (item.purpose === DOCUMENT_PURPOSE.PPA_DOC) {
                ppa = false;
            } else if (item.purpose === DOCUMENT_PURPOSE.MOU_DOC) {
                mou = false;
            } else if (item.purpose === DOCUMENT_PURPOSE.LEAVE_LICENSE_DOC) {
                license = false;
            }
        });

        return (
            <>
                {mou && (
                    <UploadImage
                        onChange={(e) =>
                            handleChangeDocuments(e, DOCUMENT_PURPOSE.MOU_DOC)
                        }
                        name={"finalMOU"}
                        bottomLabel="MOU"
                    />
                )}
                {ppa && (
                    <UploadImage
                        onChange={(e) =>
                            handleChangeDocuments(e, DOCUMENT_PURPOSE.PPA_DOC)
                        }
                        name={"finalPPA"}
                        bottomLabel="PPA"
                    />
                )}
                {license && (
                    <UploadImage
                        onChange={(e) =>
                            handleChangeDocuments(
                                e,
                                DOCUMENT_PURPOSE.LEAVE_LICENSE_DOC
                            )
                        }
                        name={"finalLeaveLicense"}
                        bottomLabel="LEAVE LICENSE"
                    />
                )}
            </>
        );
    };

    const handleSubmitFinalProposal = useCallback(
        async (isBillDate) => {
            let getValidData = validFinalProposal(
                finalProposal,
                showBillingDate
            );
            setFinalProposalError({ ...getValidData });
            if (Object.keys(getValidData).length === 0) {
                try {
                    setLoading(true);
                    setLoading(false);

                    let reqData = {
                        ...finalProposal,
                    };
                    let billData = {};
                    billData.firstBillGenerationDate = moment(
                        finalProposal.firstBillGenerationDate
                    )
                        .startOf("day")
                        .unix();
                    billData.firstBillPaymentDate = moment(
                        finalProposal.firstBillPaymentDate
                    )
                        .startOf("day")
                        .unix();

                    if (isBillDate) {
                        let res = await request.authPut({
                            endpoint: `${SURVEY_PROPOSAL_API_ENDPOINT}/final/${plantId}`,
                            body: billData,
                        });
                        setProposalStatus(res?.data?.survey?.proposalStatus);
                        toast.success(
                            res?.message ||
                                "Data has been submitted successfully"
                        );
                    } else {
                        let res = await request.authPost({
                            endpoint: `${SURVEY_PROPOSAL_API_ENDPOINT}/final/${plantId}`,
                            body: reqData,
                        });

                        setProposalStatus(res?.data?.survey?.proposalStatus);
                        toast.success(
                            res?.message ||
                                "Data has been submitted successfully"
                        );
                    }
                } catch (error) {
                    setLoading(false);
                    toast.error(typeof error === "string" ? error : ERROR_MSG);
                }
            }
        },
        [finalProposal, plantId, showBillingDate]
    );
    let showBilling = proposalStatus === "FINAL";

    const disabledInitialProposal = proposalStatus !== null;
    const postedTerms = survey?.finalTerms
        ? Number(survey?.finalTerms) / YEAR
        : null;

    return (
        <>
            <div>
                <Space
                    xxsm={["5rem 0 3rem"]}
                    stm={["6rem 0 4rem"]}
                    lgm={["8rem  0 4rem"]}
                >
                    <Section height="auto" style={{ overflow: "hidden" }}>
                        <Container>
                            {plantLoading ? (
                                <Section height="80vh"></Section>
                            ) : plantError ? (
                                <SurveyError error={plantError} />
                            ) : data !== null ? (
                                surveyStatus &&
                                surveyStatus !== SURVEY_STATUS.SURVEY_FILLED ? (
                                    <SurveyError error="Survey is not yet filled" />
                                ) : (
                                    <OnboardingContainer>
                                        <>
                                            <WrapperSurveySite
                                                lightsBills={lightsBills}
                                                siteList={siteList}
                                                disabled={true}
                                                onPreview={handleOnPreviewClick}
                                            />

                                            <Accordion
                                                size={["m", "l", "l"]}
                                                weight="b"
                                                isOpen={!proposalStatus}
                                                label={`Proposal Details`}
                                                isLine
                                            >
                                                <DetailsField
                                                    label={
                                                        "Average Monthly Electricity Bill"
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "averageMonthlyElectricityBill",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Enter monthly electricity bill"
                                                    value={
                                                        proposal?.averageMonthlyElectricityBill ||
                                                        initialProposal?.averageMonthlyElectricityBill
                                                    }
                                                    errorMessage={
                                                        formError.averageMonthlyElectricityBill
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />
                                                <DetailsField
                                                    label={
                                                        "Average Monthly Units Consumed"
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "monthlyElectricityUnits",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Enter monthly electricity units"
                                                    value={
                                                        proposal?.monthlyElectricityUnits ||
                                                        initialProposal?.monthlyElectricityUnits
                                                    }
                                                    // @TODO ==>   Change this  Thu Oct  19
                                                    errorMessage={
                                                        formError.monthlyElectricityUnits
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />
                                                <DetailsField
                                                    label={
                                                        "Shade Free Area (SqFT)"
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "shadeFreeArea",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Enter shade free area"
                                                    value={
                                                        proposal.shadeFreeArea ||
                                                        initialProposal?.shadeFreeArea
                                                    }
                                                    errorMessage={
                                                        formError?.shadeFreeArea
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />
                                                <DetailsField
                                                    label={
                                                        "Electricity Connection Type"
                                                    }
                                                    onChange={(value) =>
                                                        handleChange(
                                                            "electricityConnectionType",
                                                            value
                                                        )
                                                    }
                                                    isSelect
                                                    data={CONNECTION_TYPE}
                                                    selected={
                                                        proposal.electricityConnectionType ||
                                                        initialProposal?.electricityConnectionType
                                                    }
                                                    placeholder="Select  connection type"
                                                    errorMessage={
                                                        formError?.electricityConnectionType
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />
                                                <DetailsField
                                                    label={
                                                        "Tentative Solar Capacity"
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "tentativeSolarPower",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Enter tentative solar power"
                                                    value={
                                                        proposal.tentativeSolarPower ||
                                                        initialProposal?.tentativeSolarPower
                                                    }
                                                    errorMessage={
                                                        formError?.tentativeSolarPower
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />

                                                <DetailsField
                                                    label={
                                                        "Proposed Solar Capacity"
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "proposedSolarCapacity",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Enter proposed solar capacity"
                                                    value={
                                                        proposal.proposedSolarCapacity ||
                                                        initialProposal?.proposedSolarCapacity
                                                    }
                                                    errorMessage={
                                                        formError?.proposedSolarCapacity
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />
                                                <DetailsField
                                                    label={
                                                        "Monthly Bill To Be Paid (5Yr Term)"
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "billToBePaidFiveYears",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Enter monthly  bills to be paid (5yr Term)"
                                                    value={
                                                        proposal.billToBePaidFiveYears ||
                                                        initialProposal?.billToBePaidFiveYears
                                                    }
                                                    errorMessage={
                                                        formError?.billToBePaidFiveYears
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />
                                                <DetailsField
                                                    label={
                                                        "Monthly Bill To Be Paid (10Yr Term)"
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "billToBePaidTenYears",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Enter monthly  bills to be paid (10yr Term)"
                                                    value={
                                                        proposal.billToBePaidTenYears ||
                                                        initialProposal?.billToBePaidTenYears
                                                    }
                                                    errorMessage={
                                                        formError?.billToBePaidTenYears
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />
                                                <DetailsField
                                                    label={
                                                        "Refundable Deposit (5Yr Term)"
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "refundableDepositFiveYears",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Enter refundable deposit (5Yr Term)"
                                                    value={
                                                        proposal.refundableDepositFiveYears ||
                                                        initialProposal?.refundableDepositFiveYears
                                                    }
                                                    errorMessage={
                                                        formError?.refundableDepositFiveYears
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />
                                                <DetailsField
                                                    label={
                                                        "Refundable Deposit (10Yr Term)"
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "refundableDepositTenYears",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Enter refundable deposit (10Yr Term)"
                                                    value={
                                                        proposal.refundableDepositTenYears ||
                                                        initialProposal?.refundableDepositTenYears
                                                    }
                                                    errorMessage={
                                                        formError?.refundableDepositTenYears
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />

                                                <DetailsField
                                                    label={"Proposal Status"}
                                                    onChange={(value) =>
                                                        handleChange(
                                                            "status",
                                                            value
                                                        )
                                                    }
                                                    isSelect
                                                    data={PROPOSAL_STATUS}
                                                    selected={
                                                        proposal.status ||
                                                        initialProposal?.status
                                                    }
                                                    placeholder="Select status"
                                                    errorMessage={
                                                        formError?.status
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />

                                                <DetailsField
                                                    label={"Solar PV Model"}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "solarPVModel",
                                                            e.target.value.slice(
                                                                0,
                                                                MAX_NOTES_LENGTH
                                                            )
                                                        )
                                                    }
                                                    maxLength={
                                                        initialProposal?.solarPVModel
                                                            ? null
                                                            : MAX_NOTES_LENGTH
                                                    }
                                                    placeholder="Enter solar pv model"
                                                    value={
                                                        proposal.solarPVModel ||
                                                        initialProposal?.solarPVModel
                                                    }
                                                    componentType="textArea"
                                                    name="solarPVModel"
                                                    errorMessage={
                                                        formError?.solarPVModel
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />
                                                <DetailsField
                                                    label={
                                                        "Solar Inverter Model"
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "solarInverterModel",
                                                            e.target.value
                                                        )
                                                    }
                                                    value={
                                                        proposal.solarInverterModel ||
                                                        initialProposal?.solarInverterModel
                                                    }
                                                    placeholder="Enter solar inverter model"
                                                    errorMessage={
                                                        formError?.solarInverterModel
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />
                                                <DetailsField
                                                    label={"Comments"}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "comment",
                                                            e.target.value.slice(
                                                                0,
                                                                MAX_NOTES_LENGTH
                                                            )
                                                        )
                                                    }
                                                    value={
                                                        proposal.comment ||
                                                        initialProposal?.comment
                                                    }
                                                    componentType="textArea"
                                                    placeholder="Comments"
                                                    name="comment"
                                                    maxLength={
                                                        initialProposal?.comment
                                                            ? null
                                                            : MAX_NOTES_LENGTH
                                                    }
                                                    errorMessage={
                                                        formError?.comment
                                                    }
                                                    disabled={
                                                        disabledInitialProposal
                                                    }
                                                />

                                                {!disabledInitialProposal && (
                                                    <Flex.Container justify="center">
                                                        <Flex.Item
                                                            xxs="100%"
                                                            st="45%"
                                                            t="40%"
                                                        >
                                                            <Button
                                                                fullWidth
                                                                backgroundColor="primary"
                                                                onClick={
                                                                    handleSubmitInitialProposal
                                                                }
                                                                disabled={
                                                                    loading
                                                                }
                                                                isLoading={
                                                                    loading
                                                                }
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Flex.Item>
                                                    </Flex.Container>
                                                )}
                                            </Accordion>
                                        </>

                                        {proposalStatus && (
                                            <>
                                                <>
                                                    <Space
                                                        xxsm={["4rem  0 2rem"]}
                                                    >
                                                        <H3>
                                                            Final Proposal
                                                            Details
                                                        </H3>
                                                    </Space>
                                                    <Space
                                                        lgm={[" 0 0 25px "]}
                                                        tm={["0 0 20px"]}
                                                        xxsm={["0 0 10px"]}
                                                    >
                                                        <Flex.Container
                                                            justify="space-between"
                                                            align="center"
                                                        >
                                                            <Space
                                                                tm={["0 0 5px"]}
                                                                xsm={[
                                                                    "0 0 5px",
                                                                ]}
                                                            >
                                                                <Flex.Item
                                                                    xxs="100%"
                                                                    st="100%"
                                                                    t="40%"
                                                                    lg="35%"
                                                                >
                                                                    <P
                                                                        color="black"
                                                                        xxs="s"
                                                                        st="m"
                                                                        lg="ml"
                                                                    >
                                                                        Final
                                                                        Terms
                                                                    </P>
                                                                </Flex.Item>
                                                            </Space>

                                                            <Flex.Item
                                                                xxs="100%"
                                                                st="100%"
                                                                t="50%"
                                                                lg="60%"
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "inline-block",
                                                                        marginRight:
                                                                            "5rem",
                                                                    }}
                                                                >
                                                                    <RadioInput
                                                                        size={[
                                                                            "m",
                                                                            "m",
                                                                            "m",
                                                                        ]}
                                                                        label="5Yr"
                                                                        name="planTerm"
                                                                        value={
                                                                            "5"
                                                                        }
                                                                        disabled={
                                                                            showBilling
                                                                        }
                                                                        onChange={() =>
                                                                            handleChange(
                                                                                "finalTerms",
                                                                                "5",
                                                                                true
                                                                            )
                                                                        }
                                                                        checked={
                                                                            postedTerms &&
                                                                            postedTerms ===
                                                                                FIVE_YEARS_TERM
                                                                        }
                                                                    />
                                                                </div>
                                                                <RadioInput
                                                                    size={[
                                                                        "m",
                                                                        "m",
                                                                        "m",
                                                                    ]}
                                                                    label={
                                                                        "10Yr"
                                                                    }
                                                                    name="planTerm"
                                                                    value={"10"}
                                                                    onChange={() =>
                                                                        handleChange(
                                                                            "finalTerms",
                                                                            "10",
                                                                            true
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postedTerms &&
                                                                        postedTerms ===
                                                                            TEN_YEARS_TERM
                                                                    }
                                                                    disabled={
                                                                        showBilling
                                                                    }
                                                                />
                                                                {finalProposalError?.finalTerms && (
                                                                    <P
                                                                        color="error"
                                                                        xxs="xs"
                                                                        st="xs"
                                                                        xlg="xs"
                                                                    >
                                                                        {
                                                                            finalProposalError?.finalTerms
                                                                        }
                                                                    </P>
                                                                )}
                                                            </Flex.Item>
                                                        </Flex.Container>
                                                    </Space>
                                                    <DetailsField
                                                        label={
                                                            "Final Monthly Payment"
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "finalMonthlyPayment",
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                        placeholder="Final monthly payment"
                                                        value={
                                                            finalProposal.finalMonthlyPayment ||
                                                            survey?.finalMonthlyPayment
                                                        }
                                                        errorMessage={
                                                            finalProposalError.finalMonthlyPayment
                                                        }
                                                        disabled={showBilling}
                                                    />
                                                    <DetailsField
                                                        label={
                                                            "Final Refundable Deposit"
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "finalRefundableDeposit",
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                        placeholder="Final refundable deposit"
                                                        value={
                                                            finalProposal.finalRefundableDeposit ||
                                                            survey?.finalRefundableDeposit
                                                        }
                                                        errorMessage={
                                                            finalProposalError.finalRefundableDeposit
                                                        }
                                                        disabled={showBilling}
                                                    />
                                                    <DetailsField
                                                        label={
                                                            "Final Solar Capacity(KW)"
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "finalSolarCapacity",
                                                                e.target.value,
                                                                true
                                                            )
                                                        }
                                                        placeholder="Final Solar Capacity"
                                                        value={
                                                            finalProposal.finalSolarCapacity ||
                                                            survey?.finalSolarCapacity
                                                        }
                                                        errorMessage={
                                                            finalProposalError.finalSolarCapacity
                                                        }
                                                        disabled={showBilling}
                                                    />
                                                </>

                                                <Space
                                                    xxsm={["0 0 0.1 "]}
                                                    tm={["0 0 1rem"]}
                                                >
                                                    <P color="black">
                                                        Final Proposal Documents
                                                    </P>
                                                </Space>
                                                <Flex.Container>
                                                    {proposalDoc.map((file) => (
                                                        <PlaceHolderPreview
                                                            key={file.id}
                                                            bottomLabel={
                                                                file?.purpose
                                                            }
                                                            component={() => (
                                                                <AiOutlineFilePdf
                                                                    color={
                                                                        theme
                                                                            .color
                                                                            .primary
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                    }}
                                                                />
                                                            )}
                                                            id={file.id}
                                                            // onPreview={onPreview}
                                                        />
                                                    ))}
                                                    {showUploadDocInput()}
                                                </Flex.Container>

                                                {fileError && (
                                                    <P
                                                        color="error"
                                                        xxs="xs"
                                                        st="xs"
                                                        xlg="xs"
                                                    >
                                                        {fileError}
                                                    </P>
                                                )}

                                                {!showBilling && (
                                                    <Flex.Container justify="center">
                                                        <Flex.Item
                                                            xxs="100%"
                                                            st="45%"
                                                            t="40%"
                                                        >
                                                            <Button
                                                                fullWidth
                                                                backgroundColor="primary"
                                                                onClick={() =>
                                                                    handleSubmitFinalProposal(
                                                                        false
                                                                    )
                                                                }
                                                                disabled={
                                                                    loading
                                                                }
                                                                isLoading={
                                                                    loading
                                                                }
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Flex.Item>
                                                    </Flex.Container>
                                                )}

                                                {showBilling && (
                                                    <>
                                                        <Space
                                                            xxsm={[
                                                                "4rem  0 2rem",
                                                            ]}
                                                        >
                                                            <H3>
                                                                First Bill
                                                                Generation
                                                            </H3>
                                                        </Space>
                                                        <FinalBillingDate
                                                            label="First Bill Generation Date"
                                                            value={
                                                                survey?.firstBillGenerationDate
                                                                    ? new Date(
                                                                          Number(
                                                                              survey?.firstBillGenerationDate *
                                                                                  1000
                                                                          )
                                                                      )
                                                                    : finalProposal.firstBillGenerationDate
                                                            }
                                                            error={
                                                                finalProposalError.firstBillGenerationDate
                                                            }
                                                            minDate={new Date()}
                                                            onChange={(e) => {
                                                                handleChange(
                                                                    "firstBillGenerationDate",
                                                                    e.target
                                                                        .value,
                                                                    true
                                                                );
                                                            }}
                                                            disabled={
                                                                survey?.firstBillGenerationDate
                                                            }
                                                        />
                                                        <FinalBillingDate
                                                            label="First Bill Payment Date"
                                                            value={
                                                                survey?.firstBillPaymentDate
                                                                    ? new Date(
                                                                          Number(
                                                                              survey?.firstBillPaymentDate *
                                                                                  1000
                                                                          )
                                                                      )
                                                                    : finalProposal.firstBillPaymentDate
                                                            }
                                                            error={
                                                                finalProposalError.firstBillPaymentDate
                                                            }
                                                            // minDate={getMaxAndMinPaymentDate(
                                                            //     true
                                                            // )}
                                                            // maxDate={getMaxAndMinPaymentDate()}
                                                            onChange={(e) => {
                                                                handleChange(
                                                                    "firstBillPaymentDate",
                                                                    e.target
                                                                        .value,
                                                                    true
                                                                );
                                                            }}
                                                            disabled={
                                                                survey?.firstBillPaymentDate
                                                            }
                                                        />

                                                        <Flex.Container justify="center">
                                                            <Flex.Item
                                                                xxs="100%"
                                                                st="45%"
                                                                t="40%"
                                                            >
                                                                <Button
                                                                    fullWidth
                                                                    backgroundColor="primary"
                                                                    onClick={() =>
                                                                        handleSubmitFinalProposal(
                                                                            true
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        loading
                                                                    }
                                                                    isLoading={
                                                                        loading
                                                                    }
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </Flex.Item>
                                                        </Flex.Container>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </OnboardingContainer>
                                )
                            ) : null}
                        </Container>
                    </Section>
                </Space>
            </div>
            <ModalAlt
                isOpen={base64Preview}
                innerTimes={false}
                onClose={() => setBase64Preview("")}
                width={["100%", "1000px", "1200px"]}
                overflowx="hidden"
                overflowy="auto"
                xxsp="0"
                xsp="0"
                xlp="0"
                stp="0"
                backgroundColor="transparent"
            >
                <div>
                    <Flex.Container
                        direction="column"
                        align="center"
                        justify="center"
                    >
                        <Flex.Item xxs="auto" st="auto" xl="auto">
                            {base64Preview && (
                                <ImageDisplay>
                                    <Button
                                        size="s"
                                        type="solid"
                                        backgroundColor="primary"
                                        className="downloadImage"
                                        onClick={handleDownloadImage}
                                    >
                                        <Icon
                                            component={IoMdDownload}
                                            color="white"
                                            xxs="l"
                                            st="xl"
                                        />
                                    </Button>
                                    <img
                                        style={{ width: "auto" }}
                                        alt="base64ImagePreview"
                                        src={
                                            "data:image/png;base64," +
                                            base64Preview
                                        }
                                    />
                                </ImageDisplay>
                            )}
                        </Flex.Item>
                    </Flex.Container>
                </div>
            </ModalAlt>
            <ModalAlt
                isOpen={toUpload}
                innerTimes={false}
                onClose={() => setToUpload(null)}
                width={["100%", "600px", "600px"]}
                overflowx="hidden"
                overflowy="auto"
                xxsp="0 1rem 2rem "
                xsp="0 1.8rem 2rem "
                stp="0  2rem 2.5rem"
                xlp="0 2rem 2.5rem "
            >
                <Flex.Container justify="center">
                    <Flex.Item xxs="100%" xs="100%" st="100%">
                        <Space xxsm={["0 0 .5rem"]} stm={["0 0 1rem"]}>
                            <H4 align="center">Are you sure to upload?</H4>
                        </Space>
                    </Flex.Item>
                    <Space
                        xxsm={["0 0 1rem"]}
                        xsm={["0 2rem 0 0"]}
                        stm={["0 3rem 0 0"]}
                    >
                        <Flex.Item xxs="100%" xs="40%" st="20%">
                            <Button
                                fullWidth
                                size="s"
                                backgroundColor="green"
                                color="white"
                                onClick={handleUploadProposalFile}
                                disabled={uploadLoading}
                                isLoading={uploadLoading}
                            >
                                Yes
                            </Button>
                        </Flex.Item>
                    </Space>
                    <Flex.Item xxs="100%" xs="40%" st="20%">
                        <Button
                            fullWidth
                            size="s"
                            backgroundColor="lightGrey"
                            color="black"
                            onClick={() => {
                                setToUpload(null);
                            }}
                        >
                            No
                        </Button>
                    </Flex.Item>
                </Flex.Container>
            </ModalAlt>
        </>
    );
};

const FinalBillingDate = ({
    label,
    onChange,
    value,
    maxDate,
    minDate,
    error,
    disabled,
}) => {
    return (
        <Space lgm={[" 0 0 25px "]} tm={["0 0 20px"]} xxsm={["0 0 10px"]}>
            <Flex.Container justify="space-between" align="center">
                <Flex.Item xxs="100%" st="100%" t="40%" lg="35%">
                    <P color="black" xxs="s" st="m" lg="ml">
                        {label}
                    </P>
                </Flex.Item>
                <Flex.Item xxs="100%" st="100%" t="50%" lg="60%">
                    <PickerWrap>
                        <DatePicker
                            fontFamily="Montserrat"
                            name="date"
                            onChange={onChange}
                            backgroundColor="white"
                            value={value}
                            shadow={false}
                            minDate={minDate}
                            maxDate={maxDate}
                            padding="5px 10px 5px"
                            errorMessage={error}
                            disabled={disabled}
                        />
                    </PickerWrap>
                </Flex.Item>
            </Flex.Container>
        </Space>
    );
};

export default AdminSurvey;

const ImageDisplay = styled.div`
    padding: 0.5rem;
    background-color: ${(props) => props.theme.color.white};
    display: flex;
    justify-content: center;
    position: relative;
    .downloadImage {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        padding: 0;
        line-height: 1;
        font-size: 3rem;
    }
`;

const PickerWrap = styled.div`
    width: 100%;
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        width: 400px;
    }
`;

const SurveyError = ({ error }) => {
    return (
        <Flex.Container justify="center" style={{ minHeight: "30vh" }}>
            <Flex.Item xxs="100%" st="60%" xl="50%">
                <AlertNew
                    backgroundColor="red200"
                    borderColor="error"
                    color="error"
                >
                    {error}
                </AlertNew>
            </Flex.Item>
        </Flex.Container>
    );
};
