import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import Alert from "../alert/Alert";
import Button from "../button/Button";

import { Flex, Section } from "../styles/Elements.styles";
import { P } from "../styles/Typography.styles";

const AlertIcon = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.theme.fontSize.default.xml};
    width: 100px;
    height: 100px;
    border-radius: 100%;
    color: ${(props) => props.theme.color.error};
    opacity: 0.8;
`;

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
        <Section>
            <Flex.Container
                justify="center"
                align="center"
                style={{
                    height: "100%",
                    flexDirection: "column",
                }}
            >
                <Alert
                    margin="0px 0 20px 0"
                    backgroundColor="error"
                    message={error?.message}
                    width="80%"
                />

                <AlertIcon>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                </AlertIcon>
                <P color="black" xxs="xl" st="xl">
                    We are sorry!
                </P>
                <div>
                    <Button
                        fontSize="s"
                        color="white"
                        backgroundColor="primary"
                        borderRadius="50px"
                        type="solid"
                        style={{ marginTop: "20px", height: "auto" }}
                        onClick={resetErrorBoundary}
                    >
                        Try Again
                    </Button>
                </div>
            </Flex.Container>
        </Section>
    );
};

export default ErrorFallback;
