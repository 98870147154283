import styled from "styled-components";

export default styled.div`
    background: ${(props) => props.gradient || "rgba(0, 0, 0, 0.8)"};
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;
