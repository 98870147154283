export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";
export const UPDATE_HELPER_SCREEN = "UPDATE_HELPER_SCREEN";
export const SET_ERROR = "SET_ERROR";
export const RESET_ERROR = "RESET_ERROR";
export const SET_LOADER = "SET_LOADER";
export const RESET_LOADER = "RESET_LOADER";
export const ENABLE_VIDEO_GUIDE = "ENABLE_VIDEO_GUIDE";
export const DISABLE_VIDEO_GUIDE = "DISABLE_VIDEO_GUIDE";
export const DISABLE_VIDEO_LOCAL_GUIDE = "DISABLE_VIDEO_LOCAL_GUIDE";
export const UPDATE_REQUEST_MODAL = "UPDATE_REQUEST_MODAL";
export const RESET_REQUEST_MODAL = "RESET_REQUEST_MODAL";

export const UPDATE_QUICK_USER = "UPDATE_QUICK_USER";
export const REMOVE_QUICK_USER = "REMOVE_QUICK_USER";
export const SHOW_LOGIN_FORM = "SHOW_LOGIN_FORM";
export const CLOSE_LOGIN_FORM = "CLOSE_LOGIN_FORM";

export const SHOW_SIGNUP_FORM = "SHOW_SIGNUP_FORM";
export const CLOSE_SIGNUP_FORM = "CLOSE_SIGNUP_FORM";

export const SET_VIRTUAL_DATA = "SET_VIRTUAL_DATA";

export const SET_REDIRECT_PATH = "SET_REDIRECT_PATH";
export const REMOVE_REDIRECT_PATH = "REMOVE_REDIRECT_PATH";
