import PropTypes from "prop-types";
import React from "react";
import cn from "../../lib/cn";
const baseStyles =
    "text-primary font-semibold text-5xl sm:text-5.6xl xl:text-8.0xl 2xl:text-8.8xl 3xl:text-9xl";

const H1 = ({ className = "", children, ...rest }) => {
    return (
        <h1 {...rest} className={cn(baseStyles, className)}>
            {children}
        </h1>
    );
};

export default H1;

H1.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
