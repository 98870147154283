import React, { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { emailIcon, phoneIcon } from "../../resources/js/icons";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import ReusableMap from "../../shared/ui/Map";

export const MapContactUs = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const Maps = [
        {
            id: 1,
            address:
                "Hypersrot Technologies, Office No 6, 3rd Floor , Tulsi Green Balewadi High Street Road, Laxman Nagar, Pune, Maharashtra, 411045",
            title: "Pune",
            phoneNumber: "9970079570",
            email: "marketing@hypersrot.com",
            center: {
                lat: 18.56954272860635,
                lng: 73.77028281208628,
            },
        },
        {
            id: 2,
            address:
                "Hypersrot Technologies Private Limited, 1207/343/95H, HSR MAIN ROAD, 7TH SECTOR, HSR BANGALORE 560102",
            title: "Bangalore",
            phoneNumber: "9970079570",
            email: "marketing@hypersrot.com",
            center: {
                lat: 12.909707872352472,
                lng: 77.63528943692823,
            },
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const previousSlide = () => {
        if (currentSlide === 0) {
            setCurrentSlide(Maps.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const nextSlide = () => {
        if (currentSlide === Maps.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const AddressCard = ({ title, address, phoneNumber, email }) => (
        <div className="flex flex-col gap-3.2">
            <div className="flex flex-col gap-1.6">
                <H4 className={"text-base text-primary lg:text-2.8xl "}>
                    {title}
                </H4>
                <p className="text-xs md:text-sm lg:text-base text-secondary">
                    {address}
                </p>
            </div>
            <div className="flex flex-row md:flex-col gap-0.8">
                <div className="flex gap-1">
                    <img className="h-2" src={phoneIcon} alt={"Phone number"} />
                    <P
                        className={
                            "lg:underline text-xs text-secondary  lg:text-lg"
                        }
                    >
                        +91 {phoneNumber}
                    </P>
                </div>
                <div className="flex gap-1">
                    <img className="h-2" src={emailIcon} alt={"Email"} />
                    <P
                        className={
                            "lg:underline text-xs text-secondary lg:text-lg"
                        }
                    >
                        {email}
                    </P>
                </div>
            </div>
        </div>
    );

    return (
        <Section>
            <Container>
                <div className="flex flex-col-reverse md:flex-row gap-9 justify-start">
                    <div className="md:w-[70%]">
                        <CarouselComponent
                            selectedItem={currentSlide}
                            onChange={setCurrentSlide}
                            showArrows={false}
                            showStatus={false}
                            showIndicators={false}
                            showThumbs={false}
                            interval={3000}
                            swipeable={true}
                            preventMovementUntilSwipeScrollTolerance={true}
                            swipeScrollTolerance={50}
                            emulateTouch={true}
                        >
                            {Maps.map((map, index) => (
                                <ReusableMap
                                    key={index}
                                    address={map.address}
                                    callback={() => setIsLoaded(true)}
                                    center={map.center}
                                />
                            ))}
                        </CarouselComponent>
                    </div>
                    <div className="flex flex-col gap-2.5">
                        <H2>Our Addresses</H2>
                        {Maps.map((map, index) => (
                            <AddressCard
                                key={index}
                                title={map.title}
                                address={map.address}
                                phoneNumber={map.phoneNumber}
                                email={map.email}
                            />
                        ))}
                        <div
                            className={`flex justify-end md:justify-start gap-1 w-full items-center mt-2`}
                        >
                            <button
                                onClick={previousSlide}
                                className="text-xl xl:text-3xl 2xl:text-5xl"
                            >
                                <IoIosArrowBack />
                            </button>
                            <p>
                                <span className="text-lg xl:text-3xl 2xl:text-5xl">
                                    {currentSlide + 1 > 9
                                        ? currentSlide + 1
                                        : `0${currentSlide + 1}`}
                                </span>{" "}
                                <span className="text-sm xl:text-base 2xl:text-lg">
                                    /{" "}
                                    {Maps.length > 9
                                        ? Maps.length
                                        : `0${Maps.length}`}
                                </span>
                            </p>
                            <button
                                onClick={nextSlide}
                                className="text-xl xl:text-3xl 2xl:text-5xl"
                            >
                                <IoIosArrowForward />
                            </button>
                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    );
};
