import React, { useState } from "react";
import cn from "../../lib/cn";
import P from "../typography/P";

const SelectDropdown = ({
    selected,
    onSelect,
    icon,
    data,
    wrapperClass = "bg-blue-300 border-blue-400",
    disabled = false,
}) => {
    const [showDrop, setShowDrop] = useState(false);

    const handleSelect = (value) => {
        toggleDropdown();
        onSelect(value);
    };

    const toggleDropdown = () => {
        setShowDrop(false);
    };

    return (
        <div
            className={`relative p-1 ${
                disabled ? "bg-gray" : "bg-white border border-blue-400"
            } hover:cursor-pointer z-50`}
            role="button"
            onClick={() => {
                setShowDrop(!showDrop);
            }}
        >
            <div className="flex justify-between gap-1 items-center">
                <P className="flex-1">{selected}</P>
                {!disabled && <div className="text-xl">{icon}</div>}
            </div>

            {!disabled && (
                <div
                    className={cn(
                        "options absolute start-0 end-0 top-[45px] z-30 shadow-md transition-all duration-300",
                        wrapperClass,
                        {
                            "max-h-[500px] opacity-100 overflow-auto": showDrop,
                            "max-h-0 opacity-0 overflow-hidden": !showDrop,
                        }
                    )}
                >
                    {data.map((item, i) => (
                        <p
                            key={i}
                            role="button"
                            onClick={() => handleSelect(item)}
                            className="px-1 py-0.5 border-b text-lsm hover:bg-gray-150 transition-all duration-200 hover:cursor-pointer"
                        >
                            {item}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SelectDropdown;
