import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Loading from "../loading/Loading";
import { Flex } from "../styles/Elements.styles";
import { P } from "../styles/Typography.styles";
import { Cell, TableWrapper } from "./Table";

const Tables = ({
    tableHeader,
    mobileTableHeader,
    data,
    cellStyles,
    cellMobileStyles,
    isLoading,
}) => {
    const { width } = useWindowDimensions();
    const isLarge = width > 940;
    let headerData = isLarge
        ? tableHeader
        : mobileTableHeader
        ? mobileTableHeader
        : tableHeader;
    let rowData = isLarge ? data.large : data?.small ? data?.small : data.large;
    let columnStyles = isLarge ? cellStyles : cellMobileStyles;
    return (
        <div>
            <TableWrapper>
                <table>
                    <thead>
                        <tr>
                            {headerData.map((item) => (
                                <th key={item.id} style={{ ...columnStyles }}>
                                    <P
                                        xxs="xs"
                                        st="s"
                                        lg="m"
                                        color="white"
                                        size="s"
                                    >
                                        {item.name}
                                    </P>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {rowData.map((item) => (
                            <RenderTableRow
                                key={data.id}
                                data={item}
                                width={width}
                                cellStyles={columnStyles}
                            />
                        ))}
                    </tbody>
                </table>
                {isLoading ? (
                    <Flex.Container justify="center" align="center">
                        <Flex.Item>
                            <P style={{ fontSize: "7rem" }}>
                                <Loading />
                            </P>
                        </Flex.Item>
                    </Flex.Container>
                ) : null}
            </TableWrapper>
        </div>
    );
};

export default Tables;

const RenderTableRow = ({
    data,
    cellStyles = {},
    cellBg = "grey200",
    borderColor = "grey400",
}) => {
    const [isExpand, setIsExpand] = useState(false);

    const getData = () => {
        let dataToBeRendered = [];
        Object.keys(data).forEach((key) => {
            const dataKey = data[key];
            if (typeof dataKey === "object" && key !== "expandable") {
                let Component = null;
                if (dataKey?.component) {
                    Component = dataKey?.component;
                }
                dataToBeRendered.push(
                    <Cell
                        borderColor={borderColor}
                        bg={cellBg}
                        style={{
                            ...cellStyles,
                        }}
                    >
                        {Component ? (
                            <Component />
                        ) : dataKey?.value ? (
                            <P xxs="xs" st="s" lg="m">
                                {dataKey?.value}
                            </P>
                        ) : (
                            ""
                        )}
                    </Cell>
                );
            }
        });

        return dataToBeRendered;
    };

    let Component = data?.expandable?.component;
    return (
        <>
            <tr
                style={{ borderBottom: "grey" }}
                onClick={() => setIsExpand((x) => !x)}
            >
                {getData()}
            </tr>
            <AnimatePresence>
                {isExpand && data?.expandable?.component && (
                    <tr>
                        <Cell
                            as={motion.td}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            borderColor={borderColor}
                            bg="white"
                            style={{ ...cellStyles }}
                            colSpan="100%"
                        >
                            <Component />
                        </Cell>
                    </tr>
                )}
            </AnimatePresence>
        </>
    );
};
