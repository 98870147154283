import React from "react";
import DropDown from "../../components/dropdown/DropDown";
import Input from "../../components/input/Input";
import Space from "../../components/space/Space";
import { P } from "../../components/styles/Typography.styles";

const TopLevelInput = ({
    title,
    data,
    isSelect,
    selected,
    onChange,
    ...rest
}) => {
    return (
        <Space xxsm={["0 0 1.5rem"]} stm={["0 0 1.8rem"]} lgm={["0 0 3rem"]}>
            <div>
                <Space
                    xxsm={["0 0 1rem"]}
                    stm={["0 0 1.2rem"]}
                    lgm={["0 0 1.3rem"]}
                >
                    <P st="m" xlg="m">
                        {title}
                    </P>
                </Space>

                {isSelect ? (
                    <DropDown
                        data={data}
                        onChange={(value) => onChange(value)}
                        selected={selected}
                        {...rest}
                    />
                ) : (
                    <Input
                        onChange={onChange}
                        backgroundColor="white"
                        shadow={false}
                        {...rest}
                    />
                )}
            </div>
        </Space>
    );
};

export default TopLevelInput;
