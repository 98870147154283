import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { logoDark } from "../../assets";

import { SHOW_LOGIN_FORM } from "../../constants/contexConstant";
import { burgerNavLinks, LANDING, PROFILE } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";

import auth from "../../services/auth";
import Image from "../image/Image";

import { getNameAvatar } from "../../helpers/helpers";
import Button from "../button/Button";
import DropDown from "../dropdown/DropDown";
import { P } from "../styles/Typography.styles";
import Text from "../text/Text";
import { AdminLinks, DROP_DOWN_LINKS } from "./Header";

const Overlay = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    display: ${(props) => (props.show ? "block" : "none")};
`;

const StyledMenu = styled.nav`
    background: ${(props) => props.theme.color.black800};
    transform: ${({ isBurgerMenuOpen }) =>
        isBurgerMenuOpen ? "translateX(0)" : "translateX(100%)"};
    height: 100vh;
    width: 90vw;
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        width: 80vw;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        width: 50vw;
    }
`;

const LogoImage = styled(Image)`
    margin-left: ${(props) => props.theme.sizes.xl};
    margin-top: ${(props) => props.theme.sizes.xl};
    height: 30px;
    width: auto;
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        height: 30px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        height: 35px;
    }
`;

const MenuLinkComp = styled.div`
    margin: 20px 0;
`;

const LinkDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: ${(props) => props.theme.sizes.xl3};
`;
const MenuDiv = styled.div`
    margin-top: 55px;
    padding: 0px 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: ${(props) => props.theme.sizes.xl4};
`;
const AvatarBox = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: ${(props) => props.theme.sizes.xl4};
`;
const AvatarIn = styled.div`
    padding: 5px;
`;
const DropDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: ${(props) => props.theme.sizes.l};
`;

const Avatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: ${(props) => props.size[0]};
    height: ${(props) => props.size[0]};
    border: 2px solid ${(props) => props.theme.color.orange};
    box-sizing: border-box;
    color: ${(props) => props.theme.color.orange};
    border-radius: 100%;
    background-color: ${(props) =>
        props.backgroundColor ? props.theme.color[props.backgroundColor] : ""};
    margin-top: ${(props) => props.top || ""};
    margin-bottom: ${(props) => props.bottom || ""};
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        width: ${(props) => props.size[1]};
        height: ${(props) => props.size[1]};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        width: ${(props) => props.size[2]};
        height: ${(props) => props.size[2]};
    }
`;

const NavLink = styled(Link)`
    color: ${(props) =>
        props.active
            ? props.theme.color.lightPrimary
            : props.theme.color.white};
    text-decoration: none;
    transition: all ease 0.3s;
    font-weight: ${(props) => props.theme.fontWeight.rg};
    line-height: ${(props) => props.lineHeight || "1.6em"};
    font-size: 14px;
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: 16px;
    }

    :hover {
        color: ${(props) =>
            props.active === "true"
                ? props.theme.color.lightPrimary
                : props.theme.color.white};
    }
`;
export const UserAvatar = ({
    backgroundColor = "grey",
    fontSize = ["m", "l", "xxl"],
    size = ["38px", "48px", "55px"],
    weight = "sb",
}) => {
    const { state } = useContextState();
    return (
        <Avatar size={size} backgroundColor={backgroundColor}>
            <P color="white" weight={weight} xxs={fontSize[0]} st={fontSize[1]}>
                {getNameAvatar(state?.user?.name)}
            </P>
        </Avatar>
    );
};
const MenuLink = ({ name, link, pathname, toggleBurgerMenu }) => (
    <MenuLinkComp>
        <div
            onClick={() => {
                toggleBurgerMenu(false);
            }}
            style={{ display: "inline-block" }}
        >
            <NavLink active={`${pathname === link}`} to={link}>
                {name}
            </NavLink>
        </div>
    </MenuLinkComp>
);

const BurgerMenu = ({
    links,
    isBurgerMenuOpen,
    toggleBurgerMenu,
    location,
}) => {
    const history = useHistory();

    const handleLoginForm = (e) => {
        e.preventDefault();
        dispatch({ type: SHOW_LOGIN_FORM });
        toggleBurgerMenu(false);
    };
    const { state, dispatch } = useContextState();

    const handleAvatarClick = (e) => {
        e.preventDefault();
        history.push(PROFILE);
        toggleBurgerMenu(false);
    };
    const handleLogout = (e) => {
        e.preventDefault();

        auth.logout(dispatch)
            .then(() => {
                history.push(LANDING);
            })
            .catch(() => {});
    };

    return (
        <React.Fragment>
            <Overlay
                show={isBurgerMenuOpen}
                onClick={() => toggleBurgerMenu(!isBurgerMenuOpen)}
            />
            <StyledMenu isBurgerMenuOpen={isBurgerMenuOpen}>
                <Link to={LANDING}>
                    <LogoImage
                        onClick={() => toggleBurgerMenu(false)}
                        src={logoDark}
                        width="100%"
                    />
                </Link>

                <MenuDiv>
                    {state?.user && (
                        <AvatarBox
                            role="button"
                            tabIndex="0"
                            onClick={handleAvatarClick}
                        >
                            <AvatarIn>
                                <UserAvatar backgroundColor="primary" />
                            </AvatarIn>
                            <Text
                                color="white"
                                size="l"
                                style={{ marginLeft: "3px" }}
                            >
                                {state?.user?.name}
                            </Text>
                        </AvatarBox>
                    )}

                    <div style={{ margin: "5px 0" }}>
                        {!state?.user ? (
                            <LinkDiv>
                                {burgerNavLinks.map((menuLink, i) => (
                                    <MenuLink
                                        key={i}
                                        name={menuLink.name}
                                        link={menuLink.path}
                                        pathname={location.pathname}
                                        toggleBurgerMenu={toggleBurgerMenu}
                                    />
                                ))}
                            </LinkDiv>
                        ) : (
                            <LinkDiv>
                                {links.map((menuLink, i) => (
                                    <MenuLink
                                        key={i}
                                        name={menuLink.name}
                                        link={menuLink.path}
                                        pathname={location.pathname}
                                        toggleBurgerMenu={toggleBurgerMenu}
                                    />
                                ))}
                            </LinkDiv>
                        )}
                    </div>
                    {state?.user?.roles &&
                        state.user.roles.some(
                            (role) =>
                                role.name === "SUPER_ADMIN" ||
                                role.name === "SALES_MANAGER"
                        ) && (
                            <DropDown
                                data={DROP_DOWN_LINKS}
                                onChange={(value) => {
                                    toggleBurgerMenu(false);
                                    history.push(`${value.path}`);
                                }}
                                backgroundColor="black800"
                                color={"lightPrimary"}
                                borderColor="black800"
                                selected={DROP_DOWN_LINKS[0]}
                                noPadding={true}
                                optionBg={"blue200"}
                                xxs={"s"}
                            />
                        )}

                    {state?.user?.roles &&
                        state.user.roles.some(
                            (role) => role.name === "SUPER_ADMIN"
                        ) && (
                            <DropDiv>
                                <DropDown
                                    data={AdminLinks}
                                    onChange={(value) => {
                                        toggleBurgerMenu(false);
                                        history.push(`${value.path}`);
                                    }}
                                    backgroundColor="black800"
                                    borderColor="black800"
                                    color={"lightPrimary"}
                                    selected={AdminLinks[0]}
                                    noPadding={true}
                                    optionBg={"blue200"}
                                    xxs={"s"}
                                />
                            </DropDiv>
                        )}

                    <DropDiv>
                        <Button
                            type="solid"
                            color="white"
                            backgroundColor="primary"
                            size="s"
                            style={{ width: "100%", border: "0" }}
                            onClick={
                                state?.user ? handleLogout : handleLoginForm
                            }
                        >
                            {state?.user ? "Logout" : " LogIn"}
                        </Button>
                    </DropDiv>
                </MenuDiv>
            </StyledMenu>
        </React.Fragment>
    );
};

export default withRouter(BurgerMenu);
