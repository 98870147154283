import React from "react";
import { FiImage, FiUpload } from "react-icons/fi";
import styled from "styled-components";
import theme from "../../theme";
import { P } from "../styles/Typography.styles";
import { InlineText } from "../text/HeadingText";

const InputWarper = styled.div`
    input {
        display: none;
    }
    .uploadBox {
        margin-right: 2rem;
        margin-bottom: 1rem;
        &:hover {
            cursor: ${(props) => (props.active ? "pointer" : "default")};
        }
        .bottomLabel {
            white-space: nowrap;
            overflow: hidden;
            width: 60px;
            text-overflow: ellipsis;
        }
        label {
            width: 60px;
            height: 60px;
            background-color: ${(props) => props.theme.color.grey400};
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                font-size: 45px;
            }
            :hover {
                cursor: ${(props) => (props.active ? "pointer" : "default")};
                box-shadow: ${(props) =>
                    props.active ? "0 0 2px rgba(0, 0, 0, 0.33)" : "none"};
            }
        }
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        .uploadBox {
            .bottomLabel {
                width: 70px;
            }
            label {
                width: 70px;
                height: 70px;
                svg {
                    font-size: 50px;
                }
            }
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        .uploadBox {
            .bottomLabel {
                width: 80px;
            }
            label {
                width: 80px;
                height: 80px;
                svg {
                    font-size: 60px;
                }
            }
        }
    }
`;

const UploadImage = ({
    name,
    onChange,
    media,
    errorMessage = "",
    onClick,
    label,
    bottomLabel,
    component: Component = FiUpload,
}) => {
    return (
        <div
            style={{
                display: "inline-block",
            }}
        >
            <InputWarper active={true}>
                <div>
                    <input
                        onChange={onChange}
                        onClick={(e) => (e.target.value = null)}
                        name={name}
                        accept="*"
                        type="file"
                        id={name}
                    />
                    {!media && (
                        <div className="uploadBox">
                            <label htmlFor={name}>
                                <P size="s" color="darkGrey">
                                    {label}
                                </P>{" "}
                                <InlineText color="darkGrey">
                                    <Component style={{ display: "flex" }} />
                                </InlineText>
                            </label>
                            {bottomLabel && (
                                <P
                                    className="bottomLabel"
                                    xxs="s"
                                    st="s"
                                    align="center"
                                >
                                    {bottomLabel}
                                </P>
                            )}
                        </div>
                    )}
                </div>
            </InputWarper>
            {errorMessage && (
                <P color="error" style={{ marginTop: "5px" }} xxs="xs">
                    {errorMessage}
                </P>
            )}
        </div>
    );
};

export default UploadImage;

export const PlaceHolderPreview = ({
    bottomLabel,
    id,
    onPreview,
    component: Component,
}) => {
    return (
        <InputWarper
            title={onPreview !== null && "Click to preview"}
            disabled
            active={onPreview !== null}
        >
            <div>
                <div
                    className="uploadBox"
                    role="button"
                    onClick={() => (onPreview ? onPreview(id) : null)}
                >
                    <label>
                        {Component ? (
                            <Component />
                        ) : (
                            <FiImage
                                color={theme.color.lightGreen}
                                style={{ display: "flex" }}
                            />
                        )}
                    </label>

                    <P className="bottomLabel" xxs="s" st="s" align="center">
                        {bottomLabel}
                    </P>
                </div>
            </div>
        </InputWarper>
    );
};
