import React from "react";
import Space from "../../../../components/space/Space";
import Container from "../../../../components/styles/Container.styles";
import { Flex, Section } from "../../../../components/styles/Elements.styles";
import { H4 } from "../../../../components/styles/Typography.styles";

import { BsArrowLeft } from "react-icons/bs";
import { VscFilePdf } from "react-icons/vsc";
import Icon from "../../../../components/Icon/Icon";
import Link from "../../../../components/link/Link";
import Tables from "../../../../components/table/Tables";
import OnboardingContainer from "../../../onboarding/OnboardingContainer";
import FilterHeader from "../../dashboard/FilterHeader";
import { DetailsFiled } from "./DetailsField";
import { useHistory } from "react-router-dom";

const PaymentView = () => {
    const history = useHistory();
    return (
        <Section height="auto">
            <Container>
                <Space xxsm={["30px 0 0 0 "]}>
                    <Flex.Container
                        onClick={() => history.goBack()}
                        align="center"
                        style={{ cursor: "pointer" }}
                    >
                        <Space xxsm={["0 1rem 0 0"]} stm={["0 2rem 0 0"]}>
                            <Flex.Item>
                                <Icon component={BsArrowLeft} lg="l" />
                            </Flex.Item>
                        </Space>
                        <Flex.Item>
                            <H4>Payment Summary</H4>
                        </Flex.Item>
                    </Flex.Container>
                </Space>

                <FilterHeader
                    xxsm="30px 0 30px"
                    stm="30px 0 30px"
                    lgm="30px 0 60px"
                    title="Payment View: SN259"
                />

                <OnboardingContainer>
                    <Flex.Container justify="space-between">
                        {fields.map((field) => (
                            <Flex.Item
                                xl="48%"
                                t="100%"
                                xxs="100%"
                                key={field.name}
                            >
                                <DetailsFiled
                                    label={field.label}
                                    name={field.name}
                                    color="black"
                                    isDPD={field.isDPD}
                                    value={field.value}
                                />
                            </Flex.Item>
                        ))}
                    </Flex.Container>
                </OnboardingContainer>
                <Tables
                    mobileTableHeader={mobileTableHeader}
                    tableHeader={tableHeader}
                    data={data}
                    cellStyles={cellStyles}
                    cellMobileStyles={cellStyles}
                />
            </Container>
        </Section>
    );
};

export default PaymentView;

const tableHeader = [
    {
        id: 1,
        name: "Receipt Id",
    },
    {
        id: 2,
        name: "Due Date",
    },
    {
        id: 3,
        name: "Bill Amount",
    },

    {
        id: 4,
        name: "Status",
    },
    {
        id: 5,
        name: "Invoice",
    },
];
const mobileTableHeader = [
    {
        id: 1,
        name: "Receipt Id",
    },
    {
        id: 2,
        name: "Due Date",
    },

    {
        id: 3,
        name: "Bill Amount",
    },
    {
        id: 4,
        name: "Status",
    },
];

const cellStyles = {
    padding: "10px 0",
    borderLeft: "none",
    borderRight: "none",
};

const data = {
    large: [
        {
            index: 1,
            id: {
                
                component: () => (
                    <Link
                        color="primary"
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        45896
                    </Link>
                ),
            },
            date: {
                value: "20/04/2024",
            },

            bill: {
                value: "2,50,000/-",
            },
            status: {
                value: "Pending",
            },
            invoice: {
                value: <Icon component={VscFilePdf} lg="l" />,
            },
        },

        {
            index: 2,
            id: {
               
                component: () => (
                    <Link
                        color="primary"
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        58956
                    </Link>
                ),
            },
            date: {
                value: "25/03/2024",
            },

            bill: {
                value: "3,50,000/-",
            },
            status: {
                value: "Paid",
            },
            invoice: {
                value: <Icon component={VscFilePdf} lg="l" />,
            },
        },
        {
            index: 3,
            id: {
               
                component: () => (
                    <Link
                        color="primary"
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        85965
                    </Link>
                ),
            },
            date: {
                value: "25/03/2024",
            },

            bill: {
                value: "1,50,000/-",
            },
            status: {
                value: "Pending",
            },
            invoice: {
                value: <Icon component={VscFilePdf} lg="l" />,
            },
        },
    ],
    small: [
        {
            index: 1,
            id: {
                
                component: () => (
                    <>
                        <Link
                            color="primary"
                            isInteractive={true}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            45896
                        </Link>
                    </>
                ),
            },
            date: {
                value: "20/04/2024",
            },
            bill: {
                value: "2,50,000/-",
            },
            status: {
                value: "Pending",
            },
        },

        {
            index: 2,
            val: {
               
                component: () => (
                    <>
                        <Link
                            color="primary"
                            isInteractive={true}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            85965
                        </Link>
                    </>
                ),
            },

            date: {
                value: "25/05/2024",
            },
            bill: {
                value: "1,50,000/-",
            },
            status: {
                value: "Pending",
            },
        },
    ],
};

const fields = [
    { label: "Average Monthly Bill (Without Solar)", name: "name1" },
    { label: "Average Monthly Bill (With Solar)", name: "name2" },
    { label: "Monthly Units Consumed", name: "name3" },
    { label: "Savings on Monthly Bill", name: "name4" },
    { label: "Installed Capacity (KWp)", name: "name5" },
    { label: "Refundable Deposit  (Paid)", name: "name6" },
    { label: "Last 3 DPD", name: "name7", isDPD: true, value: "-10 -7 -5" },
    { label: "Solar Offering Term", name: "name8" },
];
