import { useEffect, useState } from "react";
import { REMOVE_USER } from "../constants/contexConstant";
import auth from "../services/auth";

const useAuth = (path, dispatch) => {
    const [isAuth, setIsAuth] = useState(null);
    useEffect(() => {
        auth.isUserAuthenticated()
            .then((isUserAuthenticated) => {
                setIsAuth(!!isUserAuthenticated);
            })
            .catch((err) => {
                setIsAuth(false);
                dispatch && dispatch({ type: REMOVE_USER, payload: null });
            });
    }, [setIsAuth, path, dispatch]);

    return { isAuth };
};

export default useAuth;
