import {
    agreement,
    card01,
    card02,
    card1,
    card2,
    card3,
    deal,
    delivery,
    gift,
    investment,
    key,
    paperCut,
    playerIcon,
    quotesBlue,
    quotesWhite,
    recognitionThumb,
    savings,
    solarPanel,
    speaker,
    survey,
    tools,
} from "../assets";
import {
    MOBILE_BREAKPOINT,
    SMALL_LAPTOP_BREAKPOINT,
    SMALL_MOBILE_BREAKPOINT,
    SMALL_TAB_BREAKPOINT,
    TAB_BREAKPOINT,
} from "../theme";
import { LANDING } from "./routes";

export const landing = {
    title: "GO SOLAR",
    subTitle: "with",
    introVideo: `https://www.youtube.com/embed/7C_DWN_GXS4`,
    inline: "with",
    typeWriter: "ZERO INVESTMENT",
    button: {
        title: "Know More",
        link: LANDING,
    },
};

export const momentum = {
    subHead: "Four Reasons to",
    head: "Choose Hypersrot",
    paragraph:
        "Choose Hypersrot for all of your solar needs. With our solar solutions, you can adopt solar in an easy monthly subscription plan with ZERO installation cost, no monthly maintenance hassles and you can save up to 50% of your existing monthly electricity bills.",
    subpara: "Sign up today for your free consultation !!!",
    card: [
        {
            id: 1,
            title: "Savings",
            subTitle: "Save Upto 50% on  Existing Bills",
            img: savings,
        },
        {
            id: 2,
            title: "Investment",
            subTitle: "Go Solar with ZERO  Upfront Investment",
            img: solarPanel,
        },
        {
            id: 3,
            title: "Ownership",
            subTitle: "Ownership Transfer After 10 Years",
            img: deal,
        },
        {
            id: 4,
            title: "Maintenance",
            subTitle: "FREE Maintenance For 10 Years",
            img: tools,
        },
    ],
};

export const getStartedData = {
    header: "Getting Started",
    leftSection: [
        {
            id: 1,
            title: "01 Sign up",
            subHead: "Fill onboarding form and book site inspection",
            img: key,
            width: "73px",
            isMonthly: true,
        },

        {
            id: 2,
            title: "02 Feasibility Check",
            subHead:
                "We will Evaluate solar capacity based on usage pattern and shade free roof area",
            img: survey,
            width: "73px",
            isMonthly: true,
        },
        {
            id: 3,
            title: "03 Offer",
            subHead:
                "We will Offer you plans with discounts on higher electricity slab rates",
            img: gift,
            width: "90px",
            isMonthly: true,
        },
    ],
    rightSection: [
        {
            id: 6,
            title: "04 Agreement",
            subHead: "Sign LOI and Power Purchase Agreement",
            img: agreement,
            width: "114px",
            isMonthly: true,
        },
        {
            id: 7,
            title: "05 Investment",
            subHead: "We will find investor to fund the solar plant",
            img: investment,
            width: "106px",
            isMonthly: true,
        },
        {
            id: 8,
            title: "06 Delivery",
            subHead:
                "After Installation of  Solar Plant Pay the  monthly discounted bill",
            img: delivery,
            width: "124px",
            isMonthly: true,
        },
    ],
};

export const recognition = {
    title: "Our Awards and Recognition",
    videoSrc: `https://www.youtube.com/embed/XcprRtUXI2k?autoplay=1`,
    video: {
        thumbnail: recognitionThumb,
        icon: speaker,
        playerIcon: playerIcon,
    },
    summary: {
        title: "Like the radiant sun, solar power illuminates the path to a brighter future, where clean energy transforms our world with brilliance and sustainability.",
        author: "Pravin, Founder & CEO",
        icon: quotesBlue,
    },

    slider: [
        {
            id: 1,
            title: "Solar power is empowering a sustainable revolution, one ray at a time.",
            author: "Pravin, Founder & CEO",
            icon: quotesWhite,
            image: paperCut,
        },
        // {
        //     id: 2,
        //     title: "Solar in India can work just as efficiently as anywhere else. The sun still shines!",
        //     author: "-Pravin Sambhaji Langote",
        //     icon: quotesWhite,
        //     image: paperCut,
        // },
        // {
        //     id: 3,
        //     title: "Solar in India can work just as efficiently as anywhere else. The sun still shines!",
        //     author: "-Pravin Sambhaji Langote",
        //     icon: quotesWhite,
        //     image: paperCut,
        // },
    ],
};

export const pastProjects = {
    title: "Our Past Projects",
    tags: [
        {
            id: 1,
            value: 20,
            unit: "MW",
            name: "Capacity Installed",
        },
        {
            id: 2,
            value: 20,
            unit: "",
            name: "Project Executed",
        },
        {
            id: 3,
            value: 4800,
            unit: "Tons",
            name: "Co2 Reduced Yearly",
        },
    ],
    cards: [
        {
            id: 1,
            img: card01,

            location: "Talegaon, Pune ",
            name: "Mohar Pratima",
            details: `Ground Mounted, Earthing, Evacuation Infra, Net metering`,
            tags: [
                {
                    id: 1,
                    value: "15",
                    unit: "KWp",
                    name: "Plant Size",
                },
                {
                    id: 2,
                    value: "Residencial",
                    unit: "",
                    name: "Connection Type",
                },
            ],
        },
        {
            id: 2,
            img: card02,

            location: "Pune",
            name: "Bora Agro Foods",
            details: `Roof mounted with elevated structure
            Mounting Structure, Evacuation Infra
            Net metering`,
            tags: [
                {
                    id: 1,
                    value: "200",
                    unit: "KWp",
                    name: "Plant Size",
                },
                {
                    id: 2,
                    value: "Industrial",
                    unit: "",
                    name: "Connection Type",
                },
            ],
        },
        {
            id: 3,
            img: card1,

            location: "Sanaswadi, Pune ",
            name: "Hirschvogel India Pvt Ltd",
            details: `Ground Mounted, Earthing, Evacuation Infra, Net metering`,
            tags: [
                {
                    id: 1,
                    value: "1200",
                    unit: "KWp",
                    name: "Plant Size",
                },
                {
                    id: 2,
                    value: "Industrial",
                    unit: "",
                    name: "Connection Type",
                },
            ],
        },
        {
            id: 4,
            img: card2,

            location: "Pune",
            name: "Bora Agro Foods",
            details: `Roof mounted with elevated structure
            Mounting Structure, Evacuation Infra
            Net metering`,
            tags: [
                {
                    id: 1,
                    value: "780",
                    unit: "KWp",
                    name: "Plant Size",
                },
                {
                    id: 2,
                    value: "Industrial",
                    unit: "",
                    name: "Connection Type",
                },
            ],
        },
        {
            id: 5,
            img: card3,

            location: "Pune",
            name: "Cosmos Bank",
            details: `Ground mounted, Roof mounted, Car port. Mounting Structure, Evacuation Infra Net metering`,
            tags: [
                {
                    id: 1,
                    value: "80",
                    unit: "KWp",
                    name: "Plant Size",
                },
                {
                    id: 2,
                    value: "Commercial",
                    unit: "",
                    name: "Connection Type",
                },
            ],
        },
    ],
};

export const savingsData = {
    title: "Estimate My Savings",
    connectionTypesData: [
        {
            id: 1,
            name: "",
            value: "",
        },
        {
            id: 1,
            name: "",
            value: "",
        },
        {
            id: 1,
            name: "",
            value: "",
        },
    ],
};

export const electricityConnectionType = [
    {
        id: 1,
        name: "Housing Society",
        value: 19.5,
    },
    {
        id: 2,
        name: "Commercial",
        value: 18,
    },
    {
        id: 3,
        name: "Industrial",
        value: 11,
    },
    {
        id: 4,
        name: "Residential",
        value: 19.5,
    },
];

export const projectsCarouselSettings = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    autoplay: true,
    responsive: [
        {
            breakpoint: SMALL_LAPTOP_BREAKPOINT,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2,
                autoplay: true,
                dots: false,
            },
        },
        {
            breakpoint: TAB_BREAKPOINT,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2,
                autoplay: true,
                dots: false,
            },
        },
        {
            breakpoint: SMALL_TAB_BREAKPOINT,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2,
                autoplay: true,
                dots: false,
            },
        },

        {
            breakpoint: MOBILE_BREAKPOINT,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                dots: false,
            },
        },

        {
            breakpoint: SMALL_MOBILE_BREAKPOINT,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                dots: false,
            },
        },
    ],
};
