import styled from "styled-components";

const Container = styled.div`
    max-width: 1300px;
    width: 100%;
    padding: 0 ${(props) => props.theme.sizes.xl3};
    margin: 0 auto;
    box-sizing: border-box;
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 0 ${(props) => props.theme.sizes.xl3};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
    }
`;

export default Container;
