import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import styled from "styled-components";
import Space from "../../components/space/Space";
import { Flex } from "../../components/styles/Elements.styles";
import { P } from "../../components/styles/Typography.styles";
import { ERROR_MSG } from "../../constants/common";
import { useContextState } from "../../context/ContextProvider";

const FlexCenter = styled(Flex.Item)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;

    background-size: cover;

    padding: 0.5rem;
    border-radius: 10px;
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        padding: 1rem;
    }
`;

const VirtualLoginSummary = ({ summaryErr = true, children }) => {
    const { state } = useContextState();

    return (
        <Flex.Container>
            <FlexCenter width="100%" tabletWidth="100%" mobileWidth="100%">
                <Space xxsm={["20px 0"]}>
                    <Flex.Container align="center">
                        <MessageIcon error={summaryErr}>
                            {summaryErr ? <FaTimes /> : <FaCheck />}
                        </MessageIcon>
                        <Flex.Item style={{ flex: "1" }}>
                            {summaryErr ? (
                                <P
                                    align="left"
                                    xxs="m"
                                    st="m"
                                    weight="700"
                                    color="white"
                                >
                                    {ERROR_MSG}
                                </P>
                            ) : (
                                <>{children}</>
                            )}
                        </Flex.Item>
                    </Flex.Container>
                </Space>
            </FlexCenter>
        </Flex.Container>
    );
};

export default VirtualLoginSummary;

const MessageIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    font-size: 3rem;
    box-sizing: border-box;
    padding: 10px;
    color: ${(props) =>
        props.error ? props.theme.color.error : props.theme.color.lightPrimary};
    border-radius: 100px;
    border: 5px solid
        ${(props) =>
            props.error
                ? props.theme.color.error
                : props.theme.color.lightPrimary};
    margin-right: 10px;

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        width: 80px;
        height: 80px;
        font-size: 4rem;
    }

    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        width: 100px;
        height: 100px;
        font-size: 5rem;
    }
`;
