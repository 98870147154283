import React from "react";
import styled from "styled-components";

import { Flex } from "../styles/Elements.styles";
import { P } from "../styles/Typography.styles";

const InputComponent = styled.input`
    width: 18px;
    height: 18px;
    border-radius: 0.15em;
    margin-right: 0.5em;

    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    background-color: ${(props) =>
        props.backgroundColor
            ? props.backgroundColor
            : props.theme.color.white};
    &.checked {
        content: "";
        position: relative;
        background-color: ${(props) =>
            props.backgroundColor
                ? props.backgroundColor
                : props.theme.color.white};
    }

    &.checked::before {
        content: "\u{2713}";
        color: ${(props) =>
            props.tickColor
                ? props.theme.color[props.tickColor]
                : props.theme.color.black};
        font-size: 1.6rem;
        position: absolute;
        right: 1px;
        top: -3px;
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        &.checked::before {
            font-size: ${(props) =>
                props.size
                    ? props.theme.fontSize.smallMobile[props.size]
                    : props.theme.fontSize.smallMobile.l};
        }
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        &.checked::before {
            font-size: ${(props) =>
                props.size
                    ? props.theme.fontSize.smallTablet[props.size]
                    : props.theme.fontSize.smallTablet.l};
        }
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        &.checked::before {
            font-size: ${(props) =>
                props.size
                    ? props.theme.fontSize.tablet[props.size]
                    : props.theme.fontSize.tablet.l};
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        &.checked::before {
            font-size: ${(props) =>
                props.size
                    ? props.theme.fontSize.large[props.size]
                    : props.theme.fontSize.large.l};
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        &.checked::before {
            font-size: ${(props) =>
                props.size
                    ? props.theme.fontSize.laptop[props.size]
                    : props.theme.fontSize.laptop.l};
        }
    }
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
`;

export const CheckboxInput = (props) => {
    return (
        <Flex.Container align="center">
            <InputComponent type="checkbox" {...props} />
            <P
                size={props.size}
                weight={props.weight}
                color={props.color}
                style={{ paddingLeft: "5px", marginRight: "1rem" }}
            >
                {props.label}
            </P>
        </Flex.Container>
    );
};
