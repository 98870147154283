import React from "react";
import cn from "../../lib/cn";
import { dummyAvatar } from "../../resources/js/images";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import { Carousel } from "../../shared/ui/Carousel";
import ListItem from "./ListItem";

const SubsidyWithReview = ({ data, onClick }) => {
    const renderCustomerReviews = () => {
        return [...Array(5)].map((item) => (
            <div key={item}>
                <H4 className="relative mb-2.8 mt-3.2 sm:mb-3.2 sm:mt-4 xl:mb-5.2  xl:mt-5 ">
                    <span className="block absolute top-[-15px]">"</span>
                    Discover How Our Customers Are Benefiting from Solar and
                    Subsidies
                    <span className="block absolute bottom-[-25px]">"</span>
                </H4>

                <div>
                    <div className="flex gap-2 items-center mb-2.4">
                        <div className="">
                            <img
                                className=" rounded-full h-[60px] w-[60px] sm:h-[80px] sm:w-[80px]  xl:h-[100px] xl:w-[100px]"
                                src={dummyAvatar}
                                alt="user_reviews"
                            />
                        </div>
                        <div>
                            <P className="font-semibold">Aadhya Shree</P>
                            <P className=" xl:text-base text-secondary">
                                Designers
                            </P>
                        </div>
                    </div>

                    <div className="w-full flex flex-wrap gap-0.5 sm:gap-0.8 xl:gap-1 mb-0.8 sm:mb-1.2  xl:mb-1.6">
                        <Tag variant="green" name="Durability" />
                        <Tag variant="orange" name="Cost Effective" />
                    </div>
                    <P className="text-secondary">
                        Switching to solar has been one of the best choices
                        we’ve made. We’re extremely satisfied with the results.
                    </P>
                </div>
            </div>
        ));
    };

    return (
        <div className="w-full">
            <div>
                {data && data.length ? (
                    <>
                        <P className="mb-1.2 xl:mb-1.6 font-medium">
                            {data[0].title}
                        </P>
                        <div className="mb-2.8 lg:mb-3.2 xl:mb-4 w-full xl:w-[60%]">
                            {data[0].list.map((item, i) => (
                                <div className="mb-1.2" key={i}>
                                    <ListItem value={item} />
                                </div>
                            ))}
                        </div>
                    </>
                ) : null}

                <div className="mb-2.8 lg:mb-3.2 xl:mb-4 w-full xl:w-[60%]">
                    <Carousel
                        slides={renderCustomerReviews()}
                        headclass="h-full"
                        autoSlide={true}
                        flexPos="justify-center lg:justify-start"
                        autoSlideInterval={3000}
                    />
                </div>
            </div>
            <Button onClick={onClick}>Got an enquiry?</Button>
        </div>
    );
};
const wrapperStyles = {
    green: "bg-green  text-green  border-green",
    orange: "bg-orange text-orange  border-orange",
};
const textStyles = {
    orange: "text-orange",
    green: "text-green",
};
const baseStyles =
    "rounded-full inline-block px-0.8 py-0.4  rounded-full border bg-opacity-10";
const Tag = ({ name, variant = "green" }) => {
    return (
        <div className={cn(baseStyles, wrapperStyles[variant])}>
            <P
                className={cn(
                    "sm:text-sm xl:text-base 2xl:text-base",
                    textStyles[variant]
                )}
            >
                {name}
            </P>
        </div>
    );
};

export default SubsidyWithReview;
