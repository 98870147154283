import { useEffect, useState } from "react";

const useHeaderScroll = () => {
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.pageYOffset;
            const scrollDown = currentScrollTop > lastScrollTop;

            setVisible(!(scrollDown && currentScrollTop > 50));
            setLastScrollTop(currentScrollTop);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [lastScrollTop]);

    return { visible };
};

export default useHeaderScroll;
