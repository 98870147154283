/* eslint-disable no-useless-escape */
const phoneRegEx = /^\d{10}$/;
const emailRegEx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const passRegEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const pinRegEx = /^([1-9]){1}[0-9]{5}?$/;
const aadharRegEx = /^([0-9]){12}?$/;
const panRegEx = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
const bankAccRegEx = /^[0-9]{9,18}$/;
const nameRegEx = /^[a-zA-Z][a-zA-Z0-9 ]*$/;
const IFSCRegEx = /^[A-Z]{4}0[A-Z0-9]{6}$/;
const decimalNumberRegEx = /^\d*\.?\d*$/;
const letterNumberRegEx = /^[a-zA-Z0-9]{2,}$/;
const addressRegEx = /^([a-zA-Z0-9])([a-zA-Z0-9,-]){2,}$/;
export {
    IFSCRegEx,
    aadharRegEx,
    addressRegEx,
    bankAccRegEx,
    decimalNumberRegEx,
    emailRegEx,
    letterNumberRegEx,
    nameRegEx,
    panRegEx,
    passRegEX,
    phoneRegEx,
    pinRegEx,
};
