import React from "react";

import { motion } from "framer-motion";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
    internalTrade,
    msme,
    socialAlpha,
    startupIndia,
    unveilingInvestor,
} from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import {
    container,
    opacity,
    slideOpacityLeft,
    slideOpacityRight,
    slideOpacityUp,
} from "../../shared/animation/constants/landingAnimation";
import H2 from "../../shared/typography/H2";
import H3 from "../../shared/typography/H3";
import Button from "../../shared/ui/Button";

const Recognition = () => {
    const { width } = useWindowDimensions();
    return (
        <Section className="bg-linear-three">
            <Container className=" py-1.8 sm:py-2.4 xl:py-8">
                <div
                    style={{
                        backgroundImage:
                            width >= 1024 && `url(${unveilingInvestor})`,
                    }}
                    className="grid grid-cols-6  w-full lg:h-[300px]  xl:h-[350px]  pl-0 py-0  lg:pl-9.6 lg:py-5.6 rounded-1.5  bg-no-repeat bg-cover  lg:bg-[center_center] xl:bg-center items-center"
                >
                    <div className="col-span-6  lg:col-span-3 ">
                        <div className="flex justify-between lg:justify-normal  items-center gap-3.2 xl:gap-6.4 2xl:gap-8 mb-1.6">
                            <H2 className="font-semibold xl:text-5xl 2xl:text-7xl ">
                                Incubated <br />
                                And Funded By
                            </H2>
                            <div className=" w-auto lg:flex-1">
                                <div className="h-[38px] sm:h-[67px] lg:my-1.6">
                                    <img
                                        className="h-full w-auto"
                                        src={socialAlpha}
                                        alt="social_alpha_logo"
                                    />
                                </div>
                                <Button
                                    onClick={() =>
                                        window.open(
                                            "https://www.socialalpha.org/our-portfolio/",
                                            "_blank"
                                        )
                                    }
                                >
                                    Learn more
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={container}
                    className=" mt-2.2 lg:mt-3 xl:mt-3  "
                >
                    <div className="flex flex-wrap justify-between mx-auto  xl:w-[90%] overflow-x-hidden">
                        <motion.div
                            variants={opacity}
                            className="lg:w-[30%] 2xl:w-[25%]"
                        >
                            <H3 className=" sm:text-5xl lg:text-5xl xl:text-5xl items-center mt-3">
                                Awarded and Recognised by
                            </H3>
                        </motion.div>

                        <div className="w-full lg:w-[65%]  2xl:w-[60%] flex flex-wrap justify-between items-center mt-2.4 ">
                            <motion.img
                                variants={slideOpacityLeft}
                                className="max-w-[100px] sm:max-w-[208px] lg:max-w-[208px]   xl:max-w-[220px]"
                                src={internalTrade}
                                alt="internal_trade"
                            />
                            <motion.img
                                variants={slideOpacityUp}
                                className="max-w-[80px] sm:max-w-[128px] lg:max-w-[128px] xl:max-w-[220px]"
                                src={msme}
                                alt="msme_logo"
                            />
                            <motion.img
                                variants={slideOpacityRight}
                                className="max-w-[100px]  sm:max-w-[164px] lg:max-w-[164px] xl:max-w-[220px]"
                                src={startupIndia}
                                alt="start-up-india-logo"
                            />
                        </div>
                    </div>
                </motion.div>
            </Container>
        </Section>
    );
};

export default Recognition;
