import { useEffect, useState } from "react";
import { COMMON_SUCCESS_MSG, ERROR_MSG } from "../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../constants/contexConstant";
import { useContextState } from "../context/ContextProvider";
import request from "../services/request";

const useNetwork = (endpoint, type, body, success_msg = COMMON_SUCCESS_MSG) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const { dispatch } = useContextState();

    useEffect(() => {
        const sendRequest = () => {
            dispatch({ type: SET_LOADER });
            const promise =
                type === "POST"
                    ? request.authPost({ endpoint, body })
                    : request.authGet({ endpoint });
            promise
                .then((data) => {
                    setData(data);
                    setError(null);
                    // toast.success(success_msg);
                })
                .catch((err) => {
                    const checkedErr =
                        typeof err === "string" ? err : ERROR_MSG;
                    setError(checkedErr);
                    dispatch({
                        type: SET_ERROR,
                        payload: checkedErr,
                    });
                })
                .finally(() => {
                    setLoading(false);
                    dispatch({ type: RESET_LOADER });
                });
        };

        sendRequest();
    }, [body, dispatch, endpoint, success_msg, type]);

    return { loading, error, data };
};

export default useNetwork;
