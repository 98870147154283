import React from "react";
import Space from "../../../components/space/Space";
import { Flex } from "../../../components/styles/Elements.styles";
import { H1, P } from "../../../components/styles/Typography.styles";
import AuditFilterRadio from "../admin/audit/AuditFilterRadio";
const FilterHeader = ({
    title,
    xxsm = "4rem 0 0rem",
    stm = "5rem 0 1.5rem",
    lgm = "6rem 0 1rem",
    showSearch = false,
    selectedType,
    name,
    onChange,
    typeRange,
    label = null,
}) => {
    return (
        <Space
            xxsm={xxsm}
            stm={stm}
            lgm={lgm}
            display="flex"
            alignItems="center"
        >
            <Flex.Container
                justify="space-between"
                align="flex-start"
                direction="row"
            >
                <Flex.Item xxs="100%" st="58%" t="50%" lg="45%" xl="50%">
                    <>
                        <Space xxsm={["2rem 0 1rem"]}>
                            <Flex.Container align="flex-start">
                                {label && (
                                    <Space xxsm={["0 2rem 0 0"]}>
                                        <Flex.Item>
                                            <P>{label}</P>
                                        </Flex.Item>
                                    </Space>
                                )}
                                <Flex.Item flex="1">
                                    <AuditFilterRadio
                                        data={
                                            typeRange && typeRange.length > 0
                                                ? typeRange
                                                : []
                                        }
                                        name={name}
                                        checked={selectedType}
                                        onChange={onChange}
                                    />
                                </Flex.Item>
                            </Flex.Container>
                        </Space>
                    </>
                </Flex.Item>
                <Flex.Item xxs="100%" st="48%">
                    <H1>{title}</H1>
                </Flex.Item>
            </Flex.Container>
        </Space>
    );
};

export default FilterHeader;
