import React, { useState } from "react";
import Space from "../../../components/space/Space";
import Container from "../../../components/styles/Container.styles";
import { Flex, Section } from "../../../components/styles/Elements.styles";
import { twoDecimalLocal } from "../../../helpers/helpers";
import TopLevelInput from "../TopLevelInput";
import Backdrop from "./Backdrop";
import DisplayValues from "./DisplayValues";
import SidebarRadioInput from "./SidebarRadioInput";

const CONNECTION_TYPE = ["Residential Society", "Commercial"];
const PLAN_DURATION = [
    "5 Years",
    "6 Years",
    "7 Years",
    "8 Years",
    "9 Years",
    "10 Years",
];

const getUnitRate = (key) => {
    switch (key) {
        case 5:
            return 10.85;
        case 6:
            return 9.75;
        case 7:
            return 8.9;
        case 8:
            return 8.25;
        case 9:
            return 7.85;
        default:
            return 7.45;
    }
};

const ProposalCalculator = () => {
    const [proposal, setProposal] = useState({
        connectionType: CONNECTION_TYPE[0],
        consumerBill: "",
        consumerTotalUnit: "",
    });

    const [planDuration, setPlanDuration] = useState(PLAN_DURATION[0]);
    const [subsidy, setSubsidy] = useState("Yes");
    const [tenants, setTenants] = useState("Yes");

    const isResidential = proposal.connectionType === CONNECTION_TYPE[0];

    const handleChange = (name, value, isSelect) => {
        if (isSelect) {
            setProposal({ ...proposal, [name]: value });
            if (name === "connectionType") {
                setSubsidy("Yes");
                setTenants("Yes");
            }
        } else if (!isNaN(value)) {
            setProposal({ ...proposal, [name]: value });
        }
    };

    let consumerUnitRate = proposal.consumerBill / proposal.consumerTotalUnit;

    const getResidentialRows = () => {
        let isSubsidy = subsidy === "Yes";

        let solarPlantSize = proposal.consumerTotalUnit / 120;

        let subsidyAmount = isSubsidy ? solarPlantSize * 18000 : 0;
        let upfrontInvestment = isSubsidy ? subsidyAmount : 0;
        let totalInvestment = isSubsidy ? upfrontInvestment - subsidyAmount : 0;

        const row1 = [
            {
                id: 1,
                title: "Upfront Investment",
                value: `₹ ${twoDecimalLocal(upfrontInvestment || 0)}`,
            },
            {
                id: 3,
                title: "Subsidy Amount",
                value: `₹ ${twoDecimalLocal(subsidyAmount || 0)}`,
            },
            {
                id: 2,
                title: "Total Investment",
                value: `₹ ${twoDecimalLocal(totalInvestment || 0)}`,
            },
        ];

        let monthlyUnitsGenerated = solarPlantSize * 120;

        let billWithSolar = isSubsidy
            ? monthlyUnitsGenerated * 11.75 * (1 - 18000 / 52575) +
              monthlyUnitsGenerated * 2
            : monthlyUnitsGenerated * 13;
        let monthlySavings = isSubsidy
            ? proposal.consumerBill - billWithSolar
            : proposal.consumerBill - billWithSolar;

        let lifetimeSavings = isSubsidy
            ? monthlySavings * 60 + proposal.consumerBill * 20 * 12
            : monthlySavings * 60 + proposal.consumerBill * 20 * 12;
        let shadesFreeArea = isSubsidy
            ? solarPlantSize * 90
            : solarPlantSize * 90;

        let monthlySavingPercentage =
            (monthlySavings / proposal.consumerBill) * 100;

        const row2 = [
            {
                id: 1,
                title: "Bill with Solar",
                value: `₹ ${twoDecimalLocal(billWithSolar || 0)}`,
            },
            {
                id: 2,
                title: "Monthly Savings",
                value: `₹ ${twoDecimalLocal(
                    monthlySavings || 0
                )}   (${twoDecimalLocal(monthlySavingPercentage || 0)}%)`,
            },
            {
                id: 3,
                title: "Lifetime Savings (25 Yr)",
                value: `₹ ${twoDecimalLocal(lifetimeSavings || 0)}`,
            },
            {
                id: 4,
                title: "Shades Free Area",
                value: `${parseInt(shadesFreeArea || 0)} sqft`,
            },
            {
                id: 5,
                title: "Solar Plant Size",
                value: `${parseInt(solarPlantSize || 0)} KWp`,
            },
            {
                id: 6,
                title: "Plan Duration",
                value: "5 Years",
            },
        ];
        return { row1, row2 };
    };
    const getCommercialRows = () => {
        let isTenants = tenants === "Yes";
        let plantLevel = Number(planDuration.slice(0, 2));
        let costPerUnit_duration = getUnitRate(plantLevel);

        let solarPlantSize = proposal.consumerTotalUnit / 120;
        let monthlyUnitsGenerated = solarPlantSize * 120;

        let billWithOutGst = proposal.consumerBill / 1.18;
        let gst = billWithOutGst * 0.18;
        let totalPayableBill = billWithOutGst + gst;
        let monthlySavings = proposal.consumerBill - billWithOutGst;
        let monthlySavingPercentage =
            (monthlySavings / proposal.consumerBill) * 100;

        let billCollectedFromTenant = totalPayableBill;

        let billPayableToHypersrot = isTenants
            ? monthlyUnitsGenerated * (2 + costPerUnit_duration) * 1.18
            : monthlyUnitsGenerated * (2 + costPerUnit_duration);

        let savingsOnCollection =
            billCollectedFromTenant - billPayableToHypersrot;

        let gstCollectedFromTenant = billCollectedFromTenant * (1 - 1 / 1.18);
        let gstPayableToHypersrot = billPayableToHypersrot * (1 - 1 / 1.18);
        let gstPayableToGovt = gstCollectedFromTenant - gstPayableToHypersrot;
        let totalMonthlyEarning = savingsOnCollection - gstPayableToGovt;

        let ownerGST = billPayableToHypersrot * 0.18;
        let totalBillPayable = isTenants
            ? billPayableToHypersrot + ownerGST
            : billPayableToHypersrot + ownerGST;

        let billWithoutSolar = proposal.consumerBill;

        let ownerSaving = billWithoutSolar - billPayableToHypersrot;
        let savingPercentage = (ownerSaving / billWithoutSolar) * 100;
        let lifetimeSavings =
            ownerSaving * plantLevel * 12 +
            billWithoutSolar * 12 * (25 - plantLevel);

        let totalMonthlySavings = ownerSaving;

        const row1 = [
            {
                id: 1,
                title: "Bill (Without GST)",
                value: `${twoDecimalLocal(billWithOutGst || 0)}`,
            },
            {
                id: 2,

                title: "GST (18%)",
                value: `${twoDecimalLocal(gst || 0)}`,
            },
            {
                id: 3,
                title: "Total Bill Payable",
                value: `${twoDecimalLocal(totalPayableBill || 0)}`,
            },
            {
                id: 4,
                title: "Monthly Savings",
                value: `${twoDecimalLocal(monthlySavings || 0)} (${Number(
                    monthlySavingPercentage || 0
                ).toFixed(2)}%)`,
            },
            {
                id: 5,
                title: "Monthly Saving Percentage",
                value: `${Number(monthlySavingPercentage || 0).toFixed(2)}%`,
            },
        ];
        const row2 = [
            {
                id: 1,
                title: "Bill Collected From Tenant",
                value: `${twoDecimalLocal(billCollectedFromTenant || 0)}`,
            },
            {
                id: 2,
                title: "Bill Payable to Hypersrot",
                value: `${twoDecimalLocal(billPayableToHypersrot || 0)}`,
            },
            {
                id: 3,
                title: "Savings on Collection",
                value: `${twoDecimalLocal(savingsOnCollection || 0)}`,
            },
            {
                id: 4,
                title: "GST Collected From Tenant",
                // @TODO ==>   add logic for "+" Tue Apr  16
                value: `${twoDecimalLocal(gstCollectedFromTenant || 0)}`,
            },
            {
                id: 5,
                title: "GST Payable to Hypersrot",
                // @TODO ==>   add logic for "-" Tue Apr  16
                value: `${twoDecimalLocal(gstPayableToHypersrot || 0)}`,
            },
            {
                id: 6,
                title: "GST Payable to Govt",
                // @TODO ==>   Add logic for govt Tue Apr  16
                value: `${twoDecimalLocal(gstPayableToGovt || 0)}`,
            },
        ];

        const row2NoTenants = [
            {
                id: 1,
                title: "Bill Payable to Hypersrot",
                value: `${twoDecimalLocal(billPayableToHypersrot || 0)}`,
            },
            {
                id: 2,

                title: "GST (18%)",
                value: `${twoDecimalLocal(ownerGST || 0)}`,
            },

            {
                id: 3,
                title: "Total Bill Payable",
                value: `${twoDecimalLocal(totalBillPayable || 0)}`,
            },
        ];
        const row3NoTenants = [
            {
                id: 1,
                title: "Bill Without Solar",
                value: `${twoDecimalLocal(billWithoutSolar || 0)}`,
            },
            {
                id: 2,
                title: "Savings (Inclusive GST)",
                // @TODO ==>  verify percentage
                value: `${twoDecimalLocal(ownerSaving || 0)} (${Number(
                    savingPercentage || 0
                ).toFixed(2)}%)`,
            },
            {
                id: 3,
                title: "Lifetime Savings (25 Yr)",
                // @TODO ==>   Add logic for govt Tue Apr  16
                value: `${twoDecimalLocal(lifetimeSavings || 0)}`,
            },
        ];

        const title1 = "Tenant Savings";
        const title2 = "Owner Savings";
        const bottomLevel = isTenants
            ? `Total Monthly Earnings: ${twoDecimalLocal(
                  totalMonthlyEarning || 0
              )}`
            : `Total Monthly Savings: ${twoDecimalLocal(
                  totalMonthlySavings || 0
              )} (${Number(savingPercentage || 0).toFixed(2)}%)`;
        return isTenants
            ? { row1, row2, bottomLevel, title1, title2 }
            : {
                  row2: [...row2NoTenants, ...row3NoTenants],
                  bottomLevel,
                  title1,
                  title2,
              };
    };

    const renderResidentialInput = () => {
        const data = getResidentialRows();
        return <DisplayValues {...data} />;
    };

    const renderCommercialInput = () => {
        const data = getCommercialRows();
        return <DisplayValues {...data} />;
    };
    return (
        <Section backgroundColor="cyan200" height="auto">
            <Container>
                <Space
                    xxsm={["4rem 0 0rem"]}
                    stm={["5rem 0 1.5rem"]}
                    lgm={["6rem 0 1rem"]}
                >
                    <Flex.Container
                        //  align="center"
                        justify="space-between"
                    >
                        <Space xxsm={["0 0 2rem"]} tm={["0"]}>
                            <Flex.Item xxs="100%" st="100%" t="30%" lg="25%">
                                <Backdrop>
                                    <TopLevelInput
                                        title="Connection Type"
                                        selected={proposal.connectionType}
                                        onChange={(value) =>
                                            handleChange(
                                                "connectionType",
                                                value,
                                                true
                                            )
                                        }
                                        isSelect
                                        data={CONNECTION_TYPE}
                                    />
                                    <TopLevelInput
                                        title="Consumer Bill"
                                        placeholder="Enter Your Total Bill"
                                        onChange={(e) =>
                                            handleChange(
                                                "consumerBill",
                                                e.target.value
                                            )
                                        }
                                        value={proposal.consumerBill}
                                    />
                                    <TopLevelInput
                                        title="Consumer’s Total Units"
                                        placeholder="Total Electricity Units Consumed"
                                        onChange={(e) =>
                                            handleChange(
                                                "consumerTotalUnit",
                                                e.target.value
                                            )
                                        }
                                        value={proposal.consumerTotalUnit}
                                    />
                                    <TopLevelInput
                                        title="Consumer Unit Rate"
                                        disabled
                                        value={twoDecimalLocal(
                                            consumerUnitRate || 0
                                        )}
                                    />
                                    {/* @TODO ==>   Show this for connectionType Commercial  Mon Apr  15  */}
                                    {!isResidential && (
                                        <TopLevelInput
                                            title="Plan duration"
                                            selected={planDuration}
                                            onChange={(value) =>
                                                setPlanDuration(value)
                                            }
                                            isSelect
                                            data={PLAN_DURATION}
                                        />
                                    )}

                                    {/* @TODO ==>   Radio input e Sun Apr  14  */}
                                    {isResidential ? (
                                        <SidebarRadioInput
                                            onChange={setSubsidy}
                                            type={"subsidy"}
                                            title={"Subsidy"}
                                            value={subsidy}
                                        />
                                    ) : (
                                        <SidebarRadioInput
                                            onChange={setTenants}
                                            type={"tenants"}
                                            title={"Do You Have Tenants"}
                                            value={tenants}
                                        />
                                    )}
                                </Backdrop>
                            </Flex.Item>
                        </Space>

                        <Flex.Item xxs="100%" st="100%" t="65%" lg="72%">
                            <Flex.Container
                                justify="space-between"
                                direction="column"
                            >
                                {isResidential
                                    ? renderResidentialInput()
                                    : renderCommercialInput()}
                            </Flex.Container>
                        </Flex.Item>
                    </Flex.Container>
                </Space>
            </Container>
        </Section>
    );
};

export default ProposalCalculator;
