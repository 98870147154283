import React from "react";
import { background } from "../../resources/js/images";
import Hero from "../home/Hero";
import ResetPasswordHero from "./ResetPasswordHero";

const ResetPasswordScreen = () => {
    return (
        <div>
            <Hero
                content={<ResetPasswordHero />}
                url={background}
                wrapperClass={
                    "h-[130vh] sm:h-[160vh] md:h-[120vh] xl:h-[115vh]  py-[5rem] "
                }
                containerClass={" mb-0 sm:mb-0 xl:mb-0 2xl:mb-0 lg:mb-0"}
            />
        </div>
    );
};

export default ResetPasswordScreen;
