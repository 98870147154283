import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Counter = ({ value, decimals, ...rest }) => {
    return (
        <CountUp
            duration={2}
            end={value}
            redraw={false}
            decimals={decimals}
            decimal="."
            {...rest}
        >
            {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                </VisibilitySensor>
            )}
        </CountUp>
    );
};

export default Counter;
