import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { virtualOTP } from "../../components/animation/virtualSolarAnimation";
import { ButtonAlt } from "../../components/button/Button";
import DatePicker from "../../components/date-picker/DatePicker";
import Input from "../../components/input/Input";
import ModalAlt from "../../components/modal/ModalAlt";
import OtpInput from "../../components/otp-input/OtpInput";
import Space from "../../components/space/Space";
import { Flex } from "../../components/styles/Elements.styles";
import { P } from "../../components/styles/Typography.styles";
import { InlineText } from "../../components/text/HeadingText";
import {
    COUNTER_TIME,
    ERROR_MSG,
    OTP_LENGTH,
    ROLE,
    TYPE_PASSWORD,
    TYPE_TEXT,
} from "../../constants/common";
import {
    CLOSE_SIGNUP_FORM,
    REMOVE_REDIRECT_PATH,
    SET_ERROR,
    SHOW_LOGIN_FORM,
    UPDATE_QUICK_USER,
} from "../../constants/contexConstant";
import { emailRegEx, phoneRegEx } from "../../constants/regularExpression";
import {
    COMMUNITY,
    DEFAULT_REF_CODE,
    PLANT_SUBSCRIPTION_REGISTRATION_API_ENDPOINT,
    RESEND_OTP_ENDPOINT,
    SIGNUP_CONSUMER_ENDPOINT,
    VALIDATE_API_ENDPOINT,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import {
    validateNameEmailPhone,
    validatePasswordDobOTP,
} from "../../helpers/fieldValidation";
import { getURLQueryParams } from "../../helpers/helpers";
import { handleRedirection } from "../../helpers/routingHelper";
import auth from "../../services/auth";
import request from "../../services/request";
import { AuthenticationHead } from "./LoginModal";
import ResentOtp from "./ResentOtp";
import VirtualLoginSummary from "./VirtualLoginSummary";

const SignupModal = () => {
    const { state, dispatch } = useContextState();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [otp, setOtp] = useState("");
    const [formError, setFormError] = useState({});

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        dateOfBirth: "",
        password: "",
        confirmPassword: "",
    });

    const [checkEmail, setCheckEmail] = useState(false);
    const [checkPhone, setCheckPhone] = useState(false);
    const [existingEmail, setExistingEmail] = useState(false);
    const [existingPhone, setExistingPhone] = useState(false);
    const [emailErr, setEmailErr] = useState(null);
    const [phoneErr, setPhoneErr] = useState(null);
    const [step, setStep] = useState(1);
    const [showSummary, setShowSummary] = useState(false);
    const [passType, setPassType] = useState(TYPE_PASSWORD);
    const [confirmPassType, setConfirmPassType] = useState(TYPE_PASSWORD);

    const location = useLocation();
    const referral = getURLQueryParams(location, "referral");

    // RESENT OTP FILEDS
    const [resendCount, setResendCount] = useState(0);
    const [counter, setCounter] = useState(0);

    /* @DESC::  close the modal and reset form  */
    const closeModalHandler = useCallback(() => {
        setFormData({
            name: "",
            email: "",
            phone: "",
            dateOfBirth: "",
            password: "",
            confirmPassword: "",
        });
        setOtp("");
        setCheckEmail(false);
        setCheckPhone(false);
        setExistingEmail(false);
        setExistingPhone(false);
        setEmailErr(null);
        setPhoneErr(null);
        setStep(1);
        dispatch({ type: CLOSE_SIGNUP_FORM });
    }, [dispatch]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        const updateCounter = () => {
            if (counter === 0) return;
            else {
                let temp = counter - 1;
                setCounter(temp);
            }
        };
        let timer = setInterval(updateCounter, 1000);
        if (counter === 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [counter]);

    /* @DESC::  Resend OTP   */
    const handleResentOTP = useCallback(
        (type) => {
            request
                .get({
                    endpoint: `${RESEND_OTP_ENDPOINT}?mobile=91${formData.phone}&type=${type}`,
                })
                .then((res) => {
                    if (res?.status === "SUCCESS") {
                        toast.success(
                            res?.message && typeof res?.message === "string"
                                ? res?.message
                                : type === "voice"
                                ? "Voice call has been sent!"
                                : "OTP has sent successfully!"
                        );
                        setLoading(false);
                        setResendCount((prev) => prev + 1);
                        setCounter(COUNTER_TIME * (resendCount + 1));
                    }
                })
                .catch((error) => {
                    setLoading(false);

                    if (error === "otp_expired") {
                        setResendCount(0);
                        setCounter(0);
                        closeModalHandler();
                    }
                    dispatch({
                        type: SET_ERROR,
                        payload: typeof error === "string" ? error : ERROR_MSG,
                    });
                });
        },
        [closeModalHandler, dispatch, formData.phone, resendCount]
    );

    /* @DESC::  validate and check existing email and phone  */
    const handleBlur = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === "email") {
            if (!emailRegEx.test(value.toLowerCase())) {
                setEmailErr("Enter a valid email!");
                setCheckEmail(false);
                setExistingEmail(false);
            } else {
                request
                    .post({
                        endpoint: VALIDATE_API_ENDPOINT,
                        body: {
                            email: value,
                            roles: [
                                ROLE.CONSUMER_USER,
                                ROLE.CONSUMER_OWNER,
                                ROLE.COMPANY_AGENT,
                                ROLE.CONSUMER_ADMIN,
                            ],
                        },
                    })
                    .then((res) => {
                        setCheckEmail(true);
                        setExistingEmail(false);
                        setEmailErr("");
                    })
                    .catch((err) => {
                        setCheckEmail(false);
                        setExistingEmail(true);
                        setEmailErr(
                            typeof err === "string"
                                ? err
                                : "Email is already in use. Click "
                        );
                    });
            }
        } else {
            /* @TODO ==>  Phone validation Mon Oct 03  */
            if (!phoneRegEx.test(value)) {
                setPhoneErr("Enter a valid phone number!");
                setCheckPhone(false);
                setExistingPhone(false);
            } else {
                request
                    .post({
                        endpoint: VALIDATE_API_ENDPOINT,
                        body: {
                            phone: `91${value}`,
                            roles: [
                                ROLE.CONSUMER_USER,
                                ROLE.CONSUMER_OWNER,
                                ROLE.COMPANY_AGENT,
                                ROLE.CONSUMER_ADMIN,
                            ],
                        },
                    })
                    .then((res) => {
                        setCheckPhone(true);
                        setExistingPhone(false);
                        setPhoneErr("");
                    })
                    .catch((err) => {
                        setCheckPhone(false);
                        setExistingPhone(true);
                        setPhoneErr(
                            typeof err === "string"
                                ? err
                                : "Phone is already in use. Click "
                        );
                    });
            }
        }
    };

    /* @DESC::  referral checking  */
    const handleCheckReferralCode = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (referral) {
                    await request.post({
                        endpoint: VALIDATE_API_ENDPOINT,
                        body: {
                            referralCode: referral,
                        },
                    });
                    return resolve(referral);
                } else {
                    return resolve(DEFAULT_REF_CODE);
                }
            } catch (error) {
                return resolve(DEFAULT_REF_CODE);
            }
        });
    };

    /* @DESC:: first call get OTP   */
    const handleGetOTP = (e) => {
        e.preventDefault();
        const values = {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
        };

        const getValidFormData = validateNameEmailPhone(values);
        setFormError(getValidFormData);

        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            const reqData = {
                name: formData.name,
                email: formData.email,
                phone: `91${formData.phone}`,
            };
            let endpoint = SIGNUP_CONSUMER_ENDPOINT;
            request
                .post({
                    endpoint,
                    body: reqData,
                })
                .then((response) => {
                    toast.success("OTP has been sent!");
                    setResendCount((prev) => prev + 1);
                    setCounter(COUNTER_TIME);
                    setLoading(false);

                    setStep(2);
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error(err || ERROR_MSG);
                });
        }
    };
    const registerUserForSubscriptionVote = (endpoint, body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.authPost({ endpoint, body });
                resolve(res);
            } catch (error) {
                resolve(error);
            }
        });
    };
    /* @DESC::  final call submit with carboon and OTP || Complete new user signup  */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const values = {
            dateOfBirth: formData.dateOfBirth,
            password: formData.password,
            confirmPassword: formData.confirmPassword,
            otp,
        };
        const getValidFormData = validatePasswordDobOTP(values);

        setFormError(getValidFormData);

        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            try {
                let refCode = await handleCheckReferralCode(referral);
                let reqData = {
                    name: formData.name,
                    email: formData.email,
                    phone: `91${formData.phone}`,
                    referralCode: null,
                    dateOfBirth: moment(formData.dateOfBirth).format(
                        "DD-MM-YYYY"
                    ),
                    password: formData.password,
                    otp,
                };
                let endpoint = SIGNUP_CONSUMER_ENDPOINT;
                await request.post({
                    endpoint,
                    body: reqData,
                });
                // @TODO ==>   some message Mon Jun  26
                await auth.login({
                    username: formData.email,
                    password: formData.password,
                    otpEnable: false,
                });

                const profile = await auth.getUserProfile(dispatch);
                let status = profile?.data?.investor_profile?.plant?.status;
                const roles = profile?.data?.investor_profile?.roles;

                const path = state.redirectPath;
                if (path) {
                    if (path.includes(COMMUNITY) && path.split("/")[2]) {
                        // @TODO ==>   Register a user for voting Thu Nov  02
                        const body = {
                            plantId: path.split("/")[2],
                        };
                        await registerUserForSubscriptionVote(
                            PLANT_SUBSCRIPTION_REGISTRATION_API_ENDPOINT,
                            body
                        );
                        history.push(path);
                    }
                } else {
                    handleRedirection(state, history, roles, status);
                }
                dispatch({
                    type: REMOVE_REDIRECT_PATH,
                });
                setLoading(false);
                // setShowSummary(true);
                closeModalHandler();
            } catch (err) {
                setLoading(false);
                toast.error(err || ERROR_MSG);
                setShowSummary(false);
            }
        }
    };

    const handleUserLogin = async (type, value) => {
        try {
            await auth.loginOTP({
                username: value,
            });
            toast.success("OTP has been sent");
            dispatch({
                type: UPDATE_QUICK_USER,
                payload: { [type]: value },
            });
            // onSuccess(true);
            closeModalHandler();
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);
            dispatch({
                type: UPDATE_QUICK_USER,
                payload: { [type]: value },
            });
        }
    };
    const handleLoginExistingUser = () => {
        setStep(1);
        dispatch({ type: SHOW_LOGIN_FORM });
    };

    const handleCloseSummaryAndLogin = () => {
        setShowSummary(false);
        handleLoginExistingUser();
    };

    const updatePasswordFiledType = (filedType) => {
        if (filedType === "password") {
            setPassType(passType === TYPE_PASSWORD ? TYPE_TEXT : TYPE_PASSWORD);
        } else {
            setConfirmPassType(
                confirmPassType === TYPE_PASSWORD ? TYPE_TEXT : TYPE_PASSWORD
            );
        }
    };
    return (
        <>
            <ModalAlt
                times={false}
                onClose={closeModalHandler}
                isOpen={state.signupForm}
                backgroundColor="primary"
                width={["100%", "600px", "600px"]}
                overflowx=""
                overflowy=""
            >
                <Space xxsp={["2rem 2.5rem"]} lgp={["2.4rem 3.2rem"]}>
                    <form>
                        <Space xxsm={["0 0 9rem"]} xsm={["0 0 6rem"]}>
                            <div>
                                <AuthenticationHead
                                    type="Existing User"
                                    actionText="Login here"
                                    onClick={handleLoginExistingUser}
                                />
                            </div>
                        </Space>

                        <Space xxsm={["0 0 3rem"]} xsm={[" 0 0 3rem"]}>
                            <P
                                align="center"
                                weight="b"
                                xxs="xl"
                                st="xl"
                                color="white"
                            >
                                Sign In
                            </P>
                        </Space>
                        <AnimatePresence>
                            {step === 1 ? (
                                <>
                                    <DetailsFiled
                                        label="Full Name"
                                        placeholder="Enter your full name"
                                        name="name"
                                        errorMessage={formError.nameError}
                                        value={formData.name}
                                        onChange={handleChange}
                                    />

                                    <DetailsFiled
                                        label="Email Id"
                                        placeholder="Enter Your Email"
                                        name="email"
                                        autoComplete="new-email"
                                        // errorMessage={formError.emailError}
                                        errorMessage={
                                            formError.emailError || emailErr ? (
                                                <>
                                                    {formError.emailError ||
                                                        emailErr}
                                                    {existingEmail && (
                                                        <ExistingBtn
                                                            xxs="s"
                                                            st="s"
                                                            color="primary"
                                                            weight="b"
                                                            onClick={() =>
                                                                handleUserLogin(
                                                                    "email",
                                                                    formData.email
                                                                )
                                                            }
                                                        >
                                                            Login
                                                        </ExistingBtn>
                                                    )}
                                                </>
                                            ) : null
                                        }
                                        value={formData.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <DetailsFiled
                                        label="Phone No."
                                        placeholder=" Enter your phone number"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        autoComplete="new-phone"
                                        errorMessage={
                                            formError.phoneError || phoneErr ? (
                                                <>
                                                    {formError.emailError ||
                                                        phoneErr}
                                                    {existingPhone && (
                                                        <ExistingBtn
                                                            xxs="s"
                                                            st="s"
                                                            color="primary"
                                                            weight="b"
                                                            onClick={() =>
                                                                handleUserLogin(
                                                                    "phone",
                                                                    `91${formData.phone}`
                                                                )
                                                            }
                                                        >
                                                            Login
                                                        </ExistingBtn>
                                                    )}
                                                </>
                                            ) : null
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <Space
                                        lgm={["0 0 30px "]}
                                        tabletmargin={["0 0 25px"]}
                                        mobilemargin={["0 0 10px"]}
                                    >
                                        <Flex.Container
                                            justify="space-between"
                                            align="center"
                                        >
                                            <Flex.Item
                                                xxs="100%"
                                                st="100%"
                                                xl="25%"
                                            >
                                                <P
                                                    color="white"
                                                    weight="b"
                                                    xxs="m"
                                                    st="m"
                                                >
                                                    Date of Birth
                                                </P>
                                            </Flex.Item>
                                            <Flex.Item
                                                xxs="100%"
                                                st="100%"
                                                xl="70%"
                                            >
                                                <DatePicker
                                                    onChange={handleChange}
                                                    maxDate={new Date()}
                                                    value={formData.dateOfBirth}
                                                    errorMessage={
                                                        formError.dateOfBirthError
                                                    }
                                                    errorType="alert"
                                                />
                                            </Flex.Item>
                                        </Flex.Container>
                                    </Space>
                                    <DetailsFiled
                                        label="Password"
                                        placeholder="Enter Password"
                                        name="password"
                                        errorMessage={formError.passwordError}
                                        value={formData.password}
                                        onChange={handleChange}
                                        type={passType}
                                        icon={
                                            passType === TYPE_PASSWORD
                                                ? faEyeSlash
                                                : faEye
                                        }
                                        onIconClick={() =>
                                            updatePasswordFiledType(
                                                TYPE_PASSWORD
                                            )
                                        }
                                    />
                                    <DetailsFiled
                                        label="Confirm Password"
                                        placeholder="Enter Confirm Password"
                                        name="confirmPassword"
                                        errorMessage={
                                            formError.confirmPasswordError
                                        }
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        type={confirmPassType}
                                        icon={
                                            confirmPassType === TYPE_PASSWORD
                                                ? faEyeSlash
                                                : faEye
                                        }
                                        onIconClick={() =>
                                            updatePasswordFiledType()
                                        }
                                    />

                                    <Space
                                        lgm={["0 0 30px "]}
                                        stm={["0 0 25px"]}
                                        xxsm={["0 0 10px"]}
                                    >
                                        <Flex.Container
                                            justify="space-between"
                                            align="center"
                                            as={motion.div}
                                            variants={virtualOTP}
                                            initial="hidden"
                                            animate="visible"
                                            exit="exit"
                                        >
                                            <Flex.Item
                                                xxs="100%"
                                                st="100%"
                                                xl="25%"
                                            >
                                                <P
                                                    color="white"
                                                    weight="sb"
                                                    xxs="m"
                                                    st="m"
                                                >
                                                    OTP
                                                </P>
                                            </Flex.Item>
                                            <Flex.Item
                                                xxs="100%"
                                                st="100%"
                                                xl="70%"
                                            >
                                                <OtpInput
                                                    label={false}
                                                    value={otp}
                                                    errorMessage={
                                                        formError.otpError
                                                    }
                                                    onChange={(e) =>
                                                        setOtp(e.target.value)
                                                    }
                                                    size={OTP_LENGTH}
                                                    isInputNum={true}
                                                    errorType="alert"
                                                />
                                            </Flex.Item>
                                        </Flex.Container>
                                    </Space>
                                    <Space
                                        lgm={["0 0 25px "]}
                                        stm={["0 0 25px"]}
                                        xxsm={["0 0 10px"]}
                                    >
                                        <ResentOtp
                                            counter={counter}
                                            onClick={handleResentOTP}
                                        />
                                    </Space>
                                </>
                            )}
                        </AnimatePresence>

                        <Flex.Container justify="center" align="center">
                            {step === 1 ? (
                                <ButtonAlt
                                    // type="solid"
                                    xxs="m"
                                    st="m"
                                    weight="b"
                                    backgroundColor="white"
                                    color="white"
                                    onClick={handleGetOTP}
                                    style={{
                                        borderRadius: "50px",
                                        padding: "1rem 3rem",
                                    }}
                                    disabled={
                                        loading || !checkEmail || !checkPhone
                                    }
                                    isLoading={loading}
                                >
                                    GET OTP
                                </ButtonAlt>
                            ) : (
                                <Flex.Container style={{ marginTop: "1rem" }}>
                                    <ButtonAlt
                                        onClick={() => setStep(1)}
                                        xxs="m"
                                        st="m"
                                        weight="b"
                                        backgroundColor="lightGrey"
                                        hoverColor="primary"
                                        color="white"
                                        style={{
                                            borderRadius: "50px",
                                            marginRight: "15px",
                                            padding: "1rem 3rem",
                                        }}
                                    >
                                        Back
                                    </ButtonAlt>
                                    <ButtonAlt
                                        // type="solid"
                                        onClick={handleSubmit}
                                        xxs="m"
                                        st="m"
                                        weight="b"
                                        backgroundColor="white"
                                        hoverColor="primary"
                                        color="white"
                                        style={{
                                            borderRadius: "50px",
                                            padding: "1rem 3rem",
                                        }}
                                        disabled={
                                            loading ||
                                            !checkEmail ||
                                            !checkPhone
                                        }
                                        isLoading={loading}
                                    >
                                        Submit
                                    </ButtonAlt>
                                </Flex.Container>
                            )}
                        </Flex.Container>
                    </form>
                </Space>
            </ModalAlt>
            <ModalAlt
                times={false}
                onClose={() => setShowSummary(false)}
                isOpen={showSummary}
                backgroundColor="primary"
                width={["100%", "600px", "600"]}
            >
                <VirtualLoginSummary
                    summaryErr={false}
                    onClose={closeModalHandler}
                >
                    <P align="left" xxs="m" st="m" weight="b" color="white">
                        Your profile is created successfully.
                    </P>
                    <Space margin={["1rem 0"]}>
                        <P
                            color="white"
                            xxs="m"
                            st="m"
                            lineHeight="1.3em"
                            align="left"
                        >
                            You can
                            <LoginBtn
                                onClick={handleCloseSummaryAndLogin}
                                role="button"
                                weight="b"
                            >
                                Login
                            </LoginBtn>{" "}
                            here
                        </P>
                    </Space>
                </VirtualLoginSummary>
            </ModalAlt>
        </>
    );
};

export default SignupModal;

const DetailsFiled = ({
    label,
    onChange,
    margin = "0 0 30px ",
    tabletmargin = "0 0 25px",
    mobilemargin = "0 0 10px",
    icon,
    onIconClick,
    ...rest
}) => {
    return (
        <Space lgm={[margin]} stm={[tabletmargin]} xxsm={[mobilemargin]}>
            <Flex.Container justify="space-between" align="center">
                <Flex.Item xxs="100%" st="100%" xl="25%">
                    <P color="white" weight="b" xxs="m" st="m">
                        {label}
                    </P>
                </Flex.Item>
                <Flex.Item xxs="100%" st="100%" xl="70%">
                    <Input
                        onChange={onChange}
                        backgroundColor="white"
                        errorType="alert"
                        icon={icon}
                        onIconClick={icon ? onIconClick : null}
                        {...rest}
                    />
                </Flex.Item>
            </Flex.Container>
        </Space>
    );
};

export const ExistingBtn = styled(InlineText)`
    text-decoration: underline;
    margin-left: 5px;
    font-weight: ${(props) => props.theme.fontWeight.b};
    :hover {
        cursor: pointer;
        color: ${(props) => props.theme.color.pureWhite};
    }
`;

const LoginBtn = styled(InlineText)`
    color: ${(props) => props.theme.color.lightGreen};
    margin: 0 5px;
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;
