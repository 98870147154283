import React from "react";
import { BsChat } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { TbHandClick } from "react-icons/tb";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { decoratedBanner, quotesWhite } from "../../assets";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/button/Button";
import Link from "../../components/link/Link";
import Space from "../../components/space/Space";
import Container from "../../components/styles/Container.styles";
import { Flex, Section } from "../../components/styles/Elements.styles";
import { H2, H3, P } from "../../components/styles/Typography.styles";
import { listOfArticles } from "../../constants/articlesData";
import { NOT_FOUND } from "../../constants/routes";

const Wrapper = styled.div`
    height: 350px;
    width: 100%;
    position: relative;
    overflow-x: hidden;

    img {
        object-fit: cover;
        object-position: left center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -2;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        object-position: top center;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        height: 400px;
    }
`;

const FlexItem = styled(Flex.Item)`
    display: flex;
    align-items: center;
`;
const Quotes = styled.div`
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.color.primary};
    padding: 1rem;
    border-radius: 0.8rem;
    margin: 2rem 0;

    .icon {
        width: 50px;
        margin-bottom: 1rem;
    }
    ${H3} {
        line-height: 1.5;
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        flex-direction: row;
        align-items: center;
        .icon {
            width: 80px;
            margin: 0 2rem 0 0;
        }
    }
`;

const BlogDetails = () => {
    const { id } = useParams();
    const history = useHistory();
    const articlesDetails1 = listOfArticles.find(
        (article) => article.id === Number(id)
    );

    if (articlesDetails1) {
        const { meta, content, published, readingTime, title, tags } =
            articlesDetails1;
        return (
            <>
                <Wrapper>
                    <Container>
                        <img
                            src={decoratedBanner}
                            alt="decorated_blogs_banner"
                        />
                    </Container>
                </Wrapper>
                <Space xxsm={["0 0 0"]} xlm={["0 0 3rem"]}>
                    <Section height="auto">
                        <Container>
                            {/* <BannerImage>
                                <img src={solarMaskBanner} alt="blog_image" />
                            </BannerImage> */}
                            <Space
                                xxsm={["-180px 0 0"]}
                                xlm={["-200px 0 3rem"]}
                            >
                                <div>
                                    <Space xxsm={["3rem 0"]} xsm={["2rem 0"]}>
                                        <H2 color="white">{title}</H2>
                                    </Space>

                                    <Space xxsm={["0 0 2rem"]}>
                                        <Flex.Container
                                            align="center"
                                            justify="space-between"
                                        >
                                            <FlexItem>
                                                <P color="white">{published}</P>
                                                <Space xxsm={["0 1rem"]}>
                                                    <P color="white">|</P>
                                                </Space>
                                                <P color="white">
                                                    {readingTime}
                                                </P>
                                            </FlexItem>
                                            <Subscribe />
                                        </Flex.Container>
                                    </Space>
                                </div>
                            </Space>

                            {content.map((item) => (
                                <div key={item.id}>
                                    {item?.paragraph && (
                                        <Space xxsm={["2.5rem 0 0 "]}>
                                            <P lineHeight="3.2rem">
                                                &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                                                {item?.paragraph}
                                            </P>
                                        </Space>
                                    )}
                                    {item?.blockParagraph && (
                                        <Space xxsm={["2.5rem 0 0 "]}>
                                            <P lineHeight="3.2rem">
                                                {item?.blockParagraph}
                                            </P>
                                        </Space>
                                    )}

                                    {item?.quote && (
                                        <Quotes>
                                            <div className="icon">
                                                <img
                                                    src={quotesWhite}
                                                    alt="quotes-icon"
                                                />
                                            </div>
                                            <div className="quote">
                                                <H3
                                                    fontStyle="italic"
                                                    color="white"
                                                    weight="m"
                                                >
                                                    {item.quote}
                                                </H3>
                                            </div>
                                        </Quotes>
                                    )}
                                </div>
                            ))}
                            <Space xxsm={["3rem 0 2rem"]}>
                                <Flex.Container align="center">
                                    {tags.map((item) => (
                                        <Tag
                                            key={item.id}
                                            color="white"
                                            lineHeight="1.3em"
                                            size="s"
                                        >
                                            {item.name}
                                        </Tag>
                                    ))}
                                </Flex.Container>
                            </Space>

                            <Space xxsm={["0"]}>
                                <Flex.Container
                                    align="center"
                                    justify="space-between"
                                >
                                    <FlexItem>
                                        <FlexItem>
                                            <Icon
                                                component={TbHandClick}
                                                size="l"
                                            />

                                            <Space xxsm={["0 2rem 0 1rem"]}>
                                                <P>{meta.likes}</P>
                                            </Space>
                                        </FlexItem>
                                        <FlexItem>
                                            <Icon component={BsChat} size="l" />
                                            <Space xxsm={["0 0 0 1rem"]}>
                                                <P>{meta.comments}</P>
                                            </Space>
                                        </FlexItem>
                                    </FlexItem>
                                    <Subscribe />
                                </Flex.Container>
                            </Space>
                        </Container>
                    </Section>
                </Space>
            </>
        );
    } else {
        history.push(NOT_FOUND);
    }
};

export default BlogDetails;

const Tag = styled(P)`
    padding: 0.2rem 0.8rem;
    background: ${(props) => props.theme.color.grey};
    margin: 1rem 0.5rem 0 0;
    border-radius: 50px;
`;

const LinkedinButton = styled(Button)`
    :active,
    :focus {
        box-shadow: none;
    }
`;

const Subscribe = () => {
    return (
        <Link
            isExternal={true}
            to="https://www.linkedin.com/newsletters/7034791305847160832/?displayConfirmation=true"
        >
            <LinkedinButton type="solid" size="s">
                <Space xxsm={["0 .21rem 0 0"]}>
                    <Icon size="s" component={FaLinkedinIn} />
                </Space>
            </LinkedinButton>
        </Link>
    );
};
