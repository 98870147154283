export const ERROR_MSG = "Something went wrong, please try again later!";
export const LOGIN_SUCCESS_MSG = "User logged-in successfully!";
export const SIGNUP_SUCCESS_MSG = "Account created successfully!";
export const OPPORTUNITIES_SUCCESS = "Opportunities fetched successfully!";
export const OPPORTUNITY_DETAILS_SUCCESS = "Listing fetched successfully!";
export const COMMON_SUCCESS_MSG = "Data fetched successfully!";
export const PROFILE_UPDATE_SUCCESS_MSG = "Profile updated successfully";
export const BANK_VERIFICATION_POST_MSG = "Bank details submitted!";
export const DOCMENT_UPLOAD_MSG = "Document uploaded successfully!";
export const ASSET_CANCELLED = "Investment cancellation success!";
export const ASSET_INTEREST_CANCELLED = "Interest cancellation success!";
export const OPPORTUNITY_INTEREST_SUCCESS =
    "Thanks for showing Interest in Opportunity";
export const PASSWORD_UPDATE_SUCCESS_MSG =
    "Password has been updated successfully!";
export const INVESTOR_QUESTIONNAIRE_STATUS =
    "Questionnaire status fetched successfully!";
export const INVESTOR_QUESTIONNAIRE_UPDATE =
    "Questionnaire updated successfully!";
export const INVESTOR_OTP_SENT_MSG = "Sending OTP to user!";
export const REQUIRED_FIELD = "This field is required!";
export const NUMERIC_FIELD = "Values must be numeric!";

/* @DESC::  new constants  */

export const INVESTMENT_OPPRTUNITIES_SUCCESS =
    "Investment opportunites fetched successfully!";
export const VIRTUAL_SOLAR_INTEREST_SUCCESS =
    "Virtual Solar Interest saved successfully";

export const DEFAULT_TENURE_PERIOD = 20;
export const ONE_KWP = 1.39;
export const MINIMUM_INVESTMENT_AMOUNT = 10000;

export const SROT_RATE_10Y = 10;
export const SROT_RATE_5Y = 13.75;
export const YEAR = 12;

export const OTP_LENGTH = 6;
export const COUNTER_TIME = 30;
export const TYPE_PASSWORD = "password";
export const TYPE_TEXT = "text";
export const MAX_FEASIBILITY_DATE = 13;

export const MINIMUM_BILL_AMOUNT = 10000;

export const validImageTypes = [
    "JPG",
    "JPEG",
    "PNG",
    "BMP",
    "HEIF",
    "GIF",
    "WEBP",
];
export const INSTALLATION_TYPE = ["ROOF TOP MOUNTED", "GROUND MOUNTED"];
export const ROOF_TOP_TYPE = ["RCC", "METAL SHEET", "GROUND"];
export const BOOLEAN_VALUES = ["YES", "NO"];

export const MIN_SHADE_FREE_AREA = 100;
export const MIN_BILL_AMOUNT = 20000;

export const MAX_PLAN_TERM = 25;
export const FIVE_YEARS_TERM = 5;
export const TEN_YEARS_TERM = 10;

export const MAX_NOTES_LENGTH = 20000;
export const MAX_BILLING_GAP = 15;

export const CONNECTION_TYPE = [
    "Residential",
    "Housing Society",
    "Commercial",
    "Industrial",
];
export const CONNECTION_TYPE1 = [
    "Residential Society",
    "Individual Homes",
    "Commercial",
    "Industrial",
];
export const Year_Terms = [
    "5 Years",
    "6 Years",
    "7 Years",
    "8 Years",
    "9 Years",
    "10 Years ",
];
export const PROPOSAL_STATUS = ["APPROVED", "REJECTED", "FINAL"];
export const ROLE = {
    INVESTOR: "INVESTOR",
    CONSUMER_USER: "CONSUMER_USER",
    COMPANY_AGENT: "COMPANY_AGENT",
    CONSUMER_ADMIN: "CONSUMER_ADMIN",
    CONSUMER_OWNER: "CONSUMER_OWNER",
    COMPANY_ADMIN: "COMPANY_ADMIN",
    PARTNER: "PARTNER",
    SUPER_ADMIN: "SUPER_ADMIN",
    SALES_MANAGER: "SALES_MANAGER",
    OPERATION_MANAGER: "OPERATION_MANAGER",
};
export const SURVEY_STATUS = {
    SURVEY_BOOKED: "SURVEY_BOOKED",
    SURVEY_FILLED: "SURVEY_FILLED",
};

export const PLANT_STATUS = {
    SURVEY: "SURVEY",
    REJECTED: "REJECTED",
    PROPOSAL: "PROPOSAL",
    APPROVED: "APPROVED",
    BANKING: "BANKING",
    AGREEMENTS: "AGREEMENTS",
    ACTIVATION: "ACTIVATION",
};

export const IMAGES_PURPOSE = {
    NORTH_IMG: "NORTH_IMG",
    SOUTH_IMG: "SOUTH_IMG",
    EAST_IMG: "EAST_IMG",
    WEST_IMG: "WEST_IMG",
    OTHER_SITE_IMG: "OTHER_SITE_IMG",
};

export const VOTE = {
    FIVE_YEARS: "FIVE_YEARS",
    TEN_YEARS: "TEN_YEARS",
};
export const TERM_TYPE = {
    FIVE_YEARS: "FIVE_YEARS_TERM",
    TEN_YEARS: "TEN_YEARS_TERM",
};
