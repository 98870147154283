import React from "react";
import cn from "../../lib/cn";
import Section from "../../shared/Section";

const Hero = ({ content, url, align, type, wrapperClass, containerClass }) => {
    return (
        <Section className={containerClass}>
            <div
                style={{ backgroundImage: `url(${url})` }}
                className={cn("bg-cover", wrapperClass)}
            >
                <div className={`bg-primary/40 ${wrapperClass} w-full`}>
                    {content}
                </div>
            </div>
        </Section>
    );
};

export default Hero;
