const virtualSolarContainer = {
    hidden: {
        opacity: 0,
        y: -200,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1,
            when: "beforeChildren",
            staggerChildren: 0.6,
        },
    },
};
const virtualSolarImg = {
    hidden: {
        opacity: 0,
        y: 200,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 2,
            when: "beforeChildren",
            staggerChildren: 9,
        },
    },
};

const virtualOTP = {
    hidden: {
        scale: 0.8,
        opacity: 0,
        y: -100,
    },
    visible: {
        scale: 1,
        opacity: 1,
        y: 0,
        // y: 0,
        transition: {
            scale: 0.8,
            opacity: 0,
            y: -100,
        },
    },
    exit: {
        scale: 0.2,
        opacity: 0,
        // y: -1000,
        transition: {
            scale: 0.8,
            opacity: 0,
            y: -100,
        },
    },
};

export { virtualSolarContainer, virtualSolarImg, virtualOTP };
