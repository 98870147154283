import { ERROR_MSG } from "../constants/common";
import { REMOVE_USER, UPDATE_USER } from "../constants/contexConstant";
import {
    LOGIN_ENDPOINT,
    LOGIN_OTP_ENDPOINT,
    LOGOUT_ENDPOINT,
    PROFILE_API_ENDPOINT,
    REFRESH_TOKEN_ENDPOINT,
} from "../constants/routes";
import request from "./request";

const auth = (() => {
    let accessToken = null;
    const AUTH_TOKEN_EXPIRY = 30; //30 mins

    const getAccessToken = async () => {
        if (!accessToken) {
            await getRefreshToken();
        }
        return accessToken;
    };

    const setAccessToken = (at) => {
        accessToken = at;
    };

    const getRefreshToken = async () => {
        try {
            const response = await request.post({
                endpoint: REFRESH_TOKEN_ENDPOINT,
                body: {},
            });
            const accessToken =
                response && response.data && response.data.access_token;
            setAccessToken(accessToken);
            setTimeout(() => {
                getRefreshToken();
            }, 60 * 1000 * AUTH_TOKEN_EXPIRY);
        } catch (error) {
            // TODO : add error handling
        }
    };

    const login = (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await request.post({
                    endpoint: LOGIN_ENDPOINT,
                    body,
                });
                const accessToken =
                    response && response.data && response.data.access_token;
                setAccessToken(accessToken);
                return resolve();
            } catch (error) {
                return reject(error);
            }
        });
    };

    const loginOTP = (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await request.post({
                    endpoint: LOGIN_OTP_ENDPOINT,
                    body,
                });

                if (response?.data?.type === "success") {
                    return resolve(response?.data?.message);
                } else {
                    reject(response?.message || ERROR_MSG);
                }
            } catch (error) {
                return reject(error);
            }
        });
    };

    const logout = (dispatch) => {
        return new Promise(async (resolve, reject) => {
            try {
                await request.get({
                    endpoint: LOGOUT_ENDPOINT,
                });
                setAccessToken(null);
                dispatch({ type: REMOVE_USER, payload: null });
                return resolve();
            } catch (error) {
                return reject(error);
            }
        });
    };

    const isUserAuthenticated = async () => {
        const isUserAuthenticated = await getAccessToken();
        return isUserAuthenticated;
    };

    const getUserProfile = async (dispatch) => {
        const data = await request.authGet({
            endpoint: PROFILE_API_ENDPOINT,
        });
        dispatch({
            type: UPDATE_USER,
            payload: data?.data?.investor_profile,
        });
        return data;
    };

    return {
        login,
        loginOTP,
        getAccessToken,
        isUserAuthenticated,
        logout,
        getUserProfile,
    };
})();

export default auth;
