import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import Icon from "../../../../components/Icon/Icon";
import DatePicker from "../../../../components/date-picker/DatePicker";
import Input from "../../../../components/input/Input";
import Space from "../../../../components/space/Space";
import Container from "../../../../components/styles/Container.styles";
import {
    Flex,
    Section,
    SuggestedBtn,
} from "../../../../components/styles/Elements.styles";
import { H1, P } from "../../../../components/styles/Typography.styles";
import AuditLogType from "./AuditFilterRadio";
import AuditTable from "./AuditTable";

const Audit = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedDay, setSelectedDay] = useState(dayRange[0].value);
    const [selectedType, setSelectedType] = useState(typeRange[0].value);

    const handleOnChange = (e) => {
      
    };
    return (
        <Section>
            <Container>
                <Space
                    xxsm={["4rem 0 0rem"]}
                    stm={["5rem 0 1.5rem"]}
                    lgm={["6rem 0 1rem"]}
                >
                    <Flex.Container justify="space-between" align="flex-end">
                        <Space xxsm={["0 0 3rem"]} stm={["0"]}>
                            <Flex.Item xxs="100%" st="30%" t="25%" lg="25%">
                                <H1>Audit Log</H1>
                            </Flex.Item>
                        </Space>

                        <Flex.Item xxs="100%" st="65%" t="72%" lg="70%">
                            <Flex.Container justify="space-between">
                                <Space xxsm={["0 .5rem 0 0"]}>
                                    <Flex.Item xxs="100%">
                                        <AuditFlexLog
                                            align="center"
                                            justify="space-between"
                                            style={{ gap: "2rem" }}
                                        >
                                            <Flex.Item>
                                                <P>Audit Log Type: </P>
                                            </Flex.Item>
                                            <Flex.Item flex="1">
                                                <AuditLogType
                                                    data={typeRange}
                                                    name="typeRange"
                                                    checked={selectedType}
                                                    onChange={handleOnChange}
                                                />
                                            </Flex.Item>
                                        </AuditFlexLog>
                                    </Flex.Item>
                                </Space>
                                <Flex.Item
                                    xxs="100%"
                                    s="80%"
                                    st="100%"
                                    t="40%"
                                    lg="45%"
                                >
                                    <Space xxsm={["2rem 0 1rem"]}>
                                        <Flex.Container align="center">
                                            <Space xxsm={["0 .5rem 0 0"]}>
                                                <Flex.Item>
                                                    <P>Filter by:</P>
                                                </Flex.Item>
                                            </Space>

                                            <Flex.Item flex="1">
                                                <Input
                                                    size="xs"
                                                    placeholder="Search by Property id"
                                                />
                                            </Flex.Item>
                                        </Flex.Container>
                                    </Space>

                                    <SuggestedBtn>
                                        SNN234 <Icon component={FaTimes} />
                                    </SuggestedBtn>
                                </Flex.Item>
                                <Flex.Item
                                    xxs="100%"
                                    s="80%"
                                    st="100%"
                                    t="55%"
                                    lg="50%"
                                >
                                    <Space xxsm={["2rem 0 1rem"]}>
                                        <Flex.Container align="flex-start">
                                            <Space xxsm={["0 .5rem 0 0"]}>
                                                <Flex.Item>
                                                    <P lineHeight="33px">
                                                        Date Range:
                                                    </P>
                                                </Flex.Item>
                                            </Space>
                                            <Flex.Item flex="1">
                                                <DatePickerItem flex="1">
                                                    <DatePicker
                                                        value={startDate}
                                                        onChange={(e) =>
                                                            setStartDate(
                                                                e.target.value
                                                            )
                                                        }
                                                        showCalender={false}
                                                    />
                                                    <DatePicker
                                                        value={endDate}
                                                        onChange={(e) =>
                                                            setEndDate(
                                                                e.target.value
                                                            )
                                                        }
                                                        showCalender={false}
                                                    />
                                                </DatePickerItem>
                                                <Space xxsm={[".5rem 0 0"]}>
                                                    <div>
                                                        <AuditLogType
                                                            data={dayRange}
                                                            name="dayRange"
                                                            checked={
                                                                selectedDay
                                                            }
                                                            onChange={
                                                                handleOnChange
                                                            }
                                                        />
                                                    </div>
                                                </Space>
                                            </Flex.Item>
                                        </Flex.Container>
                                    </Space>
                                </Flex.Item>
                            </Flex.Container>
                        </Flex.Item>
                    </Flex.Container>
                </Space>
                <AuditTable />
            </Container>
        </Section>
    );
};

export default Audit;

const dayRange = [
    {
        id: 1,
        label: "All",
        value: "All",
    },
    {
        id: 2,
        label: "7D",
        value: "7D",
    },
    {
        id: 3,
        label: "10D",
        value: "10D",
    },
];
const typeRange = [
    {
        id: 1,
        label: "All",
        value: "All",
    },
    {
        id: 2,
        label: "Survey",
        value: "Survey",
    },
    {
        id: 3,
        label: "Proposal",
        value: "Proposal",
    },
    {
        id: 4,
        label: "Updates",
        value: "Updates",
    },
];
const DatePickerItem = styled(Flex.Item)`
    display: flex;
    border: solid 2px ${(props) => props.theme.color.lightGrey};
    justify-content: space-between;
    border-radius: ${(props) =>
        props.isRounded
            ? props.theme.sizes.xl10
            : props.radius
            ? props.theme.sizes[props.radius]
            : props.theme.sizes.xxs};
    .react-date-picker {
        width: 50%;
        .react-date-picker__wrapper {
            padding: 0.5rem 0.8rem;
            border: none;
        }
    }
    .react-date-picker__button {
        display: none;
    }
`;

const AuditFlexLog = styled(Flex.Container)`
    align-items: flex-start;
    width: 100%;
    .radioInputContainer {
        width: 100%;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        .radioInputContainer {
            width: 45%;
        }
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        align-items: center;
        .radioInputContainer {
            width: 45%;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        .radioInputContainer {
            width: auto;
        }
    }
`;
