import React from "react";
import RadioInput from "../../../../components/radio/RadioInput";
import Space from "../../../../components/space/Space";
import { Flex } from "../../../../components/styles/Elements.styles";
import { P } from "../../../../components/styles/Typography.styles";

const AuditFilterRadio = ({ label, data, name, checked, onChange }) => {
    return (
        <Flex.Container align="center">
            {label && (
                <Space stm={["0 2rem 0 0"]} lgm={["0 3rem 0 0"]}>
                    <Flex.Item xxs="100%" st="100%" t="22%" lg="24%" xl="24%">
                        <P>{label}</P>
                    </Flex.Item>
                </Space>
            )}

            <Flex.Item flex="1">
                <Flex.Container justify="space-between">
                    {data.map((item, i) => (
                        <Flex.Item key={i} className="radioInputContainer">
                            <RadioInput
                                checked={item?.value === checked}
                                label={item.label}
                                id={`filter_type_${item.label}`}
                                name={name}
                                color="black800"
                                activeColor="lightGreen"
                                value={item?.value}
                                onChange={onChange}
                            />
                        </Flex.Item>
                    ))}
                </Flex.Container>
            </Flex.Item>
        </Flex.Container>
    );
};

export default AuditFilterRadio;
