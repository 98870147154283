import React from "react";
import { About1 } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";
import ImageTextCard from "../../shared/ui/Cards/ImageTextCard";

const OurStory = () => {
    const Content = (
        <>
            <div className="flex flex-col gap-0.4">
                <P className={"text-secondary xl:text-2xl uppercase"}>
                    Our story
                </P>
                <H2>Lighting the way with solar energy</H2>
            </div>
            <P className={"text-secondary"}>
                Lorem ipsum dolor sit amet consectetur. Posuere consectetur
                malesuada nibh id iaculis et erat vulputate. Lectus dui
                suspendisse in pellentesque. Condimentum tortor enim non
                venenatis ultrices massa.{" "}
            </P>
        </>
    );

    return (
        <Section>
            <Container>
                <ImageTextCard
                    wrapperClass="flex-wrap"
                    content={Content}
                    url={About1}
                />
            </Container>
        </Section>
    );
};

export default OurStory;
