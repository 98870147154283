import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

import { Flex } from "../styles/Elements.styles";
import ModalTitle from "./ModalTitle";

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 120;
    padding: 100px 0;
    /* padding-top: 100px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
`;

const Container = styled.div`
    /* height: 100%; */
    width: 84%;
    margin-inline: auto;
    /* padding: 0% 20%; */
    box-sizing: border-box;

    /* flex-flow: column; */
    /* @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        padding: 30% 10%;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 20% 8%;
    } */
`;

export const CloseIcon = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
    color: ${(props) =>
        props.color ? props.theme.color[props.color] : props.theme.color.white};
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        right: 20px;
        top: 20px;
        font-size: 16px;
    }
`;
const FlexItem = styled(Flex.Item)`
    width: ${(props) => props.width[2]};
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        width: ${(props) => props.width[1]};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        width: ${(props) => props.width[0]};
    }
`;

const Content = styled.div`
    background-color: ${(props) =>
        props.contentBg ? props.theme.color[props.contentBg] : "transparrent"};
    padding: 1.5rem 2rem;
    border-radius: 10px;
`;

const ModalComponent = ({
    isOpen,
    title,
    onClose,
    times,
    children,
    width = ["60%", "100%", "100%"],
    contentBg,
}) => {
    return isOpen ? (
        <AnimatePresence>
            <Overlay>
                <Container>
                    <Flex.Container justify="center">
                        <FlexItem width={width}>
                            <ModalTitle
                                times={times}
                                onClick={onClose}
                                title={title}
                            />
                            <Content
                                as={motion.div}
                                layout
                                initial={{ scale: 0.8, opacity: 0, y: -1000 }}
                                animate={{ scale: 1, opacity: 1, y: 0 }}
                                exit={{ scale: 0.8, opacity: 0, y: 1000 }}
                                transition={{ type: "spring", duration: 0.5 }}
                                contentBg={contentBg}
                            >
                                {children}
                            </Content>
                        </FlexItem>
                    </Flex.Container>
                </Container>
            </Overlay>
        </AnimatePresence>
    ) : null;
};

export default ModalComponent;
