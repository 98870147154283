import { AnimatePresence, motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { flushSync } from "react-dom";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import remarkGfm from "remark-gfm";
import styled, { keyframes } from "styled-components";
import Space from "../../components/space/Space";
import { Flex } from "../../components/styles/Elements.styles";
import { P } from "../../components/styles/Typography.styles";
import { ERROR_MSG } from "../../constants/common";
import { CHAT_API_ENDPOINT } from "../../constants/routes";
import request from "../../services/request";
import ChatFooter from "./ChatFooter";
const ChatWrapper = styled.div`
    border-radius: 1rem;

    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 1rem;
    transition: all linear 0.35s;
    .messagesWrapper {
        background-color: ${(props) => props.theme.color.white};
        border-radius: 1rem;
        padding: 1rem 2rem 0 2rem;
        max-height: 60vh;
        overflow-y: auto;
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        max-width: ${(props) => (props.isSmall ? "80%" : "95%")};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        max-width: ${(props) => (props.isSmall ? "60%" : "80%")};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        max-width: ${(props) => (props.isSmall ? "60%" : "75%")};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        max-width: ${(props) => (props.isSmall ? "55%" : "70%")};
    }
`;
const ChatWrap = styled.div`
    display: flex;
    justify-content: ${(props) =>
        props.direction === "left" ? "flex-start" : "flex-end"};

    .chat {
        box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.25);
        max-width: 70%;
        background-color: ${(props) => props.theme.color.cyan200};
        background-color: ${(props) =>
            props.direction === "left"
                ? props.theme.color.cyan200
                : props.theme.color.blue200};
        border-radius: 0.2rem;
        padding: 0.5rem 1rem;
        margin: 1rem 0;
    }
`;
const ChatBox = () => {
    const [messageList, setMessageList] = useState([]);
    const [message, setMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const listRef = useRef(null);

    const handleScrollInto = () => {
        let lookingForAnswer = document.getElementById("lookingForAnswer");
        if (lookingForAnswer) {
            lookingForAnswer.scrollIntoView(false, {
                behavior: "smooth",
                block: "nearest",
            });
            listRef.current?.lastElementChild?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    };

    const processMessage = async (chatMessages) => {
        try {
            setIsTyping(true);
            let res = await request.post({
                endpoint: CHAT_API_ENDPOINT,
                body: {
                    question: message,
                },
            });

            let gptResponseMsg = {
                id: Math.random() + Date.now(),
                message: res?.data?.message?.content,
                role: res?.data?.message?.role,
                direction: "left",
            };

            flushSync(() => {
                setMessage("");
                setMessageList([...chatMessages, gptResponseMsg]);
            });

            handleScrollInto();
            setIsTyping(false);
        } catch (error) {
            setIsTyping(false);
            toast.error(
                typeof error === "string"
                    ? error
                    : error.message
                    ? error.message
                    : ERROR_MSG
            );
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let newMessage = {
            id: Math.random() + Date.now(),
            message: message,
            role: "user",
            direction: "right",
        };

        let newUserMsg = [...messageList, newMessage];

        setMessage("");

        flushSync(() => {
            setMessage("");
            setMessageList(newUserMsg);
        });

        handleScrollInto();

        await processMessage(newUserMsg);
    };

    const handleClear = () => {
        setMessageList([]);
        setMessage("");
        handleScrollToTop();
    };

    const handleScrollToTop = () => {
        let lookingForAnswer = document.getElementById("chatBoxContainerId");
        if (lookingForAnswer) {
            lookingForAnswer.scrollIntoView(true, {
                behavior: "smooth",
                block: "nearest",
            });
        }
    };

    return (
        <ChatWrapper isSmall={messageList.length === 0}>
            <AnimatePresence>
                {messageList.length > 0 && (
                    <div ref={listRef} className="messagesWrapper">
                        {messageList.map((item, index) => (
                            <ChatWrap
                                as={motion.div}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    duration: 0.4,
                                }}
                                exit={{ opacity: 0 }}
                                key={item.id}
                                direction={item.direction}
                            >
                                <div className="chat">
                                    <Flex.Container>
                                        <P xxs="s" st="s" lg="s">
                                            {item.role === "assistant" ? (
                                                <ReactMarkdown
                                                    children={item.message}
                                                    remarkPlugins={[remarkGfm]}
                                                />
                                            ) : (
                                                item.message
                                            )}
                                        </P>
                                    </Flex.Container>
                                </div>
                            </ChatWrap>
                        ))}
                    </div>
                )}
            </AnimatePresence>

            <div>
                <ChatFooter
                    onSubmit={handleSubmit}
                    onClear={handleClear}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div>

            <Space xxsp={["1rem 0 1.2rem 90px"]}>
                <Flex.Container
                    style={{ opacity: isTyping ? 1 : 0 }}
                    align="flex-end"
                    id="lookingForAnswer"
                >
                    <P
                        xxs="xs"
                        st="xs"
                        lg="xs"
                        lineHeight="1em"
                        color="primary"
                        size="s"
                    >
                        Looking for answer
                    </P>
                    <Dots>
                        <div></div>
                        <div></div>
                        <div></div>
                    </Dots>
                </Flex.Container>
            </Space>
        </ChatWrapper>
    );
};

export default ChatBox;

const breatheAnimation = keyframes`
 0% { opacity: 1;
    transform: scale(1.2);
    
}
 50% { opacity: 0.5;
    transform: scale(1);

}
 100% {  opacity: 1;
    transform: scale(1.2);
    
 }`;

const Dots = styled.span`
    display: flex;
    margin-left: 5px;
    line-height: 1em;
    margin-bottom: 2px;
    div {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.color.blue200};
        margin-right: 5px;
        animation: ${breatheAnimation};
        animation-duration: 1.5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
    }
    div:nth-child(2) {
        animation-delay: 0.5s;
    }
    div:nth-child(3) {
        animation-delay: 1s;
    }
`;
