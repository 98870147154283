import React from "react";
import Space from "../../../../../components/space/Space";
import { Flex } from "../../../../../components/styles/Elements.styles";
import ProposalDeatilsContainer from "../ProposalDetailsContainer";
import { ProCheckBox, ProRadioInput } from "../PropsoalRadioInput";
import { DetailsFiled } from "../SaveProposal";

const ConsumerDataForm = ({ formData2, intro, checkboxes }) => {
    return (
        <ProposalDeatilsContainer backgroundColor={"smoke"}>
            <Space xxsm={["3px  20px "]}>
                <Flex.Container justify="space-between" align="start">
                    {fields2.map((field) => (
                        <Flex.Item
                            xl="33%"
                            t="100%"
                            xxs="100%"
                            key={field.name}
                        >
                            {(formData2["Connection Type"] === "Industrial" ||
                                formData2["Connection Type"] ===
                                    "Individual Homes") &&
                            field.label === "Plan Duration" ? (
                                ""
                            ) : (
                                <DetailsFiled
                                    label={field.label}
                                    isSelect={field.isSelect}
                                    data={field.data}
                                    selected={field.selected}
                                    value={
                                        typeof formData2[field.label] ===
                                            "string" &&
                                        !isNaN(Number(formData2[field.label]))
                                            ? Number(
                                                  formData2[field.label]
                                              ).toLocaleString()
                                            : formData2[field.label]
                                    }
                                    placeholder={field.placeholder}
                                    helperText={
                                        field.helperText &&
                                        formData2["Consumer’s Bill"] &&
                                        formData2["Consumer’s Total Unit"]
                                            ? `Unit Rate: ${(
                                                  Number(
                                                      formData2[
                                                          "Consumer’s Bill"
                                                      ]
                                                  ) /
                                                  Number(
                                                      formData2[
                                                          "Consumer’s Total Unit"
                                                      ]
                                                  )
                                              ).toFixed(2)}`
                                            : ""
                                    }
                                    helperTextAlign={field.helperTextAlign}
                                    disabled={true}
                                    direction={"column"}
                                />
                            )}
                        </Flex.Item>
                    ))}
                    {formData2["Connection Type"] !== "Industrial" && (
                        <Flex.Item xl="30%" t="100%" xxs="100%">
                            <ProRadioInput
                                onChange={""}
                                type={"subsidy"}
                                title={
                                    formData2["Connection Type"] !==
                                    "Commercial"
                                        ? "Subsidy"
                                        : "Do You Have Tenant’s?"
                                }
                                selected={intro}
                                data={["Yes", "No"]}
                                disable={true}
                                direction={"column"}
                                align={"start"}
                            />
                        </Flex.Item>
                    )}
                    <Flex.Item xl="30%" t="100%" xxs="100%">
                        <ProCheckBox
                            title={"Proposal Type"}
                            connectionType={formData2["Connection Type"]}
                            selected={checkboxes}
                            disable={true}
                            arr={
                                formData2["Connection Type"] ===
                                    "Individual Homes" &&
                                formData2["Connection Type"] === "Industrial"
                                    ? ["Purchase", "Loan"]
                                    : formData2["Connection Type"] ===
                                      "Residential Society"
                                    ? ["Purchase", "Lease"]
                                    : ["Purchase", "Lease", "Loan"]
                            }
                            direction={"column"}
                            align="start"
                        />
                    </Flex.Item>
                </Flex.Container>
            </Space>
        </ProposalDeatilsContainer>
    );
};

export default ConsumerDataForm;

const fields2 = [
    {
        label: "Connection Type",
        name: "connectionType",
        value: "Residential Society",
    },
    {
        label: "Consumer’s Bill",
        name: "consumerbill",
        placeholder: "Enter Total Bill Amount",
        value: "20,00,000",
    },
    {
        label: "Consumer’s Total Unit",
        name: "totalunits",
        placeholder: "Total Electricity Unit Consumed",
        value: "00",
        helperText: true,
        helperTextAlign: "end",
    },
    {
        label: "Plan Duration",
        name: "duration",
        value: "5 Years",
    },
];
