import React from "react";
import { emailIcon, phoneIcon } from "../../resources/js/icons";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";
import ContactForm from "../../shared/ui/Form/ContactForm";

const MobileContact = () => {
    return (
        <Section>
            <Container>
                <div className="lg:hidden flex flex-col gap-2.4">
                    <div className="flex flex-col gap-0.8">
                        <H2>General enquiry</H2>
                        <P className={"text-secondary xl:w-[60%]"}>
                            Have questions about everything solar? Reach us at
                            sample@mail.com and we will get back to you shortly.
                        </P>
                        <div className="flex gap-5">
                            <div className="flex gap-1">
                                <img
                                    className="h-2"
                                    src={phoneIcon}
                                    alt={"Phone number"}
                                />
                                <P
                                    className={
                                        "lg:underline text-xs text-secondary lg:text-lg"
                                    }
                                >
                                    +91 9876543210
                                </P>
                            </div>
                            <div className="flex gap-1">
                                <img
                                    className="h-2"
                                    src={emailIcon}
                                    alt={"Email"}
                                />
                                <P
                                    className={
                                        "lg:underline text-xs text-secondary lg:text-lg"
                                    }
                                >
                                    Samplemaile@mail.com
                                </P>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-0.8">
                        <H3>Investor enquiry</H3>
                        <P className={"text-secondary xl:w-[60%]"}>
                            Have questions about everything solar? Reach us at
                            sample@mail.com and we will get back to you shortly.
                        </P>
                        <div className="flex flex-row gap-5">
                            <div className="flex gap-1">
                                <img
                                    className="h-2"
                                    src={phoneIcon}
                                    alt={"Phone number"}
                                />
                                <P
                                    className={
                                        "lg:underline text-xs text-secondary lg:text-lg"
                                    }
                                >
                                    +91 9876543210
                                </P>
                            </div>
                            <div className="flex gap-1">
                                <img
                                    className="h-2"
                                    src={emailIcon}
                                    alt={"Email"}
                                />
                                <P
                                    className={
                                        "lg:underline text-xs text-secondary lg:text-lg"
                                    }
                                >
                                    Samplemaile@mail.com
                                </P>
                            </div>
                        </div>
                    </div>

                    <ContactForm />
                </div>
            </Container>
        </Section>
    );
};

export default MobileContact;
