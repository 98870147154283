import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import LoadingSpinner from "../components/loading/LoadingSpinner";
import { LANDING, UN_AUTHORIZED } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import { checkIsAllowed } from "../helpers/helpers";
import useAuth from "../hooks/useAuth";

const PrivateRoute = ({
    component: Component,
    allowed,
    checkPlant,
    ...rest
}) => {
    const { state, dispatch } = useContextState();
    const location = useLocation();
    const { isAuth } = useAuth(location?.pathname, dispatch);
    const plant = state?.user?.plant;

    if (isAuth === null || (isAuth && state.user === null)) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }

    if (isAuth && !allowed) {
        return <Route {...rest}>{(props) => <Component {...props} />}</Route>;
    } else if (isAuth && allowed && !checkPlant) {
        return checkIsAllowed(state?.user?.roles, allowed) ? (
            <Route {...rest}>{(props) => <Component {...props} />}</Route>
        ) : (
            <Redirect to={UN_AUTHORIZED} />
        );
    } else if (isAuth && allowed && checkPlant) {
        return checkIsAllowed(state?.user?.roles, allowed) && plant !== null ? (
            <Route {...rest}>{(props) => <Component {...props} />}</Route>
        ) : (
            <Redirect to={UN_AUTHORIZED} />
        );
    }
    return (
        <Redirect
            to={{
                pathname: LANDING,
                state: { from: location },
            }}
        />
    );
};

export default PrivateRoute;
