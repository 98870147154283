import React from "react";
import styled from "styled-components";
import Button from "../../../../../components/button/Button";
import Space from "../../../../../components/space/Space";
import { Flex } from "../../../../../components/styles/Elements.styles";
import { H4 } from "../../../../../components/styles/Typography.styles";
import { DetailsFiled } from "../GenerateProposal";
import ProposalDeatilsContainer from "../ProposalDetailsContainer";
const ButtonStyled = styled(Button)`
    border: none;
    padding: 1px 55px;
    margin-top: ${(props) => (props.margin ? props.margin : "0")};
    margin-bottom: 3rem;
    height: 100%;
    font-size: 15px;
    :hover,
    :focus,
    :active {
        box-shadow: none;
    }
`;
const SurveyForm = ({ fields1, handleInputChange, formData1 }) => {
    return (
        <div>
            <Space xxsm={["10px 0 30px"]}>
                <H4>Survey Overview</H4>
            </Space>
            <ProposalDeatilsContainer>
                <Space m={["3px  30px "]}>
                    <Flex.Container direction="column">
                        {fields1.map((field) => (
                            <Flex.Item
                                xl="100%"
                                t="100%"
                                xxs="100%"
                                key={field.name}
                            >
                                <DetailsFiled
                                    label={field.label}
                                    isSelect={field.isSelect}
                                    data={field.data}
                                    selected={field.selected}
                                    value={formData1[field.label]}
                                    handleInputChange={handleInputChange}
                                    placeholder={field.placeholder}
                                />
                            </Flex.Item>
                        ))}
                    </Flex.Container>
                </Space>
                <Flex.Container justify="center" align="center">
                    <ButtonStyled margin="6.8rem">View Survey</ButtonStyled>
                </Flex.Container>
            </ProposalDeatilsContainer>
        </div>
    );
};

export default SurveyForm;
