import styled from "styled-components";
import Button from "../../../components/button/Button";

const PropertyBtn = styled(Button)`
    background-color: ${(props) =>
        props.backgroundColor
            ? props.theme.color[props.backgroundColor]
            : props.theme.color.primary};
    height: 30px;
    color: white;
    min-height: 30px;
    padding: 2px 2rem 2px;
    font-size: ${(props) => props.theme.fontSize.default.m};
    border-radius: 0.5rem;
`;

const PropertyContainer = styled.div`
    margin-top: 5rem;
    background: ${(props) => props.theme.color.grey200};
    border-radius: 1rem;
    box-shadow: 0 0 10px ${(props) => props.theme.color.grey400};

    .footer {
        background: ${(props) => props.theme.color.white};
    }
    .main,
    .footer {
        padding: 1rem;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        .main,
        .footer {
            padding: 1.5rem;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        .main,
        .footer {
            padding: 2rem;
        }
    }
`;

export { PropertyBtn, PropertyContainer };
