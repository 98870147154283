import React from "react";
import styled from "styled-components";
import Text from "../text/Text";

const TextareaComponent = styled.textarea`
    transition: all ease-in 0.3s;
    width: 100%;
    padding: 10px 10px;
    line-height: 1.6em;
    color: ${(props) => props.theme.color.black};
    border: solid 1px
        ${(props) =>
            props.isError
                ? props.theme.color.error
                : props.theme.color.grey600};
    font-size: ${(props) => props.theme.fontSize.default.s};
    /* change latter */
    font-family: ${(props) =>
            props.type === "number" ? "Montserrat" : "Montserrat"},
        sans-serif;
    font-weight: 400;
    background: ${(props) =>
        props.disabled ? props.theme.color.grey600 : props.theme.color.white};
    border-radius: ${(props) => props.radius || "3px"};
    box-shadow: ${(props) =>
        props.shadow ? "rgba(17, 17, 26, 0.20) 0px 0px 12px" : ""};
    box-sizing: border-box;
    &:hover,
    &:focus {
        outline: none;
    }
    &:focus {
        outline: none;
        box-shadow: ${(props) =>
            props.isError
                ? "rgb(228 16 16 / 25%) 0px 0px 6px"
                : "rgb(37 104 156 / 29%) 0px 0px 4px"};
        border-color: ${(props) =>
            props.isError
                ? props.theme.color.error
                : props.theme.color.primary};
    }

    &::placeholder {
        color: ${(props) => props.theme.color.darkGrey};
    }
`;

const ErrorText = styled(Text)`
    color: ${(props) => props.theme.color.error};
    padding-top: 10px;
`;

const Textarea = ({
    name,
    value,
    onChange,
    placeholder,
    errorMessage = "",
    ...rest
}) => (
    <React.Fragment>
        <TextareaComponent
            name={name}
            rows="3"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            isError={!!errorMessage}
            {...rest}
        />
        {errorMessage ? <ErrorText>{errorMessage}</ErrorText> : null}
    </React.Fragment>
);

export default Textarea;
