import React from "react";
import { createRoot } from "react-dom/client";
import "../src/resources/css/index.css";
import "../src/resources/css/urbanist.css";
import App from "./App.js";
import ContextProvider from "./context/ContextProvider.js";

const container = document.getElementById("root");

const root = createRoot(container);
root.render(
    <ContextProvider>
        <App />
    </ContextProvider>
);
