import React from "react";
import Accordion from "../../../components/accordion/Accordion";
import { ErrorText } from "../../../components/input/Input";
import UploadImage, {
    PlaceHolderPreview,
} from "../../../components/input/UploadImage";
import Line from "../../../components/line/Line";
import Space from "../../../components/space/Space";
import { Flex } from "../../../components/styles/Elements.styles";
import { H3 } from "../../../components/styles/Typography.styles";
import SurveySite from "./SurveySite";

const WrapperSurveySite = ({
    lightsBills,
    onChange,
    lightBillsError,
    error,
    siteList,
    formError,
    onChangeSurveySite,
    setSiteUploadData,
    disabled = false,
    onPreview = null,
    onRemove,
}) => {
    return (
        <div>
            <Space xxsm={["0 0 2rem "]} lgm={["0 0 2rem "]}>
                <H3>Electricity Bill Images</H3>
            </Space>
            <Flex.Container>
                {lightsBills.map((file) => (
                    <PlaceHolderPreview
                        key={file.id}
                        bottomLabel={file?.purpose}
                        id={file.id}
                        onPreview={onPreview}
                    />
                ))}
                {!disabled && (
                    <UploadImage onChange={onChange} name="uploadLightBills" />
                )}
            </Flex.Container>
            {(lightBillsError || error) && (
                <ErrorText
                    style={{
                        display: "inline-block",
                        margin: "0 0 5px",
                    }}
                    xxs="xs"
                    st="xs"
                >
                    {lightBillsError || error}
                </ErrorText>
            )}
            <Line height="2px" backgroundColor="grey400" />
            <Space xxsm={["2rem  0"]}>
                <H3>Survey Site Details</H3>
            </Space>
            {siteList.length > 0 &&
                siteList.map((item, index) => (
                    <Accordion
                        size={["m", "l", "l"]}
                        weight="sb"
                        isOpen={true}
                        key={item?.siteId}
                        label={`Site Id #${index + 1}`}
                        isLine
                    >
                        <SurveySite
                            disabled={disabled}
                            siteId={item?.siteId}
                            index={index}
                            onChange={onChangeSurveySite}
                            data={item}
                            error={formError && formError[index]}
                            onUpload={setSiteUploadData}
                            onPreview={onPreview}
                            onRemove={onRemove}
                        />
                    </Accordion>
                ))}
        </div>
    );
};

export default WrapperSurveySite;
