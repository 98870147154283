import React from "react";

const ListItem = ({ value }) => {
    return (
        <ul className="flex items-center xl:items-start gap-1 flex-nowrap ">
            <div className="w-[6px] h-[6px] bg-black rounded-full  xl:mt-1 "></div>
            <li className="text-secondary text-xs  sm:text-base 2xl:text-xl flex-1">
                {value}
            </li>
        </ul>
    );
};
export default ListItem;
