import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import {
    MobileCostSaving,
    MobileEnergy,
    MobileEnviorment,
    MobileIncentive,
    MobileMaintenace,
    MobileResillience,
} from "../../../resources/js/images";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import {
    opacity,
    translateContainer,
    wavyHeadings,
} from "../../../shared/animation/constants/landingAnimation";
import SplitText from "../../../shared/animation/typography/SplitText";
import P from "../../../shared/typography/P";
import { Carousel } from "../../../shared/ui/Carousel";
import Tab from "../../../shared/ui/tab/Tab";

const createSlide = (imageSrc, altText, overlayText) => (
    <div className="relative  w-full h-[175px] sm:h-[365px] md:h-[300px] lg:h-[450px]  2xl:h-[550px]">
        <img
            src={imageSrc}
            alt={altText}
            className="object-cover w-full h-full"
        />
    </div>
);

const CarouselSection = () => {
    const slides = [
        {
            imageSrc: MobileEnviorment,
            altText: "Slide 1",
            overlayText:
                "We are at the forefront of the renewable energy revolution, specializing in high-quality, cost-effective solar power solutions designed to meet the diverse needs of both urban and rural communities.",
        },
        {
            imageSrc: MobileCostSaving,
            altText: "Slide 2",
            overlayText: "Slide 2 Title",
        },
        {
            imageSrc: MobileEnergy,
            altText: "Slide 3",
            overlayText: "Slide 3 Title",
        },
        {
            imageSrc: MobileIncentive,
            altText: "Slide 4",
            overlayText: "Slide 4 Title",
        },
        {
            imageSrc: MobileMaintenace,
            altText: "Slide 5",
            overlayText: "Slide 5 Title",
        },
        {
            imageSrc: MobileResillience,
            altText: "Slide 6",
            overlayText: "Slide 6 Title",
        },
    ].map((slide) =>
        createSlide(slide.imageSrc, slide.altText, slide.overlayText)
    );

    const tags = [
        "Environmental Benefits",
        "Cost Savings",
        "Energy Independence",
        "Government Incentives",
        "Low Maintenance",
        "Resilience & Reliability",
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const overflowContainerRef = useRef(null);
    const tabRefs = useRef([]);
    useEffect(() => {
        if (tabRefs.current[currentSlide] && overflowContainerRef.current) {
            const tab = tabRefs.current[currentSlide];
            const container = overflowContainerRef.current;
            const containerRect = container.getBoundingClientRect();
            const tabRect = tab.getBoundingClientRect();

            if (
                tabRect.left < containerRect.left ||
                tabRect.right > containerRect.right
            ) {
                const offset = tabRect.left - containerRect.left;
                container.scrollLeft +=
                    offset - containerRect.width / 2 + tabRect.width / 2;
            }
        }
    }, [currentSlide]);
    const handleSlideChange = (newIndex) => {
        setCurrentSlide(newIndex);
    };

    return (
        <Section>
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className="flex justify-between gap-1.6 flex-col lg:flex-row  items-center lg:gap-9 mb-3.2"
                >
                    <div className=" w-full  lg:w-[35%]  xl:w-[30%] 2xl:w-[35%]">
                        <SplitText variants={wavyHeadings} heading="h2">
                            Embracing Solar Solutions
                        </SplitText>
                    </div>
                    <motion.div
                        variants={opacity}
                        className=" w-full lg:w-[65%] xl:w-[45%]"
                    >
                        <P className="text-secondary">
                            We are at the forefront of the renewable energy
                            revolution, specializing in high-quality,
                            cost-effective solar power solutions designed to
                            meet the diverse needs of both urban and rural
                            communities.
                        </P>
                    </motion.div>
                </motion.div>
                <div
                    ref={overflowContainerRef}
                    className="flex flex-nowrap overflow-x-auto  w-full gap-2.4 no-scrollbar mb-2.4 sm:mb-4"
                >
                    {tags.map((item, i) => (
                        <div ref={(el) => (tabRefs.current[i] = el)} key={i}>
                            <Tab
                                active={i === currentSlide}
                                text={item}
                                onClick={() => handleSlideChange(i)}
                            />
                        </div>
                    ))}
                </div>
                <div>
                    <Carousel
                        slides={slides}
                        flexPos="justify-start lg:justify-center"
                        autoSlide={true}
                        autoSlideInterval={3000}
                        handleSlideChange={handleSlideChange}
                        currentSlide={currentSlide}
                        headclass="rounded-m h-full border   border-blue-400"
                    />
                </div>
            </Container>
        </Section>
    );
};

export default CarouselSection;
