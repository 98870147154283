import React, { useState } from "react";

import P from "../../../shared/typography/P";
import Faq from "../../home/Faq";
import Calculator from "../../home/Sections/Calculator";
import EnquiryPopUp from "../../home/Sections/EnquiryPopUp";
import PriceSection from "../../home/Sections/PriceSection";
import ProjectCarouselSection from "../../home/Sections/ProjectCarouselSection";
import GovernmentSubsidies from "../GovernmentSubsidies";
import SubsidyWithReview from "../SubsidyWithReview";
import InvestInsurance from "../sections/InvestInsurance";

const subsidyData = {
    summary: (
        <P className="text-secondary">
            The addition of government subsidies makes solar panels more
            affordable and accessible for everyone. The government provides
            incentives in the form of subsidies and tax benefits that reduce the
            solar cost significantly for housing societies and increase their
            adoption rate.
        </P>
    ),
    data: [
        {
            title: "For Housing Society/Resident Welfare Association (GHS/RWA)",
            list: [
                "A subsidy of ₹18,000 per kW is provided for common facilities up to 500 kW capacity.",
            ],
        },
    ],
};

const ResidentialSociety = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>
            <PriceSection />
            <Calculator
                defaultIndex={0}
                heading="Calculate and Save"
                text="Find an ideal plan by simply filling out a few details."
                bottomHeading="Go Solar: A Smart Choice for Everyone!"
                bottomText="Finding an ideal plan can be tough. With our simple plans, you only get what you pay for—no hidden costs!"
            />
            <GovernmentSubsidies
                summary={subsidyData.summary}
                content={
                    <SubsidyWithReview
                        data={subsidyData.data}
                        onClick={() => setShowModal(true)}
                    />
                }
            />
            <ProjectCarouselSection />
            <InvestInsurance />
            {/* <ContactUSForm data={contactData} enquiry={<ContactEnquiry />} /> */}
            <Faq />
            <EnquiryPopUp
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                callback={() => setShowModal(false)}
            />
        </div>
    );
};

export default ResidentialSociety;
