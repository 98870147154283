import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { logoDark } from "../../assets";

import {
    ADMIN,
    ADMIN_LABEL,
    AUDIT,
    AUDIT_LABEL,
    BLOGS,
    CONTACTUS,
    DASHBOARD,
    DASHBOARD_LABEL,
    FAQ,
    LANDING,
    NOT_FOUND,
    PAYMENT,
    PAYMENT_LABEL,
    SURVEY_VIEW,
    SURVEY_VIEW_LABEL,
    UPDATE_MASTER_DATA,
    UPDATE_MASTER_DATA_LABEL,
    burgerNavLinks,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import useScroll from "../../hooks/useScroll";

import auth from "../../services/auth";
import Button from "../button/Button";

import { ROLE } from "../../constants/common";
import { SHOW_LOGIN_FORM } from "../../constants/contexConstant";
import { RoleCheck } from "../../helpers/helpers";
import Image from "../image/Image";
import CustomLink from "../link/Link";
import Container from "../styles/Container.styles";
import { Flex } from "../styles/Elements.styles";
import Burger from "./BurgerIcon";
import Menu, { UserAvatar } from "./BurgerMenu";
import PortalDropdown from "./PortalDropdown";

export const HeaderHeight = {
    default: "75px",
    laptop: "70px",
    tablet: "60px",
};

const HeaderFixed = styled.header`
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 110;
    box-sizing: border-box;
    transition: all ease-in 0.3s;
    background: ${(props) =>
        props.isVisible ? props.theme.color.white : "transparent"};
    box-shadow: ${(props) =>
        props.isVisible ? "0 1px 6px 0 rgb(0 0 0 / 15%)" : "none"};
`;
const HeaderContainer = styled.nav`
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        /* padding: 0 20px; */
        height: 65px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        height: 70px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        height: 75px;
    }
`;

const DalogoDarkImage = styled(Image)`
    height: 30px;
    width: auto;
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        height: 30px;
        width: auto;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        height: 40px;
        width: auto;
    }
`;

const HeaderLinkSection = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: none;
    }
`;

const BurgerMenu = styled.div`
    display: unset;
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        display: none;
    }
`;

const Avatar = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    position: relative;
    cursor: pointer;
`;

export const ProfileDrop = styled.div`
    display: flex;
    flex-direction: column;
    transform: ${({ profileDropOpen }) =>
        profileDropOpen ? "translateY(0)" : "translateY(-150%)"};
    align-items: center;
    padding: 25px;
    width: 200px;
    background-color: ${(props) =>
        props.backgroundColor
            ? props.backgroundColor
            : props.theme.color.whitesmoke};
    position: absolute;
    right: -15px;
    border-radius: 5px;
    top: ${HeaderHeight.default};
    transition: transform 0.3s ease-in-out;
    margin-top: -1px;
    z-index: 10;
    @media (max-width: ${(props) => props.theme.breakPoints.laptop}) {
        top: ${HeaderHeight.laptop};
    }
`;
export const LinkDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;
const styles = { marginBottom: "20px" };

const HeaderLinkContainer = styled.div`
    white-space: nowrap;
    position: relative;
    transition: all ease 0.5s;
    /* transform: ${(props) => (props.active ? "scale(1.1)" : "")}; */
    display: flex;
    align-items: center;
    height: 100%;
`;
/* @TODO ==> change active link color  Tue Oct 04  */
const NavLink = styled(Link)`
    color: ${(props) =>
        props.active === "true"
            ? props.theme.color.secondary
            : props.theme.color.primary};
    text-decoration: none;
    transition: all ease 0.3s;
    /* font-size: ${(props) => props.theme.fontSize.default[props.size]}; */
    font-weight: ${(props) => props.theme.fontWeight.rg};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 20px;

    line-height: ${(props) => props.lineHeight || "1.6em"};
    /*  */
    font-size: 14px;
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        /* font-size: ${(props) => props.theme.fontSize.tablet[props.size]}; */
        font-size: 16px;
        /* font-size: 16px; */
    }

    :hover {
        color: ${(props) =>
            props.active === "true"
                ? props.theme.color.secondary
                : props.theme.color.primary};
    }
    ::after {
        content: "";
        background-color: ${(props) =>
            props.active === "true"
                ? props.theme.color.secondary
                : props.theme.color.primary};
        height: 4px;
        position: absolute;
        bottom: 0px;
        left: 0;
        transition: all ease 0.3s;
        display: block;
        width: ${(props) => (props.active === "true" ? "98%" : "0")};
    }
    :hover::after {
        background-color: ${(props) =>
            props.active === "true"
                ? props.theme.color.secondary
                : props.theme.color.primary};
        width: 98%;
    }
`;

export const HeaderBtn = styled(Button)`
    padding: 0.3rem;
    margin-left: 10px;
    border-radius: 100px;
    padding: 0.5rem 2rem;
    min-height: 3.5rem;
`;
const LinkWrap = styled(Link)`
    margin-left: 0px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        margin-left: 0px;
    }
`;
const ThumbnailBox = styled(Flex.Container)`
    display: none;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: flex;
    }
`;
const HeaderLink = ({ route }) => {
    const active = window.location.pathname === route.path;
    return (
        <HeaderLinkContainer active={`${active}`}>
            <NavLink active={`${active}`} to={route.path} size="s">
                {route.name}
            </NavLink>
        </HeaderLinkContainer>
    );
};

const Header = (props) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const { state, dispatch } = useContextState();
    const [profileDropOpen, setDrop] = useState(false);

    const handleDrop = useCallback(() => {
        setDrop(!profileDropOpen);
    }, [setDrop, profileDropOpen]);

    const toggleBurgerMenu = useCallback(
        (isOpen) => {
            if (isOpen) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "";
            }
            setOpen(isOpen);
        },
        [setOpen]
    );

    const handleLoginForm = (e) => {
        e.preventDefault();
        dispatch({ type: SHOW_LOGIN_FORM });
    };

    const handleLogout = (e) => {
        e.preventDefault();

        auth.logout(dispatch)
            .then(() => {
                history.push(LANDING);
            })
            .catch(() => {});
    };

    const { scrollY } = useScroll();
    const { pathname } = useLocation();
    const isVisible =
        scrollY > 50 ||
        pathname.includes(`${BLOGS}/`) ||
        pathname.includes(`${NOT_FOUND}`);

    return (
        <>
            <HeaderFixed isVisible={isVisible}>
                <Container>
                    <HeaderContainer>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <BurgerMenu>
                                <Burger
                                    isBurgerMenuOpen={open}
                                    toggleBurgerMenu={toggleBurgerMenu}
                                />
                                <Menu
                                    links={
                                        props.isAuthenticated
                                            ? !state?.user ||
                                              RoleCheck(state?.user?.roles)
                                                ? props.links
                                                : []
                                            : burgerNavLinks
                                    }
                                    isAuthenticated={props.isAuthenticated}
                                    isBurgerMenuOpen={open}
                                    toggleBurgerMenu={toggleBurgerMenu}
                                />
                            </BurgerMenu>

                            <LinkWrap
                                to={LANDING}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <DalogoDarkImage src={logoDark} />
                            </LinkWrap>
                        </div>

                        <HeaderLinkSection>
                            {!state?.user || RoleCheck(state?.user?.roles) ? (
                                props.links.map((route, index) => (
                                    <HeaderLink route={route} key={index} />
                                ))
                            ) : (
                                <></>
                            )}

                            {state?.user?.roles &&
                                state.user.roles.some(
                                    (role) =>
                                        role.name === ROLE.SUPER_ADMIN ||
                                        role.name === ROLE.SALES_MANAGER
                                ) && (
                                    <PortalDropdown
                                        links={DROP_DOWN_LINKS}
                                        isChanging={true}
                                    />
                                )}
                            {state?.user?.roles &&
                                state.user.roles.some(
                                    (role) => role.name === ROLE.SUPER_ADMIN
                                ) && (
                                    <PortalDropdown
                                        links={AdminLinks}
                                        title={{
                                            name: ADMIN_LABEL,
                                            link: ADMIN,
                                        }}
                                        isChanging={false}
                                    />
                                )}

                            {!state?.user && (
                                <div>
                                    <HeaderBtn
                                        color="white"
                                        backgroundColor="primary"
                                        xxs="xl"
                                        weight="b"
                                        onClick={handleLoginForm}
                                        st="m"
                                    >
                                        Login
                                    </HeaderBtn>
                                </div>
                            )}

                            {state?.user && (
                                <Avatar onClick={handleDrop}>
                                    <UserAvatar backgroundColor="primary" />
                                    <ProfileDrop
                                        id="profiledrop"
                                        profileDropOpen={profileDropOpen}
                                        onClick={handleDrop}
                                    >
                                        <LinkDiv>
                                            {/* <CustomLink
                                                color="white"
                                                size="s"
                                                to={PROFILE}
                                                isInteractive={true}
                                                style={styles}
                                            >
                                                My Profile
                                            </CustomLink> */}
                                            <CustomLink
                                                color="primary"
                                                size="s"
                                                to={CONTACTUS}
                                                isInteractive={true}
                                                style={styles}
                                            >
                                                Contact Us
                                            </CustomLink>
                                            <CustomLink
                                                color="primary"
                                                size="s"
                                                to={FAQ}
                                                isInteractive={true}
                                                style={styles}
                                            >
                                                FAQs
                                            </CustomLink>
                                        </LinkDiv>
                                        <Button
                                            type="solid"
                                            backgroundColor="primary"
                                            color="white"
                                            size="s"
                                            style={{ width: "100%" }}
                                            onClick={handleLogout}
                                        >
                                            Log Out
                                        </Button>
                                    </ProfileDrop>
                                </Avatar>
                            )}
                        </HeaderLinkSection>

                        <ThumbnailBox align="center">
                            {state?.user && (
                                <UserAvatar backgroundColor="primary"></UserAvatar>
                            )}
                        </ThumbnailBox>
                    </HeaderContainer>
                </Container>
            </HeaderFixed>
            {/* <Countdown /> */}
        </>
    );
};

Header.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            path: PropTypes.string,
        })
    ),
    homeLink: PropTypes.shape({
        icon: PropTypes.string,
        path: PropTypes.string,
    }),
};

export default Header;
export const DROP_DOWN_LINKS = [
    {
        name: DASHBOARD_LABEL,
        path: DASHBOARD,
    },
    {
        name: AUDIT_LABEL,
        path: AUDIT,
    },
    {
        name: PAYMENT_LABEL,
        path: PAYMENT,
    },
    {
        name: SURVEY_VIEW_LABEL,
        path: SURVEY_VIEW,
    },
];

export const AdminLinks = [
    {
        name: ADMIN_LABEL,
        link: ADMIN,
    },
    {
        name: UPDATE_MASTER_DATA_LABEL,
        path: UPDATE_MASTER_DATA,
    },
    /* {
        name: UPDATE_USER_ROLE_LABEL,
        path: UPDATE_USER_ROLE,
    },
    
    {
        name: REVISION_REQ,
        path: REVISION_REQUEST,
    },*/
];
