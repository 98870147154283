import React, { forwardRef } from "react";
import withClickOutside from "../../../hoc/withClickOutside";
import cn from "../../../lib/cn";
import P from "../../../shared/typography/P";

const BottomLineDropDown = forwardRef(
    (
        {
            selected,
            onSelect,
            isSelected,
            setIsSelected,
            icon,
            data,
            wrapperClass = "bg-blue-300 border-blue-400",
        },
        ref
    ) => {
        const handleSelect = (value) => {
            onSelect(value);
        };
        return (
            <div
                className="relative sm:pb-1.2 xl:pb-1.6 border-b-2 border-blue-500 hover:cursor-pointer"
                role="button"
                onClick={(e) => {
                    return setIsSelected((prev) => !prev);
                }}
                ref={ref}
            >
                <div className="flex justify-between gap-1 items-center">
                    <P className="flex-1">{selected}</P>{" "}
                    <div className="icon">{icon}</div>
                </div>

                <div
                    className={cn(
                        "options absolute start-0 end-0 top-[45px] z-30  shadow-md transition-all duration-300",
                        wrapperClass,
                        {
                            "max-h-[500px] overflow-auto": isSelected,
                            "h-0 overflow-hidden": !isSelected,
                        }
                    )}
                >
                    {data.map((item, i) => (
                        <P
                            key={i}
                            role="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsSelected(!isSelected);
                                return handleSelect(item);
                            }}
                            className="px-1 py-0.5 border-b  hover:bg-gray-150 transition-all duration-200 hover:cursor-pointer"
                        >
                            {item}
                        </P>
                    ))}
                </div>
            </div>
        );
    }
);

export default withClickOutside(BottomLineDropDown);
