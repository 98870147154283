import React from "react";
import { TiTick } from "react-icons/ti";
import "./stepper.css";

const StepIndicator = ({ step, currentStep, onClick, label }) => (
    <div
        className={`step-item ${currentStep === step ? "active" : ""} ${
            currentStep > step ? "complete" : ""
        }`}
    >
        <div className="step" onClick={() => onClick(step)}>
            {currentStep > step ? <TiTick size={24} /> : step}
        </div>
        <div className="step-label mt-2 font-urbanist font-semibold text-xl">
            {label}
        </div>
    </div>
);

const ProgressBar = ({ currentStep, setCurrentStep }) => {
    const stepLabels = ["Lifestyle", "Mobility", "Household"];

    return (
        <div className="flex justify-between items-center mb-6">
            {[1, 2, 3].map((step, index) => (
                <React.Fragment key={step}>
                    <StepIndicator
                        step={step}
                        currentStep={currentStep}
                        onClick={setCurrentStep}
                        label={stepLabels[index]}
                    />
                    {step < 3 && (
                        <div
                            className={`flex-1 h-0.5 ${
                                currentStep > step
                                    ? "bg-green-600"
                                    : "bg-slate-200"
                            }`}
                        />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default ProgressBar;
