import React from "react";
import DropDown from "../../components/dropdown/DropDown";
import Input from "../../components/input/Input";
import Space from "../../components/space/Space";
import { Flex } from "../../components/styles/Elements.styles";
import { P } from "../../components/styles/Typography.styles";

const DetailsField = ({
    label,
    onChange,
    isSelect,
    selected,
    data,
    componentType = "input",
    maxLength,
    ...rest
}) => {
    return (
        <Space lgm={[" 0 0 25px "]} tm={["0 0 20px"]} xxsm={["0 0 10px"]}>
            <Flex.Container justify="space-between" align="center">
                <Flex.Item xxs="100%" st="100%" t="40%" lg="35%">
                    <P color="black" xxs="s" st="m" lg="ml">
                        {label}
                    </P>
                </Flex.Item>
                <Space tm={[".1rem 0 0 "]}>
                    <Flex.Item xxs="100%" st="100%" t="50%" lg="60%">
                        {isSelect ? (
                            <DropDown
                                data={data}
                                onChange={(value) => onChange(value)}
                                selected={selected}
                                {...rest}
                            />
                        ) : (
                            <Input
                                onChange={onChange}
                                backgroundColor="white"
                                shadow={false}
                                componentType={componentType}
                                maxLength={maxLength}
                                style={{
                                    paddingBottom: "7px",
                                    paddingTop: "7px",
                                }}
                                {...rest}
                            />
                        )}
                    </Flex.Item>
                </Space>
            </Flex.Container>
        </Space>
    );
};

export default DetailsField;
