import { climateJustice, climateCrisis } from "../assets";

const articlesCard = [
    {
        id: 1,
        img: climateJustice,

        tag: "#Climate",
        date: "27Feb '23",
        title: "Working Together To Address The Climate Crisis",
        content:
            "The first step to fighting climate change is to increase awareness and understanding of the issue",
    },
    {
        id: 2,
        img: climateCrisis,
        tag: "#Sustainability",
        date: "06Mar '23",
        title: "Fight against climate change - It Comes at a Cost",
        content:
            "While the fight against climate change comes at a cost, the cost of inaction is far greater.",
    },
];

const listOfArticles = [
    {
        id: 1,
        title: "Working Together To Address The Climate Crisis",
        published: "27th Feb 2023",
        readingTime: "3 min read",
        content: [
            {
                id: 1,
                blockParagraph:
                    "Climate change is a global challenge that requires a global response. That’s why it’s essential to have open and constructive dialogue among all stakeholders to address this urgent issue. Climate dialogues bring together policymakers, experts, businesses, and civil society to discuss and find solutions to the complex challenges of climate change.",
            },
            {
                id: 2,
                blockParagraph:
                    "Through climate dialogue, we can exchange knowledge and ideas, share best practices, and identify new opportunities for collaboration. These conversations can help identify areas where we need to take action and provide a platform to mobilize collective efforts.",
            },
            {
                id: 3,
                quote: "The first step to fighting climate change is to increase awareness and understanding of the issue.",
            },
            {
                id: 4,
                blockParagraph:
                    "This can include educating oneself about the causes and effects of climate change, and the actions that can be taken to mitigate its impact. It is also important to recognize the urgency of the issue and the need for collective action. We can build a movement toward a more sustainable and resilient future by spreading awareness and encouraging others to take action. Additionally, individuals can take steps to reduce their carbon footprint, such as reducing energy consumption, using sustainable transportation, and making environmentally-conscious choices in their daily lives.",
            },
            {
                id: 5,
                blockParagraph:
                    "The Climate dialogues can be a platform for engaging and raising awareness about the importance of addressing climate change. It will empower individuals to take action in their communities and encourage them to advocate for climate policies that promote sustainability and resilience.",
            },
            {
                id: 6,
                blockParagraph:
                    "Let's bring together diverse stakeholders and foster cooperation, to accelerate progress toward a sustainable and resilient future for all. So let’s keep the conversation going and work together to create a better world for future generations.",
            },
        ],
        tags: [
            {
                id: 1,
                name: "Climate Change ",
            },
            {
                id: 2,
                name: "Sustainability",
            },
        ],
        meta: {
            likes: "1.96K",
            comments: "52",
        },
    },
    {
        id: 2,
        title: "Fight against climate change - It Comes at a Cost",
        published: "06Mar '23",
        readingTime: "6 min read",
        content: [
            {
                id: 1,
                quote:
                    "While the fight against climate change comes at a cost, the cost of inaction is far greater.",
            },
            {
                id: 2,
                blockParagraph:
                    "Climate change is one of the most critical issues humanity is facing today. It poses a significant threat to the planet, with devastating consequences for people, wildlife, and ecosystems around the world. Addressing this global crisis requires a collective effort from individuals, businesses, and governments worldwide. However, the fight against climate change comes at a cost.",
            },
            {
                id: 3,
                blockParagraph: 
                    "One of the most significant cost of addressing climate change is the financial cost. The transition to a low-carbon economy requires a significant investment in renewable energy, energy efficiency, and other climate-friendly technologies. This investment comes at a considerable cost, and many businesses and governments may be hesitant to make such a substantial financial commitment.",
            },
            {
                id: 4,
                blockParagraph:
                    "For example, transitioning to renewable energy sources like solar, wind, or hydroelectric power requires significant investment in infrastructure and technology. The cost of installing solar panels or wind turbines can be expensive, especially for individuals or small businesses. The same is true for building new energy-efficient buildings or retrofitting existing ones to reduce their carbon footprint. While the initial cost may be high, the long-term benefits of reducing carbon emissions can help offset the financial burden.",
            },
            {
                id: 5,
                blockParagraph:
                    "Another cost of fighting climate change is the impact on jobs and industries. Many industries, such as fossil fuel production, will need to undergo significant changes to reduce their carbon emissions. This may lead to job losses and economic upheaval in these industries, which can have ripple effects throughout the economy.",
            },
            {
                id: 6,
                blockParagraph:
                    "For example, the coal industry has been hit hard by the transition to renewable energy sources. As demand for coal declines, many coal miners have lost their jobs, and entire communities have been impacted. While renewable energy sources can create new jobs, it can take time for these industries to develop, and the transition can be difficult for those who have lost their livelihoods.",
            },
            {
                id: 7,
                blockParagraph:
                    "In addition to financial costs and economic impacts, the fight against climate change also comes with social costs. For example, reducing carbon emissions may require changes in individual behavior, such as driving less or consuming less meat. These changes may be difficult for some individuals and can lead to feelings of inconvenience or sacrifice.",
            },
            {
                id: 8,
                blockParagraph:
                    "For some individuals, reducing their carbon footprint can be a significant challenge. For example, those who live in areas with limited public transportation options may have no choice but to drive, and those who live in food deserts may have limited access to healthy, plant-based foods. It is essential to consider these social costs and develop policies that can help individuals overcome these challenges.",
            },
            {
                id: 9,
                blockParagraph:
                    "Despite the costs associated with fighting climate change, the consequences of inaction are far greater. Climate change poses a significant threat to our planet, including rising sea levels, more frequent and severe natural disasters, and widespread ecological disruption. Failing to address these threats will have catastrophic consequences for both current and future generations.",
            },
            {
                id: 10,
                blockParagraph:
                    "In india June-October monsoon floods caused a loss of $10 billion to be the seventh- costliest. They also led to the death of 1,750 people — the most among all natural disasters in 2018.",
            },
            {
                id: 11,
                blockParagraph:
                    "Cyclone Fani, which affected Odisha in India and Bangladesh, was the tenth-most costly natural disaster in the world.",
            },
            {
                id: 12,
                blockParagraph:
                    "Tropical cyclones have been the costliest natural catastrophe in the world in the last two decades. In 2018, 2017, 2012, 2005 and 2004 cyclones accounted for nearly $909 billion of a total $1.43 trillion lost due to disasters.",
            },
            {
                id: 13,
                quote:
                    "The Asia-Pacific region — affected by earthquakes, tsunamis, floods, and cyclones — accounted for $1.3 trillion. That’s 44 per cent of the total loss.",
            },
            {
                id: 14,
                blockParagraph:
                    "While the fight against climate change comes at a cost, the cost of inaction is far greater. Addressing climate change will require significant financial, economic, and social investments, but the benefits of doing so are clear. We must act now to ensure a sustainable future for ourselves and for generations to come. Governments, businesses, and individuals all have a role to play in reducing greenhouse gas emissions and mitigating the impacts of climate change. By working together, we can build a cleaner, healthier, and more sustainable future for all.",
            },
        ],
        tags: [
            {
                id: 1,
                name: "Climate Change ",
            },
            {
                id: 2,
                name: "Sustainability",
            },
        ],
        meta: {
            likes: "866",
            comments: "5",
        },
    },
];

export { articlesCard, listOfArticles };
