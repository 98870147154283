import React from "react";
import { drVinay, mrSambhaji, pravinSambhaji, vikasRana } from "../../assets";
import { Car1 } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";

const data = [
    {
        id: 1,
        img: pravinSambhaji,
        title: "Pravin Sambhaji Langote",
        position: "Co-Founder & CEO",
        desc: "Lorem ipsum dolor sit amet consectetur. Eu vestibulum mattis vehicula penatibus non aliquam scelerisque venenatis ridiculus. ",
    },
    {
        id: 2,
        img: vikasRana,
        title: "Vikas Rana",
        position: "Co-Founder & CEO",
        desc: "Lorem ipsum dolor sit amet consectetur. Eu vestibulum mattis vehicula penatibus non aliquam scelerisque venenatis ridiculus. ",
    },
    {
        id: 3,
        img: drVinay,
        title: "Dr. Vinay Hasabnis",
        position: "Technical Advisor",
        desc: "Lorem ipsum dolor sit amet consectetur. Eu vestibulum mattis vehicula penatibus non aliquam scelerisque venenatis ridiculus. ",
    },
    {
        id: 4,
        img: mrSambhaji,
        title: "Sambhaji K Langote",
        position: "Technical Advisor",
        desc: "Lorem ipsum dolor sit amet consectetur. Eu vestibulum mattis vehicula penatibus non aliquam scelerisque venenatis ridiculus. ",
    },
    {
        id: 5,
        img: drVinay,
        title: "Dr. Vinay Hasabnis",
        position: "Technical Advisor",
        desc: "Lorem ipsum dolor sit amet consectetur. Eu vestibulum mattis vehicula penatibus non aliquam scelerisque venenatis ridiculus. ",
    },
    {
        id: 6,
        img: Car1,
        title: "Lorem ipsum",
        position: "Founder",
        desc: "Lorem ipsum dolor sit amet consectetur. Eu vestibulum mattis vehicula penatibus non aliquam scelerisque venenatis ridiculus. ",
    },
];
const TeamSection = () => {
    const renderReviewsItem = () => {
        return data.map((item) => (
            <div key={item.id} className="w-full sm:w-[326px] xl:w-[360px]">
                <img src={item.img} alt="" className="w-full aspect-square  " />
                <H4 className="mt-2 ">{item.title}</H4>
                <P className="mt-0.6 mb-0.8">{item.position}</P>

                <P className="text-xs text-secondary">{item.desc}</P>
            </div>
        ));
    };
    return (
        <Section>
            <Container>
                <div
                    className={`flex justify-between gap-1.6 flex-col lg:flex-row  items-center   mb-3.2`}
                >
                    <div className=" w-full lg:w-[20%] flex flex-col justify-start items-start">
                        <H2 className={"text-start"}>Meet the Team</H2>
                    </div>

                    <P className={" lg:w-[35%] text-secondary"}>
                        Lorem ipsum dolor sit amet consectetur. Morbi sed
                        volutpat egestas faucibus mauris ut ut. Risus mi
                        dictumst et viverra.
                    </P>
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3  gap-2 sm:gap-3.2 lg:gap-4 xl:gap-4.8 2xl:gap-5.6">
                    {renderReviewsItem(0, 4)}
                </div>
            </Container>
        </Section>
    );
};

export default TeamSection;
