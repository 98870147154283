import React from "react";
import Container from "../../../../components/styles/Container.styles";
import { Flex, Section } from "../../../../components/styles/Elements.styles";
import { P } from "../../../../components/styles/Typography.styles";
import Link from "../../../../components/link/Link";
import Tables from "../../../../components/table/Tables";
import OnboardingContainer from "../../../onboarding/OnboardingContainer";
import FilterHeader from "../../dashboard/FilterHeader";
import { DetailsFiled } from "./DetailsField";
import theme from "../../../../theme";

const Payment = () => {
    return (
        <Section height="auto">
            <Container>
                <FilterHeader
                    xxsm="30px 0 30px"
                    stm="30px 0 30px"
                    lgm="30px 0 60px"
                    title="Payment Summary"
                />

                <OnboardingContainer>
                    <Flex.Container justify="space-between">
                        {fields.map((field) => (
                            <Flex.Item
                               
                                xl="48%"
                                t="100%"
                            

                                xxs="100%"
                                
                                key={field.name}
                            >
                                <DetailsFiled
                                    label={field.label}
                                    name={field.name}
                                    color="black"
                                />
                            </Flex.Item>
                        ))}
                    </Flex.Container>
                </OnboardingContainer>

                <Tables
                    mobileTableHeader={mobileTableHeader}
                    tableHeader={tableHeader}
                    data={data}
                    cellStyles={cellStyles}
                    cellMobileStyles={cellStyles}
                />
            </Container>
        </Section>
    );
};

export default Payment;

const tableHeader = [
    {
        id: 1,
        name: "Property ID",
    },
    {
        id: 2,
        name: "Next Due Date",
    },
    {
        id: 3,
        name: "Bill Amount",
    },

    {
        id: 4,
        name: "Last 3 DPD",
    },
    {
        id: 5,
        name: "Status",
    },
    {
        id: 6,
        name: "Max DPD",
    },
];
const mobileTableHeader = [
    {
        id: 1,
        name: "Property ID",
    },
    {
        id: 2,
        name: "Next Due Date",
    },

    {
        id: 3,
        name: "Bill Amount",
    },
    {
        id: 4,
        name: "Last 3 DPD",
    },
];

const cellStyles = {
    padding: "10px 0",
    borderLeft: "none",
    borderRight: "none",
};

const data = {
    large: [
        {
            index: 1,
            id: {
                component: () => (
                    <Link
                        color="primary"
                        to={`/payment/${45896}`}
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        45896
                    </Link>
                ),
            },
            date: {
                value: "20/04/2024",
            },

            bill: {
                value: "2,50,000/-",
            },
            lastDPD: {
                component: () => (
                    <P
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            color: theme.color.green,
                            fontWeight: theme.fontWeight.m,
                        }}
                    >
                        -10 -7 -5
                    </P>
                ),
            },
            status: {
                value: "3/60",
            },
            maxDPD: {
                component: () => (
                    <P
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            color: theme.color.green,
                            fontWeight: theme.fontWeight.m,
                        }}
                    >
                        -5
                    </P>
                ),
            },
        },

        {
            index: 2,
            id: {
                component: () => (
                    <Link
                        color="primary"
                        to={`/payment/${58956}`}
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        58956
                    </Link>
                ),
            },
            date: {
                value: "25/03/2024",
            },

            bill: {
                value: "3,50,000/-",
            },
            lastDPD: {
                component: () => (
                    <P
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            color: theme.color.green,
                            fontWeight: theme.fontWeight.m,
                        }}
                    >
                        -4 -8 -2
                    </P>
                ),
            },
            status: {
                value: "3/60",
            },
            maxDPD: {
                component: () => (
                    <P
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            color: theme.color.green,
                            fontWeight: theme.fontWeight.m,
                        }}
                    >
                        -2
                    </P>
                ),
            },
        },
        {
            index: 3,
            id: {
                component: () => (
                    <Link
                        color="primary"
                        to={`/payment/${85965}`}
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        85965
                    </Link>
                ),
            },
            date: {
                value: "25/03/2024",
            },

            bill: {
                value: "1,50,000/-",
            },
            lastDPD: {
                component: () => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: theme.fontWeight.m,
                            gap: "5px",
                        }}
                    >
                        <P
                            style={{
                                color: theme.color.green,
                                fontWeight: theme.fontWeight.m,
                            }}
                        >
                            -4
                        </P>
                        <P
                            style={{
                                color: theme.color.green,
                                fontWeight: theme.fontWeight.m,
                            }}
                        >
                            0
                        </P>
                        <P
                            style={{
                                color: theme.color.error,
                                fontWeight: theme.fontWeight.m,
                            }}
                        >
                            +10
                        </P>
                    </div>
                ),
            },
            status: {
                value: "3/60",
            },
            maxDPD: {
                component: () => (
                    <P
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            color: theme.color.error,
                            fontWeight: theme.fontWeight.m,
                        }}
                    >
                        +10
                    </P>
                ),
            },
        },
    ],
    small: [
        {
            index: 1,
            id: {
                component: () => (
                    <>
                        <Link
                            color="primary"
                            to={`/payment/${45896}`}
                            isInteractive={true}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            45896
                        </Link>
                    </>
                ),
            },
            date: {
                value: "20/04/2024",
            },
            bill: {
                value: "2,50,000/-",
            },
            lastDPD: {
                component: () => (
                    <P
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            color: theme.color.green,
                            fontWeight: theme.fontWeight.m,
                        }}
                    >
                        -10 -7 -5
                    </P>
                ),
            },
        },

        {
            index: 2,
            val: {
                component: () => (
                    <>
                        <Link
                            color="primary"
                            to={`/payment/${58956}`}
                            isInteractive={true}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            58956
                        </Link>
                    </>
                ),
            },

            date: {
                value: "25/03/2024",
            },
            bill: {
                value: "3,50,000/-",
            },
            lastDPD: {
                component: () => (
                    <P
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            color: theme.color.green,
                            fontWeight: theme.fontWeight.m,
                        }}
                    >
                        -4 -8 -2
                    </P>
                ),
            },
        },
        {
            index: 3,
            val: {
                component: () => (
                    <>
                        <Link
                            color="primary"
                            to={`/payment/${85965}`}
                            isInteractive={true}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            85965
                        </Link>
                    </>
                ),
            },

            date: {
                value: "25/03/2024",
            },
            bill: {
                value: "1,50,000/-",
            },
            lastDPD: {
                component: () => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: theme.fontWeight.m,
                            gap: "5px",
                        }}
                    >
                        <P
                            style={{
                                color: theme.color.green,
                                fontWeight: theme.fontWeight.m,
                            }}
                        >
                            -4
                        </P>
                        <P
                            style={{
                                color: theme.color.green,
                                fontWeight: theme.fontWeight.m,
                            }}
                        >
                            0
                        </P>
                        <P
                            style={{
                                color: theme.color.error,
                                fontWeight: theme.fontWeight.m,
                            }}
                        >
                            +10
                        </P>
                    </div>
                ),
            },
        },
    ],
};

const fields = [
    { label: "Total Consumer Count", name: "totalConsumerCount" },
    { label: "Bill to be Collected", name: "billToBeCollected" },
    { label: "Consumer Past DPD", name: "consumerPastDPD" },
    { label: "Payment Received", name: "paymentReceived" },
];
