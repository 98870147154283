import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export const Carousel = ({
    slides,
    flexPos = "",
    autoSlide = false,
    autoSlideInterval = 3000,
    handleSlideChange = () => {},
    topNav = false,
    slideWidth = "full",
    translateFactor = 100,
    classes = "",
    currentSlide = 0,
    headclass = "",
    nav = true,
}) => {
    const [current, setCurrent] = useState(currentSlide);
    const [transitionEnabled, setTransitionEnabled] = useState(true);
    const [hovering, setHovering] = useState(false);
    const [translateFact, setTranslateFactor] = useState(translateFactor);

    useEffect(() => {
        const calculateTranslateFactor = () => {
            const deviceWidth = window.innerWidth;
            let factor = 100;

            if (slideWidth !== "full") {
                if (
                    typeof slideWidth === "string" &&
                    slideWidth.endsWith("%")
                ) {
                    factor = parseFloat(slideWidth);
                } else if (
                    typeof slideWidth === "number" ||
                    slideWidth.endsWith("px")
                ) {
                    const slideWidthInPixels =
                        typeof slideWidth === "number"
                            ? slideWidth
                            : parseFloat(slideWidth);
                    factor = (slideWidthInPixels / deviceWidth) * 100;
                }
            }
            setTranslateFactor(factor);
        };

        calculateTranslateFactor();

        window.addEventListener("resize", calculateTranslateFactor);

        return () =>
            window.removeEventListener("resize", calculateTranslateFactor);
    }, [slideWidth]);

    const previousSlide = () => {
        if (current === 0) {
            setTransitionEnabled(false);
            setCurrent(slides.length - 1);
            setTimeout(() => {
                setTransitionEnabled(true);
            }, 50);
        } else {
            setCurrent(current - 1);
        }
        handleSlideChange(current === 0 ? slides.length - 1 : current - 1);
    };

    const nextSlide = () => {
        if (current === slides.length - 1) {
            setTransitionEnabled(false);
            setCurrent(0);
            setTimeout(() => {
                setTransitionEnabled(true);
            }, 50);
        } else {
            setCurrent(current + 1);
        }
        handleSlideChange(current === slides.length - 1 ? 0 : current + 1);
    };

    useEffect(() => {
        if (!autoSlide || hovering) return;

        const slideInterval = setInterval(() => {
            nextSlide();
        }, autoSlideInterval);

        return () => clearInterval(slideInterval);
    }, [autoSlide, autoSlideInterval, slides.length, current, hovering]);

    useEffect(() => {
        setCurrent(currentSlide);
    }, [currentSlide]);

    return (
        <div>
            {nav && topNav && (
                <div
                    className={`py-2 flex mb-2 ${
                        flexPos ? flexPos : "justify-center"
                    } gap-0.8 sm:gap-1 xl:gap-1.8 2xl:gap-3.2 w-full`}
                >
                    <div
                        className={`w-52 z-20 relative flex items-center ${
                            flexPos ? flexPos : "justify-center"
                        }`}
                    >
                        <button
                            onClick={previousSlide}
                            className="text-xl xl:text-3xl 2xl:text-5xl"
                        >
                            <IoIosArrowBack />
                        </button>

                        <p>
                            <span className=" text-lg xl:text-3xl 2xl:text-5xl">
                                {current + 1 > 9
                                    ? current + 1
                                    : `0${current + 1}`}
                            </span>{" "}
                            /{" "}
                            <span className=" text-sm xl:text-lsm 2xl:text-lg ">
                                {slides.length > 9
                                    ? slides.length
                                    : `0${slides.length}`}
                            </span>
                        </p>

                        <button
                            onClick={nextSlide}
                            className="text-xl xl:text-3xl 2xl:text-5xl"
                        >
                            <IoIosArrowForward />
                        </button>
                    </div>
                </div>
            )}
            <div
                className={`overflow-hidden relative h-[400px] ${headclass}`}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
            >
                <div
                    className={`flex flex-row ${
                        transitionEnabled
                            ? "transition ease-out duration-500"
                            : ""
                    }  ${classes}`}
                    style={{
                        transform: `translateX(-${current * translateFact}%)`,
                    }}
                >
                    {slides.map((s, index) => (
                        <div
                            key={index}
                            className={`flex-shrink-0 w-${slideWidth} h-full`}
                        >
                            {s}
                        </div>
                    ))}
                    {slides.map((s, index) => (
                        <div
                            key={index}
                            className={`flex-shrink-0 w-${slideWidth} h-full`}
                        >
                            {s}
                        </div>
                    ))}
                </div>
            </div>

            {nav && !topNav && (
                <div
                    className={`mt-3.2 flex ${
                        flexPos ? flexPos : "justify-center"
                    } gap-0.8 sm:gap-1 xl:gap-1.8 2xl:gap-3.2 w-full items-center`}
                >
                    <button
                        onClick={previousSlide}
                        className="text-xl xl:text-3xl 2xl:text-5xl"
                    >
                        <IoIosArrowBack />
                    </button>

                    <p>
                        <span className=" text-lg xl:text-3xl 2xl:text-5xl">
                            {current + 1 > 9 ? current + 1 : `0${current + 1}`}
                        </span>{" "}
                        <span className=" text-sm xl:text-base 2xl:text-lg">
                            /{" "}
                            {slides.length > 9
                                ? slides.length
                                : `0${slides.length}`}
                        </span>
                    </p>

                    <button
                        onClick={nextSlide}
                        className="text-xl xl:text-3xl 2xl:text-5xl"
                    >
                        <IoIosArrowForward />
                    </button>
                </div>
            )}
        </div>
    );
};
