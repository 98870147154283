import React from "react";
import { AiOutlineClear } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import styled from "styled-components";
import Icon from "../../components/Icon/Icon";
import { InputComponent } from "../../components/input/Input";
import { Flex } from "../../components/styles/Elements.styles";
const MessageInput = styled.div`
    display: flex;
    align-items: center;
    border: 2px solid ${(props) => props.theme.color.grey300};
    position: relative;
    box-sizing: border-box;
    transition: all linear 0.2s;
    border-radius: 10px;
    margin-left: 2rem;
    overflow: hidden;
    :focus-within {
        border-color: ${(props) => props.theme.color.primary};
    }

    .sendIcon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${(props) => props.theme.color.grey300};
        width: 50px;
        :hover {
            background: ${(props) => props.theme.color.lightGrey};
            cursor: pointer;
        }
    }
`;
const Input = styled(InputComponent)`
    border: none;
    ::placeholder {
        color: ${(props) => props.theme.color.grey600};
    }
`;
const Wrapper = styled(Flex.Container)`
    margin-top: 2rem;
    .clean {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;

        background: ${(props) => props.theme.color.grey300};
        width: 50px;
        :hover {
            background: ${(props) => props.theme.color.lightGrey};
            cursor: pointer;
        }
    }
`;
const ChatFooter = ({ onSubmit, onClear, value, onChange }) => {
    return (
        <div>
            <form onSubmit={onSubmit}>
                <Wrapper>
                    <Flex.Item
                        role="button"
                        onClick={onClear}
                        className="clean"
                    >
                        <Icon size="l" component={AiOutlineClear} />
                    </Flex.Item>

                    <Flex.Item flex="1">
                        <MessageInput>
                            <Input
                                value={value}
                                onChange={onChange}
                                placeholder="What is PV system?"
                            />
                            <div
                                role="button"
                                onClick={onSubmit}
                                className="sendIcon"
                            >
                                <Icon size="l" component={FiSend} />
                            </div>
                        </MessageInput>
                    </Flex.Item>
                </Wrapper>
            </form>
        </div>
    );
};

export default ChatFooter;
