import moment from "moment";
import {
    IMAGES_PURPOSE,
    INSTALLATION_TYPE,
    MAX_NOTES_LENGTH,
    MIN_BILL_AMOUNT,
    MIN_SHADE_FREE_AREA,
    NUMERIC_FIELD,
    OTP_LENGTH,
    REQUIRED_FIELD,
    validImageTypes,
} from "../constants/common";
import {
    IFSCRegEx,
    bankAccRegEx,
    emailRegEx,
    nameRegEx,
    passRegEX,
    phoneRegEx,
} from "../constants/regularExpression";
import { validAdultAge } from "./helpers";

export const validateUserId = (userId) => {
    const errors = {};
    if (!userId) {
        errors.userId = "User ID  is required!";
    } else if (!phoneRegEx.test(userId) && !emailRegEx.test(userId)) {
        errors.userId = "Enter valid email or phone number!";
    }
    return errors;
};

export const validateNameEmailPhone = (values) => {
    const errors = {};
    if (!values.email) {
        errors.emailError = "Email is required!";
    } else if (!emailRegEx.test(values.email.toLowerCase())) {
        errors.emailError = "Enter a valid email!";
    }
    if (!values.phone) {
        errors.phoneError = "Phone No. is required!";
    } else if (!phoneRegEx.test(values.phone)) {
        errors.phoneError = "Enter a valid phone number!";
    }
    if (!values.name) {
        errors.nameError = "Full Name is required!";
    } else if (values.name.length < 3) {
        errors.nameError = "Full Name must be greater than 3 characters!";
    }
    return errors;
};

export const validateNameEmailPhoneWithPassword = (values) => {
    const namePassError = validateNameEmailPhone(values);
    const errors = { ...namePassError };

    if (!values.password) {
        errors.passwordError = "Password is required!";
    } else if (!passRegEX.test(values.password)) {
        errors.passwordError =
            "Password should be min 8 char, with at least 1  special char, 1 upper and 1 lower case letters and a number!";
    }
    if (!values.confirmPassword) {
        errors.confirmPasswordError = "Confirm password is required!";
    } else if (
        values.confirmPassword &&
        values.confirmPassword !== values.password
    ) {
        errors.confirmPasswordError = "Password doesn't matched!";
    }

    return errors;
};

export const validatePasswordDobOTP = (values) => {
    const errors = {};
    if (!values.dateOfBirth) {
        errors.dateOfBirthError = "D.O.B is required!";
    } else if (validAdultAge(values.dateOfBirth) < 18) {
        errors.dateOfBirthError = "User must be 18+!";
    }
    if (!values.password) {
        errors.passwordError = "Password is required!";
    } else if (!passRegEX.test(values.password)) {
        errors.passwordError =
            "Password should be min 8 char, with at least 1  special char, 1 upper and 1 lower case letters and a number!";
    }
    if (!values.confirmPassword) {
        errors.confirmPasswordError = "Confirm password is required!";
    } else if (
        values.confirmPassword &&
        values.confirmPassword !== values.password
    ) {
        errors.confirmPasswordError = "Password doesn't matched!";
    }
    if (!values.otp) {
        errors.otpError = "OTP is required!";
    } else if (values.otp.length !== OTP_LENGTH) {
        errors.otpError = "OTP is incomplete!";
    }
    return errors;
};

export const validBankInfo = (values) => {
    const errors = {};
    if (!values.beneficiary_name) {
        errors.beneficiary_name = REQUIRED_FIELD;
    } else if (!nameRegEx.test(values.beneficiary_name)) {
        errors.beneficiary_name =
            "Name should start with a letter and contain only letters and numbers!";
    } else if (
        values.beneficiary_name.length < 4 ||
        values.beneficiary_name.length > 120
    ) {
        errors.beneficiary_name =
            "Beneficiary name should be between 4 to 120 characters!";
    }
    if (!values.method) {
        errors.method = REQUIRED_FIELD;
    }
    if (!values.account_type) {
        errors.account_type = REQUIRED_FIELD;
    }
    if (!values.ifsc_code) {
        errors.ifsc_code = REQUIRED_FIELD;
    } else if (!IFSCRegEx.test(values.ifsc_code)) {
        errors.ifsc_code = "Enter valid IFSC code!";
    }
    if (!values.account_number) {
        errors.account_number = REQUIRED_FIELD;
    } else if (isNaN(values.account_number)) {
        errors.account_number = NUMERIC_FIELD;
    } else if (!bankAccRegEx.test(values.account_number)) {
        errors.account_number = "Enter valid bank account number!";
    }

    return errors;
};
export const validatePasswordAndOtp = (values, checkOtp) => {
    const errors = {};

    if (!values.password) {
        errors.password = "Password is required!";
    } else if (!passRegEX.test(values.password)) {
        errors.password =
            "Password should be min 8 char, with at least 1  special char, 1 upper and 1 lower case letters and a number!";
    }
    if (!values.confirmPassword) {
        errors.confirmPassword = "Confirm password is required!";
    } else if (
        values.confirmPassword &&
        values.confirmPassword !== values.password
    ) {
        errors.confirmPassword = "Password doesn't matched!";
    }

    if (checkOtp) {
        if (!values.otp) {
            errors.otp = "OTP is required!";
        } else if (values.otp.length !== OTP_LENGTH) {
            errors.otp = "OTP is incomplete!";
        }
    }

    return errors;
};
export const validatePlantData = (
    values,
    connectionType,
    site,
    latLong,
    isOtp = false
) => {
    const errors = {};

    if (!site) {
        errors.addressError = "Site location is required!";
    } else if (latLong === null) {
        errors.addressError = "Site location must be valid!";
    }
    if (!values.bill) {
        errors.billError = "Bill is required!";
    } else if (isNaN(values.bill)) {
        errors.billError = "Bill must be numeric values!";
    } else if (Number(values.bill) < MIN_BILL_AMOUNT) {
        errors.billError = `Min bill amount is ${MIN_BILL_AMOUNT.toLocaleString()}`;
    }
    if (!connectionType) {
        errors.connectionTypeError = "Connection type is required!";
    }

    if (!values.siteName) {
        errors.siteNameError = "Plant name is required!";
    } else if (values.siteName.length < 3) {
        errors.siteNameError = "Plant name minimum length is 3 characters";
    }
    if (isOtp) {
        if (!values.otp) {
            errors.otpError = "OTP is required!";
        } else if (values.otp.length !== OTP_LENGTH) {
            errors.otpError = "OTP is incomplete!";
        }
    }

    return errors;
};

export const validateNamePhoneDate = (values) => {
    const errors = {};

    if (!values.phone) {
        errors.phoneError = "Phone no. is required!";
    } else if (!phoneRegEx.test(values.phone)) {
        errors.phoneError = "Enter a valid phone number!";
    }
    if (!values.name) {
        errors.nameError = "Contact person name is required!";
    } else if (values.name.length < 3) {
        errors.nameError = "Contact person name minimum length is 3 characters";
    }

    if (!values.date) {
        errors.dateError = "Date is required!";
    } else if (moment(values.date).isValid() === false) {
        errors.dateError = "Date is invalid!";
    } else {
        const currentMoment = moment().startOf("day").unix();
        const maxTimeEndDays = moment().add(13, "days").endOf("day").unix();
        const userMoment = moment(values.date).startOf("days").unix();
        if (currentMoment > userMoment) {
            errors.dateError = "Past date is invalid!";
        } else if (maxTimeEndDays < userMoment) {
            errors.dateError = "Date can't be more than 2 weeks!";
        }
    }

    return errors;
};

export const validateSurveySite = (siteList) => {
    const newErrors = [];
    siteList.forEach((list, index) => {
        const {
            roofTopType,
            installationType,
            elevationHeight,
            shadeFreeArea, //
            isSolarThermal,
            isElevatedStructure, //
            documents,
            comment,
        } = list;

        const error = {};
        if (!installationType) {
            error.installationType = REQUIRED_FIELD;
        } else if (installationType === INSTALLATION_TYPE[0]) {
            if (!roofTopType) {
                error.roofTopType = REQUIRED_FIELD;
            }
            if (!elevationHeight) {
                error.elevationHeight = REQUIRED_FIELD;
            } else if (isNaN(elevationHeight)) {
                error.elevationHeight = "Height must numeric values!";
            } else if (
                Number(elevationHeight) <= 0 ||
                Number(elevationHeight) % 1 !== 0
            ) {
                error.elevationHeight =
                    "Height must be greater than 0 and contains no floating point";
            }
            if (!isSolarThermal) {
                error.isSolarThermal = REQUIRED_FIELD;
            }
        }

        if (!shadeFreeArea) {
            error.shadeFreeArea = REQUIRED_FIELD;
        } else if (isNaN(shadeFreeArea)) {
            error.shadeFreeArea = "Area must numeric values!";
        } else if (
            Number(shadeFreeArea) < MIN_SHADE_FREE_AREA ||
            Number(shadeFreeArea) % 1 !== 0
        ) {
            error.shadeFreeArea = `Minimum area is ${MIN_SHADE_FREE_AREA} Sqft and contains no floating point`;
        }

        if (!isElevatedStructure) {
            error.isElevatedStructure = REQUIRED_FIELD;
        }
        if (comment && comment.length > MAX_NOTES_LENGTH) {
            error.comment = `Max Limit is ${MAX_NOTES_LENGTH}`;
        }
        if (documents.length === 0) {
            error.documents = "Images required for north, south, east, west ";
        } else {
            let NORTH_IMG = ` ${IMAGES_PURPOSE.NORTH_IMG},`
                .replace("_IMG", "")
                .toLowerCase();
            let SOUTH_IMG = ` ${IMAGES_PURPOSE.SOUTH_IMG},`
                .replace("_IMG", "")
                .toLowerCase();
            let EAST_IMG = ` ${IMAGES_PURPOSE.EAST_IMG},`
                .replace("_IMG", "")
                .toLowerCase();
            let WEST_IMG = ` ${IMAGES_PURPOSE.WEST_IMG}`
                .replace("_IMG", "")
                .toLowerCase();
            documents.forEach((item) => {
                if (item?.purpose === IMAGES_PURPOSE.NORTH_IMG) NORTH_IMG = "";
                else if (item?.purpose === IMAGES_PURPOSE.SOUTH_IMG)
                    SOUTH_IMG = "";
                else if (item?.purpose === IMAGES_PURPOSE.EAST_IMG)
                    EAST_IMG = "";
                else if (item?.purpose === IMAGES_PURPOSE.WEST_IMG)
                    WEST_IMG = "";
            });

            if (NORTH_IMG || SOUTH_IMG || EAST_IMG || WEST_IMG)
                error.documents = `Images required for ${NORTH_IMG}${SOUTH_IMG}${EAST_IMG}${WEST_IMG}`;
        }

        if (Object.keys(error).length > 0) {
            newErrors[index] = error;
        }
    });

    return newErrors;
};

export const validSurveyProposalData = (values) => {
    const error = {};
    if (!values.averageMonthlyElectricityBill) {
        error.averageMonthlyElectricityBill = REQUIRED_FIELD;
    } else if (isNaN(values.averageMonthlyElectricityBill)) {
        error.averageMonthlyElectricityBill = NUMERIC_FIELD;
    }

    if (!values.monthlyElectricityUnits) {
        error.monthlyElectricityUnits = REQUIRED_FIELD;
    } else if (isNaN(values.monthlyElectricityUnits)) {
        error.monthlyElectricityUnits = NUMERIC_FIELD;
    }

    if (!values.shadeFreeArea) {
        error.shadeFreeArea = REQUIRED_FIELD;
    } else if (isNaN(values.shadeFreeArea)) {
        error.shadeFreeArea = NUMERIC_FIELD;
    } else if (
        Number(values.shadeFreeArea) < MIN_SHADE_FREE_AREA ||
        Number(values.shadeFreeArea) % 1 !== 0
    ) {
        error.shadeFreeArea = `Min shade free area is ${MIN_SHADE_FREE_AREA} Sqft and contains no floating point`;
    }

    if (!values.tentativeSolarPower) {
        error.tentativeSolarPower = REQUIRED_FIELD;
    } else if (isNaN(values.tentativeSolarPower)) {
        error.tentativeSolarPower = NUMERIC_FIELD;
    }

    if (!values.electricityConnectionType) {
        error.electricityConnectionType = REQUIRED_FIELD;
    }

    if (!values.proposedSolarCapacity) {
        error.proposedSolarCapacity = REQUIRED_FIELD;
    } else if (isNaN(values.proposedSolarCapacity)) {
        error.proposedSolarCapacity = NUMERIC_FIELD;
    }

    if (!values.billToBePaidFiveYears) {
        error.billToBePaidFiveYears = REQUIRED_FIELD;
    } else if (isNaN(values.billToBePaidFiveYears)) {
        error.billToBePaidFiveYears = NUMERIC_FIELD;
    }
    if (!values.billToBePaidTenYears) {
        error.billToBePaidTenYears = REQUIRED_FIELD;
    } else if (isNaN(values.billToBePaidTenYears)) {
        error.billToBePaidTenYears = NUMERIC_FIELD;
    }

    if (!values.refundableDepositFiveYears) {
        error.refundableDepositFiveYears = REQUIRED_FIELD;
    } else if (isNaN(values.refundableDepositFiveYears)) {
        error.refundableDepositFiveYears = NUMERIC_FIELD;
    }
    if (!values.refundableDepositTenYears) {
        error.refundableDepositTenYears = REQUIRED_FIELD;
    } else if (isNaN(values.refundableDepositTenYears)) {
        error.refundableDepositTenYears = NUMERIC_FIELD;
    }
    if (!values.solarPVModel) {
        error.solarPVModel = REQUIRED_FIELD;
    }
    if (!values.solarInverterModel) {
        error.solarInverterModel = REQUIRED_FIELD;
    }
    if (!values.status) {
        error.status = REQUIRED_FIELD;
    }
    if (!values.comment) {
        error.comment = REQUIRED_FIELD;
    }

    return error;
};

export const handleValidateFiles = (e, fileTypes = validImageTypes) => {
    const selectedFile = e.target.files[0];
    const fileName = selectedFile.name;
    var extName = fileName
        .substring(fileName.lastIndexOf(".") + 1)
        .toUpperCase();
    if (fileTypes.includes(extName.toString())) {
        return selectedFile;
    }
    let fileLength = fileTypes.length;
    return `Invalid File Types! Only supported type${
        fileLength > 1 ? "s" : ""
    } ${fileLength > 1 ? "are" : "is"} ${fileTypes.toString().toUpperCase()}`;
};

export const validate = (formData, rules) => {
    const errors = {};

    Object.keys(rules).forEach((field) => {
        const value = formData[field];
        const rule = rules[field];

        if (rule.required && !value.trim()) {
            errors[field] = `${
                field.charAt(0).toUpperCase() + field.slice(1)
            } is required`;
        } else if (!rule.required && !value.trim()) {
            errors[field] = "";
        } else if (rule.pattern && !rule.pattern.test(value)) {
            errors[field] = `${
                field.charAt(0).toUpperCase() + field.slice(1)
            } is invalid`;
        }
    });

    return errors;
};

export const validateLoginForm = (formData, currentTab, getOtp) => {
    const newErrors = {};
    if (!formData.username.trim()) {
        newErrors.username = "UserId is required";
    } else if (
        !phoneRegEx.test(formData.username) &&
        !emailRegEx.test(formData.username)
    ) {
        newErrors.username = "Invalid UserId";
    }
    if (currentTab === 1) {
        if (!formData.password.trim()) {
            newErrors.password = "Password is required";
        }
    } else if (getOtp) {
        if (!formData.otp.trim()) {
            newErrors.otp = "OTP is required";
        }
    }
    return newErrors;
};
