import PropTypes from "prop-types";
import React from "react";
import cn from "../../lib/cn";

const baseStyles =
    "text-primary font-normal text-sm sm:text-base xl:text-base 2xl:text-xl";

const P = ({ className = "", children, ...rest }) => {
    return (
        <p className={cn(baseStyles, className)} {...rest}>
            {children}
        </p>
    );
};

P.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default P;
