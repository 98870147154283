import React, { useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import {
    REMOVE_REDIRECT_PATH,
    SET_ERROR,
} from "../../constants/contexConstant";
import { emailRegEx } from "../../constants/regularExpression";
import { ONBOARDING } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { validateLoginForm } from "../../helpers/fieldValidation";
import { handleRedirection } from "../../helpers/routingHelper";
import auth from "../../services/auth";
import Button from "../../shared/ui/Button";
import TextField from "../../shared/ui/Form/TextField";
import OTP from "../../shared/ui/OTP";
import ForgotPasswordForm from "./ForgotPasswordForm";

const LoginForm = ({
    currentTab,
    isSignUp,
    showForgotPassword,
    changeMethod,
    handleForgotPasswordClick,
    UpdatePass,
}) => {
    const { state, dispatch } = useContextState();
    const [formData, setFormData] = useState({
        password: "",
        username: "",
        otp: "",
    });

    const [getOtp, setGetOtp] = useState(false);
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };
    const history = useHistory();

    const handleGetOTP = async () => {
        setLoading(true);
        let val = emailRegEx.test(formData.username)
            ? formData.username
            : `91${formData.username}`;
        try {
            await auth.loginOTP({
                username: val,
            });
            setGetOtp(true);
            toast.success("OTP has been sent", {
                containerId: "otpContainer",
            });

            setLoading(false);
        } catch (error) {
            setLoading(false);
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };

    const handleResentOTP = async (mode) => {
        setLoading(true);
        let val = emailRegEx.test(formData.username)
            ? formData.username
            : `91${formData.username}`;
        try {
            await auth.loginOTP({
                username: val,
                type: "retry",
                mode,
            });
            if (mode === "text") {
                toast.success("OTP has been sent", {
                    containerId: "recentOtp",
                });
            } else {
                toast.success("Call has been sent", {
                    containerId: "recentOtp",
                });
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);

            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };

    const handlePassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateLoginForm(
            formData,
            currentTab,
            getOtp
        );

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors({});
            if (currentTab === 1) {
                setLoading(true);

                try {
                    let val = emailRegEx.test(formData.username)
                        ? formData.username
                        : `91${formData.username}`;
                    await auth.login({
                        username: val,
                        password: formData.password,
                        otpEnable: false,
                    });

                    let profile = await auth.getUserProfile(dispatch);
                    let status = profile?.data?.investor_profile?.plant?.status;
                    const roles = profile?.data?.investor_profile?.roles;
                    handleRedirection(state, history, roles, status);
                    dispatch({
                        type: REMOVE_REDIRECT_PATH,
                    });
                    setLoading(false);
                } catch (error) {
                    setLoading(false);

                    dispatch({
                        type: SET_ERROR,
                        payload: typeof error === "string" ? error : ERROR_MSG,
                    });
                }
            } else {
                if (getOtp) {
                    setLoading(true);

                    try {
                        let val = emailRegEx.test(formData.username)
                            ? formData.username
                            : `91${formData.username}`;
                        await auth.login({
                            username: val,
                            password: formData.otp,
                            otpEnable: true,
                        });
                        let profile = await auth.getUserProfile(dispatch);
                        setLoading(false);
                        let status =
                            profile?.data?.investor_profile?.plant?.status;
                        const roles = profile?.data?.investor_profile?.roles;
                        handleRedirection(state, history, roles, status);
                        dispatch({
                            type: REMOVE_REDIRECT_PATH,
                        });
                    } catch (error) {
                        setLoading(false);

                        dispatch({
                            type: SET_ERROR,
                            payload:
                                typeof error === "string" ? error : ERROR_MSG,
                        });
                    }
                } else {
                    handleGetOTP();
                }
            }
        }
    };

    return (
        <div>
            {showForgotPassword ? (
                <ForgotPasswordForm UpdatePass={UpdatePass} />
            ) : (
                <form onSubmit={handleSubmit}>
                    <div>
                        {currentTab === 1 ? (
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-1.6 lg:gap-x-[3rem] xl:gap-x-3.2  xl:mt-3.6">
                                <TextField
                                    label={"Email/Phone Number"}
                                    value={formData.username}
                                    onChange={handleChange}
                                    name={"username"}
                                    type={"text"}
                                    errorMsg={errors.username}
                                />
                                <TextField
                                    label={"Password"}
                                    value={formData.password}
                                    onChange={handleChange}
                                    name={"password"}
                                    type={showPassword ? "text" : "password"}
                                    errorMsg={errors.password}
                                    icon={
                                        showPassword ? (
                                            <FaEyeSlash />
                                        ) : (
                                            <IoEyeSharp />
                                        )
                                    }
                                    iconWrapperClass={
                                        "right-0 px-[2rem] rounded-md bg-gray-100 my-0.1 mx-px"
                                    }
                                    iconChange={handlePassword}
                                />
                            </div>
                        ) : (
                            <div className="flex flex-col gap-1 mt-3.6">
                                <TextField
                                    label={"Email/Phone Number"}
                                    value={formData.username}
                                    onChange={handleChange}
                                    name={"username"}
                                    type={"text"}
                                    errorMsg={errors.username}
                                />
                                {getOtp && (
                                    <OTP
                                        otp={formData.otp}
                                        onChange={handleChange}
                                        error={error}
                                        errorMessage={errors.otp}
                                        handleResentOTP={handleResentOTP}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <Button
                        type="submit"
                        className="w-full mt-2.4 xl:mt-4"
                        disabled={loading}
                        isLoading={loading}
                        loaderClass="w-2 h-2 lg:w-3 lg:h-3"
                    >
                        {currentTab === 1 || getOtp ? "Login" : "Get OTP"}
                    </Button>

                    <div className="flex justify-between items-center mt-2">
                        <div
                            className="cursor-pointer"
                            onClick={() => history.push(ONBOARDING)}
                        >
                            <p className="underline text-blue text-xs md:text-sm">
                                New user? Sign up here.
                            </p>
                        </div>

                        {currentTab === 1 && (
                            <div
                                className="cursor-pointer"
                                onClick={() => handleForgotPasswordClick()}
                            >
                                <p className="underline text-blue text-xs md:text-sm">
                                    Forgot Password
                                </p>
                            </div>
                        )}
                    </div>
                </form>
            )}
        </div>
    );
};

export default LoginForm;
