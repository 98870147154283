import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../../components/button/Button";
import Space from "../../../../../components/space/Space";
import { Flex } from "../../../../../components/styles/Elements.styles";
import { H4 } from "../../../../../components/styles/Typography.styles";
import { DetailsFiled } from "../GenerateProposal";
import ProposalDeatilsContainer from "../ProposalDetailsContainer";
import { ProCheckBox, ProRadioInput } from "../PropsoalRadioInput";
const ButtonStyled = styled(Button)`
    border: none;
    padding: 1px 55px;
    margin-top: ${(props) => (props.margin ? props.margin : "0")};
    margin-bottom: 3rem;
    height: 100%;
    font-size: 15px;
    :hover,
    :focus,
    :active {
        box-shadow: none;
    }
`;
const GenerateForm = ({
    fields2,
    renderIcon,
    handleInputChange2,
    formData2,
    fetchBillAmount,
    isServerCalculation,
    currentProposal,
    intro,
    OnChange,
    optionArr,
    checkboxes,
    onChange,
    generateId,
    plantName,
}) => {
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        // Validate Consumer Total Unit
        const consumerTotalUnit = formData2["Consumer’s Total Unit"];
        if (!consumerTotalUnit) {
            formIsValid = false;
            errors["Consumer’s Total Unit"] = "Consumer Total Unit is required";
        } else if (!/^\d+$/.test(consumerTotalUnit)) {
            formIsValid = false;
            errors["Consumer’s Total Unit"] =
                "Consumer Total Unit must be a number";
        }

        // Validate Consumer’s Bill
        const consumerBill = formData2["Consumer’s Bill"];
        if (!consumerBill) {
            formIsValid = false;
            errors["Consumer’s Bill"] = "Consumer’s Bill is required";
        }

        // Validate checkboxes
        const isAnyCheckboxChecked = Object.values(checkboxes).some(
            (value) => value === true
        );
        if (!isAnyCheckboxChecked) {
            formIsValid = false;
            errors["checkboxes"] =
                "At least one Proposal Type must be selected";
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleSubmit = (event) => {
        if (!validateForm()) {
            event.preventDefault();
        }
    };

    return (
        <>
            <Space xxsm={["10px 0 30px"]}>
                <H4>Generate Proposal: {plantName}</H4>
            </Space>
            <ProposalDeatilsContainer>
                <Space m={["3px  30px "]}>
                    <Flex.Container direction="column">
                        {fields2.map((field) => (
                            <Flex.Item
                                xl="100%"
                                t="100%"
                                xxs="100%"
                                key={field.name}
                            >
                                {(formData2["Connection Type"] ===
                                    "Industrial" ||
                                    formData2["Connection Type"] ===
                                        "Individual Homes") &&
                                field.label === "Plan Duration" ? (
                                    ""
                                ) : (
                                    <DetailsFiled
                                        label={field.label}
                                        isSelect={field.isSelect}
                                        data={field.data}
                                        icon={renderIcon(field.name)}
                                        onIconClick={fetchBillAmount}
                                        selected={formData2[field.label]}
                                        backgroundColor={
                                            currentProposal ||
                                            (isServerCalculation &&
                                                field.label ===
                                                    "Consumer’s Bill")
                                                ? "grey400"
                                                : "white"
                                        }
                                        disabled={
                                            currentProposal
                                                ? true
                                                : field.label ===
                                                      "Plan Duration" &&
                                                  (formData2[
                                                      "Connection Type"
                                                  ] === "Residential Society" ||
                                                      formData2[
                                                          "Connection Type"
                                                      ] === "Individual Homes")
                                                ? true
                                                : isServerCalculation &&
                                                  field.name ===
                                                      "Consumer’s Bill"
                                                ? true
                                                : false
                                        }
                                        value={formData2[field.label]}
                                        placeholder={field.placeholder}
                                        helperText={
                                            field.helperText &&
                                            formData2["Consumer’s Bill"] &&
                                            formData2["Consumer’s Total Unit"]
                                                ? `Unit Rate: ${(
                                                      Number(
                                                          formData2[
                                                              "Consumer’s Bill"
                                                          ]
                                                      ) /
                                                      Number(
                                                          formData2[
                                                              "Consumer’s Total Unit"
                                                          ]
                                                      )
                                                  ).toFixed(2)}`
                                                : ""
                                        }
                                        helperTextAlign={field.helperTextAlign}
                                        handleInputChange={handleInputChange2}
                                        errorMessage={errors[field.label]}
                                    />
                                )}
                            </Flex.Item>
                        ))}
                        {formData2["Connection Type"] !== "Industrial" && (
                            <ProRadioInput
                                onChange={OnChange}
                                type={"subsidy"}
                                title={
                                    formData2["Connection Type"] !==
                                    "Commercial"
                                        ? "Subsidy"
                                        : "Do You Have Tenant’s?"
                                }
                                selected={intro}
                                disable={currentProposal ? true : false}
                                data={["Yes", "No"]}
                            />
                        )}
                        <ProCheckBox
                            title={"Proposal Type"}
                            connectionType={formData2["Connection Type"]}
                            arr={optionArr}
                            onChange={onChange}
                            disable={currentProposal ? true : false}
                            selected={checkboxes}
                            errorMessage={errors["checkboxes"]}
                        />
                    </Flex.Container>
                </Space>
                <Flex.Container justify="center" align="center">
                    <Link
                        to={
                            currentProposal
                                ? {
                                      pathname: `/${currentProposal?.plantId}/viewProposal`,
                                  }
                                : {
                                      pathname: `/${generateId}/save`,
                                      state: {
                                          formData2,
                                          checkboxes,
                                          intro,
                                      },
                                  }
                        }
                        onClick={handleSubmit}
                    >
                        <ButtonStyled margin="1rem">
                            {currentProposal
                                ? "View Proposal"
                                : "Generate Proposal"}
                        </ButtonStyled>
                    </Link>
                </Flex.Container>
            </ProposalDeatilsContainer>
        </>
    );
};

export default GenerateForm;
