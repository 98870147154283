import React from "react";
import useScroll from "../../hooks/useScroll";
import cn from "../../lib/cn";
import useHeaderScroll from "./useHeaderScroll";

const TermsHeader = ({ activeSection, handleLinkClick }) => {
    const { scrollY } = useScroll();
    const { visible } = useHeaderScroll();
    const scrollStyles = visible ? "bottom-0" : "bottom-[-100px]";
    const isVisible = scrollY < 50;
    const baseStyles =
        "fixed z-50 transition-all ease-in-out duration-500 left-0 right-0 bg-gray-250";

    return (
        <header
            className={cn(baseStyles, scrollStyles, {
                "bg-gray-250": isVisible,
            })}
        >
            <nav className="flex justify-center items-center p-1">
                <button
                    onClick={() => handleLinkClick("terms")}
                    className={`block text-lg font-semibold font-urbanist ${
                        activeSection === "terms"
                            ? "font-extrabold text-blue-600"
                            : "text-gray-900"
                    } mx-4`}
                    style={
                        activeSection === "terms"
                            ? {
                                  borderBottom: "3px solid",
                                  borderImageSource:
                                      "linear-gradient(180deg, #FEFEFE 0%, #366CDC 100%)",
                                  borderImageSlice: 1,
                              }
                            : {}
                    }
                >
                    Terms & Conditions
                </button>
                <button
                    onClick={() => handleLinkClick("privacy")}
                    className={`block text-lg font-semibold font-urbanist ${
                        activeSection === "privacy"
                            ? "font-bold text-blue-600"
                            : "text-gray-900"
                    } mx-4`}
                    style={
                        activeSection === "privacy"
                            ? {
                                  borderBottom: "3px solid",
                                  borderImageSource:
                                      "linear-gradient(180deg, #FEFEFE 0%, #366CDC 100%)",
                                  borderImageSlice: 1,
                              }
                            : {}
                    }
                >
                    Privacy Policy
                </button>
            </nav>
        </header>
    );
};

export default TermsHeader;
