const valueProposition = "Value_Proposition";
const howToinvest = "How_to_Invests_SROT";
const KYCAgreements = "KYC_Agreements";
const srotOpportunities = "Srot_Opportunities";
export const payoutTaxes = "Payout_Taxes";
export const referralProgram = "Referral_Program";
export const NRIInvestment = "NRI_Investment";
export const investmentRisk = "Investment_Risk";
const investMiscellaneous = "Invest_Miscellaneous";

/* @DESC:: Faq Tags  Fri June 14,2024 */
let co2Reduced = "Co2 Reduced";
let trending = "Trending";
let invest = "Invest";
let technology = "Technology";
let govtSubsidy = "Government Subsidy";
let srot = "Srot";
let opportunity = "Opportunity";
let solar = "Solar";

const propositionFaq = [
    {
        question: "Why platform is called SROT(स्रोत)?",
        answer: `SROT / स्रोत (in Sanskrit) means source or origin, and we want to build SROT(स्रोत) of wealth, energy, and prosperity for all of us.`,
        tags: [srot],
    },
    {
        question: "Why SROT(स्रोत) Platform is created?",
        answer: `We have build SROT(स्रोत) platform to help others contribute to and save our "Mother Earth". 
        Global Warming and climate change are very much real and we are on the brink of climate disaster.  
        Every year Floods, Droughts, and Cyclones are killing thousands of people. 
        Many studies have shown Trillions of dollars of economic loss, billions of people will lose their homes and millions of people will die due to climate change in the coming decade.`,
        tags: [srot, trending, co2Reduced],
    },
    {
        question: "Why I should Invest?",
        answer: `Climate change & Global warming is not only impacting our life, but they will make our planet inhabitable for our future generation. The primary reason for human-induced Climate change is Carbon emission, we should not wait for the Government or Corporates to cut down their emission instead each one of us should know our carbon footprint and try to offset it by investing in Green Energy Projects.`,
        tags: [invest],
    },
    {
        question: "How does solar energy cut down carbon footprint",
        answer: `Solar panels are capable of generating power with zero carbon emissions. The generated power can replace conventional energy which presently comes from burning coal or fossil fuels. Every kilowatt of green energy produced by Solar can help you offset 666 KG of CO2 emission annually.`,
        tags: [co2Reduced, trending, co2Reduced],
    },
    {
        question: "How can I calculate my Carbon Footprint?",
        answer: `You can check our Carbon Footprint Calculator on https://srot.app/offset page, and calculate your family carbon footprint and offset it by Investing in fully self-managed Solar Assets`,
        tags: [co2Reduced, trending, technology],
    },
    {
        question:
            "What will be the benefits of Investment with the SROT(स्रोत) platform?",
        answer: `SROT(स्रोत) platform will help you in the following ways`,
        list: [
            "You can diversify your investment portfolio into Renewable Energy Assets",
            "Your Investments can earn a return of 10-12% per annum with very low risk. And you will get a stable monthly rental from your Solar Assets",
            "You can sit back and relax while we manage your Solar Assets and help you offset your carbon footprint.",
            "You can be Green Influencer, tell others about our SROT(स्रोत) platform and earn a 'Referral' for each of your friends to join.",
        ],
        tags: [invest, co2Reduced, srot],
    },
];
const investFaq = [
    {
        question:
            "What are the steps to invest in assets using the SROT(स्रोत) platform?",
        list: [
            "Sign up on SROT(स्रोत) platform with a valid mobile number and email id.",
            "Complete KYC and bank account verification process",
            "Load funds in the Wallet using the payment gateway",
            "Choose from the investment options of pre-leased assets from the opportunity tab",
            "Sign the Sale and leaseback agreement",
            "Start earning monthly rental income",
        ],
        tags: [invest, trending],
    },
    {
        question: "What are pre-leased assets?",
        answer: `Pre-leased assets mean the assets which have got an expression of interest of taking them on lease by consumers before even the same have been built or installed. So that after investing in assets you do not have to wait for the consumer to take it on lease and pay monthly rent.`,
        tags: [invest],
    },
    {
        question: "What is the minimum amount I can invest?",
        answer: `
            You can start investing from as low as INR 10,000.`,
        tags: [invest],
    },
    {
        question: "What is the maximum amount I can invest?",
        answer: `You can invest a maximum of up to INR 5,00,000 (INR Five lakh) at once, you can invest multiple times in subsequent investment opportunities, and there is no limit to the number of times you invest with us.`,
        tags: [invest, trending],
    },
    {
        question:
            "Is there a way to invest more than more investment limit in the Opportunity?",
        answer: `If you want to invest more than INR 5,00,000 (INR Five lakh), please drop a mail to founders@srot.io.`,
        tags: [invest, trending],
    },
    {
        question: "Why do we have a maximum investment cap?",
        answer: `We want to diversify the investment of Opportunities among different investors`,
        tags: [invest, srot],
    },
    {
        question: "How many times I can invest?",
        answer: `You can invest as many times as you want with us, there is NO limit on that. You can always earn more by investing multiple times in different investment opportunities.`,
        tags: [invest, trending],
    },
    // {
    //     question: "Is there any lock-in period?",
    //     answer: `Yes, there is a lock-in period for your investment depending upon the plan you choose.`,
    // },
    {
        question: "How much is the lock-in period?",
        answer: `Lock-in periods are 1 year, after that you can choose to sell your assets to other investors.
        `,
        tags: [invest, co2Reduced, technology],
    },
    // {
    //     question: "What happens after the lock-in period is over?",
    //     answer: `At the end of the term, your solar assets will be sold to other investors and you will receive depreciated asset value as per your Investment plan.`,
    // },
    {
        question: "What are the payment options available while investing?",
        answer: `You can pay by NEFT/RTGS/IMPS, Debit card, Credit Card, or UPI using the payment gateway on the SROT(स्रोत) platform.`,
        tags: [invest, trending],
    },
    // {
    //     question: "Can I pay by Cheque/ D.D./Cash?",
    //     answer: `Payment by Cheque/Demand Draft/Cash is NOT accepted.`,
    // },
];
const kycFaq = [
    {
        question: "What are the KYC requirements?",
        answer: `You need to have a valid PAN, Aadhaar card, and bank account. You need to upload images of PAN and Aadhaar and verify your bank account on the SROT platform before making any investment.`,
        tags: [invest],
    },
    {
        question: "What are the documents required to invest?",
        answer: `The documents required are as follows`,
        list: [
            "PAN Card",
            "Aadhaar Card",
            "Bank Passbook or Cancel cheque mentioning the Account number and IFSC",
        ],
        tags: [invest, co2Reduced, srot],
    },
    {
        question: "Which agreement will be signed?",
        answer: `The sale and leaseback agreement will be signed before investing.`,
        tags: [srot, invest],
    },
    {
        question: "What is the sale and leaseback agreement?",
        answer: `The sale and leaseback agreement consists of terms that state that the company will sell the assets to the investor and take the same assets on lease for further use to generate revenue from it.`,
        tags: [srot],
    },
    {
        question:
            "Between whom the sale and leaseback agreement will be signed?",
        answer: `The sale and leaseback agreement will be signed between you and the company i.e Hypersrot Technologies Pvt Ltd.`,
        tags: [invest, technology],
    },
    {
        question: "Are there any taxes applied while investing?",
        answer: `Yes, GST of 13.8% applies to the initial investment amount as you are buying physical solar assets. The GST rate is valid till there is any revision of GST rates by the Goods and Service Tax Council, Government of India.`,
        tags: [invest, govtSubsidy, srot, trending],
    },
    {
        question: "Are there any extra charges or fees on investment?",
        answer: `NO, there will not be any hidden charges or fees for investment on SROT(स्रोत) platform.`,
        tags: [invest, co2Reduced, srot],
    },
    {
        question: "Can I cancel the agreement before the lock-in period?",
        answer: `NO, you can not cancel the agreement before the lock-in period.`,
        tags: [invest, trending],
    },
];
const opportunityFaq = [
    {
        question: "What is an opportunity?",
        answer: `Opportunity is similar to Investment Fund, here we can add 1 or more pre-leased solar assets for which we are raising capital.`,
        tags: [opportunity, co2Reduced, srot],
    },
    {
        question: "What is the prerequisite for investment into opportunity?",
        list: [
            "Your KYC should be completed and PAN, Aadhaar and Bank account should be verified.",
            "You should have funds pre-loaded into the wallet.",
            "Opportunity should be in a 'FUNDING' state and should not reach fund limit.",
        ],
        tags: [opportunity, co2Reduced, srot],
    },
    {
        question: "What is the different status of opportunity?",
        answer: `Opportunity can have the following states`,
        list: [
            "Pending: If the current date is less than the Opportunity Start Date",
            "Funding: Fund will open on the Start Date, and will have status Funding till it is not 100% invested.",
            "Procuring: Opportunity will be a move to Procuring state when we have raised 100% capital for solar assets",
            "Installing: Opportunity will move to Install state when procurement of Solar Equipment is completed and installation begins.",
            "Active: Opportunity will move to an Active state once Installation & Commissioning completed and the plant is generating revenue",
            "Closed: Opportunity will move to a Closed state after we pay the final installment at the end of term.",
        ],
        tags: [opportunity, co2Reduced, srot],
    },
    {
        question:
            "What are the key points I should be aware of in the opportunity?",
        answer: `Opportunity Glossary:`,
        list: [
            "Status: It shows the status of the opportunity and can be in any one state from Pending to Closed during the lifetime of the opportunity",
            "Start Date: Date on which the opportunity will open and Investors can start investing in it.",
            "Fund Size: Total investment opportunity is rasing for funding the underlying solar projects.",
            "Targeted Yield: When you invest in an opportunity, this is the targeted rental yield you will get on your invested capital for the opportunity term.",
            "Investment Terms: Opportunity investment term for which you will earn rental yield from the solar asset and end of the term you will receive Depreciated Asset Value by selling an asset to other Investors.",
            "Gross Payout: Rental Payout you will receive every month from revenue generated from your solar asset",
            "Tax deducted at source(TDS): You need to pay 2% as TDS on the rental payout from your solar asset.",
            "Net Payout: Payout is paid to you every month after deducting TDS.",
            "Depreciated Asset Value: You will receive this amount in the final payout by selling your asset to other investors.",
        ],
        tags: [opportunity, govtSubsidy, invest, trending],
    },
];
const payoutTaxesFaq = [
    {
        question: "What is Targeted Yield?",
        answer: `When you invest into an opportunity, on your invested capital you will get rental which is equivalent to the Targeted Yield.`,
        tags: [opportunity],
    },
    {
        question: "How the Targeted Yield is calculated for the opportunity?",
        format: {
            __html: `
            The monthly payout will be calculated on the fly and will depend on invested capital and chosen investment term, <br> 
            If you invest INR 1 Lakh for a 5-year plan and the Targeted Rental yield is 12.5% with a monthly payout of INR 1678.40 and 38.3% depreciation on asset value, then IRR and targeted rental yield are calculated as follows:<br>
        <span style="font-style: italic"}}>
        - Monthly Gross Payout     = INR 1678.40 <br>
        - Total Gross Payout in 5Y = INR 100,704. <br>
        - Depreciated asset value   = INR 61,797 <br>
        - Total Rental Yield       = INR 61,797 + INR 100,704 = INR 1,62,501 <br> </span> 
        (Which is equivalent to 12.5% ROI and 16.03% IRR for a 5-year term.) <br>
        `,
        },
        tags: [opportunity, govtSubsidy, trending],
    },
    {
        question: "How will I get the payout?",
        answer: `Once you buy the asset and you will be paid monthly based on your Investment payout schedule, the payout will be credited to your wallet which you can either use to re-invest into an open opportunity or withdraw to your registered bank account.`,
        tags: [opportunity, invest, trending],
    },
    {
        question: "What will be the payout schedule?",
        answer: `Payout will be paid monthly. You can choose to opt for quarterly, or yearly payments as well.`,
        tags: [opportunity],
    },
    {
        question: "When will I start getting a payout for the investment?",
        answer: "Once a solar plant is commissioned and installed, the opportunity will move to an Active state and your investment payout schedule will be generated. Your plant will be authorized and become active within 45 days from the Procurement date.",
        tags: [opportunity],
    },
    {
        question: "Why does it take 45 days to start getting payout?",
        answer: `Your solar power plants that require 30-45 days to be installed at consumer sites and start generating revenue.`,
        tags: [opportunity, invest],
    },
    {
        question: "Is there any Tax deduction on payout?",
        answer: `Yes, since the payout will come as rental income from the solar asset (which comes under the movable asset category), so your monthly payout will be paid after deducting 2% TDS against your PAN and will be credited to your wallet.`,
        tags: [opportunity],
    },
];
const referralProgramFaq = [
    {
        question: "How can I refer my friends on SROT(स्रोत) platform?",
        answer: `You can signup on the SROT(स्रोत) platform and go to the "Referral" tab, there you will find your Referral Code which you can share with your friends.`,
        tags: [srot, trending],
    },
    {
        question: "How can I earn by referring my friends?",
        answer: `When your friend joins and makes their first investment on SROT(स्रोत) platform, you and your friend each will earn INR 1,000 for all successful referrals.`,
        tags: [opportunity, invest],
    },
    {
        question: "How can I use my referral bonus?",
        answer: `Referral bonus can only be used in the SROT(स्रोत) platform for subsequent investment. Your friends can use the referral bonus along with their first investment.`,
        tags: [trending],
    },
    {
        question: "When will the referral bonus be paid?",
        answer: `The referral bonus will be unlocked after your friends make their first investment and you can check your referral status in the referral section.`,
        tags: [opportunity, govtSubsidy, invest, trending],
    },
];

const investRiskFaq = [
    {
        question: "How is my investment very low risk?",
        answer: `The investment is considered to be of very low risk because`,
        list: [
            "This is an asset-backed investment i.e., you are buying the solar assets from your investment which generates electricity and generates revenue from the consumer where these assets are installed.",
            "The assets are leased out for the long term up to 20 years also backed by the Net-metering agreement of term 20 years between consumer and government electricity utility company like MSEDCL.",
            "We take 4-6 months of the advance rentals as a deposit from consumers. If the consumer default or delays on rent payment, we will have 100% ownership of the plant with us and we will transfer the plant to other paying consumers so that any disruption in rental payments does not impact investors.",
            "Your investment amount is backed by physical solar assets and solar assets have 20-year life and around 1% power depreciation per year.",
        ],
        tags: [invest],
    },
    {
        question: "What is Asset-backed investment?",
        answer: `An asset-backed investment is an investment whose income payments and value is derived from the collateralized pool of underlying assets. In this case, the income is backed by the solar assets you are buying at the time of investing.`,
        tags: [opportunity, trending, invest],
    },
];

const investMiscellaneousFaq = [
    {
        question: "What is the solar asset?",
        answer: `Solar assets are Solar Photovoltaic Power Plants, which will be installed on rooftops or ground at the consumer's premises`,
        tags: [co2Reduced, solar],
    },
    {
        question: "Who has ownership of the solar asset?",
        answer: `You will have the fractional ownership of the solar assets proportional to the amount you invested and the total value of the asset.`,
        tags: [solar, opportunity],
    },
    {
        question:
            "Do I get the physical procession of the solar assets bought on the platform?",
        answer: `No, you will not get the physical procession of the solar assets because these are pre-leased assets and will be installed at consumer premises and once your agreement period is over the same will be bought back from you.`,
        tags: [solar, srot],
    },
    {
        question: "Where will the solar assets be installed?",
        answer: `Solar assets will be installed at the consumer premises as per the pre-lease agreement associated with the solar assets`,
        tags: [solar, srot, trending],
    },
    {
        question: "What is fractional ownership?",
        answer: `Fractional ownership is a method in which several unrelated parties can share in, and mitigate the risk of, ownership of a high-value tangible asset, in our case it is solar assets.`,
        tags: [solar, opportunity],
    },
    {
        question: "Who will be responsible for the maintenance of assets?",
        answer: `SROT(स्रोत) will be completely and entirely responsible for the Engineering, Procuring, commissioning, installing and maintaining the solar assets at consumer sites.`,
        tags: [solar, opportunity, srot, govtSubsidy],
    },
];
const NRIInvestmentFaq = [
    {
        question: "Can NRI investors use the SROT(स्रोत) platform?",
        answer: `Yes, NRI investors can invest in SROT(स्रोत) platform from UPI, wallets, or Indian issued debit/credit cards but need an NRO accounts to receive the payouts.`,
        tags: [invest, srot],
    },
    {
        question: "What are the KYC documents for NRI investors?",
        answer: `PAN, NRO account, and Aadhaar are required, if Aadhaar is not present then you can upload a valid Passport as well.`,
        tags: [solar, invest],
    },
];
let faqs = [
    {
        title: "Value Proposition",
        id: valueProposition,
        faqs: propositionFaq,
    },

    {
        title: "How to Invest",
        id: howToinvest,
        faqs: investFaq,
    },
    {
        title: "KYC/Agreements",
        id: KYCAgreements,
        faqs: kycFaq,
    },
    {
        title: "Opportunities",
        id: srotOpportunities,
        faqs: opportunityFaq,
    },
    {
        title: "Payout & Taxes",
        id: payoutTaxes,
        faqs: payoutTaxesFaq,
    },
    {
        title: "Referral Program",
        id: referralProgram,
        faqs: referralProgramFaq,
    },
    {
        title: "NRI Investment",
        id: NRIInvestment,
        faqs: NRIInvestmentFaq,
    },
    {
        title: "Investment Risk",
        id: investmentRisk,
        faqs: investRiskFaq,
    },
    {
        title: "Miscellaneous",
        id: investMiscellaneous,
        faqs: investMiscellaneousFaq,
    },
];

const opporTunityTabfaqs = [
    {
        question: "What is an opportunity?",
        answer: `Opportunity is similar to Investment Fund, here we can add 1 or more pre-leased solar assets for which we are raising capital.`,
        tags: [trending, opportunity],
    },
    {
        question: "What is the prerequisite for investment into opportunity?",
        list: [
            "Your KYC should be completed and PAN, Aadhaar and Bank account should be verified.",
            "You should have funds pre-loaded into the wallet.",
            "Opportunity should be in a 'FUNDING' state and should not reach fund limit.",
        ],
        tags: [trending, opportunity],
    },
    {
        question: "What is the different status of opportunity?",
        answer: `Opportunity can have the following states`,
        list: [
            "Pending: If the current date is less than the Opportunity Start Date",
            "Funding: Fund will open on the Start Date, and will have status Funding till it is not 100% invested.",
            "Procuring: Opportunity will be a move to Procuring state when we have raised 100% capital for solar assets",
            "Installing: Opportunity will move to Install state when procurement of Solar Equipment is completed and installation begins.",
            "Active: Opportunity will move to an Active state once Installation & Commissioning completed and the plant is generating revenue",
            "Closed: Opportunity will move to a Closed state after we pay the final installment at the end of term.",
        ],
        tags: [solar, invest, co2Reduced, srot, opportunity],
    },
    {
        question:
            "What are the key points I should be aware of in the opportunity?",
        answer: `Opportunity Glossary:`,
        list: [
            "Status: It shows the status of the opportunity and can be in any one state from Pending to Closed during the lifetime of the opportunity",
            "Start Date: Date on which the opportunity will open and Investors can start investing in it.",
            "Fund Size: Total investment opportunity is rasing for funding the underlying solar projects.",
            "Targeted Yield: When you invest in an opportunity, this is the targeted rental yield you will get on your invested capital for the opportunity term.",
            "Investment Terms: Opportunity investment term for which you will earn rental yield from the solar asset and end of the term you will receive Depreciated Asset Value by selling an asset to other Investors.",
            "Gross Payout: Rental Payout you will receive every month from revenue generated from your solar asset",
            "Tax deducted at source(TDS): You need to pay 2% as TDS on the rental payout from your solar asset.",
            "Net Payout: Payout is paid to you every month after deducting TDS.",
            "Depreciated Asset Value: You will receive this amount in the final payout by selling your asset to other investors.",
        ],
        tags: [solar, opportunity, govtSubsidy, srot, invest],
    },
];

export {
    NRIInvestmentFaq,
    faqs,
    investFaq,
    investMiscellaneousFaq,
    investRiskFaq,
    kycFaq,
    opporTunityTabfaqs,
    opportunityFaq,
    payoutTaxesFaq,
    propositionFaq,
    referralProgramFaq,
};
