import Space from "../../../components/space/Space";
import { H3, P } from "../../../components/styles/Typography.styles";
import Backdrop from "./Backdrop";
import InputRow from "./InputRow";

import styled from "styled-components";
const BottomLevel = styled.div`
    background-color: ${(props) => props.theme.color.primary};
    padding: 1rem 2rem;
    text-align: center;
    border-radius: 0.8rem;
    margin-top: 2rem;
`;

const DisplayValues = (props) => {
    return (
        <>
            {props?.row1 && (
                <Space
                    xxsm={["0 0 2rem"]}
                    stm={["0 0 5rem"]}
                    lgm={["0 0 4rem"]}
                >
                    <div>
                        <Backdrop>
                            {props?.title1 && (
                                <Space
                                    xxsm={["0 0 2rem"]}
                                    stm={["0 0 3rem"]}
                                    lgm={["0 0 4rem"]}
                                >
                                    <H3>{props?.title1}</H3>
                                </Space>
                            )}
                            <InputRow
                                data={props?.row1}
                                spacing={["2rem", "0", "0"]}
                            />
                        </Backdrop>
                    </div>
                </Space>
            )}
            <div>
                <Backdrop>
                    {props?.title2 && (
                        <Space
                            xxsm={["0 0 2rem"]}
                            stm={["0 0 3rem"]}
                            lgm={["0 0 4rem"]}
                        >
                            <H3>{props?.title2}</H3>
                        </Space>
                    )}
                    {props?.row2 && <InputRow data={props?.row2} />}
                    {props?.bottomLevel && (
                        <BottomLevel>
                            <P color="white" weight="b">
                                {props?.bottomLevel}
                            </P>
                        </BottomLevel>
                    )}
                </Backdrop>
            </div>
        </>
    );
};

export default DisplayValues;
