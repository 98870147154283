import React from "react";
import Link from "../../../../components/link/Link";
import Tables from "../../../../components/table/Tables";
const cellStyles = {
    padding: "10px 0",
    borderLeft: "none",
    borderRight: "none",
    width: "20%",
};

const data = {
    large: [
        {
            index: 1,
            id: {
                value: null,
                component: () => (
                    <Link
                        color="primary"
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        SNN245 SNN F-Residency
                    </Link>
                ),
            },
            name: {
                value: "20/03/2024 / 12:14",
            },

            status: {
                value: "BANKING",
            },
            proposal: {
                value: "Feasibility Survey is book by Divyanshu Person Name: Shivam Feasibility Survey is book by Divyanshu Contact Person Name: Shivam",
            },
        },

        {
            index: 2,
            id: {
                value: null,
                component: () => (
                    <Link
                        color="primary"
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        SNN245 SNN F-Residency
                    </Link>
                ),
            },
            name: {
                value: "20/03/2024 / 12:14",
            },

            status: {
                value: "BANKING",
            },
            proposal: {
                value: "Feasibility Survey is book by Divyanshu Contact Person Name: Shivam Feasibility Survey is book by Divyanshu Contact Person Name: Shivam",
            },
        },
    ],
};
const AuditTable = () => {
    return (
        <div>
            <Tables
                tableHeader={tableHeader}
                data={data}
                cellStyles={cellStyles}
                cellMobileStyles={cellStyles}
            />
        </div>
    );
};

export default AuditTable;

const tableHeader = [
    {
        id: 1,
        name: "Project Name",
    },
    {
        id: 2,
        name: "Date / Time",
    },
    {
        id: 3,
        name: "Log Type",
    },

    {
        id: 4,
        name: "Audit Message",
    },
];
