import React from "react";
import { FaCog } from "react-icons/fa";
import styled, { keyframes } from "styled-components";

const RotationAnimation = keyframes`
0% {
    transform: rotate(0deg)
}
100% {
    transform: rotate(360deg)
}
`;

const Loader = styled(FaCog)`
    transform-origin: center;
    animation: ${RotationAnimation} 2s linear infinite;
`;

const Loading = () => <Loader />;

export default Loading;
