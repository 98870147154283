import React, { useState } from "react";
import P from "../../../shared/typography/P";
import ContactUSForm from "../../home/ContactUSForm";
import Faq from "../../home/Faq";
import Calculator from "../../home/Sections/Calculator";
import EnquiryPopUp from "../../home/Sections/EnquiryPopUp";
import PriceSection from "../../home/Sections/PriceSection";
import ProjectCarouselSection from "../../home/Sections/ProjectCarouselSection";
import GovernmentSubsidies from "../GovernmentSubsidies";
import SubsidyWithReview from "../SubsidyWithReview";
import ContactEnquiry from "../sections/ContactEnquiry";
import InvestInsurance from "../sections/InvestInsurance";

const subsidyData = {
    summary: (
        <P className="text-secondary">
            With the government’s help and our simple plans, solar plants have
            become more affordable and accessible than ever. The government
            offers incentives and tax benefits, making it easy to own a solar
            plant, start generating power, and save money on monthly electricity
            bills.
        </P>
    ),
    data: [],
};

const contactData = {
    heading: {
        title: "Ready to make the switch?",
        content:
            "Know more about our products, pricing, and plans by calling us or filling out the form.",
    },
    general: {
        title: "General Inquiry",
        content:
            "Simply fill out the form to get a call back from our team and learn more about it.",
    },
    investor: {
        title: "Investor Inquiry",
        content:
            "Looking to invest in solar energy? Reach out to us at marketing@hypersrot.com to learn more about the investment opportunity.",
    },
};

const Commercial = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>
            <PriceSection />
            <Calculator
                defaultIndex={1}
                heading="Calculate your savings"
                text="Determine your savings estimate by filling out a few details."
                bottomHeading="Sunlight to Savings"
                bottomText="Finding an ideal plan can be challenging. Use our calculator to find the plan that is best for you."
            />
            <GovernmentSubsidies
                summary={subsidyData.summary}
                content={
                    <SubsidyWithReview
                        data={subsidyData.data}
                        onClick={() => setShowModal(true)}
                    />
                }
            />
            <ProjectCarouselSection />
            <InvestInsurance />
            <ContactUSForm data={contactData} enquiry={<ContactEnquiry />} />
            <Faq />
            <EnquiryPopUp
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                callback={() => setShowModal(false)}
            />
        </div>
    );
};

export default Commercial;
