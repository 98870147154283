import React from "react";
import styled from "styled-components";
import { Section } from "../../components/styles/Elements.styles";
import Text from "../../components/text/Text";
const HeadingContainer = styled.div`
    padding: 180px 5% 120px;
    background: ${(props) => props.theme.color.secondary};
`;
const Privacy = () => (
    <>
        <HeadingContainer>
            <Text size="xml" align="center" weight="bold" color="white">
                Privacy Policy
            </Text>
        </HeadingContainer>
        <Section height="auto">
            <Text style={{ paddingTop: "100px" }} weight="400" size="s">
                At Hypersrot Technologies Pvt. Ltd., accessible from
                https://srot.app, one of our main priorities is the privacy of
                our visitors. This Privacy Policy document contains types of
                information that is collected and recorded by Hypersrot
                Technologies Pvt. Ltd. and how we use it. by using the services
                or by otherwise giving us your information, you will be deemed
                to have read, understood, and agreed to the practices and
                policies outlined in this privacy policy and agree to be bound
                by the privacy policy. you hereby consent to our collection,
                use, and sharing, disclosure of your information as described in
                this privacy policy. we reserve the right to change, modify, add
                or delete portions of the terms of this privacy policy, at our
                sole discretion, at any time. if you do not agree with this
                privacy policy at any time, do not use any of the services or
                give us any of your information. if you use the services on
                behalf of someone else (such as your child) or an entity (such
                as your employer), you represent that you are authorized by such
                individual or entity to (i) accept this privacy policy on such
                individual’s or entity’s behalf, and (ii) consent on behalf of
                such individual or entity to our collection, use and disclosure
                of such individual’s or entity’s information as described in
                this privacy policy.
            </Text>
            <br />
            <Text weight="600" size="s">
                Collection and use of your personal information
            </Text>
            <Text weight="400" size="s">
                Hypersrot may or may not collect your Personal Information to
                provide services on its web-based platform or mobile
                application. Hypersrot, may or may not, request sensitive
                information from You at the time of account opening, which would
                include but not be limited to, (i) setting a password; (ii)
                financial information such as Bank account or credit card or
                debit card, or other payment instrument details; (iii)
                information such as name, contact number, gender, date of birth,
                residence and employment addresses, father’s/mother’s and
                spouses names, bank account details, scans/copies of document
                proofs of address & identity such as Photograph, PAN, passport,
                drivers license, Aadhaar (voluntarily), etc. (iv) uploading
                signature proof/photo, etc. All such information would be for
                the purpose of identity verification and background checking for
                account opening as per KYC & AML Rules, personalize services
                offered to you, keeping you updated on our products and services
                relevant to you, and information pertaining to our services
                you’ve subscribed to, and for any legal and regulatory / audit
                purposes. Under Regulation 5 of the Information Technology
                (Reasonable Security and Procedures and Sensitive Personal Data
                or Information) Rules, 2011, Hypersrot is obliged to provide
                every registered user of Hypersrot with a readable copy of the
                personal information you have provided us. We would save such
                information as required by the law, and as directed by the
                regulators along with such intermediaries regulated by them, for
                the purpose of offering You our services, and for such further
                regulatory, audit, and business purposes. We collect Personal
                Information from you only when you voluntarily use the services
                provided by Hypersrot, and/or enquire to begin an association
                with Hypersrot and/or complete the process of opening an account
                with Hypersrot. With regard to security, we respect all
                information provided to us and take all reasonable steps towards
                the protection of the same. We have implemented technology and
                policies, with the objective of protecting your privacy from
                unauthorized access and improper use, and periodically review
                the same. We maintain procedural safeguards to protect the
                confidentiality and security of personally identifiable
                information transmitted to us.
            </Text>
            <br />
            <Text weight="600" size="s">
                Collection and use of your non-personal information
            </Text>
            <Text weight="400" size="s">
                Hypersrot may from time to time collect information, i.e.
                Non-personal information which cannot be used to identify you
                personally, while You are using the website or mobile
                application. Hypersrot may from time to time collect information
                such as non-personal information such as IP (internet protocol)
                addresses, browser types, the name of your ISP (internet service
                provider), information about a third-party link/URL from where
                You arrive to our website/mobile application, which pages/URLs
                do You click on the Hypersrot website / mobile application, and
                the amount of time spent on each. Hypersrot uses Google
                Analytics, a web analytics service provided by Google, Inc.
                Google Analytics uses first-party cookies to report on user
                interactions with our web-based services. These cookies store
                non-personally identifiable information.
            </Text>
            <br />
            <Text weight="600" size="s">
                Cookies:
            </Text>
            <Text weight="400" size="s">
                Our Website uses "Cookies" to identify the areas of our Website
                that you have visited. A Cookie is a small piece of data stored
                on your computer or mobile device by your web browser. We use
                Cookies to personalize the Content that you see on our Website.
                Most web browsers can be set to disable the use of Cookies.
                However, if you disable Cookies, you may not be able to access
                functionality on our Website correctly or at all. We never place
                Personally Identifiable Information in Cookies. Geographical
                Information: We will not share your current location with other
                users or partners. If you do not want us to use your location
                for the purposes set forth above, you should turn off the
                location services for the mobile application located in your
                account settings or in your mobile phone settings and/or within
                the mobile application.
            </Text>
            <br />
            <Text weight="600" size="s">
                Log Data:
            </Text>
            <Text weight="400" size="s">
                We follow a standard procedure of using log files. These files
                log visitors when they visit websites. All hosting companies do
                this and are a part of hosting services' analytics. The
                information collected by log files includes internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analyzing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information.
            </Text>
            <br />
            <Text weight="600" size="s">
                Consent:
            </Text>
            <Text weight="400" size="s">
                By using our website, you hereby consent to our Privacy Policy
                and agree to its Terms and Conditions.
            </Text>
            <br />
            <Text weight="600" size="s">
                Subject to Change
            </Text>
            <Text weight="400" size="s" style={{ paddingBottom: "100px" }}>
                This Privacy Policy is subject to change and may be updated or
                revised from time to time, without any prior notification to the
                User, at the sole discretion of Hypersrot. It is the
                responsibility of the User to periodically review the Privacy
                Policy to determine whether there have been any revisions or
                updates. The last updated date of the Policy has been mentioned
                above for the reference of the User. If you do not agree with
                the terms of this Privacy Policy or any changes made to this
                Policy, please stop using all products and services provided by
                Hypersrot immediately and write to us at the email ID stated
                above.
                <br />
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us through
                email at founders@srot.io
            </Text>
        </Section>
    </>
);

export default Privacy;
