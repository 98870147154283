import React from "react";

const TextField = ({
    label,
    value,
    onChange,
    type,
    name,
    helperMsg,
    errorMsg,
    required,
    icon,
    iconChange,
    errorButton,
    iconWrapperClass = "right-3 flex items-center",
    ...rest
}) => {
    return (
        <div className="flex flex-col">
            <label
                className="text-secondary font-normal text-sm sm:text-base xl:text-lg mb-0.8 "
                htmlFor={name}
            >
                {label} {required && <span className="text-red">*</span>}
            </label>
            <div
                className={` relative px-px py-px border  ${
                    errorMsg && errorMsg?.length > 0
                        ? "border-red"
                        : "bg-custom-gradient"
                }  rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-btn-s`}
            >
                <input
                    className={`border-none w-full  rounded-lg px-1 py-1  lg:py-1.2
                    text-primary font-normal text-sm sm:text-base xl:text-base 2xl:text-xl
                    `}
                    value={value}
                    name={name}
                    type={type}
                    onChange={onChange}
                    required={false}
                    {...rest}
                />
                {icon && (
                    <div className={`cursor-pointer`}>
                        <span
                            onClick={() => iconChange()}
                            className={`absolute cursor-pointer inset-y-0 ${iconWrapperClass} flex items-center`}
                        >
                            {icon}
                        </span>
                    </div>
                )}
            </div>
            {errorMsg && (
                <>
                    <span className="text-red text-sm xl:text-base flex items-center flex-wrap gap-0.5">
                        {errorMsg}* {errorButton}
                    </span>
                </>
            )}
            {helperMsg && (
                <span className="text-xs text-gray-400">{helperMsg}</span>
            )}
        </div>
    );
};

export default TextField;
