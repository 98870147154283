import styled from "styled-components";

const Line = styled.div`
    width: ${(props) => props.width || "auto"};
    max-width: ${(props) => props.maxWidth || "auto"};
    height: ${(props) => props.height || "auto"};
    min-height: ${(props) => props.minHeight || "auto"};
    background-color: ${(props) =>
        props.backgroundColor ? props.theme.color[props.backgroundColor] : ""};
    border-radius: ${(props) => props.borderRadius || ""};
    padding: ${(props) => props.padding || ""};
    margin: ${(props) => props.margin || ""};
    flex: ${(props) => props.flex || ""};
`;

export default Line;
