import React from "react";
import { callCalling } from "../../../resources/js/icons";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";

const ContactEnquiry = () => {
    return (
        <div className="flex items-center flex-wrap mt-1.6 xl:mt-0.8">
            <Button
                style={{ borderRadius: "0px" }}
                className="lg:rounded-none  xl:rounded-none 2xl:rounded-none mr-3"
            >
                Get an enquiry
            </Button>
            <div className="flex items-center flex-nowrap">
                <P className="flex items-center gap-1">
                    <img src={callCalling} alt="call_phone_icon" />
                    <a href="tel:+91 9876543210">+91 9876543210</a>
                </P>
            </div>
        </div>
    );
};

export default ContactEnquiry;
