import { ROLE } from "../constants/common";
import { DASHBOARD, ONBOARDING } from "../constants/routes";

import { checkIsAllowed } from "./helpers";

export const handleRedirection = (state, history, roles, status) => {
    if (state.redirectPath) {
        history.push(state.redirectPath);
    } else if (
        checkIsAllowed(roles, [
            ROLE.SUPER_ADMIN,
            ROLE.SALES_MANAGER,
            ROLE.OPERATION_MANAGER,
        ])
    ) {
        history.push(DASHBOARD);
    } else {
        history.push(`${ONBOARDING}`);
    }
};
