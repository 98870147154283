import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useRef } from "react";
import { Bar as BAR } from "react-chartjs-2";
import theme from "../../../theme";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ChartDataLabels,
    Legend
);

ChartJS.defaults.font.size = 16;
ChartJS.defaults.scale.grid.display = false;
ChartJS.defaults.color = "white";

const Bar = (props) => {
    const chartRef = useRef(null);

    const data = {
        labels: props.labels,
        datasets: props.datasets,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Set to false to allow the chart to resize freely
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    color: theme.color.main,
                    font: {
                        size: 14,
                    },
                },
            },
            datalabels: false,
            title: {
                display: false,
                text: "",
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "black",
                    font: {
                        size: 16,
                    },
                },
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    color: "black",
                    font: {
                        size: 16,
                    },
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    // useEffect(() => {
    //     const chart = chartRef.current;

    //     if (chart) {
    //         const legendContainer = document.getElementById("chart-legend");
    //         legendContainer.innerHTML = "";

    //         chart.legend.legendItems.forEach((legendItem) => {
    //             const legendItemDiv = document.createElement("div");
    //             legendItemDiv.style.display = "flex";
    //             legendItemDiv.style.alignItems = "center";
    //             legendItemDiv.style.marginBottom = "5px";

    //             const colorBox = document.createElement("span");
    //             colorBox.style.backgroundColor = legendItem.fillStyle;
    //             colorBox.style.width = "20px";
    //             colorBox.style.height = "20px";
    //             colorBox.style.marginRight = "10px";
    //             const text = document.createElement("span");
    //             text.textContent = legendItem.text;
    //             text.style.color = "black";
    //             text.style.fontSize = "16px";
    //             legendItemDiv.appendChild(colorBox);
    //             legendItemDiv.appendChild(text);
    //             legendContainer.appendChild(legendItemDiv);
    //         });
    //     }
    // }, [props.datasets, props.labels]);

    return (
        <div>
            <div className="h-[250px] sm:h-[350px] lg:h-[450px] ">
                <BAR ref={chartRef} options={options} data={data} />
            </div>
        </div>
    );
};

export default Bar;
