import React, { useState } from "react";
import Container from "../../../../shared/Container";
import Section from "../../../../shared/Section";
import H2 from "../../../../shared/typography/H2";
import H3 from "../../../../shared/typography/H3";
import P from "../../../../shared/typography/P";
import Voting from "./Voting";
const TabButton = ({ onChange, index }) => {
    return (
        <div className="flex w-full bg-gray-100 border-0.5 border-blue-500  rounded-full">
            {["Lease", "Purchase", "Loan"].map((option, ind) => (
                <button
                    key={option}
                    className={`flex-1 py-1 px-1 rounded-full transition-colors duration-300 xl:text-lg md:text-lsm font-normal flex items-center justify-center text-nowrap ${
                        index === ind
                            ? "bg-primary text-white"
                            : "bg-gray-100 text-secondary"
                    } ${option === "Plan Details" ? "relative" : ""}`}
                    onClick={() => onChange(ind)}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

const Calculation = () => {
    const [index, setIndex] = useState(0);
    const [graph, setGraph] = useState("Lease");
    const handleGraphChange = (index) => {
        if (index === 0) {
            setGraph("Lease");
            setIndex(0);
        }
        if (index === 1) {
            setGraph("Purchase");
            setIndex(1);
        }
        if (index === 2) {
            setGraph("Loan");
            setIndex(2);
        }
    };

    return (
        <div>
            {" "}
            <Section>
                <Container>
                    <div className="flex justify-between gap-1.2 flex-col lg:flex-row items-center lg:gap-2 mb-3.2">
                        <div className="w-full lg:w-[35%] xl:w-[30%] 2xl:w-[35%]">
                            <H2>Solar Plant Installation Plan</H2>
                        </div>
                        <div className="w-full lg:w-[65%] xl:w-[45%]">
                            <P className="text-secondary">
                                We are at the forefront of the renewable energy
                                revolution, specializing in high-quality,
                                cost-effective solar power solutions designed to
                                meet the diverse needs of both urban and rural
                                communities.
                            </P>
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-between items-center  lg:gap-2">
                        <div>
                            <H3 className="w-full lg:flex-1">
                                Lease Plan Proposal
                            </H3>
                        </div>
                        <div className=" lg:flex w-[60%]  lg:w-auto">
                            <TabButton
                                onChange={handleGraphChange}
                                index={index}
                            />
                        </div>
                    </div>

                    <div className="mt-6 grid grid-cols-2 gap-2 sm:grid-cols-2 lg:grid-cols-4 ">
                        <div className="text-center shadow-md rounded-xl p-6 border border-gray-200 bg-linear-border">
                            <div className="text-gray-500 mt-1">
                                <P className="font-urbanist font-semibold">
                                    Govt Subsidy
                                </P>
                            </div>
                            <div className="text-lg font-semibold text-gray-800">
                                <P className="font-urbanist font-semibold">
                                    20%
                                </P>
                            </div>
                        </div>
                        <div className="text-center shadow-md rounded-xl p-6 border border-gray-200 bg-linear-border">
                            <div className="text-gray-500 mt-1">
                                <P className="font-urbanist font-semibold">
                                    Bill With Solar
                                </P>
                            </div>
                            <div className="text-lg font-semibold text-gray-800">
                                <P className="font-urbanist font-semibold">
                                    ₹ 4,000
                                </P>
                            </div>
                        </div>
                        <div className="text-center shadow-md rounded-xl p-6 border border-gray-200 bg-linear-border">
                            <div className="text-gray-500 mt-1">
                                <P className="font-urbanist font-semibold">
                                    Solar Plant Size
                                </P>
                            </div>
                            <div className="text-lg font-semibold text-gray-800">
                                <P className="font-urbanist font-semibold">
                                    110kWp
                                </P>
                            </div>
                        </div>
                        <div className="text-center shadow-md rounded-xl p-6 border border-gray-200 bg-linear-border">
                            <div className="text-gray-500 mt-1">
                                <P className="font-urbanist font-semibold">
                                    ROI
                                </P>
                            </div>
                            <div className="text-lg font-semibold text-gray-800">
                                <P className="font-urbanist font-semibold">
                                    10%
                                </P>
                            </div>
                        </div>
                    </div>

                    <div className="border-2 border-blue-300  px-2 mt-3">
                        <div className="flex justify-between gap-1.2 flex-col lg:flex-row items-center lg:gap-7 mb-0">
                            <div className="md:w-[50%] w-[100%]">
                                <P className=" md:mt-0 mt-2 mb-2">
                                    PLAN POLLING
                                </P>
                                <H3>Vote for the Plan You Prefer More</H3>
                                <P>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Minima obcaecati similique
                                    veniam sed dignissimos consectetur! Facilis
                                    quidem nesciunt assumenda nam exercitationem
                                    a veritatis dolores voluptate!
                                    Exercitationihil sequi mollitia accusamus
                                    pariatur officia animi consequatur! Aliquid,
                                    corrupti! Minus, rem.
                                </P>
                            </div>
                            <div className="md:w-[50%] w-[100%]">
                                <Voting />
                            </div>
                        </div>
                    </div>
                </Container>
            </Section>
        </div>
    );
};

export default Calculation;
