import { motion } from "framer-motion";
import React, { useRef } from "react";
import { homeVideoUrl } from "../../resources/js/videos";
import Container from "../../shared/Container";
import {
    container,
    headingsTwo,
    slideOpacityLeft,
    translateContainer,
    wavyHeadings,
} from "../../shared/animation/constants/landingAnimation";
import SplitText from "../../shared/animation/typography/SplitText";
import H3 from "../../shared/typography/H3";
import Button from "../../shared/ui/Button";
import ContactUSForm from "./ContactUSForm";
import CustomerReviews from "./CustomerReviews";
import Faq from "./Faq";
import Hero from "./Hero";
import Recognition from "./Recognition";
import Calculator from "./Sections/Calculator";
import CarouselSection from "./Sections/CarouselSection";
import FeatureSection from "./Sections/FeatureSection";
import PriceSection from "./Sections/PriceSection";
import ProjectCarouselSection from "./Sections/ProjectCarouselSection";
const HeroContent = ({ onClick }) => {
    return (
        <Container>
            <div
                style={{
                    paddingTop:
                        "clamp(3rem, calc((100vw - 1439px) * 80), 5rem)",
                }}
            >
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className="text-white w-full 
                2xs:w-[94%] sm:w-[600px] md:w-[680px] lg:w-[700px] xl:w-[810px] 2xl:w-[1100px] 3xl:w-[1200px]
                "
                >
                    <SplitText
                        className="gap-x-0.8 sm:gap-x-1.2 flex flex-wrap text-white "
                        variants={wavyHeadings}
                    >
                        Illuminate Your Future with Innovative Solar Solutions
                    </SplitText>
                </motion.div>

                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={container}
                    className="overflow-x-hidden"
                >
                    <motion.div variants={headingsTwo}>
                        <H3 className="font-normal text-white mt-0.8 sm:mt-2.4 xl:mt-5 sm:text-[2.6rem] lg:text-5xl">
                            Powering Possibilities with Solar Energy
                        </H3>
                    </motion.div>

                    <motion.div variants={slideOpacityLeft}>
                        <Button
                            variant="secondary"
                            className="mt-2.4 xl:mt-2.4 2xl:mt-4.8"
                            onClick={onClick}
                        >
                            Learn more
                            <span>
                                <svg
                                    width="13"
                                    height="14"
                                    className="  w-[14px] h-[14px]  lg:w-[18px] lg:h-[18px] xl:w-[24px] xl:h-[24px] "
                                    viewBox="0 0 13 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.38162"
                                        y="0.9866"
                                        width="12.0268"
                                        height="12.0268"
                                        rx="6.01338"
                                        stroke="currentColor"
                                        strokeWidth="0.76324"
                                    />
                                    <path
                                        d="M4 9.39498L8.79 4.60498M8.79 4.60498H4.479M8.79 4.60498V8.91598"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </Button>
                    </motion.div>
                </motion.div>
            </div>
        </Container>
    );
};
const Home = () => {
    const priceSectionRef = useRef(null);

    const handleLearnMoreClick = () => {
        if (priceSectionRef.current) {
            priceSectionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div>
            <Hero
                content={<HeroContent onClick={handleLearnMoreClick} />}
                url={homeVideoUrl}
                type={"video"}
                wrapperClass=" xl:h-[830px]  2xl:h-screen"
            />
            <FeatureSection />
            <CarouselSection />
            <div ref={priceSectionRef}>
                <PriceSection />
            </div>
            <Calculator />
            <ProjectCarouselSection />
            <Recognition />
            <CustomerReviews />
            <ContactUSForm />
            <Faq />
        </div>
    );
};

export default Home;
