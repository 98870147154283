import { useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import { FaLink } from "react-icons/fa";
import styled from "styled-components";
import Link from "../../../../components/link/Link";
import Space from "../../../../components/space/Space";
import Container from "../../../../components/styles/Container.styles";
import { Flex, Section } from "../../../../components/styles/Elements.styles";
import { H1, P } from "../../../../components/styles/Typography.styles";
import Tables from "../../../../components/table/Tables";
import { GOOGLE_MAP_API_KEY, SURVEY_VIEW } from "../../../../constants/routes";
const Wrapper = styled(Container)`
    padding-top: 100px;
`;

const placesLibrary = ["places"];
const cellStyles = {
    padding: "10px 0",
    borderLeft: "none",
    borderRight: "none",
};

const data = {
    large: [
        {
            index: 1,
            name: {
                value: null,
                component: () => (
                    <Link
                        color="primary"
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                        to={`${SURVEY_VIEW}/SNN245 `}
                    >
                        SNN F-residency
                    </Link>
                ),
            },
            surveyDate: {
                value: "25/03/2024",
            },
            contactPerson: {
                value: "Rohit /  8965875896",
            },
            siteOwner: {
                value: "Ankit / 8965875896",
            },
            status: {
                value: "BOOKED",
            },
            surveyLink: {
                value: null,
                component: () => (
                    <Link
                        color="primary"
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                        to={`${SURVEY_VIEW}/SNN245 `}
                    >
                        <FaLink />
                    </Link>
                ),
            },
        },
        {
            index: 2,
            name: {
                value: null,
                component: () => (
                    <Link
                        color="primary"
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                        to={`${SURVEY_VIEW}/SNN255 `}
                    >
                        SNN N-Residency
                    </Link>
                ),
            },
            surveyDate: {
                value: "05/04/2024",
            },
            contactPerson: {
                value: "Aman / 7589652458",
            },
            siteOwner: {
                value: "Raj / 7589652458",
            },
            status: {
                value: "FILLED",
            },
            surveyLink: {
                value: null,
                component: () => (
                    <Link
                        color="primary"
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                        to={`${SURVEY_VIEW}/SNN255 `}
                    >
                        <FaLink />
                    </Link>
                ),
            },
        },
    ],
    small: [
        {
            index: 1,
            val: {
                value: null,
                component: () => (
                    <>
                        <Link
                            color="primary"
                            isInteractive={true}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                            size={"12px"}
                            to={`${SURVEY_VIEW}/45896 `}
                        >
                            45896
                        </Link>{" "}
                        <P xxs="20px">/05/04/2024</P>
                    </>
                ),
            },
            billAmount: {
                value: "2,50,000/-",
            },
            lastDPD: {
                value: "-10    -7    -5",
            },
            status: {
                value: "3/60",
            },
        },

        {
            index: 2,
            val: {
                value: null,
                component: () => (
                    <>
                        <Link
                            color="primary"
                            isInteractive={true}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                            to={`${SURVEY_VIEW}/58956 `}
                            size="12px"
                        >
                            58956
                        </Link>
                        <P xxs="20px">/05/04/2024</P>
                    </>
                ),
            },
            billAmount: {
                value: "3,50,000/-",
            },
            lastDPD: {
                value: "-4    -8     -2",
            },
            status: {
                value: "3/60",
            },
        },
    ],
};
const AgentSurveyOverview = () => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
        libraries: placesLibrary,
    });
    return (
        <Section height="auto">
            <Container>
                <Space
                    xxsm={["4rem 0 0rem"]}
                    stm={["5rem 0 1.5rem"]}
                    lgm={["6rem 0 1rem"]}
                >
                    <Flex.Item xxs="100%" st="48%">
                        <H1>Survey Overview</H1>
                    </Flex.Item>
                </Space>
                {isLoaded && (
                    <Tables
                        mobileTableHeader={mobileTableHeader}
                        tableHeader={tableHeader}
                        data={data}
                        cellStyles={cellStyles}
                        cellMobileStyles={cellStyles}
                    />
                )}
            </Container>
        </Section>
    );
};

export default AgentSurveyOverview;

const tableHeader = [
    {
        id: 1,
        name: "Property Name",
    },
    {
        id: 2,
        name: "Survey Date",
    },
    {
        id: 3,
        name: "Contact PersonName/Mobile",
    },

    {
        id: 4,
        name: "Site OwnerName/Mobile",
    },
    {
        id: 5,
        name: "Status",
    },
    {
        id: 6,
        name: "SurveyLink",
    },
];
const mobileTableHeader = [
    {
        id: 1,
        name: "Id/Due Date",
    },
    {
        id: 2,
        name: "Bill Amount",
    },
    {
        id: 3,
        name: "Last 3 DPD",
    },
    {
        id: 4,
        name: "Status",
    },
];
