import React from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import Icon from "../Icon/Icon";
const Wraper = styled.div`
    background-color: ${(props) =>
        props.variant === "light"
            ? props.theme.color.darkGrey
            : props.theme.color.white};

    color: ${(props) => props.theme.color.white};
    display: flex;
    justify-content: center;
    border-radius: 100%;
    padding: 5px;
    :hover {
        cursor: pointer;
    }
`;
const Times = ({ bgTheme }) => {
    return (
        <Wraper variant={bgTheme}>
            <FaTimes />
        </Wraper>
    );
};
const ModalTimes = ({ onClose, bgTheme }) => {
    return (
        <Icon
            onClick={onClose}
            color={bgTheme === "light" ? "white" : "grey"}
            component={() => <Times bgTheme={bgTheme} />}
        />
    );
};

export default ModalTimes;
