import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

const defaultSettings = {
    dots: true,
    infinite: true,
    speed: 50,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
};

const SlickCarousel = ({ settings = defaultSettings, children }) => {
    return (
        <SliderContainer>
            <Slider className="flexBox" {...settings}>
                {children}
            </Slider>
        </SliderContainer>
    );
};

export default SlickCarousel;

const SliderContainer = styled.div`
    color: white;
    .slick-prev:before,
    .slick-next:before {
        color: ${(props) => props.theme.color.black800};
        display: none;
    }
    .slick-dots li button:before {
        font-size: 0.8rem;
        color: ${(props) => props.theme.color.primary};
    }
`;
