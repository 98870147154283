import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { topLaptop } from "../../assets";
import {
    container,
    slideLeft,
    slideRight,
} from "../../components/animation/landingAnimation";
import Container from "../../components/styles/Container.styles";
import { Flex } from "../../components/styles/Elements.styles";
import { H1 } from "../../components/styles/Typography.styles";
import HostPanel from "../landing/HostPanel";
import Articles from "./Articles";

const title = "Monetise Your Vacant Roof Space";
const summary = `Now you can easily monetise your free space or
vacant roof by switching to Solar Energy with no
installation charges and can save up to 50% of
your existing electricity bill`;
const Blogs = () => {
    return (
        <>
            <BlogHero />
            <Articles />
            <HostPanel title={title} summary={summary} />
        </>
    );
};

export default Blogs;

const Wrapper = styled.div`
    min-height: 60vh;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    img {
        object-fit: cover;
        object-position: right center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -2;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        object-position: right center;
        img {
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        img {
            object-position: right center;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        min-height: 60vh;
        height: 60vh;
        img {
            object-position: right center;
        }
    }
`;

const TextContainer = styled(Flex.Container)`
    width: 100%;
    height: 100%;
    margin-top: 15rem;
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        margin-top: 0;
    }
`;

const BlogHero = () => {
    return (
        <div>
            <Wrapper>
                <img src={topLaptop} alt="blog_bg_image" />
                <Container style={{ height: "100%" }}>
                    <TextContainer
                        justify="flex-end"
                        align="center"
                        as={motion.div}
                        initial="hidden"
                        whileInView="visible"
                        variants={container}
                    >
                        <Flex.Item xxs="100%" st="70%" t="60%" lg="50%">
                            <H1
                                align="center"
                                as={motion.h1}
                                variants={slideRight}
                            >
                                Learn
                            </H1>
                            <H1
                                textTransform="capitalize"
                                align="center"
                                as={motion.h1}
                                variants={slideLeft}
                            >
                                Something new today
                            </H1>
                        </Flex.Item>
                    </TextContainer>
                </Container>
            </Wrapper>
        </div>
    );
};
