import React from "react";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../constants/routes";
import Container from "../../shared/Container";
import H1 from "../../shared/typography/H1";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";
import UpdatePasswordForm from "./UpdatePasswordForm";

const ResetPasswordHero = () => {
    const history = useHistory();

    return (
        <Container>
            <div className="flex justify-center xl:justify-between flex-wrap flex-col xl:flex-row items-center xl:gap-9 xl:mt-[9rem]">
                <div className="w-full hidden xl:w-[30%] xl:flex flex-col gap-4.8 xl:ml-4">
                    <H1 className="text-white font-bold">Reset Password</H1>
                    <P className="text-white">
                        Lorem ipsum dolor sit amet consectetur. Platea at eu
                        viverra vitae suscipit ut.
                    </P>
                </div>
                <div className="flex-1 w-full xl:w-1/5 2xl:w-1/2 xl:mr-4">
                    <div className="w-full mx-auto bg-white rounded-1.5 shadow-md py-5.6 px-4">
                        <div className="flex justify-between gap-0.6 items-center ">
                            <H2 className="font-normal xl:text-3xl mb-1 xl:mb-0">
                                Reset Your Password
                            </H2>
                            (
                            <div
                                className="cursor-pointer"
                                onClick={() => {
                                    history.push(LOGIN);
                                }}
                            >
                                <p className="underline text-blue text-xs md:text-sm">
                                    Have an account? Log in here
                                </p>
                            </div>
                            )
                        </div>
                        <UpdatePasswordForm />
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default ResetPasswordHero;
