import React from "react";

import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";
const OurAim = () => {
    return (
        <Section>
            <Container>
                <div
                    className={`flex justify-between gap-1.6 flex-col lg:flex-row  items-center   xl:mb-3.2`}
                >
                    <div className=" w-full lg:w-[40%] flex flex-col justify-start items-start">
                        <P
                            className={
                                "text-secondary text-start uppercase xl:text-2xl"
                            }
                        >
                            OUR AIM
                        </P>
                        <H2 className={"text-start"}>
                            Building a better tommorow.
                        </H2>
                    </div>

                    <P
                        className={
                            "lg:text-right lg:w-[60%] xl:w-[40%] text-secondary"
                        }
                    >
                        Lorem ipsum dolor sit amet consectetur. Posuere
                        consectetur malesuada nibh id iaculis et erat vulputate.
                        Lectus dui suspendisse in pellentesque. Condimentum
                        tortor enim non venenatis ultrices massa.
                    </P>
                </div>
            </Container>
        </Section>
    );
};

export default OurAim;
