import styled from "styled-components";

import Loading from "../loading/Loading";
import { ButtonComponent, Flex } from "../styles/Elements.styles";

const ButtonLoadingContainer = styled.div`
    padding: 0 5px;
    height: 1em;
`;

const ButtonLoaderSpace = styled.div`
    width: 26px;
    height: 5px;
`;

const Button = (props) => (
    <ButtonComponent {...props}>
        <Flex.Container
            align="center"
            justify="center"
            style={{ flexWrap: "nowrap" }}
        >
            {props.isLoading ? <ButtonLoaderSpace /> : null}
            {props.children}
            {props.isLoading ? (
                <ButtonLoadingContainer>
                    <Loading />
                </ButtonLoadingContainer>
            ) : null}
        </Flex.Container>
    </ButtonComponent>
);

export default Button;

export const ButtonAlt = styled(Button)`
    background: transparent;
    border: 2px solid ${(props) => props.theme.color[props.backgroundColor]};
    box-sizing: border-box;
    transition: all ease 0.4s;
    padding: ${(props) => (props.size === "s" ? ".5rem 1rem" : "1rem 1.8rem")};
    :hover {
        box-shadow: ${(props) =>
            props.disabled
                ? "none"
                : `0 0 7px ${props.theme.color[props.backgroundColor]}`};
    }
`;
