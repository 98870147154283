import React, { useState } from "react";
import { SiTicktick } from "react-icons/si";
import H4 from "../../../../shared/typography/H4";

const Voting = () => {
    const [votes, setVotes] = useState({
        Lease: 75,
        Loan: 10,
        Purchase: 10,
        NotSure: 5,
    });

    const [voted, setVoted] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const totalVotes =
        votes.Lease + votes.Loan + votes.Purchase + votes["NotSure"];

    const handleVote = (option) => {
        setVotes((prevVotes) => ({
            ...prevVotes,
            [option]: prevVotes[option] + 1,
        }));
        setSelectedOption(option);
        setVoted(true);
    };

    const calculatePercentage = (count) =>
        Math.round((count / totalVotes) * 100);

    return (
        <div className="p-2 pt-2 md:ml-5">
            {Object.keys(votes).map((option) => {
                const percentage = voted
                    ? calculatePercentage(votes[option])
                    : 0;
                return (
                    <div
                        key={option}
                        onClick={() => handleVote(option)}
                        className="my-2 cursor-pointer flex items-center border-2 border-blue-500 rounded overflow-hidden"
                    >
                        <div className="w-full flex items-center bg-white text-blue-700 transition relative">
                            <div
                                className="h-15 flex items-center justify-between relative bg-blue-300"
                                style={{
                                    width: `${percentage}%`,
                                }}
                            >
                                <H4 className="ml-2 flex items-center">
                                    {selectedOption === option && (
                                        <SiTicktick className="mr-2" />
                                    )}
                                    {option}
                                </H4>
                            </div>
                        </div>
                        {voted && (
                            <span className="">
                                <H4 className="mr-2">{percentage}%</H4>
                            </span>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Voting;
