import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { motion } from "framer-motion";

import {
    Foods,
    JP,
    MoharPratima,
    insight4,
} from "../../../resources/js/images";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import {
    opacity,
    translateContainer,
    wavyHeadings,
} from "../../../shared/animation/constants/landingAnimation";
import Counter from "../../../shared/animation/typography/Counter";
import SplitText from "../../../shared/animation/typography/SplitText";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import ProjectCard from "../../../shared/ui/Cards/ProjectCard";

const projects = [
    {
        id: 1,
        industry: "Agriculture Industry",
        image: MoharPratima,
        title: "Mohar Pratima",
        location: "Talegaon, Pune",
        plantSize: "15 KWp",
    },
    {
        id: 2,
        industry: "Commercial",
        image: JP,
        title: "JK Industries",
        location: "Chakan, Pune",
        plantSize: "20 KWp",
    },
    {
        id: 3,
        industry: "Commercial",
        image: Foods,
        title: "Bora Agro Foods",
        location: "Pune",
        plantSize: "200 KWp",
    },
    {
        id: 4,
        industry: "Industry",
        image: insight4,
        title: "Mohar Pratima",
        location: "Talegaon, Pune",
        plantSize: "15 KWp",
    },
];

const ProjectCarouselSection = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", () => {});
        return () => window.removeEventListener("resize", () => {});
    }, []);
    const previousSlide = () => {
        if (currentSlide === 0) {
            setCurrentSlide(projects.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const nextSlide = () => {
        if (currentSlide === projects.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    return (
        <Section>
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className=" flex flex-col  lg:flex-row lg:justify-between lg:items-center "
                >
                    <div className="text-left w-full lg:w-[40%] xl:w-[35%] 2xl:w-[40%] gap-1 flex flex-col lg:mb-4 xl:mb-4.8 2xl:mb-5.6">
                        <SplitText heading="h2" variants={wavyHeadings}>
                            Past Projects Paving the Way Forward
                        </SplitText>
                        <motion.div variants={opacity}>
                            <P className=" text-base sm:text-lg  text-secondary text-justify sm:text-left">
                                Our portfolio of past projects is a testament to
                                our commitment to building a greener and cleaner
                                world. Our track record speaks for itself; look
                                at our collection of best projects.
                            </P>
                        </motion.div>
                    </div>
                    <div className=" flex flex-row justify-between sm:justify-normal  gap-1 sm:gap-4 lg:gap-4 xl:gap-5   my-3.2">
                        <div className="md:text-center">
                            <H3 className="text-lg sm:text-2xl lg:text-5xl text-nowrap font-semibold text-gray-900">
                                <Counter value={20} />+ MW
                            </H3>
                            <P className=" text-xs sm:text-base  mt-0.4 lg:text-lg text-nowrap text-secondary">
                                Capacity installed
                            </P>
                        </div>
                        <div className="md:text-center ">
                            <H3 className="text-lg sm:text-2xl lg:text-5xl text-nowrap font-semibold text-gray-900">
                                <Counter value={20} />+
                            </H3>
                            <P className="text-xs sm:text-base  mt-0.4 lg:text-lg text-nowrap text-secondary">
                                Projects executed
                            </P>
                        </div>
                        <div className="md:text-center ">
                            <H3 className="text-lg sm:text-2xl  lg:text-5xl text-nowrap font-semibold text-gray-900">
                                <Counter start={2000} value={4800} />+ Tons
                            </H3>
                            <P className=" text-xs sm:text-base mt-0.4 lg:text-lg text-nowrap text-secondary">
                                CO2 reduced yearly
                            </P>
                        </div>
                    </div>
                </motion.div>

                <div>
                    <CarouselComponent
                        selectedItem={currentSlide}
                        onChange={setCurrentSlide}
                        showArrows={false}
                        showStatus={false}
                        showIndicators={false}
                        infiniteLoop={true}
                        showThumbs={false}
                        interval={3000}
                        autoPlay={true}
                        stopOnHover={true}
                        transitionTime={500}
                        swipeable={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        emulateTouch={true}
                        centerMode={true}
                        centerSlidePercentage={
                            document.body.clientWidth < 1279
                                ? document.body.clientWidth < 1100
                                    ? document.body.clientWidth < 766
                                        ? document.body.clientWidth < 630
                                            ? 100
                                            : 80
                                        : 60
                                    : 46
                                : document.body.clientWidth > 1535
                                ? 28
                                : 33
                        }
                    >
                        {projects.map((project, index) => (
                            <ProjectCard
                                key={index}
                                industry={project.industry}
                                image={project.image}
                                title={project.title}
                                location={project.location}
                                plantSize={project.plantSize}
                                label={"Plant Size"}
                            />
                        ))}
                    </CarouselComponent>

                    <div
                        className={` mt-2 flex justify-start gap-1 w-full items-center`}
                    >
                        <button
                            onClick={previousSlide}
                            className="text-xl xl:text-3xl 2xl:text-5xl"
                        >
                            <IoIosArrowBack />
                        </button>

                        <p>
                            <span className=" text-lg xl:text-3xl 2xl:text-5xl">
                                {currentSlide + 1 > 9
                                    ? currentSlide + 1
                                    : `0${currentSlide + 1}`}
                            </span>{" "}
                            <span className=" text-sm xl:text-base 2xl:text-lg">
                                /{" "}
                                {projects.length > 9
                                    ? projects.length
                                    : `0${projects.length}`}
                            </span>
                        </p>

                        <button
                            onClick={nextSlide}
                            className="text-xl xl:text-3xl 2xl:text-5xl"
                        >
                            <IoIosArrowForward />
                        </button>
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default ProjectCarouselSection;
