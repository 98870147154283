import React, { createContext, useContext, useReducer } from "react";
import { reducer } from "./reducer";
import { initialState } from "./store";

const ContextState = createContext();

export const useContextState = () => {
    return useContext(ContextState);
};

const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        state,
        dispatch,
    };

    return (
        <ContextState.Provider value={value}>{children}</ContextState.Provider>
    );
};

export default ContextProvider;
