import React, { useState } from "react";
import { insurance } from "../../../resources/js/images";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H2 from "../../../shared/typography/H2";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import EnquiryPopUp from "../../home/Sections/EnquiryPopUp";

const InvestInsurance = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <Section className="xl:bg-linear-gradient-background-cyan ">
            <Container className="mx-0 px-0 py-0 xl:mx-auto lg:py-1.8 sm:py-2.4 xl:py-8 ">
                <div
                    style={{
                        backgroundImage: `url(${insurance})`,
                    }}
                    className=" w-full h-[250px] sm:h-[450px] lg:h-[350px]  xl:h-[400px]   xl:rounded-1.5  bg-no-repeat bg-cover  bg-center lg:bg-[center_center] xl:object-cover items-center sm:items-start relative"
                >
                    <div className="absolute inset-x-0 inset-y-0 bg-white sm:bg-transparent bg-opacity-40 z-30 grid grid-cols-6 pl-0 py-0  lg:pl-6.2 xl:pl-9.6 lg:py-5.6 ">
                        <div className="col-span-6  sm:col-span-3 lg:col-span-3 p-2">
                            <div className="flex flex-wrap lg:block items-center justify-between lg:gap-3.2 xl:mb-1.6  w-full  lg:w-[80%] xl:w-[90%] 2xl:w-[70%] 3xl:w-[55%]">
                                <H2 className="font-normal  2xl:text-7xl">
                                    Insurance
                                </H2>
                                <P className="text-secondary my-1.6">
                                    The government has many initiatives
                                    supporting a clean energy future by
                                    providing incentives in the form of
                                    subsidies and tax benefits for panels of all
                                    sizes. You can browse our plans and find the
                                    one most beneficial to you.
                                </P>
                            </div>

                            <Button onClick={() => setShowModal(true)}>
                                Got an enquiry?
                            </Button>
                        </div>
                    </div>
                </div>
                <EnquiryPopUp
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    callback={() => setShowModal(false)}
                />
            </Container>
        </Section>
    );
};

export default InvestInsurance;
