import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { ERROR_MSG } from "../../constants/common";
import Button from "../button/Button";

import Modal from "../modal/Modal";
import { Flex } from "../styles/Elements.styles";
import { P } from "../styles/Typography.styles";

const Container = styled.div`
    background: ${(props) => props.theme.color.white};
    border-radius: 8px;
`;

const Header = styled.div`
    background: ${(props) => props.theme.color.error};
    border-radius: 8px 8px 0 0;
    padding: 1em 2em;
`;

const Content = styled(Flex.Container)`
    padding: 1em 2em;
`;

const ErrorDialog = ({ onClose, error }) => {
    const [shouldShow, setShouldShow] = useState(!!error);

    const dismissErrorDialog = useCallback(() => {
        onClose && onClose();
        setShouldShow(false);
    }, [onClose, setShouldShow]);

    const errorMsg = typeof error === "string" ? error : ERROR_MSG;
    return (
        <Modal
            isOpen={shouldShow}
            onClose={dismissErrorDialog}
            contentBg={false}
        >
            <Container>
                <Header>
                    <P color="white" weight="rg">
                        Error
                    </P>
                </Header>
                <Content direction="column" align="center">
                    <P
                        style={{
                            paddingBottom: "1em",
                            overflowWrap: "anywhere",
                        }}
                    >
                        {errorMsg}
                    </P>
                    <Button color="white" onClick={dismissErrorDialog}>
                        Okay
                    </Button>
                </Content>
            </Container>
        </Modal>
    );
};

export default ErrorDialog;
