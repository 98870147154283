import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { Flex } from "../styles/Elements.styles";
import { P } from "../styles/Typography.styles";
export const InputComponent = styled.input`
    width: 100%;
    line-height: 1.3;
    min-height: ${(props) => (props.size === "xs" ? "35px" : "40px")};
    height: 100%;
    color: ${(props) => props.theme.color.darkGrey};
    box-sizing: border-box;
    border: solid 2px
        ${(props) =>
            props.disabled
                ? props.theme.color.grey300
                : props.isError
                ? props.theme.color.error
                : props.theme.color.lightGrey};

    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    background: ${(props) =>
        props.backgroundColor
            ? props.theme.color[props.backgroundColor]
            : props.disabled
            ? props.theme.color.grey300
            : props.theme.color.white};
    box-shadow: ${(props) =>
        props.shadow ? "rgba(17, 17, 26, 0.20) 0px 0px 12px" : ""};
    box-sizing: border-box;
    transition: ${(props) => props.theme.transition.xs};
    &:hover,
    &:focus {
        outline: none;
    }
    &:focus {
        outline: none;
        border-color: ${(props) =>
            props.isError
                ? props.theme.color.error
                : props.theme.color.primary};
    }
    &::placeholder {
        color: ${(props) => props.theme.color.grey700};
    }
    font-size: ${(props) =>
        props.xxs
            ? props.theme.fontSize.smallMobile[props.xxs]
            : props.theme.fontSize.default.m};
    font-family: "Montserrat", sans-serif;
    font-weight: ${(props) => props.theme.fontWeight[props.fontWeight || "rg"]};
    padding: ${(props) =>
        props.size === "s"
            ? ".7rem 1.8rem"
            : props.size === "xs"
            ? ".5rem 1.4rem"
            : "1.2rem 1.8rem"};
    border-radius: ${(props) =>
        props.isRounded
            ? props.theme.sizes.xl10
            : props.radius
            ? props.theme.sizes[props.radius]
            : props.theme.sizes.xxs};
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.xlg
                ? props.theme.fontSize.default[props.xlg]
                : props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
`;
export const TextAreaComponent = styled.textarea`
    width: 100%;
    line-height: 1.3;
    min-height: 40px;
    height: 100%;
    color: ${(props) => props.theme.color.darkGrey};
    box-sizing: border-box;
    border: solid 2px
        ${(props) =>
            props.disabled
                ? props.theme.color.grey300
                : props.isError
                ? props.theme.color.error
                : props.theme.color.lightGrey};

    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    background: ${(props) =>
        props.disabled ? props.theme.color.grey300 : props.theme.color.white};
    box-shadow: ${(props) =>
        props.shadow ? "rgba(17, 17, 26, 0.20) 0px 0px 12px" : ""};
    box-sizing: border-box;
    transition: ${(props) => props.theme.transition.xs};
    &:hover,
    &:focus {
        outline: none;
    }
    &:focus {
        outline: none;
        border-color: ${(props) =>
            props.isError
                ? props.theme.color.error
                : props.theme.color.primary};
    }
    &::placeholder {
        color: ${(props) => props.theme.color.grey700};
    }
    font-size: ${(props) =>
        props.xxs
            ? props.theme.fontSize.smallMobile[props.xxs]
            : props.theme.fontSize.default.m};
    font-family: "Montserrat", sans-serif;
    font-weight: ${(props) => props.theme.fontWeight[props.fontWeight || "rg"]};
    padding: ${(props) =>
        props.size === "s"
            ? ".7rem 1.8rem"
            : props.size === "xs"
            ? ".5rem 1.4rem"
            : "1.2rem 1.8rem"};
    border-radius: ${(props) =>
        props.isRounded
            ? props.theme.sizes.xl10
            : props.radius
            ? props.theme.sizes[props.radius]
            : props.theme.sizes.xxs};
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.xlg
                ? props.theme.fontSize.default[props.xlg]
                : props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
`;

export const DefaultInputComponent = styled(InputComponent)`
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    text-align: right;
    appearance: none;
    &::placeholder {
        color: ${(props) => props.theme.color.lightGrey};
    }
`;
export const DefaultTextAreaComponent = styled(TextAreaComponent)`
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    text-align: right;
    appearance: none;

    &::placeholder {
        color: ${(props) => props.theme.color.lightGrey};
    }
`;

export const ErrorText = styled(P)`
    color: ${(props) => props.theme.color.error};
    /* padding-top: 10px; */
`;
export const AlertMessage = styled(P)`
    color: ${(props) => props.theme.color.error};
    padding: 0 10px;
    margin-top: 2px;
    background-color: ${(props) => props.theme.color.red200};
    color: ${(props) => props.theme.color.error};
    border: 1px solid ${(props) => props.theme.color.error};
`;
const InputWrapper = styled.div`
    margin: ${(props) => props.wrapperMargin || ""};
    width: 100%;
    box-sizing: border-box;
`;
const InnerWrapper = styled.div`
    margin: ${(props) => props.wrapperMargin || "0px"};
    width: 100%;
    position: relative;
    box-sizing: border-box;
`;
const RightIcon = styled.div`
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    padding: 0 15px;
    box-sizing: border-box;
    background: ${(props) => props.theme.color.grey300};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 3px;
    color: ${(props) => props.theme.color.darkGrey};
    font-size: ${(props) => props.theme.fontSize.default.s};
    cursor: pointer;
`;
const Input = ({
    name,
    onChange,
    value,
    type,
    placeholder,
    errorMessage = "",
    helperText = "",
    helperTextAlign = "start",
    margin,
    icon = null,
    onIconClick,
    errorType = "message",
    iconSize = "1x",
    size = "s",
    componentType = "input",
    maxLength = null,
    shadow = false,
    ...rest
}) => {
    let InputArea =
        componentType === "input" ? InputComponent : TextAreaComponent;
    return (
        <InputWrapper size="s" wrapperMargin={margin}>
            <InnerWrapper>
                <InputArea
                    size={size}
                    name={name}
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                    isError={!!errorMessage}
                    type={type}
                    shadow={shadow}
                    {...rest}
                />
                {icon && (
                    <RightIcon
                        className="right-icon"
                        role="button"
                        tabIndex={0}
                        onClick={onIconClick}
                    >
                        <FontAwesomeIcon size={iconSize} icon={icon} />
                    </RightIcon>
                )}
                {maxLength !== null && maxLength?.length && (
                    <P style={{ lineHeight: "1" }} xxs="xs" st="xs">
                        {value.length.toLocaleString()}/
                        {Number(maxLength).toLocaleString()}
                    </P>
                )}
            </InnerWrapper>

            {errorMessage && errorType === "alert" ? (
                <AlertMessage xxs="xs" st="xs">
                    {errorMessage}
                </AlertMessage>
            ) : errorMessage ? (
                <ErrorText xxs="xs" st="xs">
                    {errorMessage}
                </ErrorText>
            ) : null}

            {!errorMessage && helperText && helperText.length !== 0 ? (
                <Flex.Container justify={helperTextAlign}>
                    <ErrorText xxs="xs" st="xs">
                        {helperText}
                    </ErrorText>
                </Flex.Container>
            ) : null}
        </InputWrapper>
    );
};

export default Input;
