import React from "react";
import styled from "styled-components";
import { learnBanner } from "../../assets";
import Space from "../../components/space/Space";
import Container from "../../components/styles/Container.styles";
import { Flex } from "../../components/styles/Elements.styles";
import { H1 } from "../../components/styles/Typography.styles";
import Articles from "../blogs/Articles";
import ChatBox from "./ChatBox";

const SectionWrap = styled.div`
    background: #f4f4f2;
    ::before {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        background-color: ${(props) => props.theme.color.blue};
        display: block;
    }
`;
const Learn = () => {
    return (
        <>
            <SectionWrap>
                <LearnHero />
            </SectionWrap>
            <Articles />
        </>
    );
};

export default Learn;

const Wrapper = styled.div`
    min-height: 400px;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    background-image: url(${learnBanner});
    object-fit: cover;
    object-position: bottom center;
    background-repeat: no-repeat;
`;

const LearnHero = () => {
    return (
        <Wrapper id="chatBoxContainerId">
            <Container>
                <Space xxsm={["120px 0"]}>
                    <Flex.Container justify="center">
                        <Flex.Item xxs="100%" t="80%">
                            <H1 align="center">Ask me anything</H1>
                            <H1 align="center">related to Solar !!!</H1>
                        </Flex.Item>
                    </Flex.Container>
                </Space>
                <ChatBox />
            </Container>
        </Wrapper>
    );
};
