import React, { forwardRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import withClickOutside from "../../hoc/withClickOutside";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import P from "../typography/P";

const HeaderProfileDropDown = forwardRef(
    (
        { setIsSelected, isSelected, links, onLogout, user, onBurgerOpen },
        ref
    ) => {
        const { width } = useWindowDimensions();
        console.log(isSelected, "IS selected");
        return (
            <button
                ref={ref}
                onClick={(e) => {
                    e.stopPropagation();
                    return width < 1280
                        ? onBurgerOpen()
                        : setIsSelected((prev) => !prev);
                }}
                id="dropButton"
                className={` xl:w-auto mr-1 xl:h-[50px] bg-gray-100 flex items-center  gap-1 xl:px-1 hover:cursor-pointer  relative ${
                    isSelected ? "rounded " : "rounded-full"
                }`}
            >
                <div className="bg-gray-150 w-[35px] h-[35px] sm:w-[40px] sm:h-[40px] xl:w-[40px]  xl:h-[40px] rounded-full flex items-center justify-center text-base sm:text-2xl sm:leading-[40px] font-bold leading-[35px] xl:leading-[40px] border-2 border-blue-100">
                    {user.name[0]}
                </div>
                <div className=" hidden xl:flex items-center ">
                    <P className="flex items-center gap-0.4">
                        <span className="  max-w-[80px] truncate">
                            {user.name.split(" ")[0]}
                        </span>

                        <IoIosArrowDown />
                    </P>
                </div>
                <div
                    className={`absolute inset-x-0 min-w-[120px]  bg-blue-300    px-1 rounded shadow-sm transition-all  duration-300 top-[50px] ${
                        isSelected
                            ? "top-[35px] xl:top-[50px] opacity-100"
                            : "-top-[1000px] opacity-0"
                    }`}
                >
                    <ul className="flex flex-col ">
                        <li className="py-0.5">
                            <button
                                onClick={onLogout}
                                className=" px-1 py-0.2 sm:py-0.2 2xl:py-0.5  text-primary text-start text-sm sm:text-base xl:text-btn-s font-normal"
                            >
                                Log out
                            </button>
                        </li>
                    </ul>
                </div>
            </button>
        );
    }
);

export default withClickOutside(HeaderProfileDropDown);
