import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../components/button/Button";
import RadioInput from "../../../components/radio/RadioInput";
import Space from "../../../components/space/Space";
import { Flex, Section } from "../../../components/styles/Elements.styles";
import { P } from "../../../components/styles/Typography.styles";
import Textarea from "../../../components/text-area/Textarea";
import { ERROR_MSG, ROLE } from "../../../constants/common";
import {
    PROPOSAL_UPDATE,
    SALES_PROPOSAL_BASE_ENDPOINT,
    SALES_PROPOSAL_UPDATE,
} from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import { checkIsAllowed } from "../../../helpers/helpers";
import request from "../../../services/request";
import ProposalDeatilsContainer from "../admin/proposal/ProposalDetailsContainer";
import { DetailsFiled } from "../admin/proposal/SaveProposal";
import ProposalSuperAdmin from "./ProposalSuperAdmin";
const plantDuration = {
    5: "5 Years",
    6: "6 Years",
    7: "7 Years",
    8: "8 Years",
    9: "9 Years",
    10: "10 Years",
};
const connectionType = {
    RESIDENTIAL_SOCIETY: "RESIDENTIAL SOCIETY",
    COMMERCIAL: "COMMERCIAL",
    INDUSTRIAL: "INDUSTRIAL",
    INDIVIDUAL_HOMES: "INDIVIDUAL HOMES",
};

const ProposalEdit = () => {
    const { state } = useContextState();

    const { generateId } = useParams();
    const [initialData, setInitialData] = useState(null);
    const [formData, setFormData] = useState({
        category: "",
        consumerBill: "",
        consumerTotalUnits: "",
        plantDuration: "",
        purchase: "",
        lease: "",
        loan: "",
        subsidy: false,
        tenant: false,
        comment: "",
    });

    useEffect(() => {
        const handleCurrentProposal = async () => {
            try {
                const response = await request.authGet({
                    endpoint: `${SALES_PROPOSAL_BASE_ENDPOINT}/${generateId}/current`,
                });
                const proposal = response.data.proposal;

                setFormData({
                    category: (proposal.category || "").replace(/_/g, " "),
                    consumerBill: proposal.consumerBill || "",
                    consumerTotalUnits: proposal.consumerTotalUnits || "",
                    plantDuration: `${proposal.plantDuration} Years` || "",
                    purchase: proposal.purchase || "",
                    lease: proposal.lease || "",
                    loan: proposal.loan || "",
                    subsidy: proposal.subsidy || false,
                    tenant: proposal.tenant || false,
                    comment: proposal.comment || "",
                });
                setInitialData({
                    category: (proposal.category || "").replace(/_/g, " "),
                    consumerBill: proposal.consumerBill || "",
                    consumerTotalUnits: proposal.consumerTotalUnits || "",

                    plantDuration: `${proposal.plantDuration} Years` || "",
                    purchase: proposal.purchase || "",
                    lease: proposal.lease || "",
                    loan: proposal.loan || "",
                    subsidy: proposal.subsidy || false,
                    tenant: proposal.tenant || false,
                    comment: proposal.comment || "",
                });
            } catch (error) {
                toast.error("Failed to fetch initial data");
            }
        };

        if (generateId) {
            handleCurrentProposal();
        }
    }, [generateId]);

    const handleChange = (field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleUpdateProposal = async (masterData) => {
        if (formData.comment.trim() === "") {
            toast.error("Admin comment is required");
            return;
        }
        if (!initialData) {
            toast.error("Failed to fetch initial data");
            return;
        }

        const updatedData = {};
        for (const key in formData) {
            if (formData[key] !== initialData[key] || key === "comment") {
                if (key === "category") {
                    updatedData[key] = formData[key];
                } else if (key === "plantDuration") {
                    updatedData[key] = parseInt(formData[key]) * 12;
                } else {
                    updatedData[key] = formData[key];
                }
            }
        }

        if (masterData) {
            updatedData.data = masterData;
        }
        try {
            if (checkIsAllowed(state?.user?.roles, [ROLE.SUPER_ADMIN])) {
                await request.authPut({
                    endpoint: `${PROPOSAL_UPDATE}/${generateId}`,
                    body: updatedData,
                });
            } else {
                await request.authPut({
                    endpoint: `${SALES_PROPOSAL_UPDATE}/${generateId}`,
                    body: updatedData,
                });
            }

            toast.success("Proposal updated successfully");
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);
        }
    };

    return (
        <Section height="auto">
            <ProposalDeatilsContainer backgroundColor={"smoke"} radius="0.5rem">
                <Flex.Container justify="space-between" align="start">
                    <Flex.Container direction="column">
                        <Flex.Item t="100%" xxs="100%">
                            <DetailsFiled
                                label={"Connection Type"}
                                data={Object.values(connectionType)}
                                selected={formData.category}
                                onChange={(value) =>
                                    handleChange("category", value)
                                }
                                backgroundColor="white"
                                placeholder="Input the data"
                                isSelect={true}
                                direction={"column"}
                            />
                        </Flex.Item>
                        <Flex.Item xxs="100%" s="28rem" st="30rem" t="40rem">
                            <DetailsFiled
                                label={"Purchase"}
                                data={[
                                    "INTERESTED",
                                    "REJECTED",
                                    "APPROVED",
                                    "SHARED",
                                ]}
                                selected={formData.purchase}
                                onChange={(value) =>
                                    handleChange("purchase", value)
                                }
                                backgroundColor="white"
                                placeholder="Input the data"
                                isSelect={true}
                                direction={"column"}
                            />
                        </Flex.Item>
                        <Flex.Item t="100%" xxs="100%">
                            {formData.category !==
                                connectionType.INDIVIDUAL_HOMES &&
                                formData.category !==
                                    connectionType.INDUSTRIAL && (
                                    <DetailsFiled
                                        label={"Lease"}
                                        data={[
                                            "INTERESTED",
                                            "REJECTED",
                                            "APPROVED",
                                            "SHARED",
                                        ]}
                                        selected={formData.lease}
                                        onChange={(value) =>
                                            handleChange("lease", value)
                                        }
                                        backgroundColor="white"
                                        isSelect={true}
                                        direction={"column"}
                                    />
                                )}
                        </Flex.Item>
                        <Flex.Item t="100%" xxs="100%">
                            {formData.category !==
                                connectionType.RESIDENTIAL_SOCIETY && (
                                <DetailsFiled
                                    label={"Loan"}
                                    data={[
                                        "INTERESTED",
                                        "REJECTED",
                                        "APPROVED",
                                        "SHARED",
                                    ]}
                                    selected={formData.loan}
                                    onChange={(value) =>
                                        handleChange("loan", value)
                                    }
                                    backgroundColor="white"
                                    placeholder="Input the data"
                                    isSelect={true}
                                    direction={"column"}
                                />
                            )}
                        </Flex.Item>
                    </Flex.Container>
                    <Flex.Container direction="column" align="stretch">
                        <Flex.Item xxs="100%" s="28rem" st="30rem">
                            <DetailsFiled
                                label={"Consumer's Total Unit"}
                                placeholder="Consumers Total Unit"
                                value={formData.consumerTotalUnits}
                                onChange={(e) =>
                                    handleChange(
                                        "consumerTotalUnits",
                                        e.target.value
                                    )
                                }
                                backgroundColor="white"
                                direction={"column"}
                            />
                        </Flex.Item>
                        <Flex.Item xxs="100%">
                            <DetailsFiled
                                label={"Consumer's Bill"}
                                backgroundColor="white"
                                placeholder="Consumers Bill"
                                value={formData.consumerBill}
                                onChange={(e) =>
                                    handleChange("consumerBill", e.target.value)
                                }
                                disabled={
                                    formData.category ===
                                        connectionType.INDIVIDUAL_HOMES ||
                                    formData.category ===
                                        connectionType.RESIDENTIAL_SOCIETY
                                }
                                direction={"column"}
                            />
                        </Flex.Item>
                    </Flex.Container>
                    <Flex.Container direction="column">
                        <Flex.Item xxs="100%" st="30rem">
                            <DetailsFiled
                                label={"Plan Duration"}
                                data={Object.values(plantDuration)}
                                selected={formData.plantDuration}
                                onChange={(value) =>
                                    handleChange("plantDuration", value)
                                }
                                backgroundColor="white"
                                placeholder="Input the data"
                                isSelect={true}
                                direction={"column"}
                            />
                        </Flex.Item>
                        <Flex.Item xxs="100%">
                            <br />
                            {formData.category ===
                                connectionType.COMMERCIAL && (
                                <React.Fragment>
                                    <P>Tenant</P>
                                    <br />
                                    <Flex.Container justify="space-between">
                                        <Flex.Item xxs="100%" lg="45%">
                                            <RadioInput
                                                id="tenantYes"
                                                label="Yes"
                                                name="tenant"
                                                checked={
                                                    formData.tenant === true
                                                }
                                                onChange={() =>
                                                    handleChange("tenant", true)
                                                }
                                            />
                                        </Flex.Item>
                                        <Flex.Item xxs="100%" lg="45%">
                                            <RadioInput
                                                id="tenantNo"
                                                label="No"
                                                name="tenant"
                                                checked={
                                                    formData.tenant === false
                                                }
                                                onChange={() =>
                                                    handleChange(
                                                        "tenant",
                                                        false
                                                    )
                                                }
                                            />
                                        </Flex.Item>
                                    </Flex.Container>
                                </React.Fragment>
                            )}

                            {formData.category ===
                                connectionType.INDUSTRIAL && (
                                <React.Fragment>
                                    <P>Government Subsidy / Tenant</P>
                                    <br />
                                    <Flex.Container justify="space-between">
                                        <Flex.Item xxs="100%">
                                            <RadioInput
                                                id="governmentSubsidNo"
                                                label="No"
                                                name="governmentSubsidy"
                                                checked={true}
                                                onChange={() =>
                                                    handleChange(
                                                        "subsidy",
                                                        false
                                                    )
                                                }
                                                disabled={true}
                                            />
                                        </Flex.Item>
                                    </Flex.Container>
                                </React.Fragment>
                            )}

                            {formData.category !== connectionType.COMMERCIAL &&
                                formData.category !==
                                    connectionType.INDUSTRIAL && (
                                    <React.Fragment>
                                        <P>Government Subsidy</P>
                                        <br />
                                        <Flex.Container justify="space-between">
                                            <Flex.Item xxs="100%">
                                                <RadioInput
                                                    id="governmentSubsidYes"
                                                    label="Yes"
                                                    name="governmentSubsidy"
                                                    checked={
                                                        formData.subsidy ===
                                                        true
                                                    }
                                                    onChange={() =>
                                                        handleChange(
                                                            "subsidy",
                                                            true
                                                        )
                                                    }
                                                />
                                            </Flex.Item>
                                            <Flex.Item xxs="100%" lg="45%">
                                                <RadioInput
                                                    id="governmentSubsidNo"
                                                    label="No"
                                                    name="governmentSubsidy"
                                                    checked={
                                                        formData.subsidy ===
                                                        false
                                                    }
                                                    onChange={() =>
                                                        handleChange(
                                                            "subsidy",
                                                            false
                                                        )
                                                    }
                                                />
                                            </Flex.Item>
                                        </Flex.Container>
                                    </React.Fragment>
                                )}
                        </Flex.Item>
                    </Flex.Container>
                </Flex.Container>
                <Space xxsm={["  20px  0"]}>
                    <Flex.Container justify="space-between">
                        <Flex.Item xxs="100%" lg="68%">
                            <P>Admin's Comment</P> <br />
                            <Textarea
                                onChange={(value) => {
                                    handleChange("comment", value);
                                }}
                                value={formData.comment}
                                placeholder={"Please Provide Update Summary"}
                                type="text"
                                name="comment"
                                style={{
                                    paddingBottom: "7px",
                                    paddingTop: "7px",
                                    backgroundColor: "white",
                                }}
                                rows="7"
                            />
                        </Flex.Item>
                    </Flex.Container>
                </Space>
            </ProposalDeatilsContainer>

            <Flex.Item>
                {checkIsAllowed(state?.user?.roles, [ROLE.SUPER_ADMIN]) ? (
                    <ProposalSuperAdmin onSubmit={handleUpdateProposal} />
                ) : (
                    <Space sm={["50px 200px 0"]}>
                        <Flex.Item xxs="100%" st="80%" lg="60%">
                            <Flex.Container direction="row" justify="center">
                                <Flex.Item xxs="48%" xl="40%">
                                    <Button
                                        color="white"
                                        type="solid"
                                        backgroundColor="primary"
                                        fullWidth
                                        onClick={() => {
                                            handleUpdateProposal(null);
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Flex.Item>
                            </Flex.Container>
                        </Flex.Item>
                    </Space>
                )}
            </Flex.Item>
        </Section>
    );
};

export default ProposalEdit;
