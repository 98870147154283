import React from "react";
import styled from "styled-components";
import { P } from "../styles/Typography.styles";
const LabelWrap = styled.label`
    display: inline-flex;
    align-items: ${(props) => (props.isSubText ? "flex-start" : "center")};
    cursor: pointer;
    .radioInput {
        display: none;
    }
    label {
        display: none;
    }
    .customRadio {
        width: 18px;
        height: 18px;
        border: 2px solid ${(props) => props.theme.color.lightGrey};
        border-radius: 100%;
        padding: 2px;
        box-sizing: border-box;
        background-color: ${(props) =>
            props.backgroundColor ? props.backgroundColor : ""};
        margin-right: 8px;
        ::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: ${(props) =>
                props.theme.color[props.activeColor]};
            border-radius: 100%;
            transform: scale(0);
            transition: all linear 0.2s;
        }
    }
    .radioInput:checked + .customRadio {
        ::after {
            transform: scale(1);
        }
    }
    .radioInput:disabled + .customRadio {
        border: 2px solid ${(props) => props.theme.color.lightGrey};
        opacity: 0.7;
        cursor: not-allowed;
        ::after {
            background-color: ${(props) => props.theme.color.primary};
        }
    }
`;

const RadioInput = ({
    label,
    id,
    onChange,
    color = "black",
    backgroundColor,
    activeColor = "cyan500",
    size = ["s", "m", "ml"],
    subTextSize = ["xs", "s", "s"],
    subText,
    component: Component,
    ...rest
}) => {
    return (
        <LabelWrap
            htmlFor={id}
            isSubText={subText}
            activeColor={activeColor}
            backgroundColor={backgroundColor}
        >
            <input
                onChange={onChange}
                type="radio"
                id={id}
                {...rest}
                className="radioInput"
            />
            <div className="customRadio"></div>
            {(label || Component) && (
                <div>
                    <label htmlFor={id}></label>
                    {label && (
                        <>
                            <P
                                color={color}
                                xxs={size[0]}
                                st={size[1]}
                                lg={size[2]}
                                lineHeight={subText ? "1.1em" : "1.6em"}
                            >
                                {label}
                            </P>
                            <P
                                align="center"
                                color={color}
                                xxs={subTextSize[0]}
                                st={subTextSize[1]}
                                lg={subTextSize[2]}
                            >
                                {subText}
                            </P>
                        </>
                    )}
                    {Component && <Component />}
                </div>
            )}
        </LabelWrap>
    );
};

export default RadioInput;
