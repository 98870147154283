import React from "react";
import styled from "styled-components";
import { P } from "../styles/Typography.styles";

const Wrapper = styled(P)`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;
const Icon = ({ component: Component, ...rest }) => {
    return (
        <Wrapper as="div" {...rest}>
            <Component  />
        </Wrapper>
    );
};

export default Icon;
