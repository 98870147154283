import React from "react";
import styled from "styled-components";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { MOBILE_BREAKPOINT, TAB_BREAKPOINT } from "../../theme";
import Circle from "../circle/Circle";
import Line from "../line/Line";
import { Flex } from "../styles/Elements.styles";
import { Span } from "../styles/Typography.styles";

const Wrapper = styled.div`
    border-radius: 10px;
    padding: ${(props) => props.padding};
    margin: 1rem 0 3.5rem;

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        padding: ${(props) => props.padding};
    }
`;
const CircleExtend = styled(Circle)`
    position: relative;
    cursor: ${(props) => (props.isEnabled ? "pointer" : "not-allowed")};
    span {
        position: absolute;
        bottom: ${(props) => props.bottom || ""};
        top: ${(props) => props.top || ""};
    }
`;
const Label = styled(Span)`
    width: max-content;
    color: black;
    position: absolute;
    top: 50;
    display: ${(props) =>
        props.showLevelAlways ? "block" : props.isActive ? "block" : "none"};
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: block;
    }
`;

const HorizontalStepper = ({
    data,
    activeStep,
    completedSteps,
    onClick,
    padding = "40px 2rem 110px",
    top,
    bottom,
    showLevelAlways = false,
}) => {
    const { width } = useWindowDimensions();
    const circleWidth =
        width > TAB_BREAKPOINT
            ? "20px"
            : width > MOBILE_BREAKPOINT
            ? "15px"
            : "10px";
    const lineHeight =
        width > TAB_BREAKPOINT
            ? "4px"
            : width > MOBILE_BREAKPOINT
            ? "3px"
            : "2px";

    return (
        <Wrapper className="wrapper" padding={padding}>
            <Flex.Container style={{ width: "100%" }} align="center">
                {data.map((item, index) => (
                    <React.Fragment key={index}>
                        <CircleExtend
                            role="button"
                            tabIndex="0"
                            isEnabled={completedSteps >= item.step}
                            disabled={completedSteps < item.step}
                            onClick={() =>
                                completedSteps < item.step
                                    ? null
                                    : onClick
                                    ? onClick(item)
                                    : null
                            }
                            top={top}
                            bottom={bottom}
                            backgroundColor={
                                activeStep >= item.step ? "cyan" : "grey400"
                            }
                            size={circleWidth}
                        >
                            {item?.bottom && (
                                <Label
                                    isActive={activeStep === item.step}
                                    showLevelAlways={showLevelAlways}
                                    xxs="s"
                                    st="m"
                                    lg="m"
                                    style={{
                                        left: index === 0 ? 0 : null,
                                        right:
                                            data.length - 1 === index
                                                ? 0
                                                : null,
                                        top: 30,
                                    }}
                                    color="white"
                                    align={
                                        index === 0
                                            ? "left"
                                            : index === data.length - 1
                                            ? "right"
                                            : "center"
                                    }
                                >
                                    {item?.bottom}
                                </Label>
                            )}
                            {item?.top && (
                                <Label
                                    isActive={activeStep === item.step}
                                    showLevelAlways={showLevelAlways}
                                    xxs="s"
                                    st="m"
                                    lg="m"
                                    style={{
                                        left: index === 0 ? 0 : null,
                                        right:
                                            data.length - 1 === index
                                                ? 0
                                                : null,
                                        bottom: 30,
                                    }}
                                    color="white"
                                    align={
                                        index === 0
                                            ? "left"
                                            : index === data.length - 1
                                            ? "right"
                                            : "center"
                                    }
                                >
                                    {item?.top}
                                </Label>
                            )}
                        </CircleExtend>
                        {index < data.length - 1 && (
                            <Line
                                backgroundColor={
                                    completedSteps > item.step
                                        ? "cyan"
                                        : "grey400"
                                }
                                flex="1"
                                height={lineHeight}
                            />
                        )}
                    </React.Fragment>
                ))}
            </Flex.Container>
        </Wrapper>
    );
};

export default HorizontalStepper;
