import {
    Autocomplete,
    GoogleMap,
    MarkerF,
    useJsApiLoader,
} from "@react-google-maps/api";
import { AnimatePresence, motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { setKey } from "react-geocode";
// import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../../components/button/Button";
import DatePicker from "../../../components/date-picker/DatePicker";
import { HeaderBtn } from "../../../components/header/Header";
import Input, { InputComponent } from "../../../components/input/Input";
import Link from "../../../components/link/Link";
import Loading from "../../../components/loading/Loading";
import ModalTimes from "../../../components/modal/ModalTimes";
import ModalTitle from "../../../components/modal/ModalTitle";
import Pagination from "../../../components/pagination";
import Space from "../../../components/space/Space";
import HorizontalStepper from "../../../components/stepper/HorizontaStepper";
import Container from "../../../components/styles/Container.styles";
import { Flex, Section } from "../../../components/styles/Elements.styles";
import { H2, P } from "../../../components/styles/Typography.styles";
import Tables from "../../../components/table/Tables";
import { ERROR_MSG } from "../../../constants/common";
import { emailRegEx, phoneRegEx } from "../../../constants/regularExpression";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
    API_ENDPOINT,
    DASHBOARD,
    GET_ALL_PLANT_ENDPOINT,
    GET_PLANT_BY_QUERY_ENDPOINT,
    GOOGLE_MAP_API_KEY,
    LANDING,
    NEW_ENQUIRY_ENDPOINT,
} from "../../../constants/routes";
import { validateNamePhoneDate } from "../../../helpers/fieldValidation";
import { getLatLongGeocoder } from "../../../helpers/helpers";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import auth from "../../../services/auth";
import request from "../../../services/request";
import { SMALL_TAB_BREAKPOINT } from "../../../theme";
import OnboardingContainer from "../../onboarding/OnboardingContainer";
import { DetailsFiled } from "../admin/payment/DetailsField";
import { DetailsFiled as DropDownProperty } from "../admin/proposal/SaveProposal";
import FilterHeader from "./FilterHeader";
const cellStyles = {
    padding: "10px 0",
    borderLeft: "none",
    borderRight: "none",
};

const HorizontalWrapper = styled.div`
    .wrapper {
        margin-bottom: 10px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        .wrapper {
            margin-bottom: 3.5rem;
        }
    }
`;

const MapContainer = styled.div`
    .map {
        width: 100%;
        height: 100px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    }
    input {
        font-size: ${(props) => props.theme.fontSize.default.s};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        height: 200px;
    }
`;
const center = {
    lat: 18.5204303,
    lng: 73.8567437,
};
const DEFAULT_ADDRESS =
    "SNN RAJ ETTERNIA, Haralur Road, Off Sarjapur Road Sarjapur Hobli Close to Kudlu, Sy. no.146/1,147/1,147/2,Silver County Rd, Parappana Agrahara, Bengaluru";
setKey(GOOGLE_MAP_API_KEY);

const typeRange = [
    {
        id: 1,
        label: "All",
        value: "All",
    },
    {
        id: 2,
        label: "Mine",
        value: "Mine",
    },
    {
        id: 3,
        label: "Search",
        value: "Search",
    },
];
const SearchInput = styled(InputComponent)`
    padding: 1.5rem 1rem;
    padding-right: 2rem;
    border: 2px solid black;
    background: white;
    border-radius: 7px;
    font-size: 1.5rem;
`;

const SearchWrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    height: 1rem;

    padding-bottom: 5rem;
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 10px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    font-size: 2rem;
`;
const SearchContainer = styled.div`
    @media (min-width: ${(props) => props.theme.breakPoints.largeMobile}) {
        width: 40%;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        width: 98%;
    }
`;

const placesLibrary = ["places"];
const Dashboard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formState, setFormState] = useState(0);
    const [markerPointA, setMarkerPointA] = useState(center);
    const [map, setMap] = useState(/** @type google.maps.Map */ (null));
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchOption, setSearchOption] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [simpleAddress, setSimpleAddress] = useState(null);
    const [billError, setBillError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    const [dateError, setDateError] = useState("");
    const [submittingData, setSubmittingData] = useState(false);

    const { width } = useWindowDimensions();

    const siteRef = useRef();
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        billAmount: "",
        surveyDate: new Date(),
    });
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({ large: [], small: [] });
    const [pageData, setPageData] = useState({});
    const [assignee, setAssignee] = useState(typeRange[0].value);

    const fetchAllPlantData = useCallback(
        async (pageNumber = 0, pageSize = 6) => {
            try {
                let endpoint;
                if (searchOption === "Property Name") {
                    endpoint = `${GET_PLANT_BY_QUERY_ENDPOINT}?columnName=name&searchPattern=${searchValue}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
                } else if (searchOption === "Property Id") {
                    endpoint = `${GET_PLANT_BY_QUERY_ENDPOINT}?columnName=plantId&searchPattern=${searchValue}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
                } else if (
                    assignee === typeRange[0].value ||
                    assignee === typeRange[1].value
                ) {
                    endpoint = `${GET_ALL_PLANT_ENDPOINT}?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=true&assignee=${assignee.toLowerCase()}`;
                } else {
                    return;
                }
                setIsLoading(true);
                const data = await request.authGet({ endpoint });
                setData(() => ({
                    large: data?.data?.plants.map((item, index) => ({
                        index: index + 1,
                        id: {
                            value: null,
                            component: () => (
                                <Link
                                    color="primary"
                                    isInteractive={true}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                    to={`${DASHBOARD}/${item.plantId}`}
                                >
                                    {item.plantId}
                                </Link>
                            ),
                        },
                        name: {
                            value: item.projectName,
                        },
                        date: {
                            value: item?.date || "-",
                        },
                        assignee: { value: item?.assigneeName || "-" },
                    })),

                    small: data?.data?.plants.map((item, index) => ({
                        index: index + 1,
                        id: {
                            value: null,
                            component: () => (
                                <>
                                    <Link
                                        color="primary"
                                        isInteractive={true}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        to={`${DASHBOARD}/${item.plantId}`}
                                    >
                                        {item.plantId}
                                    </Link>{" "}
                                    <Space xxsm={[".5rem 0 0"]}>
                                        <P>{item.projectName}</P>
                                    </Space>
                                </>
                            ),
                        },
                        date: {
                            component: () => (
                                <>
                                    <P>{item?.assigneeName || "-"}</P>
                                    <Space xxsm={[".5rem 0 0"]}>
                                        <P>{item?.date}</P>
                                    </Space>
                                </>
                            ),
                        },
                    })),
                }));
                let result = data?.data;
                const paginationData = {
                    isFirst: result?.isFirst,
                    isLast: result?.isLast,
                    pageNumber: result?.pageNumber,
                    pageCount: result?.totalPages,
                };

                setPageData(paginationData);
                setIsLoading(false);
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);
                setIsLoading(false);
            }
        },
        [assignee, searchOption, searchValue]
    );
    const handleSearchOptionChange = useCallback((value) => {
        setSearchOption(value);
    }, []);

    const handleSearchValueChange = useCallback((e) => {
        setSearchValue(e.target.value);
    }, []);

    const handleSearchSubmit = useCallback(
        (e) => {
            e.preventDefault();

            fetchAllPlantData();
        },
        [fetchAllPlantData]
    );
    const handleFilterChange = useCallback((e) => {
        const selectedValue = e.target.value;

        if (
            selectedValue === typeRange[0].value ||
            selectedValue === typeRange[1].value
        ) {
            setAssignee(selectedValue);
            setSearchOption("");
            setSearchValue("");
            setIsSearchActive(false);
        } else {
            setAssignee(selectedValue);
            setSearchOption("Property Name");
            setIsSearchActive(selectedValue === "Search");
        }
    }, []);

    useEffect(() => {
        fetchAllPlantData();
    }, [fetchAllPlantData, assignee]);

    const onLoad = useCallback((map) => {
        setMap(map);
    }, []);

    const onUnmount = useCallback((map) => {
        setMap(null);
    }, []);

    const handleChangeUser = (e) => {
        let value = e.target.value;
        if (e.target.name === "fullName") {
            if (value.trim().length < 3) {
                setNameError(true);
            } else {
                setNameError(false);
            }
        } else if (e.target.name === "email") {
            if (!emailRegEx.test(value)) {
                setEmailError(true);
            } else {
                setEmailError(false);
            }
        } else if (e.target.name === "phone") {
            if (!phoneRegEx.test(value)) {
                setPhoneError(true);
            } else {
                setPhoneError(false);
            }
        } else if (e.target.name === "billAmount") {
            if (value < 1000) {
                setBillError(true);
            } else {
                setBillError(false);
            }
        } else if (e.target.name === "surveyDate") {
            const userMoment = moment(value);

            if (!value) {
                setDateError("Date is required!");
            } else if (!userMoment.isValid()) {
                setDateError("Date is invalid!");
            } else {
                const currentMoment = moment().startOf("day").unix();
                const maxTimeEndDays = moment()
                    .add(13, "days")
                    .endOf("day")
                    .unix();
                const userMoment = moment(value).startOf("days").unix();
                if (currentMoment > userMoment) {
                    setDateError("Past date is invalid!");
                } else if (maxTimeEndDays < userMoment) {
                    setDateError("Date can't be more than 2 weeks!");
                } else {
                    setDateError("");
                }
            }
        }
        setFormData({
            ...formData,
            [e.target.name]: value,
        });
    };

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
        libraries: placesLibrary,
    });

    const handlePlaceChanged = async () => {
        const res = await getLatLongGeocoder(siteRef.current.value);

        setSimpleAddress(res);
        setMarkerPointA(res);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmittingData(true);

        const validationData = {
            name: formData.fullName,
            phone: formData.phone,
            date: formData.surveyDate,
        };

        const errors = validateNamePhoneDate(validationData);
        if (!siteRef?.current?.value) {
            toast.error("Site address is required");
        } else if (Object.keys(errors).length > 0) {
            toast.error(
                errors.dateError || errors.phoneError || errors.nameError
            );
            return;
        }

        const submitData = {
            date: moment(formData.surveyDate).unix().toString(),
            address: siteRef?.current?.value,
            bill: formData.billAmount.toString(),
            city: simpleAddress.city,
            state: simpleAddress.state,
            pinCode: Number(simpleAddress.pinCode),
            latitude: markerPointA.lat.toString(),
            longitude: markerPointA.lng.toString(),
            name: formData.fullName,
            email: formData.email,
            phone: `91${formData.phone}`,
        };

        try {
            await authPostRequest({
                endpoint: NEW_ENQUIRY_ENDPOINT,
                body: submitData,
            });
            await fetchAllPlantData();
            toast.success("Enquiry submitted successfully");

            setSubmittingData(false);
            setIsModalOpen(false);
            setFormData({
                fullName: "",
                email: "",
                phone: "",
                billAmount: "",
                surveyDate: new Date(),
            });
            setSimpleAddress({ city: "", state: "", pinCode: "" });
            setMarkerPointA(center);
            setFormState(0);
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);
            setSubmittingData(false);
        }
    };

    const postRequest = ({
        endpoint,
        body,
        headers = {},
        isFormData = false,
        errorData = false,
        checkStatusCode = false,
    }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const defaultHeader = {
                    "Content-Type": "application/json",
                    Accept: "*",
                };

                const response = await fetch(`${API_ENDPOINT}${endpoint}`, {
                    method: "POST",
                    headers: isFormData
                        ? headers
                        : { ...defaultHeader, ...headers },
                    body: isFormData ? body : JSON.stringify(body),
                    credentials: "same-origin",
                });
                if (checkStatusCode && response.status === 200) {
                    return resolve(response);
                }
                const data = await response.json();
                if (data?.status !== "SUCCESS") {
                    if (errorData) throw data;
                    throw data.message || ERROR_MSG;
                }
                return resolve(data);
            } catch (error) {
                return reject(error);
            }
        });
    };

    const authPostRequest = async ({
        endpoint,
        body,
        headers = {},
        isFormData = false,
        errorData = false,
        checkStatusCode = false,
    }) => {
        const accessToken = await auth.getAccessToken();
        if (!accessToken) {
            window.location = LANDING;
            return;
        }
        return postRequest({
            endpoint,
            body,
            headers: {
                Authorization: `Bearer ${accessToken}`,
                ...headers,
            },
            isFormData,
            errorData,
            checkStatusCode,
        });
    };

    const handleChangePage = useCallback(
        async (event) => {
            fetchAllPlantData(event.selected);
        },
        [fetchAllPlantData]
    );

    return (
        <Section height="auto">
            <Container>
                <Space xxsm={["40px 0 0"]} lgm={["50px 0px 0px 0px"]}>
                    <Flex.Item>
                        <OnboardingContainer>
                            <Flex.Container justify="center">
                                <Space
                                    xxsm={["0 0 1rem"]}
                                    xsm={["0"]}
                                    lgm={["0 50px 0px 10px"]}
                                >
                                    <Flex.Item>
                                        <H2>Start a new enquiry!</H2>
                                    </Flex.Item>
                                </Space>
                                <Flex.Item>
                                    <HeaderBtn
                                        color="white"
                                        backgroundColor="primary"
                                        xxs="xl"
                                        weight="b"
                                        onClick={() => setIsModalOpen(true)}
                                        st="m"
                                    >
                                        Enquiry
                                    </HeaderBtn>
                                </Flex.Item>
                            </Flex.Container>
                        </OnboardingContainer>
                    </Flex.Item>
                </Space>
                <Flex.Container>
                    <H2>Project Overview</H2>
                </Flex.Container>

                <Flex.Container
                    align="center"
                    justify="space-between"
                    margin="-55px"
                >
                    <Flex.Item flex="1" xxs="50%">
                        <FilterHeader
                            onChange={handleFilterChange}
                            typeRange={typeRange}
                            selectedType={assignee}
                            name="FilterBy"
                            label={"Filter By:"}
                            showSearch={isSearchActive}
                        />
                    </Flex.Item>
                    {isSearchActive && (
                        <Space xxsp={["2.9rem 0 0 0"]}>
                            <Flex.Item flex="flex-1">
                                <form onSubmit={handleSearchSubmit}>
                                    <Flex.Container
                                        align="center"
                                        justify="space-between"
                                        direction="row"
                                    >
                                        <Flex.Item xxs="100%" st="55%" lg="45%">
                                            <DropDownProperty
                                                label=""
                                                data={[
                                                    "Property Name",
                                                    "Property Id",
                                                ]}
                                                selected={searchOption}
                                                onChange={
                                                    handleSearchOptionChange
                                                }
                                                backgroundColor="white"
                                                placeholder="Input the data"
                                                isSelect={true}
                                                disabled={false}
                                                style={{ width: "170px" }}
                                            />
                                        </Flex.Item>

                                        <Flex.Item xxs="100%" st="55%" lg="54%">
                                            <SearchContainer>
                                                <SearchWrapper>
                                                    <SearchInput
                                                        value={searchValue}
                                                        onChange={
                                                            handleSearchValueChange
                                                        }
                                                        placeholder={`Search by ${
                                                            searchOption ===
                                                            "Property Name"
                                                                ? " Name"
                                                                : " ID"
                                                        }`}
                                                        type="text"
                                                        name="searchValue"
                                                    />
                                                    <IconWrapper>
                                                        <button
                                                            type="submit"
                                                            style={{
                                                                background:
                                                                    "none",
                                                                border: "none",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faSearch}
                                                            />
                                                        </button>
                                                    </IconWrapper>
                                                </SearchWrapper>
                                            </SearchContainer>
                                        </Flex.Item>
                                    </Flex.Container>
                                </form>
                            </Flex.Item>
                        </Space>
                    )}
                </Flex.Container>

                <Tables
                    mobileTableHeader={mobileTableHeader}
                    tableHeader={tableHeader}
                    data={data}
                    cellStyles={cellStyles}
                    cellMobileStyles={cellStyles}
                    isLoading={isLoading}
                />

                {data.large.length && (
                    <Pagination
                        isFirst={pageData?.isFirst}
                        isLast={pageData?.isLast}
                        pageCount={pageData?.pageCount}
                        onChange={handleChangePage}
                    />
                )}
            </Container>
            <ModalAlt
                times={false}
                innerTimes={true}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen("")}
                width={["100%", "100%", "50%"]}
                topPadding="0px"
                backgroundColor="white"
            >
                <Space xxsp={["0px 20px 0px"]} stp={["20px 20px 20px"]}>
                    <div>
                        {formState === 0 ? (
                            <>
                                <HorizontalStepper
                                    activeStep={1}
                                    padding="0px 50px 0px"
                                    data={formSteps}
                                    completedSteps={1}
                                />
                                <DetailsFiled
                                    label="Full Name"
                                    placeholder="Enter your full name"
                                    name="fullName"
                                    color="black"
                                    value={formData.fullName}
                                    onChange={handleChangeUser}
                                    disabled={false}
                                    errorMessage={
                                        nameError
                                            ? "Full name is required"
                                            : null
                                    }
                                />
                                <DetailsFiled
                                    label="Email Id"
                                    placeholder="Enter your email"
                                    name="email"
                                    color="black"
                                    autoComplete="new-email"
                                    disabled={false}
                                    value={formData.email}
                                    onChange={handleChangeUser}
                                    errorMessage={
                                        emailError ? "Invalid email" : null
                                    }
                                />

                                <DetailsFiled
                                    label="Phone No."
                                    placeholder="Enter your phone no."
                                    name="phone"
                                    color="black"
                                    autoComplete="new-phone"
                                    disabled={false}
                                    type="number"
                                    value={formData.phone}
                                    onChange={handleChangeUser}
                                    errorMessage={
                                        phoneError
                                            ? "Valid phone number is required"
                                            : null
                                    }
                                />

                                <DetailsFiled
                                    label="Bill Amount"
                                    placeholder="Enter Consumer Bill Amount"
                                    name="billAmount"
                                    color="black"
                                    type="number"
                                    disabled={false}
                                    min="1000"
                                    value={formData.billAmount}
                                    onChange={handleChangeUser}
                                    errorMessage={
                                        billError
                                            ? "Bill amount must be at least 1000"
                                            : null
                                    }
                                />

                                <Flex.Container justify="center">
                                    <Space xxsp={["40px 20px 40px"]}>
                                        <Flex.Item xxs="100%" xl="60%">
                                            <Button
                                                color="white"
                                                type="solid"
                                                backgroundColor="primary"
                                                fullWidth
                                                onClick={() => {
                                                    if (
                                                        formData.billAmount <
                                                        1000
                                                    ) {
                                                        setBillError(true);
                                                    } else {
                                                        setBillError(false);
                                                        setFormState(1);
                                                    }
                                                }}
                                            >
                                                NEXT
                                            </Button>
                                        </Flex.Item>
                                    </Space>
                                </Flex.Container>
                            </>
                        ) : (
                            <>
                                <HorizontalWrapper>
                                    <HorizontalStepper
                                        activeStep={2}
                                        padding="0px 50px 0px"
                                        data={formSteps}
                                        completedSteps={2}
                                    />
                                </HorizontalWrapper>
                                <Flex.Container justify="space-between">
                                    <Space xxsm={["5px 0 5px"]}>
                                        <Flex.Item
                                            xxs="100%"
                                            st="100%"
                                            xl="30%"
                                        >
                                            <P color="black">Survery Date</P>
                                        </Flex.Item>
                                    </Space>
                                    <Flex.Item xxs="100%" st="100%" xl="60%">
                                        <DatePicker
                                            name="surveyDate"
                                            onChange={handleChangeUser}
                                            minDate={new Date()}
                                            maxDate={
                                                new Date(
                                                    new Date().setDate(
                                                        new Date().getDate() +
                                                            14
                                                    )
                                                )
                                            }
                                            value={formData.surveyDate}
                                            size="s"
                                            marginBottom="10px"
                                            calendarIcon={true}
                                            errorMessage={dateError}
                                        />
                                    </Flex.Item>
                                </Flex.Container>
                                <Space tm={["0 0 25px"]} xsm={["0 0 10px"]}>
                                    <Flex.Container
                                        justify="space-between"
                                        align="center"
                                    >
                                        <Space xxsm={["5px 0 5px"]}>
                                            <Flex.Item
                                                xl="30%"
                                                st="20%"
                                                xxs="100%"
                                            >
                                                <P color="black" xxs="m">
                                                    Site Location
                                                </P>
                                            </Flex.Item>
                                        </Space>
                                        <Flex.Item xxs="100%" xl="60%">
                                            {isLoaded ? (
                                                <>
                                                    <Autocomplete
                                                        onPlaceChanged={
                                                            handlePlaceChanged
                                                        }
                                                    >
                                                        <InputComponent
                                                            size="s"
                                                            id="siteLocationId"
                                                            shadow={false}
                                                            ref={siteRef}
                                                        />
                                                    </Autocomplete>
                                                </>
                                            ) : (
                                                <Loading />
                                            )}
                                        </Flex.Item>
                                    </Flex.Container>
                                </Space>
                                <FlexInput
                                    label="Site Address"
                                    type="textarea"
                                    value={
                                        (simpleAddress &&
                                            simpleAddress?.city +
                                                ", " +
                                                simpleAddress?.state +
                                                ", " +
                                                simpleAddress?.pinCode) ||
                                        DEFAULT_ADDRESS
                                    }
                                    rows={width > SMALL_TAB_BREAKPOINT ? 4 : 2}
                                />
                                <Flex.Item justify="space-between">
                                    <Space
                                        xxsm={["0 0 2rem"]}
                                        stm={["2rem 0 4rem 0"]}
                                    >
                                        <Flex.Container justify="space-between">
                                            <Space xxsm={["5px 0 5px"]}>
                                                <Flex.Item>
                                                    <P color="black">
                                                        Site Map
                                                    </P>
                                                </Flex.Item>
                                            </Space>

                                            <Flex.Item xxs="100%" lg="60%">
                                                <MapContainer>
                                                    <GoogleMap
                                                        mapContainerClassName="map"
                                                        center={markerPointA}
                                                        zoom={15}
                                                        options={{
                                                            streetViewControl: false,
                                                            mapTypeControl: false,
                                                            fullscreenControl: true,
                                                        }}
                                                        onLoad={onLoad}
                                                        onUnmount={onUnmount}
                                                    >
                                                        <MarkerF
                                                            draggable
                                                            position={
                                                                markerPointA
                                                            }
                                                        ></MarkerF>
                                                    </GoogleMap>
                                                </MapContainer>
                                            </Flex.Item>
                                        </Flex.Container>
                                    </Space>
                                </Flex.Item>

                                <Flex.Container
                                    direction="row"
                                    justify="space-between"
                                >
                                    <Flex.Item xl="40%">
                                        <Button
                                            color="white"
                                            type="solid"
                                            backgroundColor="grey500"
                                            fullWidth
                                            disabled={submittingData}
                                            onClick={() => setFormState(0)}
                                        >
                                            Back
                                        </Button>
                                    </Flex.Item>
                                    <Flex.Item xl="40%">
                                        <Button
                                            color="white"
                                            type="solid"
                                            backgroundColor="primary"
                                            fullWidth
                                            disabled={submittingData}
                                            isLoading={submittingData}
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </Button>
                                    </Flex.Item>
                                </Flex.Container>
                            </>
                        )}
                    </div>
                </Space>
            </ModalAlt>
        </Section>
    );
};

export default Dashboard;

const tableHeader = [
    {
        id: 1,
        name: "ID",
    },
    {
        id: 2,
        name: "Project Name",
    },
    {
        id: 3,
        name: "Date",
    },

    {
        id: 4,
        name: "Assignee",
    },
];
const mobileTableHeader = [
    {
        id: 1,
        name: "ID",
    },

    {
        id: 2,
        name: "Assignee /Date",
    },
];
const steps = [
    {
        step: 1,
        bottom: "Paid",
        top: "02/12/22",
    },
    {
        step: 2,
        bottom: "Procuring",
        top: "02/12/22",
    },
    {
        step: 3,
        bottom: "Installing",
        top: "",
    },
    {
        step: 4,
        bottom: "Active",
        top: "",
    },
    {
        step: 5,
        bottom: "Closed",
        top: "",
    },
];

const formSteps = [
    {
        step: 1,
    },
    {
        step: 2,
    },
];

const FlexInput = ({ label, type = "input", value, ...rest }) => {
    return (
        <Space xxsm={["0 0 1rem"]} stm={["0 0 2rem"]}>
            <Flex.Container justify="space-between" align="center">
                <Space xxsm={["5px 0 5px"]}>
                    <Flex.Item xxs="100%" lg="35%">
                        <P>{label}</P>
                    </Flex.Item>
                </Space>
                <Flex.Item xxs="100%" lg="60%">
                    <Input
                        componentType={type}
                        disabled
                        value={value}
                        {...rest}
                    ></Input>
                </Flex.Item>
            </Flex.Container>
        </Space>
    );
};

const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 115;
    padding: 75px 0;
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        padding: ${(props) => props.topPadding || "100px"} 0;
    }
`;

const ContainerModal = styled.div`
    width: 95%;
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        width: 84%;
    }
    margin-inline: auto;
    box-sizing: border-box;
`;

export const CloseIcon = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: ${(props) => props.theme.fontSize.mobile.m};
    cursor: pointer;
    color: ${(props) =>
        props.color ? props.theme.color[props.color] : props.theme.color.white};
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) => props.theme.fontSize.tablet.m};
    }
`;
const FlexItem = styled(Flex.Item)`
    width: ${(props) => props.width[0]};
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        width: ${(props) => props.width[1]};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        width: ${(props) => props.width[2]};
    }
`;

const Content = styled.div`
    background-color: ${(props) => props.theme.color[props.backgroundColor]};
    padding: ${(props) => props.xxsp};
    border-radius: 10px;
    position: relative;
    overflow-x: ${(props) => props.overflowx};
    overflow-y: visible;
    .innerTimes {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: ${(props) => props.xsp};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        padding: ${(props) => props.stp};
        .innerTimes {
            top: 15px;
            right: 15px;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        padding: ${(props) => props.xlp};
    }
`;

const ModalAlt = ({
    isOpen,
    title,
    onClose,
    times,
    innerTimes = true,
    children,
    backgroundColor = "white",
    bgTheme = "light",
    width = ["100%", "100%", "60%"],
    topPadding,
    overflowx = "hidden",
    overflowy = "auto",
    xxsp = "2rem 1rem",
    xsp = "2rem 1.8rem",
    stp = "2.5rem 2rem",
    xlp = "2.5rem 2rem",
}) => {
    return isOpen ? (
        <AnimatePresence>
            <Overlay topPadding={topPadding}>
                <ContainerModal>
                    <Flex.Container justify="center">
                        <FlexItem width={width}>
                            <ModalTitle
                                times={times}
                                onClick={onClose}
                                title={title}
                            />
                            <Content
                                as={motion.div}
                                backgroundColor={backgroundColor}
                                layout
                                initial={{ scale: 0.8, opacity: 0, y: -1000 }}
                                animate={{ scale: 1, opacity: 1, y: 0 }}
                                exit={{ scale: 0.8, opacity: 0, y: 1000 }}
                                transition={{ type: "spring", duration: 0.5 }}
                                overflowx={overflowx}
                                overflowy={overflowy}
                                xxsp={xxsp}
                                xsp={xsp}
                                stp={stp}
                                xlp={xlp}
                            >
                                {children}
                                {innerTimes && (
                                    <div className="innerTimes">
                                        <ModalTimes
                                            bgTheme={bgTheme}
                                            onClose={onClose}
                                        />
                                    </div>
                                )}
                            </Content>
                        </FlexItem>
                    </Flex.Container>
                </ContainerModal>
            </Overlay>
        </AnimatePresence>
    ) : null;
};
