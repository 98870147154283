import React, { useEffect, useState } from "react";
import { BsArrowDown } from "react-icons/bs";
import { FaCarAlt } from "react-icons/fa";
import { FaHouseChimney } from "react-icons/fa6";
import { GiKnifeFork } from "react-icons/gi";
import Container from "../../../../shared/Container";
import Section from "../../../../shared/Section";
import P from "../../../../shared/typography/P";
import Button from "../../../../shared/ui/Button";
import TextField from "../../../../shared/ui/Form/TextField";
import BottomLineDropDown from "../BottomLineDropDown";
import ProgressBar from "./ProgressBar";
const INDIA_AVG_EMISSION = 6.92;

const placeholders = {
    monthlyExpense: "Select Monthly Expense",
    foodType: "Select Food Type",
    commuteMethod: "Select Commute Method",
    longDistanceTravel: "Select Long Distance Travel",
    familyMembers: "Select Family Members",
    cookingMethod: "Select Cooking Method",
    governmentServices: "Select Government Services",
};

const familyMembersOptions = ["2-4 members", "more than 5 members"];
const commuteOptions = [
    "Usually, Walk occasionally Public transport",
    "More Usage of Public Transport",
    "Usually car occasionally Public Transport",
    "Personal Car",
    "Only 2 Wheeler",
    "Electric car or 2 wheeler",
];
const longDistanceOptions = ["Train", "Flight"];
const foodTypeOptions = [
    "Only Vegan",
    "Vegetarian",
    "Non-veg alternatively",
    "Mostly Non-veg",
];
const monthlyExpenseOptions = [
    "< Rs. 5000 per Month",
    "< Rs. 15000 per Month",
    "< Rs. 25000 per Month",
    "> Rs. 45000 per Month",
];

const carbonValues = {
    foodType: {
        "Only Vegan": 1.1,
        Vegetarian: 1.53,
        "Non-veg alternatively": 1.92,
        "Mostly Non-veg": 2.67,
    },

    commuteMethod: {
        "Usually, Walk occasionally Public transport": 0.313,
        "More Usage of Public Transport": 0.42,
        "Usually car occasionally Public Transport": 1.79,
        "Personal Car": 3.59,
        "Only 2 Wheeler": 2.1,
        "Electric car or 2 wheeler": 0,
    },
    longDistanceTravel: {
        Train: 0.85,
        Flight: 4.5,
    },
    monthlyExpense: {
        "< Rs. 5000 per Month": 2.8,
        "< Rs. 15000 per Month": 3.57,
        "< Rs. 25000 per Month": 4.48,
        "> Rs. 45000 per Month": 6.23,
    },
    familyMembers: {
        "2-4 members": 0.11,
        "more than 5 members": 1.44,
    },
};

const MultiStepForm = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [familyMembers, setFamilyMembers] = useState(
        placeholders.familyMembers
    );

    const [electricityBill, setElectricityBill] = useState("");
    const [commuteMethod, setCommuteMethod] = useState(
        placeholders.commuteMethod
    );
    const [longDistanceTravel, setLongDistanceTravel] = useState(
        placeholders.longDistanceTravel
    );
    const [monthlyExpense, setMonthlyExpense] = useState(
        placeholders.monthlyExpense
    );
    const [foodType, setFoodType] = useState(placeholders.foodType);

    const [lifestyleEmissions, setLifestyleEmissions] = useState(0);
    const [transportationEmissions, setTransportationEmissions] = useState(0);
    const [householdEmissions, setHouseholdEmissions] = useState(0);
    const [totalEmissions, setTotalEmissions] = useState(0);

    const [submitted, setSubmitted] = useState(false);

    const nextStep = () => {
        if (currentStep === 1) {
            if (
                monthlyExpense !== placeholders.monthlyExpense &&
                foodType !== placeholders.foodType
            ) {
                setCurrentStep(currentStep + 1);
            } else {
                alert("Please fill out all fields before proceeding.");
            }
        } else if (currentStep === 2) {
            if (
                commuteMethod !== placeholders.commuteMethod &&
                longDistanceTravel !== placeholders.longDistanceTravel
            ) {
                setCurrentStep(currentStep + 1);
            } else {
                alert("Please fill out all fields before proceeding.");
            }
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
        setSubmitted(false);
    };
    const avgPercentage = (
        INDIA_AVG_EMISSION /
        (INDIA_AVG_EMISSION + Number(totalEmissions))
    ).toFixed(2);
    const percentage = (
        Number(totalEmissions) /
        (INDIA_AVG_EMISSION + Number(totalEmissions))
    ).toFixed(2);
    const calculateEmissions = () => {
        const lifestyleEmissions =
            (carbonValues.foodType[foodType] || 0) +
            (carbonValues.monthlyExpense[monthlyExpense] || 0);
        const transportationEmissions =
            (carbonValues.commuteMethod[commuteMethod] || 0) +
            (carbonValues.longDistanceTravel[longDistanceTravel] || 0);
        const householdEmissions =
            (carbonValues.familyMembers[familyMembers] || 0) +
            electricityBill * 0.0012;

        const total =
            lifestyleEmissions + transportationEmissions + householdEmissions;

        setLifestyleEmissions(lifestyleEmissions);
        setTransportationEmissions(transportationEmissions);
        setHouseholdEmissions(householdEmissions);
        setTotalEmissions(total);
    };

    useEffect(() => {
        calculateEmissions();
    }, [
        familyMembers,

        electricityBill,
        commuteMethod,
        longDistanceTravel,
        monthlyExpense,
        foodType,
    ]);

    const handleSubmit = () => {
        if (
            familyMembers !== placeholders.monthlyExpense &&
            electricityBill !== ""
        ) {
            setSubmitted(true);
        } else {
            alert("Please fill out all fields before proceeding.");
        }
    };
    const handleReset = () => {
        setCurrentStep(1);
        setFamilyMembers(placeholders.familyMembers);
        setElectricityBill("");
        setCommuteMethod(placeholders.commuteMethod);
        setLongDistanceTravel(placeholders.longDistanceTravel);
        setMonthlyExpense(placeholders.monthlyExpense);
        setFoodType(placeholders.foodType);
        setLifestyleEmissions(0);
        setTransportationEmissions(0);
        setHouseholdEmissions(0);
        setTotalEmissions(0);
        setSubmitted(false);
    };

    return (
        <Section>
            <Container>
                <div className="mt-3.2 sm:mt-4 xl:mt-4.8 2xl:mt-5.6 flex flex-col lg:flex-row gap-3 xl:gap-2">
                    <div className="flex-1 lg:w-3/5 xl:w-3/5">
                        <div className="bg-white p-6 rounded shadow-md w-full">
                            <ProgressBar
                                currentStep={currentStep}
                                setCurrentStep={setCurrentStep}
                            />
                            {currentStep === 1 && (
                                <div>
                                    <div className="mb-4">
                                        <P className="block mb-1 mt-2 font-semibold text-[#171D39] font-urbanist xl:text-lg md:text-lsm">
                                            Your monthly Expense for Clothing /
                                            Electronics / Furniture / Leisure /
                                            culture / health?
                                        </P>
                                        <BottomLineDropDown
                                            selected={monthlyExpense}
                                            data={monthlyExpenseOptions}
                                            onSelect={setMonthlyExpense}
                                            placeholder={
                                                placeholders.monthlyExpense
                                            }
                                            icon={
                                                <P>
                                                    <BsArrowDown />
                                                </P>
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <P className="block mb-1 mt-2 font-semibold text-[#171D39] font-urbanist xl:text-lg md:text-lsm">
                                            What type of food do you consume?
                                        </P>
                                        <BottomLineDropDown
                                            selected={foodType}
                                            data={foodTypeOptions}
                                            onSelect={setFoodType}
                                            placeholder={placeholders.foodType}
                                            icon={
                                                <P>
                                                    <BsArrowDown />
                                                </P>
                                            }
                                        />
                                    </div>
                                    <div className="flex justify-end">
                                        <Button
                                            variant="primary"
                                            className=" lg:flex text-white"
                                            onClick={nextStep}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {currentStep === 2 && (
                                <div>
                                    <div className="mb-4">
                                        <P className="block mb-1 mt-2 font-semibold text-[#171D39] font-urbanist xl:text-lg md:text-lsm">
                                            How do you commute daily?
                                        </P>
                                        <BottomLineDropDown
                                            selected={commuteMethod}
                                            data={commuteOptions}
                                            onSelect={setCommuteMethod}
                                            placeholder={
                                                placeholders.commuteMethod
                                            }
                                            icon={
                                                <P>
                                                    <BsArrowDown />
                                                </P>
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <P className="block mb-1 mt-2 font-semibold text-[#171D39] font-urbanist xl:text-lg md:text-lsm">
                                            Do you travel long distances?
                                        </P>
                                        <BottomLineDropDown
                                            selected={longDistanceTravel}
                                            data={longDistanceOptions}
                                            onSelect={setLongDistanceTravel}
                                            placeholder={
                                                placeholders.longDistanceTravel
                                            }
                                            icon={
                                                <P>
                                                    <BsArrowDown />
                                                </P>
                                            }
                                        />
                                    </div>
                                    <div className="flex justify-between">
                                        <Button
                                            variant="primary"
                                            className="lg:flex text-white"
                                            onClick={prevStep}
                                        >
                                            Previous
                                        </Button>
                                        <Button
                                            variant="primary"
                                            className=" lg:flex text-white"
                                            onClick={nextStep}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {currentStep === 3 && (
                                <div>
                                    <div className="mb-4">
                                        <P className="block mb-1 mt-2 font-semibold text-[#171D39] font-urbanist xl:text-lg md:text-lsm">
                                            Number of Family Members
                                        </P>
                                        <BottomLineDropDown
                                            selected={familyMembers}
                                            data={familyMembersOptions}
                                            onSelect={setFamilyMembers}
                                            placeholder={
                                                placeholders.familyMembers
                                            }
                                            icon={
                                                <P>
                                                    <BsArrowDown />
                                                </P>
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <TextField
                                            label={" Electricity Bill Monthly"}
                                            value={electricityBill}
                                            onChange={(e) =>
                                                setElectricityBill(
                                                    e.target.value
                                                )
                                            }
                                            type={"text"}
                                        />
                                    </div>

                                    <div className="flex justify-between">
                                        <Button
                                            variant="primary"
                                            className=" lg:flex text-white"
                                            onClick={prevStep}
                                        >
                                            Previous
                                        </Button>

                                        {submitted ? (
                                            <Button
                                                variant="primary"
                                                className="lg:flex text-white"
                                                onClick={handleReset}
                                            >
                                                Reset
                                            </Button>
                                        ) : (
                                            <>
                                                <Button
                                                    variant="primary"
                                                    className="lg:flex text-white"
                                                    onClick={handleSubmit}
                                                >
                                                    Show Results
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex-1 lg:w-2/5 xl:w-2/5 w-full mx-auto md:mt-3 ">
                        <div className="h-auto bg-white p-6 rounded-lg shadow-lg items-center">
                            <div className="mb-2">
                                <div className="flex items-center w-full">
                                    <FaHouseChimney className="text-xl mr-2 text-black" />
                                    <P className="font-urbanist font-semibold md:mr-4 w-32 mr-0">
                                        Lifestyle
                                    </P>
                                    <div className="flex-grow ml-4">
                                        {submitted ? (
                                            <div className="relative h-1.5 bg-gray-200 rounded-full border border-black">
                                                <div
                                                    className="absolute top-0 left-0 h-1.5 bg-orange rounded-full"
                                                    style={{
                                                        width: `${
                                                            (lifestyleEmissions /
                                                                totalEmissions) *
                                                                100 || 0
                                                        }%`,
                                                    }}
                                                ></div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <P className="font-semibold md:text-2.8xl ml-4">
                                        {submitted
                                            ? `${(
                                                  (lifestyleEmissions /
                                                      totalEmissions) *
                                                  100
                                              ).toFixed(2)} %`
                                            : `${lifestyleEmissions.toFixed(
                                                  2
                                              )} tons`}
                                    </P>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="flex items-center w-full">
                                    <FaCarAlt className="text-xl mr-2 text-black" />
                                    <P className="font-urbanist font-semibold md:mr-4  mr-0 w-32">
                                        Transportation
                                    </P>
                                    <div className="flex-grow ml-4">
                                        {submitted ? (
                                            <div className="relative h-1.5 bg-gray-200 rounded-full border border-black">
                                                <div
                                                    className="absolute top-0 left-0 h-1.5 bg-green rounded-full"
                                                    style={{
                                                        width: `${
                                                            (transportationEmissions /
                                                                totalEmissions) *
                                                                100 || 0
                                                        }%`,
                                                    }}
                                                ></div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <P className="font-semibold md:text-2.8xl ml-4">
                                        {submitted
                                            ? `${(
                                                  (transportationEmissions /
                                                      totalEmissions) *
                                                  100
                                              ).toFixed(2)} %`
                                            : `${transportationEmissions.toFixed(
                                                  2
                                              )} tons`}
                                    </P>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="flex items-center w-full">
                                    <GiKnifeFork className="text-xl mr-2 text-black" />
                                    <P className="font-urbanist font-semibold md:mr-4 w-32 mr-0">
                                        Household
                                    </P>
                                    <div className="flex-grow ml-4">
                                        {submitted ? (
                                            <div className="relative h-1.5 bg-gray-200 rounded-full border border-black">
                                                <div
                                                    className="absolute top-0 left-0 h-1.5 bg-black rounded-full"
                                                    style={{
                                                        width: `${
                                                            (householdEmissions /
                                                                totalEmissions) *
                                                                100 || 0
                                                        }%`,
                                                    }}
                                                ></div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <P className="font-semibold md:text-2.8xl ml-4">
                                        {submitted
                                            ? `${(
                                                  (householdEmissions /
                                                      totalEmissions) *
                                                  100
                                              ).toFixed(2)} %`
                                            : `${householdEmissions.toFixed(
                                                  2
                                              )} tons`}
                                    </P>
                                </div>
                            </div>
                            <div className="flex-1 w-full mx-auto">
                                <div className="h-auto bg-black p-6 py-3 rounded-lg shadow-lg">
                                    <P className="font-urbanist font-semibold text-white">
                                        Your Total Annual Emissions
                                    </P>
                                    <div className="flex items-center">
                                        <div className="relative h-1.5 bg-gray-200 rounded-full border border-[#0065FF] flex-grow">
                                            <div
                                                className="absolute top-0 left-0 h-1.5 bg-green rounded-full"
                                                style={{
                                                    width: `${Math.min(
                                                        percentage * 100,
                                                        100
                                                    )}%`,
                                                }}
                                            ></div>
                                        </div>
                                        <P className="ml-4 font-semibold text-2.8xl text-white">
                                            {totalEmissions.toFixed(2)} tons
                                        </P>
                                    </div>
                                    <P className="font-urbanist font-semibold text-white mt-6">
                                        Average Indian Annual Emissions
                                    </P>
                                    <div className="flex items-center">
                                        <div className="relative h-1.5 bg-gray-200 rounded-full border border-[#0065FF] flex-grow">
                                            <div
                                                className="absolute top-0 left-0 h-1.5 bg-green rounded-full"
                                                style={{
                                                    width: `${Math.min(
                                                        avgPercentage * 100,
                                                        60
                                                    )}%`,
                                                }}
                                            ></div>
                                        </div>
                                        <P className="ml-4 font-semibold text-2.8xl text-white">
                                            {INDIA_AVG_EMISSION.toFixed(2)} tons
                                        </P>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {submitted && (
                    <div
                        className=" mt-10  shadow-md rounded-lg p-6 border border-gray-200"
                        style={{
                            background:
                                "linear-gradient(180deg, #A4CEFF 0%, #F0F6FE 100%)",
                        }}
                    >
                        <P className="text-lg font-semibold text-gray-800">
                            You are emitting {totalEmissions.toFixed(2)} Tons of
                            CO2 per Year. To offset, you need to plant and grow{" "}
                            {Math.ceil((totalEmissions / 1.39) * 67.2)} Trees.
                            Alternatively, you can offset these emissions by
                            investing in Solar Assets of capacity{" "}
                            {(totalEmissions / 1.39).toFixed(2)} KWp.
                        </P>
                    </div>
                )}
            </Container>
        </Section>
    );
};

export default MultiStepForm;
