import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Text from "../text/Text";

const InteractiveLinkComponent = styled(Text)`
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
        color: ${(props) =>
            props.theme.color[props.activeColor] || props.theme.color.primary};
        transform: scale(1.1);
    }
`;

const LinkComponent = styled(Text)`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export default ({
    to,
    children,
    size,
    color,
    fontFamily,
    weight,
    align,
    isExternal,
    activeColor,
    style = {},
    isInteractive,
    isInline,
}) => {
    const Component = isInteractive ? InteractiveLinkComponent : LinkComponent;
    return (
        <Component
            align={align}
            color={color}
            weight={weight}
            size={size}
            fontFamily={fontFamily}
            style={style}
            activeColor={activeColor}
            isInline={isInline}
        >
            {isExternal ? (
                <a
                    href={to}
                    target="_blank"
                    style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                    }}
                >
                    {children}
                </a>
            ) : (
                <Link
                    to={to}
                    style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "block",
                    }}
                >
                    {children}
                </Link>
            )}
        </Component>
    );
};
