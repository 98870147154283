import styled from "styled-components";
const Circle = styled.div`
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.backgroundColor
            ? props.theme.color[props.backgroundColor]
            : props.theme.color.white};
    color: ${(props) => props.theme.color.white};
    font-size: ${(props) => props.theme.fontSize.default[props.fontSize]};
    border-radius: 100%;
    font-family: ${(props) => props.fontFamily || "'Montserrat', sans-serif"};
    font-weight: ${(props) => props.fontWeight || "500"};
    margin: ${(props) => props.margin || ""};
`;
export default Circle;
