import React, { forwardRef } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import styled from "styled-components";
import withClickOutside from "../../hoc/withClickOutside";
import { AlertMessage, ErrorText } from "../input/Input";
import { P } from "../styles/Typography.styles";
import { InlineText } from "../text/HeadingText";

const MainWrap = styled.div`
    margin: ${(props) => props.margin || ""};
`;

export const DropWrapper = styled.div`
    width: 100%;
    line-height: 1.3;
    min-height: 40px;
    height: 100%;
    position: relative;
    width: 100%;
    cursor: pointer;
    transition: ${(props) => props.theme.transition.xs};
    box-sizing: border-box;
    border: solid 2px
        ${(props) =>
            props.isError
                ? props.theme.color.error
                : props.borderColor
                ? props.theme.color[props.borderColor]
                : props.theme.color.lightGrey};

    padding: ${(props) =>
        props.noPadding
            ? "1rem 0"
            : props.size === "s"
            ? ".7rem 1.8rem"
            : "1.2rem 1.8rem"};
    border-radius: ${(props) =>
        props.isRounded
            ? props.theme.sizes.xl10
            : props.radius
            ? props.theme.sizes[props.radius]
            : props.theme.sizes.xxs};
    align-items: center;
    background-color: ${(props) =>
        props.disabled
            ? props.theme.color.grey300
            : props.backgroundColor
            ? props.theme.color[props.backgroundColor]
            : "transparent"};

    .options-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 5;
        top: 32px;
        margin-top: 8px;
    }
    .bottomSelectIcon {
        width: 30px;
        transition: all ease 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding-right: 8px;
        top: 0;
        bottom: 0;
        right: 0;
        transform: ${(props) =>
            props.isShow ? " rotateZ(180deg)" : " rotateZ(0deg)"};
    }
    &:focus {
        outline: none;
        border-color: ${(props) =>
            props.isError
                ? props.theme.color.error
                : props.disabled
                ? ""
                : props.theme.color.primary};
    }
    &:hover {
        cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.xlg
                ? props.theme.fontSize.default[props.xlg]
                : props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
`;
const OptionWrapper = styled.div`
    cursor: pointer;
    background-color: ${(props) =>
        props.backgroundColor
            ? props.theme.color[props.backgroundColor]
            : props.theme.color.darkGrey};
    border-radius: 3px;
    /* border-radius: 10px; */
    transition: all ease 0.3s;
    max-height: ${(props) => (props.isShow ? "200px" : "0px")};
    overflow: ${(props) => (props.isShow ? "unset" : "hidden")};
    overflow-y: ${(props) => (props.isShow ? "scroll" : "hidden")};
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    ::-webkit-scrollbar {
        width: 10px;
        background-color: ${(props) => props.theme.color.lightGrey};
        opacity: 0.3;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.primary};
        border-radius: 10px;
    }
`;
const Options = styled(P)`
    padding: 0.45em 10px;
    transition: all 0.2s;
    border-bottom: 1px solid ${(props) => props.theme.color.white};
    color: ${(props) =>
        props.color
            ? props.theme.color[props.color]
            : props.theme.color[props.isHighlighted ? "white" : "darkGrey"]};
    background-color: ${(props) =>
        props.backgroundColor
            ? props.theme.color[props.backgroundColor]
            : props.theme.color[props.isHighlighted ? "primary" : "grey300"]};
    :hover {
        background-color: ${(props) => props.theme.color.primary};
        color: ${(props) => props.theme.color.white};
    }
    font-size: ${(props) =>
        props.xxs
            ? props.theme.fontSize.default[props.st]
            : props.theme.fontSize.default.m};
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        font-size: ${(props) =>
            props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        font-size: ${(props) =>
            props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        font-size: ${(props) =>
            props.xlg
                ? props.theme.fontSize.default[props.xlg]
                : props.lg
                ? props.theme.fontSize.default[props.lg]
                : props.st
                ? props.theme.fontSize.default[props.st]
                : props.theme.fontSize.default.m};
    }
`;

const DropDown = forwardRef(
    (
        {
            data,
            label,
            selected,
            onChange,
            margin = "0",
            xxs = "m",
            st = "m",
            xlg = "m",
            borderColor = "secondary",
            backgroundColor = "white",
            errorMessage = "",
            errorType = "message",
            color = "grey700",
            isSelected,
            setIsSelected,
            placeholder = "Select type",
            disabled = false,
            themeColor = "",
            optionBg = "",
            noPadding = false,
        },
        ref
    ) => {
        const getHighlighted = (list) => {
            if (list?.id) {
                return list.id === selected.id;
            } else if (!list?.id) {
                return list === selected;
            }

            return false;
        };
        return (
            <MainWrap margin={margin} ref={ref}>
                <P color="secondary">{label}</P>
                <DropWrapper
                    margin={margin}
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                        disabled ? null : setIsSelected(!isSelected)
                    }
                    isShow={isSelected}
                    borderColor={borderColor}
                    backgroundColor={backgroundColor}
                    className="dropWrapper"
                    size={"s"}
                    isError={errorMessage}
                    disabled={disabled}
                    noPadding={noPadding}
                >
                    {!selected ? (
                        <P xxs={xxs} st={st} xlg={xlg} color={color}>
                            {placeholder}
                        </P>
                    ) : (
                        <P xxs={xxs} st="m" xlg="m" color={color}>
                            {selected?.name || selected}
                        </P>
                    )}
                    <OptionWrapper
                        isShow={isSelected}
                        className="options-wrapper"
                        backgroundColor={optionBg}
                    >
                        {data.map((list, index) => (
                            <Options
                                xxs={xxs}
                                st={st}
                                xlg={xlg}
                                color={themeColor}
                                backgroundColor={optionBg}
                                isHighlighted={
                                    getHighlighted(list)

                                    // list?.id === selected?.id
                                }
                                role="button"
                                tabIndex={index}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onChange(list);
                                    setIsSelected(false);
                                }}
                                key={index}
                                as="div"
                            >
                                {list?.name || list}
                            </Options>
                        ))}
                    </OptionWrapper>
                    <InlineText
                        className="bottomSelectIcon"
                        color={color}
                        size="m"
                    >
                        <AiFillCaretDown />
                    </InlineText>
                </DropWrapper>
                {errorMessage && errorType === "alert" ? (
                    <AlertMessage xxs="xs" st="xs">
                        {errorMessage}
                    </AlertMessage>
                ) : errorMessage ? (
                    <ErrorText xxs="xs" st="xs">
                        {errorMessage}
                    </ErrorText>
                ) : null}
            </MainWrap>
        );
    }
);

export default withClickOutside(DropDown);
