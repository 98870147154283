import {
    Autocomplete,
    DirectionsRenderer,
    GoogleMap,
    MarkerF,
    useJsApiLoader,
} from "@react-google-maps/api";
import React, { useState } from "react";
import Input, { InputComponent } from "../../../../components/input/Input";
import Space from "../../../../components/space/Space";
import Container from "../../../../components/styles/Container.styles";
import { Flex, Section } from "../../../../components/styles/Elements.styles";
import { H3, P } from "../../../../components/styles/Typography.styles";
import SurveyViewContainer from "./SurveyViewContainer";

import { useRef } from "react";

import { useCallback } from "react";
import { setKey } from "react-geocode";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../components/button/Button";
import { GOOGLE_MAP_API_KEY } from "../../../../constants/routes";

setKey(GOOGLE_MAP_API_KEY);

const placesLibrary = ["places"];
const INITIAL_ADDRESS =
    "SNN RAJ ETTERNIA, Haralur Road, Off Sarjapur Road Sarjapur Hobli Close to Kudlu, Sy. no.146/1,147/1,147/2,Silver County Rd, Parappana Agrahara, Bengaluru";
const MapContainer = styled.div`
    .map {
        width: 100%;
        height: 200px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    }
    input {
        font-size: ${(props) => props.theme.fontSize.default.s};
    }
`;

const ButtonStyled = styled(Button)`
    border: none;
    padding: 5px 15px;
    height: 100%;
    font-size: 15px;
    :hover,
    :focus,
    :active {
        box-shadow: none;
    }
`;
const center = {
    lat: 18.5204303,
    lng: 73.8567437,
};
const SurveyOverview = () => {
    let { surveyId } = useParams();
    const [propertyName, setPropertyName] = useState("SNN RAJ ETTERNIA");
    const [siteAddress, setSiteAddress] = useState(INITIAL_ADDRESS);
    const [map, setMap] = useState(/** @type google.maps.Map */ (null));
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [markerPointA, setMarkerPointA] = useState(center);
    const sourceRef = useRef();
    const siteRef = useRef();
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
        libraries: placesLibrary,
    });
    const onLoad = useCallback((map) => {
        setMap(map);
    }, []);

    const onUnmount = useCallback((map) => {
        setMap(null);
    }, []);

    const calculateRoute = async (e) => {
        e.preventDefault();

        if (siteRef.current.value === "" || sourceRef.current.value === "") {
            return;
        }

        const directionsService = new window.google.maps.DirectionsService();
        const results = await directionsService.route({
            origin: siteRef.current.value,
            destination: sourceRef.current.value,
            travelMode: window.google.maps.TravelMode.DRIVING,
        });

        setDirectionsResponse(results);
    };
    return (
        <Section height="auto">
            <Container>
                <Space
                    xxsm={["4rem 0 5rem 0rem"]}
                    stm={["5rem 0 5rem 1.5rem"]}
                    lgm={["6rem 0 8rem 0rem"]}
                >
                    <H3>Survey View: {surveyId}</H3>
                </Space>
                <SurveyViewContainer>
                    <Flex.Container justify="space-between" align="center">
                        <Flex.Item xl="48%" t="100%" xxs="100%">
                            <PropertyDetails
                                label={"Property Name"}
                                placeholder="Enter your Property Name"
                                name="propertyname"
                                color="black"
                                errorMessage={""}
                                value={propertyName}
                                disabled={true}
                                lg={"20px"}
                                xlg={"20px"}
                                st={"20px"}
                                xss={"20px"}
                            />
                            <PropertyDetails
                                label={"Site Address"}
                                placeholder="Enter your Site Address"
                                name="siteaddress"
                                color="black"
                                errorMessage={""}
                                value={siteAddress}
                                disabled={true}
                                componentType={"textarea"}
                                rows="4"
                                lg={"15.5px"}
                                xlg={"15.5px"}
                                st={"15.5px"}
                                xxs={"15.5px"}
                            />
                            <Flex.Container align="center">
                                <Space xxsm={["10px 0 0"]}>
                                    <Flex.Item xl="30%" st="20%" xxs="100%">
                                        <P color="black" lg={"25px"}>
                                            Navigate
                                        </P>
                                    </Flex.Item>
                                </Space>

                                <Flex.Item flex={"1"}>
                                    {" "}
                                    <Autocomplete>
                                        <InputComponent
                                            size="s"
                                            shadow={false}
                                            ref={sourceRef}
                                        />
                                    </Autocomplete>
                                </Flex.Item>

                                <Flex.Item>
                                    <Space xxsm={["0 0 0 1rem"]}>
                                        <ButtonStyled onClick={calculateRoute}>
                                            Go
                                        </ButtonStyled>
                                    </Space>
                                </Flex.Item>
                            </Flex.Container>
                        </Flex.Item>
                        <Flex.Item xl="48%" t="100%" xxs="100%">
                            {isLoaded && (
                                <>
                                    <Space xxsm={["2rem 0"]}>
                                        <Flex.Item xl="30%" st="60%" xxs="100%">
                                            <Space stm={["0 0 1.5rem 0"]}>
                                                <P color="black" lg={"25px"}>
                                                    Site Map Location
                                                </P>
                                            </Space>
                                        </Flex.Item>
                                    </Space>
                                    <Flex.Item xl="100%" t="100%" xxs="100%">
                                        <MapContainer>
                                            <GoogleMap
                                                mapContainerClassName="map"
                                                center={markerPointA}
                                                zoom={15}
                                                options={{
                                                    // zoomControl: false,
                                                    streetViewControl: false,
                                                    mapTypeControl: false,
                                                    fullscreenControl: true,
                                                }}
                                                onLoad={onLoad}
                                                onUnmount={onUnmount}
                                            >
                                                <MarkerF
                                                    draggable
                                                    position={markerPointA}
                                                ></MarkerF>
                                                {directionsResponse && (
                                                    <DirectionsRenderer
                                                        directions={
                                                            directionsResponse
                                                        }
                                                    />
                                                )}
                                            </GoogleMap>
                                        </MapContainer>
                                    </Flex.Item>
                                </>
                            )}
                        </Flex.Item>
                    </Flex.Container>
                    <Space xxsm={["4rem 0"]}>
                        <Flex.Container justify="center" align="center">
                            <ButtonStyled >
                                View Survey
                            </ButtonStyled>
                        </Flex.Container>
                    </Space>
                </SurveyViewContainer>
            </Container>
        </Section>
    );
};
const PropertyDetails = ({ label, onChange, errorMessage, data, ...rest }) => {
    return (
        <Space margin={[" 0 0 30px "]} tm={["0 0 25px"]} xsm={["0 0 10px"]}>
            <Flex.Container justify="space-between" align="center">
                <Space xxsm={["10px 0 0"]} stm={["0"]}>
                    <Flex.Item xl="30%" st="20%" xxs="100%">
                        <P color="black" lg={"25px"} xlg={"25px"}>
                            {label}
                        </P>
                    </Flex.Item>
                </Space>

                <Space tm={[".1rem 0 0 "]}>
                    <Flex.Item flex="1">
                        <Input
                            errorMessage={errorMessage}
                            onChange={onChange}
                            backgroundColor="white"
                            shadow={false}
                            style={{
                                paddingBottom: "7px",
                                paddingTop: "7px",
                            }}
                            {...rest}
                        />
                    </Flex.Item>
                </Space>
            </Flex.Container>
        </Space>
    );
};
export default SurveyOverview;
