import styled from "styled-components";

const StyledBurger = styled.div`
    position: ${(props) => (props.isBurgerMenuOpen ? "fixed" : "")};
    top: ${(props) => (props.isBurgerMenuOpen ? "25px" : "22px")};
    right: ${(props) => (props.isBurgerMenuOpen ? "4%" : "auto")};
    left: ${(props) => (props.isBurgerMenuOpen ? "auto" : "4%")};
    margin-right: ${(props) => (props.isBurgerMenuOpen ? "" : "20px")};
    z-index: 15;
    flex-direction: column;
    justify-content: space-around;
    width: 26px;
    height: 26px;
    cursor: pointer;
    padding: 0;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: flex;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        width: 22px;
        height: 22px;
    }

    div {
        width: 26px;
        height: 3px;
        background: ${(props) => props.theme.color.primary};
        border-radius: 2px;
        transition: all 0.2s linear;
        position: relative;
        ${"" /* transform-origin: 1px; */}
        margin-bottom:3px;
        @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
            width: 22px;
        }

        :first-child {
            transform: ${({ isBurgerMenuOpen }) =>
                isBurgerMenuOpen ? "rotate(42deg)" : "rotate(0)"};

            transform-origin: center left;
        }
        :nth-child(2) {
            opacity: ${({ isBurgerMenuOpen }) =>
                isBurgerMenuOpen ? "0" : "1"};
            transform: ${({ isBurgerMenuOpen }) =>
                isBurgerMenuOpen ? "translateX(20px)" : "translateX(0)"};
        }
        :nth-child(3) {
            transform: ${({ isBurgerMenuOpen }) =>
                isBurgerMenuOpen ? "rotate(-42deg)" : "rotate(0)"};

            transform-origin: center left;
            margin-bottom: ${({ isBurgerMenuOpen }) =>
                isBurgerMenuOpen ? "3px" : "1px"};
        }
    }
`;

const BurgerIcon = ({ isBurgerMenuOpen, toggleBurgerMenu }) => (
    <StyledBurger
        isBurgerMenuOpen={isBurgerMenuOpen}
        onClick={() => toggleBurgerMenu(!isBurgerMenuOpen)}
    >
        <div />
        <div />
        <div />
    </StyledBurger>
);

export default BurgerIcon;
