import {
    FIVE_YEARS_TERM,
    MAX_PLAN_TERM,
    MINIMUM_BILL_AMOUNT,
    YEAR,
} from "../constants/common";

export const getProposalCalculatedData = (planTerm, proposal) => {
    const billsToBePaid =
        planTerm === FIVE_YEARS_TERM
            ? proposal?.billToBePaidFiveYears
            : proposal?.billToBePaidTenYears;
    const savingsOnMonthlyBill =
        proposal?.averageMonthlyElectricityBill - billsToBePaid || 0;

    const yearlySavings = savingsOnMonthlyBill * 12;

    const totalSavings = yearlySavings * planTerm;

    const savingsAfterPlanTerm =
        proposal?.averageMonthlyElectricityBill *
        12 *
        (MAX_PLAN_TERM - planTerm);
    const over25YearsTotalSavings = totalSavings + savingsAfterPlanTerm;
    const savingsPercentage = Number(
        (
            (billsToBePaid / proposal?.averageMonthlyElectricityBill) *
            100
        ).toFixed(1)
    );
    return {
        savingsOnMonthlyBill,
        over25YearsTotalSavings,
        yearlySavings,
        savingsAfterPlanTerm,
        totalSavings,
        savingsPercentage,
    };
};

export const getElectricityData = (type, proposal, planTerm) => {
    let electricityBill,
        subscriptionCharge,
        savingsWithSubscription,
        carbonReduced;
    subscriptionCharge =
        planTerm === FIVE_YEARS_TERM
            ? proposal?.billToBePaidFiveYears
            : proposal?.billToBePaidTenYears;

    carbonReduced = (proposal?.proposedSolarCapacity * 1.39) / 12;

    if (type === "Monthly") {
        electricityBill = proposal?.averageMonthlyElectricityBill;
        savingsWithSubscription = electricityBill - subscriptionCharge;
    } else {
        electricityBill = proposal?.averageMonthlyElectricityBill * 12;
        subscriptionCharge = subscriptionCharge * 12;
        savingsWithSubscription = electricityBill - subscriptionCharge;
        carbonReduced = carbonReduced * 12;
    }

    const subscriptionPercentage = (100 * subscriptionCharge) / electricityBill;

    return [
        {
            id: 1,
            accent: "grey800",
            range: 100,
            value: electricityBill,
            title: `Your Current ${type} Electricity Bill`,
        },
        {
            id: 2,
            accent: "primary",
            range: subscriptionPercentage,
            value: subscriptionCharge,
            title: `Your ${type} Solar Subscription Charge`,
        },
        {
            id: 3,
            accent: "cyan",
            range: 100 - subscriptionPercentage,
            value: savingsWithSubscription,
            title: `${type} Savings With Subscription Plan`,
        },
        {
            id: 4,
            accent: "cyan500",
            range: 100,
            value: carbonReduced,
            title: `${type} Carbon Reduced (in Tons)`,
        },
    ];
};

const calculateGreyBarElectricity = (year, billAmount, divisor = 100000) => {
    let yearlyElectricityBill =
        (billAmount * YEAR * Math.pow(1.025, year - 1)) / divisor;
    return Number(yearlyElectricityBill.toFixed(1));
};

const calculateBlueBarElectricity = (
    billUnits,
    SROT_RATE,
    divisor = 100000
) => {
    return Number(((billUnits * SROT_RATE * 12) / divisor).toFixed(1));
};

export const getEntireBarValues = (
    TOTAL_YEARS,
    SOLAR_TERM,
    billAmount,
    billUnits,
    SROT_RATE
) => {
    let GREY_BAR = [];
    let BLUE_BAR = [];
    let GREEN_BAR = [];
    let GREY_BAR_SUM = 0;
    let BLUE_BAR_SUM = 0;
    let divisor = billAmount < MINIMUM_BILL_AMOUNT ? 1000 : 100000;

    for (let i = 0; i < TOTAL_YEARS; i++) {
        let res = calculateGreyBarElectricity(i + 1, billAmount, divisor);
        GREY_BAR[i] = res;
        GREY_BAR_SUM += res;
    }

    for (let i = 0; i < TOTAL_YEARS; i++) {
        if (i < SOLAR_TERM) {
            let res = calculateBlueBarElectricity(
                billUnits,
                SROT_RATE,
                divisor
            );
            BLUE_BAR[i] = res;
            BLUE_BAR_SUM += res;
        } else {
            BLUE_BAR[i] = 0;
        }
    }
    for (let i = 0; i < TOTAL_YEARS; i++) {
        GREEN_BAR[i] = Number((GREY_BAR[i] - BLUE_BAR[i]).toFixed(1));
    }
    let GREEN_BAR_SUM = GREY_BAR_SUM - BLUE_BAR_SUM;
    return {
        GREY_BAR,
        BLUE_BAR,
        GREEN_BAR,
        GREY_BAR_SUM,
        BLUE_BAR_SUM,
        GREEN_BAR_SUM,
    };
};

export const getDisplayGraphData = (
    BAR_CHART_LABELS,
    TOTAL_YEARS,
    SOLAR_TERM,
    billAmount,
    billUnits,
    SROT_RATE
) => {
    const { BLUE_BAR, GREEN_BAR, GREY_BAR } = getEntireBarValues(
        TOTAL_YEARS,
        SOLAR_TERM,
        billAmount,
        billUnits,
        SROT_RATE
    );

    const displayGrey = [];
    const displayBlue = [];
    const displayGreen = [];

    for (let i = 0; i <= BAR_CHART_LABELS.length; i++) {
        displayGrey[i] = GREY_BAR[Number(BAR_CHART_LABELS[i]) - 1];
        displayBlue[i] = BLUE_BAR[Number(BAR_CHART_LABELS[i]) - 1];
        displayGreen[i] = GREEN_BAR[Number(BAR_CHART_LABELS[i]) - 1];
    }

    return {
        GREY_BAR: displayGrey,
        BLUE_BAR: displayBlue,
        GREEN_BAR: displayGreen,
    };
};
