import React from "react";

import { motion } from "framer-motion";
import {
    featureImg1,
    featureImg2,
    featureImg3,
    featureImg4,
} from "../../../resources/js/icons";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import {
    container,
    translateContainer,
    wavyHeadings,
} from "../../../shared/animation/constants/landingAnimation";
import SplitText from "../../../shared/animation/typography/SplitText";
import FeatureCard from "../../../shared/ui/Cards/FeatureCard";
const features = [
    {
        title: "Savings",
        description:
            "Make the most of government subsidies and save up to 50% on monthly electricity expenses",
        icon: featureImg1,
    },
    {
        title: "Investment",
        description:
            "Offering zero upfront investment  and affordable monthly pricing plans",
        icon: featureImg2,
    },
    {
        title: "Ownership",
        description:
            "Effortless transfer of ownership at the end of the term and enjoy Solar benefits for rest of life",
        icon: featureImg3,
    },
    {
        title: "Maintenance",
        description:
            "Free maintenance throughout the plan duration, get top-notch lifetime service at affordable rates",
        icon: featureImg4,
    },
];

const FeatureSection = () => {
    return (
        <Section>
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className="mb-2.4 sm:mb-4.8 xl:mb-5 2xl:mb-8.8"
                >
                    <SplitText variants={wavyHeadings} heading="h2">
                        Trusted Leader in Solar Solutions
                    </SplitText>
                </motion.div>

                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={container}
                    className="grid gap-x-1.6 gap-y-2.4 sm:gap-y-3.2 grid-cols-2 lg:grid-cols-4 xl:gap-x-3.6 2xl:gap-x-3.8 overflow-x-hidden"
                >
                    {features.map((feature, index) => (
                        <FeatureCard
                            feature={feature}
                            key={index}
                            index={index}
                        />
                    ))}
                </motion.div>
            </Container>
        </Section>
    );
};

export default FeatureSection;
