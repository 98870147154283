import imageCompression from "browser-image-compression";

export const handleScrollInto = (id, block = "nearest") => {
    let lookingForAnswer = document.getElementById(id);
    if (lookingForAnswer) {
        lookingForAnswer.scrollIntoView({
            behavior: "smooth",
            block: block,
        });
    }
};

const defaultImageOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
};
const MAX_IMAGE_SIZE = 1 * 1024 * 1024; // 1MB
export const optimizeImageSize = async (
    imageFile,
    options = defaultImageOptions
) => {
    if (imageFile instanceof File && imageFile.size <= MAX_IMAGE_SIZE) {
        return imageFile;
    } else if (imageFile instanceof File) {
        try {
            const compressedFile = await imageCompression(imageFile, options);

            const optimizedImage = new File(
                [compressedFile],
                compressedFile.name
            );

            return optimizedImage;
        } catch (error) {
            return null;
        }
    } else {
        return null;
    }
};
