import React from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import Icon from "../Icon/Icon";

import Text from "../text/Text";

const Wraper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`;

const CrossBtn = styled.div`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    :hover {
        color: red;
    }
`;
const ModalTitle = ({ title, size = "xl", onClick, times = true }) => {
    return (
        <Wraper>
            <Text weight="bold" color="white" size={size}>
                {title}
            </Text>

            {times && (
                <CrossBtn role="button" onClick={onClick}>
                    <Icon color="white" size={size} component={FaTimes} />
                </CrossBtn>
            )}
        </Wraper>
    );
};

export default ModalTitle;
