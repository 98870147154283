import PropTypes from "prop-types";
import React from "react";
import cn from "../../lib/cn";

const baseStyles =
    "text-primary font-semibold text-4xl sm:text-5xl xl:text-7xl  2xl:text-8xl";

const H2 = ({ className = "", children }) => {
    return <h2 className={cn(baseStyles, className)}>{children}</h2>;
};

export default H2;

H2.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
