import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { bgPattern } from "../../assets";
import {
    container,
    slideLeft,
    slideRight,
} from "../../components/animation/landingAnimation";
import Button from "../../components/button/Button";
import Space from "../../components/space/Space";
import Container from "../../components/styles/Container.styles";
import { Flex, Section } from "../../components/styles/Elements.styles";
import { H2, P } from "../../components/styles/Typography.styles";

export const ButtonWhiteOutline = styled(Button)`
    border: 2px solid ${(props) => props.theme.color.primary};
    transition: all 0.3s;
    :hover {
        background: transparent;
        border-color: ${(props) => props.theme.color.white};
    }
`;

const SectionStyled = styled(Section)`
    background: url(${bgPattern});
    object-fit: cover;
`;

const HostPanel = ({ title, summary, onClick }) => {
    return (
        <SectionStyled
            style={{ overflow: "hidden" }}
            backgroundColor="black"
            height="auto"
        >
            <Container>
                <Flex.Container
                    justify="center"
                    as={motion.div}
                    initial="hidden"
                    whileInView="visible"
                    variants={container}
                >
                    <Flex.Item
                        xl="80%"
                        xxs="100%"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            align: "center",
                        }}
                    >
                        <H2
                            color="white"
                            align="center"
                            as={motion.h2}
                            variants={slideLeft}
                        >
                            {title}
                        </H2>

                        <Space xxsm={["1.4rem 0"]}>
                            <P
                                size="m"
                                align="center"
                                color="white"
                                as={motion.p}
                                variants={slideRight}
                            >
                                {summary}
                            </P>
                        </Space>
                    </Flex.Item>
                    <Flex.Item st="30%" xxs="100%" xs="80%">
                        {onClick && (
                            <ButtonWhiteOutline
                                // as={motion.button}
                                // variants={slideRight}
                                radius="100px"
                                fontSize="l"
                                fullWidth
                                // color="error"
                                backgroundColor="primary"
                                type="solid"
                                onClick={onClick}
                            >
                                Start Onboarding
                            </ButtonWhiteOutline>
                        )}
                    </Flex.Item>
                </Flex.Container>
            </Container>
        </SectionStyled>
    );
};

export default HostPanel;
