import styled from "styled-components";
import Input from "../../../../components/input/Input";
import Space from "../../../../components/space/Space";
import { Flex } from "../../../../components/styles/Elements.styles";
import { P } from "../../../../components/styles/Typography.styles";
import theme from "../../../../theme";

export const DetailsFiled = ({ label, isDPD, ...rest }) => {
    return (
        <Space margin={["0 0 30px"]} tm={["0 0 25px"]} xsm={["0 0 10px"]}>
            <Flex.Container justify="space-between" align="center">
                <Space xxsm={["10px 0 0"]} stm={["0"]}>
                    <Flex.Item xl="40%" st="40%" s="100%" xxs="100%">
                        <P color="black">{label}</P>
                    </Flex.Item>
                </Space>

                {isDPD ? (
                    <FlexItem s="100%" xxs="100%">
                        <Input
                            backgroundColor="white"
                            shadow={false}
                            disabled={true}
                            style={{
                                paddingBottom: "7px",
                                paddingTop: "7px",
                                color: theme.color.green,
                                fontWeight: theme.fontWeight.m,
                            }}
                            {...rest}
                        />
                    </FlexItem>
                ) : (
                    <FlexItem s="100%" xxs="100%">
                        <Input
                            backgroundColor="white"
                            shadow={false}
                            disabled={true}
                            style={{
                                paddingBottom: "7px",
                                paddingTop: "7px",
                            }}
                            {...rest}
                        />
                    </FlexItem>
                )}
            </Flex.Container>
        </Space>
    );
};

const FlexItem = styled(Flex.Item)`
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        flex: 1;
    }
`;
