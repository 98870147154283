export const initialState = {
    user: null,
    isRegHelperScreenEnabled: true,
    error: null, // string || null
    loading: false, // true || false
    videoGuide: localStorage.getItem("videoGuide")
        ? JSON.parse(localStorage.getItem("videoGuide"))
        : null,
    requestCallback: false,
    quickUser: null,
    loginForm: false,
    signupForm: false,
    virtualData: null,
    redirectPath: null,
};
