import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    background-color: ${(props) => props.theme.color.grey200};
    border-radius: 10px;
    padding: 3rem 3rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    margin-bottom: 3rem;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        padding: 2rem 3rem;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 2rem 3rem;
    }
`;
const SurveyViewContainer = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

export default SurveyViewContainer;
