import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { ButtonAlt } from "../../../components/button/Button";
import { ErrorText } from "../../../components/input/Input";
import UploadImage, {
    PlaceHolderPreview,
} from "../../../components/input/UploadImage";
import Space from "../../../components/space/Space";
import { Flex } from "../../../components/styles/Elements.styles";
import { P } from "../../../components/styles/Typography.styles";
import {
    BOOLEAN_VALUES,
    IMAGES_PURPOSE,
    INSTALLATION_TYPE,
    MAX_NOTES_LENGTH,
    ROOF_TOP_TYPE,
} from "../../../constants/common";
import { handleValidateFiles } from "../../../helpers/fieldValidation";
import DetailsField from "../DetailsField";

const SurveySite = ({
    siteId,
    index,
    onChange,
    data,
    error,
    onUpload,
    disabled,
    onPreview,
    onRemove,
}) => {
    const [fileError, setFileError] = useState("");

    const handleOnChange = (e, name) => {
        let result = handleValidateFiles(e);
        if (typeof result === "string") {
            setFileError(result);
        } else {
            setFileError("");
            onUpload({ document: result, purpose: name, index, id: siteId });
        }
    };

    const showUploadImageIcon = () => {
        let north = true;
        let south = true;
        let east = true;
        let west = true;
        data.documents.forEach((item) => {
            if (item.purpose === IMAGES_PURPOSE.NORTH_IMG) {
                north = false;
            } else if (item.purpose === IMAGES_PURPOSE.SOUTH_IMG) {
                south = false;
            } else if (item.purpose === IMAGES_PURPOSE.EAST_IMG) {
                east = false;
            } else if (item.purpose === IMAGES_PURPOSE.WEST_IMG) {
                west = false;
            }
        });
        return (
            <>
                {!disabled && north && (
                    <UploadImage
                        onChange={(e) =>
                            handleOnChange(e, IMAGES_PURPOSE.NORTH_IMG)
                        }
                        name={"uploadSiteImagesNorth" + siteId}
                        bottomLabel="North"
                    />
                )}
                {!disabled && south && (
                    <UploadImage
                        onChange={(e) =>
                            handleOnChange(e, IMAGES_PURPOSE.SOUTH_IMG)
                        }
                        name={"uploadSiteImagesSouth" + siteId}
                        bottomLabel="South"
                    />
                )}
                {!disabled && east && (
                    <UploadImage
                        onChange={(e) =>
                            handleOnChange(e, IMAGES_PURPOSE.EAST_IMG)
                        }
                        name={"uploadSiteImagesEast" + siteId}
                        bottomLabel="East"
                    />
                )}
                {!disabled && west && (
                    <UploadImage
                        onChange={(e) =>
                            handleOnChange(e, IMAGES_PURPOSE.WEST_IMG)
                        }
                        name={"uploadSiteImagesWest" + siteId}
                        bottomLabel="West"
                    />
                )}
            </>
        );
    };

    return (
        <div>
            {onRemove && (
                <Flex.Container justify="flex-end">
                    <Space xxsp={["2px 1rem"]} xxsm={["0 0 2rem"]}>
                        <ButtonAlt
                            backgroundColor="error"
                            color="error"
                            size="s"
                            xxs="s"
                            st="s"
                            onClick={() => onRemove({ siteId, id: index + 1})}
                        >
                            Remove
                        </ButtonAlt>
                    </Space>
                </Flex.Container>
            )}
            <DetailsField
                label={"Installation Type"}
                onChange={(value) => onChange(index, "installationType", value)}
                isSelect
                data={INSTALLATION_TYPE}
                selected={
                    data?.installationType !== null
                        ? `${data?.installationType}`.replaceAll("_", " ")
                        : data?.installationType
                }
                name="installationType"
                errorMessage={
                    error && error?.installationType
                        ? error?.installationType
                        : ""
                }
                disabled={disabled}
            />
            {(`${data?.installationType}`.replaceAll("_", " ") ===
                INSTALLATION_TYPE[0] ||
                !data.installationType) && (
                <>
                    <DetailsField
                        label={"Roof Top Type"}
                        onChange={(value) =>
                            onChange(index, "roofTopType", value)
                        }
                        isSelect
                        data={ROOF_TOP_TYPE}
                        selected={
                            data?.roofTopType !== null
                                ? `${data?.roofTopType}`.replaceAll("_", " ")
                                : data?.roofTopType
                        }
                        placeholder="Select Roof Top Type"
                        name="roofTopType"
                        errorMessage={
                            error && error?.roofTopType
                                ? error?.roofTopType
                                : ""
                        }
                        disabled={disabled}
                    />
                    <DetailsField
                        label={"Height of the Building"}
                        onChange={(e) =>
                            onChange(index, "elevationHeight", e.target.value)
                        }
                        placeholder="Enter Height"
                        name="elevationHeight"
                        value={data?.elevationHeight || ""}
                        errorMessage={
                            error && error?.elevationHeight
                                ? error?.elevationHeight
                                : ""
                        }
                        disabled={disabled}
                    />
                    <DetailsField
                        label={"Solar Water is Present"}
                        onChange={(value) =>
                            onChange(index, "isSolarThermal", value)
                        }
                        isSelect
                        data={BOOLEAN_VALUES}
                        selected={
                            data?.isSolarThermal === true
                                ? BOOLEAN_VALUES[0]
                                : data?.isSolarThermal === false
                                ? BOOLEAN_VALUES[1]
                                : data?.isSolarThermal
                        }
                        placeholder="Select  Water is present or not"
                        name="isSolarThermal"
                        errorMessage={
                            error && error?.isSolarThermal
                                ? error?.isSolarThermal
                                : ""
                        }
                        disabled={disabled}
                    />
                </>
            )}

            <DetailsField
                label={"Shade Free Area (in SqFT)"}
                onChange={(e) =>
                    onChange(index, "shadeFreeArea", e.target.value)
                }
                value={data?.shadeFreeArea || ""}
                placeholder="Enter Shade Free Area"
                name="shadeFreeArea"
                errorMessage={
                    error && error?.shadeFreeArea ? error?.shadeFreeArea : ""
                }
                disabled={disabled}
            />

            <DetailsField
                label={"Elevated Structured Required"}
                onChange={(value) =>
                    onChange(index, "isElevatedStructure", value)
                }
                isSelect
                data={BOOLEAN_VALUES}
                selected={
                    data?.isElevatedStructure === true
                        ? BOOLEAN_VALUES[0]
                        : data?.isElevatedStructure === false
                        ? BOOLEAN_VALUES[1]
                        : data?.isElevatedStructure
                }
                placeholder="Select Elevated Structured"
                name="isElevatedStructure"
                errorMessage={
                    error && error?.isElevatedStructure
                        ? error?.isElevatedStructure
                        : ""
                }
                disabled={disabled}
            />
            <Space xxsm={["0 0 0.1 "]} tm={["0 0 1rem"]}>
                <P color="black">Site Images</P>
            </Space>

            <Flex.Container>
                {data?.documents.map((file) => (
                    <PlaceHolderPreview
                        key={file.id}
                        bottomLabel={file?.purpose}
                        id={file.id}
                        onPreview={onPreview}
                    />
                ))}
                {showUploadImageIcon()}

                {!disabled && data.documents.length >= 4 && (
                    <UploadImage
                        onChange={(e) =>
                            handleOnChange(e, IMAGES_PURPOSE.OTHER_SITE_IMG)
                        }
                        name={"uploadSiteImagesOthers" + siteId}
                        bottomLabel="Others"
                        component={FiPlus}
                    />
                )}
            </Flex.Container>

            {((error && error?.documents) || fileError) && (
                <ErrorText
                    style={{
                        display: "inline-block",
                    }}
                    xxs="xs"
                    st="xs"
                >
                    {error?.documents || fileError}
                </ErrorText>
            )}

            <DetailsField
                label={"Survey Notes"}
                onChange={(e) =>
                    onChange(
                        index,
                        "comment",
                        e.target.value.slice(0, MAX_NOTES_LENGTH)
                    )
                }
                value={data?.comment || ""}
                componentType="textArea"
                placeholder="Comments"
                name="comment"
                disabled={disabled}
                maxLength={disabled ? null : MAX_NOTES_LENGTH}
            />
        </div>
    );
};

export default SurveySite;
