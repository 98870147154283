import { motion } from "framer-motion";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { slideRight } from "../../components/animation/landingAnimation";
import Space from "../../components/space/Space";
import Container from "../../components/styles/Container.styles";
import { Flex, Section } from "../../components/styles/Elements.styles";
import { H2, P } from "../../components/styles/Typography.styles";
import Text from "../../components/text/Text";
import { articlesCard } from "../../constants/articlesData";
import { BLOGS } from "../../constants/routes";
import { ImgCard } from "../landing/PastProjects";

const ImgCardStyled = styled(ImgCard)`
    width: 100%;
    .card {
        min-height: 440px;
        margin-top: 0;
        margin-bottom: ${(props) => props.theme.sizes.xl5};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        width: 90%;
        .card {
            min-height: 480px;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        width: 340px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 290px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        width: 300px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        width: 340px;
    }
`;
const FlexContainer = styled(Flex.Container)`
    justify-content: center;

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        justify-content: space-evenly;
    }
`;

const Articles = () => {
    const history = useHistory();

    return (
        <Section height="auto" style={{ overflow: "hidden" }}>
            <Container>
                <Space xxsm={["0 0 3rem "]} lgm={["0 0 4rem "]}>
                    <H2
                        as={motion.h2}
                        variants={slideRight}
                        initial="hidden"
                        whileInView="visible"
                    >
                        Our Articles
                    </H2>
                </Space>

                <FlexContainer>
                    {articlesCard.map((item) => (
                        <ProjectCard
                            key={item.id}
                            {...item}
                            onClick={() => history.push(`${BLOGS}/${item.id}`)}
                        />
                    ))}
                </FlexContainer>
            </Container>
        </Section>
    );
};

const ProjectCard = (props) => {
    const { tag, date, content, onClick, img, title } = props;
    return (
        <ImgCardStyled onClick={onClick}>
            <div
                className="card"
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div className="overly">
                    <img src={img} alt={title} />
                </div>

                <Space xxsp={[".8rem 1rem 2rem"]}>
                    <div
                        style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            justify: "space-between",
                        }}
                    >
                        <div>
                            <Flex.Container
                                align="center"
                                justify="space-between"
                            >
                                <P xxs="s" st="s" lg="m" color="white">
                                    {tag}
                                </P>
                                <Text size="s" color="white">
                                    {date}
                                </Text>
                            </Flex.Container>
                            <Space xxsm={["1rem 0"]}>
                                <P weight="b" color="white">
                                    {props.title}
                                </P>
                            </Space>

                            <P color="white">{content}</P>
                        </div>
                        <P size="s" color="white">
                            Read more..
                        </P>
                    </div>
                </Space>
            </div>
        </ImgCardStyled>
    );
};

export default Articles;
