import React, { useState } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import theme from "../../theme";
import Icon from "../Icon/Icon";
import Button from "../button/Button";
import { P } from "../styles/Typography.styles";
import { LinkDiv, ProfileDrop } from "./Header";

const DropdownWrapper = styled.div`
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    position: relative;
    cursor: pointer;
`;

const PortalDropdown = ({ links, isChanging, title }) => {
    const { pathname } = useLocation();
    const firstEndpoint = pathname.split("/");
    const matchingItems = links.filter(
        (link) => link.path === `/${firstEndpoint[1]}`
    );
    const [profileDropOpen, setDrop] = useState(false);
    const [active, setActive] = useState(
        matchingItems.length > 0
            ? isChanging
                ? matchingItems[0]
                : title
            : links[0]
    );
    const history = useHistory();

    const handleDrop = () => {
        setDrop((profileDropOpen) => !profileDropOpen);
    };

    const itemOnClick = (link) => {
        history.push(link.path);
        setDrop((profileDropOpen) => !profileDropOpen);
        isChanging && setActive(link);
    };
    return (
        <div>
            <DropdownWrapper>
                <Button
                    size="s"
                    xxs="xs"
                    st="s"
                    backgroundColor={"white"}
                    color="primary"
                    border="none"
                    onClick={handleDrop}
                >
                    <P color={"primary"}>{active.name}</P>
                    {profileDropOpen ? (
                        <Icon
                            component={MdArrowDropUp}
                            xxs="m"
                            size={"4rem"}
                            color="primary"
                        />
                    ) : (
                        <Icon
                            component={MdArrowDropDown}
                            xlg="4rem"
                            size={"4rem"}
                            color="primary"
                        />
                    )}
                </Button>
                <ProfileDrop
                    style={{ top: "50px" }}
                    id="profiledrop"
                    profileDropOpen={profileDropOpen}
                >
                    <LinkDiv>
                        {links.map((link) => (
                            <li
                                to={link.path}
                                isInteractive={true}
                                style={{
                                    marginBottom: "10px",
                                    fontSize: theme.fontSize.default.m,
                                    backgroundColor:
                                        active.name == link.name
                                            ? theme.color.grey100
                                            : "",
                                    color: theme.color.darkSecondary,
                                }}
                                key={link.name}
                                onClick={() => itemOnClick(link)}
                            >
                                {link.name}
                            </li>
                        ))}
                    </LinkDiv>
                </ProfileDrop>
            </DropdownWrapper>
        </div>
    );
};

export default PortalDropdown;
