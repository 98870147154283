import React, { useEffect, useRef, useState } from "react";

export default function withClickOutside(WrappedComponent) {
    const Component = (props) => {
        const [isSelected, setIsSelected] = useState(false);

        const ref = useRef();

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (!ref?.current?.contains(event.target)) {
                    setIsSelected(false);
                }
            };
            document.addEventListener("mousedown", handleClickOutside);

            return () =>
                document.removeEventListener("mousedown", handleClickOutside);
        }, [ref]);

        return (
            <WrappedComponent
                {...props}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                ref={ref}
            />
        );
    };

    return Component;
}
