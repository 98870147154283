import React from "react";
import DotLoader from "react-spinners/DotLoader";
import styled from "styled-components";
import theme from "../../theme";

const LoadingSpinner = ({ color = "primary", size = 120 }) => {
    return (
        <LoaderContainer>
            <DotLoader
                color={theme.color[color]}
                loading={true}
                size={size}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </LoaderContainer>
    );
};

export default LoadingSpinner;

const LoaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
`;
