import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = () => {
    return (
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            // pauseOnFocusLoss
            // draggable
            // pauseOnHover
            style={{ marginTop: "60px" }}
            limit={1}
        />
    );
};

export default Toast;
