import React from "react";
import { Route, Switch } from "react-router-dom";
import { PRICING_LABELS } from "../../constants/routes";
import FeatureSection from "../home/Sections/FeatureSection";
import Commercial from "./commercial";
import IndividualHomes from "./individual-homes";
import Industrial from "./industrial";
import PricingOverView from "./overview";
import ResidentialSociety from "./residential-society";
import PricingHeroAndContent from "./sections/PricingHeroAndContent";

const Pricing = () => {
    return (
        <div>
            <PricingHeroAndContent />
            <FeatureSection />
            <Switch>
                <Route
                    path={PRICING_LABELS.OVERVIEW}
                    component={PricingOverView}
                    exact={true}
                />
                <Route
                    path={PRICING_LABELS.INDIVIDUAL_HOMES}
                    component={IndividualHomes}
                    exact={true}
                />
                <Route
                    path={PRICING_LABELS.RESIDENTIAL_SOCIETIES}
                    component={ResidentialSociety}
                    exact={true}
                />
                <Route
                    path={PRICING_LABELS.COMMERCIAL}
                    component={Commercial}
                    exact={true}
                />
                <Route
                    path={PRICING_LABELS.INDUSTRIAL}
                    component={Industrial}
                    exact={true}
                />
            </Switch>
        </div>
    );
};

export default Pricing;
