import PropTypes from "prop-types";
import React from "react";
import cn from "../../lib/cn";
const baseStyles =
    "text-primary font-medium text-base sm:text-4xl 2xl:text-4xl 2xl:text-2xl";

const H4 = ({ className = "", children }) => {
    return <h4 className={cn(baseStyles, className)}>{children}</h4>;
};

export default H4;

H4.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
