import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import Button from "../button/Button";
import Text from "../text/Text";
const AlertWrapper = styled.div`
    background: ${(props) => props.theme.color[props.backgroundColor]};
    margin: ${(props) => props.margin || "20px auto"};
    padding: 10px;
    width: ${(props) => props.width || "100%"};
    border-radius: 5px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    display: ${(props) => (props.close ? "none" : "flex")};
    flex-wrap: nowrap;
    opacity: 0.83;
    transition: all ease-in 0.5s;
`;

const CloseBtn = styled(Button)`
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    :hover,
    :focus {
        box-shadow: none;
    }
`;

const Alert = ({
    backgroundColor = "grey",
    margin = "",
    message = "Something went wrong!",
    width,
}) => {
    const [close, setClose] = useState(false);
    return (
        <AlertWrapper
            margin={margin}
            close={close}
            backgroundColor={backgroundColor}
            width={width}
        >
            <Text color="white">{message}</Text>
            <CloseBtn
                onClick={() => setClose(true)}
                style={{ marginLeft: "10px" }}
                color="white"
            >
                <FontAwesomeIcon icon={faTimes} />
            </CloseBtn>
        </AlertWrapper>
    );
};

export default Alert;
