import React from "react";

import cn from "../../lib/cn";
import Section from "../../shared/Section";
const Hero = ({ content, url, align, type, wrapperClass, containerClass }) => {
    return (
        <Section className={containerClass}>
            <div
                className={cn(
                    "bg-white relative h-[593px] sm:h-[993px]  xl:h-screen",
                    wrapperClass
                )}
            >
                {type === "video" ? (
                    <video
                        src={url}
                        className="absolute inset-y-0 inset-x-0 isolate h-full bg-no-repeat w-full object-cover"
                        autoPlay
                        loop
                        muted
                    ></video>
                ) : (
                    <img
                        className="h-full object-cover  w-full absolute inset-y-0 inset-x-0 isolate  bg-no-repeat"
                        src={url}
                        alt={"hero"}
                    />
                )}
                <div
                    className={`absolute inset-x-0 inset-y-0 h-full flex ${
                        align ? align : "items-center"
                    } isolate bg-primary/40`}
                >
                    {content}
                </div>
            </div>
        </Section>
    );
};

export default Hero;
