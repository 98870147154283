import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Input from "../../../components/input/Input";
import Space from "../../../components/space/Space";
import Container from "../../../components/styles/Container.styles";
import { Flex, Section } from "../../../components/styles/Elements.styles";
import { P } from "../../../components/styles/Typography.styles";
import FilterHeader from "./FilterHeader";

import { useJsApiLoader } from "@react-google-maps/api";
import { setKey } from "react-geocode";

import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import styled from "styled-components";
import { DropWrapper } from "../../../components/dropdown/DropDown";
import { ERROR_MSG } from "../../../constants/common";
import {
    GET_PLANT_BY_ID_ENDPOINT,
    GOOGLE_MAP_API_KEY,
} from "../../../constants/routes";
import request from "../../../services/request";
import { PropertyBtn, PropertyContainer } from "./PropertyElements";
import PropertyLocation from "./PropertyLocation";

const Wrapper = styled.div`
    ${DropWrapper} {
        height: 30px;
        min-height: 30px;
        box-sizing: border-box;
        ${P} {
            font-size: ${(props) => props.theme.fontSize.default.m};
        }
        padding: 2px 2rem 2px;
    }
`;

setKey(GOOGLE_MAP_API_KEY);
const placesLibrary = ["places"];

const DOCUMENTS_TYPE = [
    "All Project Docs",
    "Electricity Bills",
    "Site Images",
    "MOU Agreement",
    "PPA Agreement",
    "Lease Agreement",
];

const Editable = styled.div`
    .right-icon {
        background-color: ${(props) => props.theme.color.primary};
        color: ${(props) => props.theme.color.white};
    }
`;

const PropertyDetails = () => {
    const history = useHistory();
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
        libraries: placesLibrary,
    });

    const { propertyId } = useParams();
    const [formData, setFormData] = useState({});
    const [propertyName, setPropertyName] = useState("");
    const [enableEdit, setEnableEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const endpoint = `${GET_PLANT_BY_ID_ENDPOINT}/${propertyId}`;
            const data = await request.authGet({ endpoint });
            setFormData(data?.data?.plant);
            setPropertyName(data?.data?.plant?.propertyName);
        };
        fetchData().catch((err) => {
            toast.error(typeof err === "string" ? err : ERROR_MSG);
        });
    }, []);

    const goToSurveyPage = (e) => {
        e.preventDefault();
        history.push(`/survey/${propertyId}`);
    };
    const [nameError, setNameError] = useState("");

    const updatePropertyNameHandler = async () => {
        if (propertyName === formData["propertyName"]) {
            setNameError("Property name can't be same");
        } else {
            try {
                setLoading(true);

                let res = await request.authPost({
                    endpoint: `${GET_PLANT_BY_ID_ENDPOINT}/${propertyId}?plantName=${propertyName}`,
                });
                if (res?.data?.Plant?.propertyName) {
                    setFormData((prev) => ({
                        ...prev,
                        propertyName: res?.data?.Plant?.propertyName,
                    }));
                }
                setLoading(false);
                setEnableEdit(false);
                setNameError("");
                toast.success(
                    typeof res?.message === "string"
                        ? res?.message
                        : "Plant name has been updated"
                );
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);
                setLoading(false);
                setEnableEdit(false);
                setNameError("");
            }
        }
    };
    return (
        <Section height="auto">
            <Container>
                <FilterHeader title={`Property Details: ${propertyId}`} />
                <PropertyContainer>
                    <div className="main">
                        <Flex.Container justify="space-between">
                            <Flex.Item xxs="100%" lg="60%">
                                <Editable>
                                    <FlexInput
                                        label="Property Name"
                                        value={propertyName}
                                        icon={faPencilAlt}
                                        disabled={!enableEdit}
                                        onIconClick={() => setEnableEdit(true)}
                                        iconSize="2x"
                                        onChange={(e) =>
                                            setPropertyName(e.target.value)
                                        }
                                        errorMessage={nameError}
                                    />
                                    {enableEdit && (
                                        <Space
                                            xxsm={["0 0 1rem"]}
                                            stm={["0 0 2rem"]}
                                        >
                                            <Flex.Container
                                                justify="flex-end"
                                                align="center"
                                            >
                                                <Flex.Container
                                                    xxs="100%"
                                                    lg="60%"
                                                >
                                                    <Space
                                                        xxsm={["0 1rem 0 0"]}
                                                    >
                                                        <PropertyBtn
                                                            onClick={
                                                                updatePropertyNameHandler
                                                            }
                                                            isLoading={loading}
                                                            disabled={loading}
                                                        >
                                                            Update
                                                        </PropertyBtn>
                                                    </Space>

                                                    <PropertyBtn
                                                        onClick={() => {
                                                            setEnableEdit(
                                                                false
                                                            );
                                                            setNameError("");
                                                            setPropertyName(
                                                                formData[
                                                                    "propertyName"
                                                                ]
                                                            );
                                                        }}
                                                        backgroundColor="grey600"
                                                        isLoading={loading}
                                                        disabled={loading}
                                                    >
                                                        Cancel
                                                    </PropertyBtn>
                                                </Flex.Container>
                                            </Flex.Container>
                                        </Space>
                                    )}
                                </Editable>

                                <FlexInput
                                    label="Monthly Electricity Bill"
                                    value={formData["monthlyElectricBill"]}
                                />
                                <FlexInput
                                    label="Site Address"
                                    type="textarea"
                                    value={formData["siteAddress"]}
                                    rows="4"
                                />
                                <FlexInput
                                    label="Project Status"
                                    value={formData["projectStatus"]}
                                />
                            </Flex.Item>
                            <Flex.Item xxs="100%" lg="35%">
                                {isLoaded && formData && (
                                    <PropertyLocation formData={formData} />
                                )}
                            </Flex.Item>
                        </Flex.Container>
                    </div>
                    <div className="footer">
                        <Flex.Container justify="space-between">
                            <Space xxsm={["0 0 1.5rem"]} stm={["0"]}>
                                <Flex.Item xxs="45%" st="auto">
                                    <PropertyBtn
                                        onClick={goToSurveyPage}
                                        fullWidth
                                    >
                                        Survey
                                    </PropertyBtn>
                                </Flex.Item>
                            </Space>
                            <Space xxsm={["0 0 1.5rem"]} stm={["0"]}>
                                <Flex.Item xxs="45%" st="auto">
                                    <PropertyBtn
                                        onClick={() =>
                                            history.push(
                                                `/${propertyId}/generate`
                                            )
                                        }
                                        fullWidth
                                    >
                                        Proposal
                                    </PropertyBtn>
                                </Flex.Item>
                            </Space>
                        </Flex.Container>
                    </div>
                </PropertyContainer>
            </Container>
        </Section>
    );
};

export default PropertyDetails;

const FlexInput = ({
    label,
    type = "input",
    value,
    disabled = true,
    ...rest
}) => {
    return (
        <Space xxsm={["0 0 1rem"]} stm={["0 0 2rem"]}>
            <Flex.Container justify="space-between" align="center">
                <Flex.Item xxs="100%" lg="35%">
                    <P>{label}</P>
                </Flex.Item>
                <Flex.Item xxs="100%" lg="60%">
                    <Input
                        componentType={type}
                        disabled={disabled}
                        value={value}
                        {...rest}
                    ></Input>
                </Flex.Item>
            </Flex.Container>
        </Space>
    );
};
