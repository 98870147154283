import { CheckboxInput } from "../../../../components/checkbox/Checkbox";
import { AlertMessage, ErrorText } from "../../../../components/input/Input";

import RadioInput from "../../../../components/radio/RadioInput";
import Space from "../../../../components/space/Space";
import { Flex } from "../../../../components/styles/Elements.styles";
import { P } from "../../../../components/styles/Typography.styles";
const BOOLEAN_VALUES = {
    yes: "Yes",
    no: "No",
};
export const ProRadioInput = ({
    type,
    title,
    onChange,
    value,
    direction,
    align,
    data,
    selected,
    disable,
}) => {
    return (
        <Flex.Container align={align ? align : "center"} direction={direction}>
            <Space xxsm={["10px 0 0"]} stm={["10px 0"]}>
                <Flex.Item
                    xl={direction === "column" ? "65%" : "45%"}
                    st="100%"
                    xxs="100%"
                >
                    <P>{title}</P>
                </Flex.Item>
            </Space>
            <Space tm={[".1rem 0 0 "]}>
                <Flex.Item flex="1">
                    <Flex.Container align="center" justify="space-between">
                        <Space xxsm={["0 2rem 0 0"]}>
                            <Flex.Item xxs="45%" st="45%" lg="5%">
                                <RadioInput
                                    checked={data[0] === selected}
                                    label={data[0]}
                                    id={`${type}_yes`}
                                    name={type}
                                    color="black800"
                                    backgroundColor="black"
                                    activeColor="lightGreen"
                                    value={data[0]}
                                    onChange={() => onChange(data[0])}
                                    disabled={disable}
                                />
                            </Flex.Item>
                        </Space>
                        <Flex.Item xxs="45%" st="45%" lg="45%">
                            <RadioInput
                                checked={data[1] === selected}
                                label={data[1]}
                                id={`${type}_no`}
                                name={type}
                                color="black800"
                                activeColor="lightGreen"
                                backgroundColor="black"
                                value={data[1]}
                                onChange={() => onChange(data[1])}
                                disabled={disable}
                            />
                        </Flex.Item>
                    </Flex.Container>
                </Flex.Item>
            </Space>
        </Flex.Container>
    );
};

export const ProCheckBox = ({
    title,
    selected,
    connectionType,
    direction,
    align,
    disable,
    arr,
    errorMessage = "",
    errorType = "",
    ...rest
}) => {
    return (
        <Flex.Container align={align ? align : "center"} direction={direction}>
            <Space xxsm={["10px 0 0"]} stm={["10px 0"]}>
                <Flex.Item xl="45%" st="100%" xxs="100%">
                    <P>{title}</P>
                </Flex.Item>
            </Space>
            <Space tm={[".1rem 0 0 "]}>
                <Flex.Item flex="1">
                    <Flex.Container alignItems="center" justify="space-between">
                        {arr.map((option) => (
                            <Flex.Item key={option}>
                                <CheckboxInput
                                    value={option}
                                    label={option}
                                    className={
                                        selected[option] ? "checked" : ""
                                    }
                                    backgroundColor={"black"}
                                    tickColor={"cyan500"}
                                    disabled={disable}
                                    {...rest}
                                />
                            </Flex.Item>
                        ))}
                    </Flex.Container>

                    {errorMessage && errorType === "alert" ? (
                        <AlertMessage xxs="xs" st="xs">
                            {errorMessage}
                        </AlertMessage>
                    ) : errorMessage ? (
                        <ErrorText xxs="xs" st="xs">
                            {errorMessage}
                        </ErrorText>
                    ) : null}
                </Flex.Item>
            </Space>
        </Flex.Container>
    );
};
