import {
    Autocomplete,
    DirectionsRenderer,
    GoogleMap,
    MarkerF,
} from "@react-google-maps/api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { setKey } from "react-geocode";
import styled from "styled-components";
import { InputComponent } from "../../../components/input/Input";
import Space from "../../../components/space/Space";
import { Flex } from "../../../components/styles/Elements.styles";
import { GOOGLE_MAP_API_KEY } from "../../../constants/routes";
import { PropertyBtn } from "./PropertyElements";

const AutoSuggestionInput = styled(InputComponent)`
    margin-bottom: 0px;
    min-height: 30px;
    box-sizing: border-box;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: ${(props) => props.theme.fontSize.default.s};
    border-radius: 0.2rem;
`;

const Button = styled(PropertyBtn)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

const MapContainer = styled.div`
.map {
    width: 100%;
    height: 220px;
}
input {
    font-size: ${(props) => props.theme.fontSize.default.s};
}
@media (min-width: ${(props) => props.theme.breakPoints.large}) {
    .map {
        width: 100%;
        height: 280px;
    }
}
   
`;

setKey(GOOGLE_MAP_API_KEY);

const PropertyLocation = ({ formData }) => {
    const [markerPointA, setMarkerPointA] = useState({
        lat: parseFloat(formData["latitude"]),
        lng: parseFloat(formData["longitude"]),
    });
    useEffect(() => {
        setMarkerPointA({
            lat: parseFloat(formData["latitude"]),
            lng: parseFloat(formData["longitude"]),
        });
    }, [formData,]);
    const [map, setMap] = useState(/** @type google.maps.Map */ (null));
    const [directionsResponse, setDirectionsResponse] = useState(null);

    const onLoad = useCallback((map) => {
        setMap(map);
    }, []);

    const onUnmount = useCallback((map) => {
        setMap(null);
    }, []);

    const sourceRef = useRef();
    return (
        <div>
            {markerPointA && (
                <MapContainer>
                    <GoogleMap
                        mapContainerClassName="map"
                        center={markerPointA}
                        zoom={15}
                        options={{
                            // zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: true,
                        }}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        <MarkerF draggable position={markerPointA}></MarkerF>
                        {directionsResponse && (
                            <DirectionsRenderer
                                directions={directionsResponse}
                            />
                        )}
                    </GoogleMap>
                </MapContainer>
            )}
           
        </div>
    );
};

export default PropertyLocation;
