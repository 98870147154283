import React from "react";
import styled from "styled-components";
import { Flex } from "../../components/styles/Elements.styles";
import { P } from "../../components/styles/Typography.styles";
export const OTPButton = styled(P)`
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;
const ResentOtp = ({ counter, onClick, color = "white" }) => {
    return (
        <Flex.Container justify="space-evenly" align="center">
            {counter === 0 ? (
                <>
                    <OTPButton
                        onClick={() => onClick("text")}
                        role="button"
                        xxs="s"
                        st="s"
                        color={color}
                        weight="b"
                    >
                        Get OTP Text
                    </OTPButton>
                    <OTPButton
                        color={color}
                        onClick={() => onClick("voice")}
                        role="button"
                        weight="b"
                        xxs="s"
                        st="s"
                    >
                        Get OTP on Call
                    </OTPButton>
                </>
            ) : (
                <P weight="b" color={color} xxs="s" st="s">
                    Resend OTP in {counter}s
                </P>
            )}
        </Flex.Container>
    );
};

export default ResentOtp;
