import React from "react";
import { background } from "../../resources/js/images";
import Hero from "../contactUs/Hero";
import HeroDetails from "./heroDetails";

const Login = () => {
    return (
        <div>
            <Hero
                content={<HeroDetails />}
                url={background}
                wrapperClass={"min-h-[70vh]"}
                containerClass={" mb-0 sm:mb-0 xl:mb-0 2xl:mb-0 lg:mb-0"}
            />
        </div>
    );
};

export default Login;
