import React, { useState } from "react";
import { emailRegEx, phoneRegEx } from "../../constants/regularExpression";
import { FORGET_PASSWORD_ENDPOINT } from "../../constants/routes";
import request from "../../services/request";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import TextField from "../../shared/ui/Form/TextField";

const ForgotPasswordForm = () => {
    const [formData, setFormData] = useState({
        userId: "",
    });
    const [emailSent, setEmailSent] = useState("");
    const [error, setError] = useState("");
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setFormError({ ...formError, [e.target.name]: "" });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.userId.trim()) {
            newErrors.userId = "Email/Phone is required";
        } else if (
            !phoneRegEx.test(formData.userId) &&
            !emailRegEx.test(formData.userId)
        ) {
            newErrors.userId = "Email/Phone is invalid";
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setFormError(validationErrors);
        } else {
            try {
                setFormError({});
                setEmailSent("");
                const endpoint = FORGET_PASSWORD_ENDPOINT;
                const credentials = phoneRegEx.test(formData.userId)
                    ? `91${formData.userId}`
                    : formData.userId;

                const body = {
                    credentials,
                };

                setLoading(true);
                let res = await request.put({ endpoint, body });
                setEmailSent(res?.message || "Email has been sent");
                setLoading(false);
                setError("");
            } catch (error) {
                setError(error);
                setLoading(false);
                setEmailSent("");
            }
        }
    };

    return (
        <div className="mt-3">
            <form onSubmit={handleSubmit}>
                <TextField
                    label={"Email/Phone Number"}
                    value={formData.userId}
                    onChange={handleChange}
                    name={"userId"}
                    type={"text"}
                    errorMsg={formError.userId}
                />
                <Button
                    type="submit"
                    disabled={loading}
                    isLoading={loading}
                    className="w-full flex justify-center px-4 py-2 mt-4"
                >
                    Request Password Reset
                </Button>
                {emailSent && <P className="text-green mt-1">{emailSent}</P>}
                {error && <P className="text-red mt-1">{error}</P>}
            </form>
        </div>
    );
};

export default ForgotPasswordForm;
