import React from "react";
import { featureImg1, featureImg2 } from "../../resources/js/icons";
import { About2, About3 } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";
import ImageTextCard from "../../shared/ui/Cards/ImageTextCard";

const ThirdSection = () => {
    const Content = (
        <>
            <div className="flex flex-col gap-1.5 sm:gap-2 xl:gap-2.4 items-start">
                <img
                    className="h-4 sm:h-6.4 xl:h-5.6 2xl:h-8"
                    src={featureImg2}
                    alt={"Enviorment"}
                />
                <H3>Environmental Impact</H3>
            </div>
            <div className="flex flex-col gap-1.5 sm:gap-2 xl:gap-2.4">
                <P className={"text-secondary text-xs sm:text-lg 2xl:text-xl"}>
                    We are driven by a commitment to reducing carbon emissions
                    and combating climate change. By focusing on solar energy,
                    they offer a sustainable alternative to fossil fuels,
                    helping to decrease environmental degradation and promote
                    cleaner air and water.
                </P>
                <P className={"text-secondary text-xs sm:text-lg 2xl:text-xl"}>
                    Our work aligns with global efforts to transition to
                    renewable energy sources, reflecting their dedication to
                    environmental stewardship and sustainable development.
                </P>
            </div>
        </>
    );
    const Content2 = (
        <>
            <div className="flex flex-col gap-1.5 sm:gap-2 xl:gap-2.4 items-start">
                <img
                    className="h-4 sm:h-6.4 xl:h-5.6 2xl:h-8"
                    src={featureImg1}
                    alt={"Environment"}
                />
                <H3>Energy Independence</H3>
            </div>
            <div className="flex flex-col gap-1.5 sm:gap-2 xl:gap-2.4">
                <P className={"text-secondary text-xs sm:text-lg 2xl:text-xl"}>
                    We aim to enhance energy security and independence for
                    communities by reducing their reliance on imported fuels.
                </P>
                <P className={"text-secondary text-xs sm:text-lg 2xl:text-xl"}>
                    Solar energy, being abundant and accessible, allows for
                    local generation of power, which can stabilize energy prices
                    and reduce geopolitical dependencies. This commitment not
                    only supports local economies but also builds resilience
                    against economic and supply disruptions in the energy
                    markets.
                </P>
            </div>
        </>
    );
    return (
        <Section>
            <Container>
                <div className="flex flex-col gap-y-5.6  xl:gap-y-9.6 2xl:gap-y-15.5">
                    <ImageTextCard
                        content={Content}
                        url={About2}
                        width="w-full lg:w-[45%] "
                        col="flex-col-reverse"
                    />
                    <ImageTextCard
                        content={Content2}
                        url={About3}
                        wrapperClass="flex-wrap lg:flex-row-reverse"
                        row="flex-row-reverse "
                        width="w-full lg:w-[45%] "
                        col="flex-col-reverse"
                    />
                </div>
            </Container>
        </Section>
    );
};

export default ThirdSection;
