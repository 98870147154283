import React from "react";
import cn from "../../../lib/cn";

const ImageTextCards = ({
    content,
    url,
    wrapperClass = "flex-row flex-wrap",
    width = "lg:w-[65%] xl:w-[55%]",
    contentWidth = "w-full  lg:w-[40%]  xl:w-[35%] 2xl:w-[40%]",
}) => {
    return (
        <div
            className={cn(
                `flex justify-between gap-1.6  items-center `,
                wrapperClass
            )}
        >
            <div className={`${contentWidth} flex flex-col gap-1.6`}>
                {content}
            </div>
            <div className={`w-full ${width}`}>
                <img src={url} alt="solar_right" className="w-full" />
            </div>
        </div>
    );
};

export default ImageTextCards;
