import React from "react";
import styled from "styled-components";
import { P } from "../styles/Typography.styles";
const MessageText = styled(P)`
    padding: 10px 20px;
    border-radius: 3px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) => props.theme.fontSize.mobile.m};
    }
`;
const Message = styled(MessageText)`
    background: ${(props) => props.theme.color[props.backgroundColor]};
    border: 1px solid ${(props) => props.theme.color[props.borderColor]};
`;
const AlertNew = ({
    backgroundColor = "grey300",
    color = "black800",
    borderColor,
    xxs = "l",
    st = "l",
    children,
}) => {
    return (
        <Message
            xxs={xxs}
            st={st}
            align="center"
            color={color}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
        >
            {children}
        </Message>
    );
};

export default AlertNew;
