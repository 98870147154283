import React from "react";

const Tag = ({ text }) => {
    return (
        <div className={`inline-block    rounded-0.7  bg-gray-100 `}>
            <span className=" text-sm  xl:text-base 2xl:text-btn-s font-regular px-1.2 py-0.5  text-blue-100">
                {text}
            </span>
        </div>
    );
};

export default Tag;
