import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AlertNew from "../../../components/alert/AlertNew";
import Button from "../../../components/button/Button";
import ModalAlt from "../../../components/modal/ModalAlt";
import Space from "../../../components/space/Space";
import Container from "../../../components/styles/Container.styles";
import { Flex, Section } from "../../../components/styles/Elements.styles";
import { H4, P } from "../../../components/styles/Typography.styles";
import {
    BOOLEAN_VALUES,
    ERROR_MSG,
    INSTALLATION_TYPE,
    SURVEY_STATUS,
    validImageTypes,
} from "../../../constants/common";
import {
    AGENT_SITE_ENDPOINT,
    GET_SURVEY_SITE_API_ENDPOINT,
    UPLOAD_SITE_IMAGE_ENDPOINT,
    UPLOAD_SURVEY_IMAGE_ENDPOINT,
} from "../../../constants/routes";
import { validateSurveySite } from "../../../helpers/fieldValidation";
import useNetwork from "../../../hooks/useNetwork";
import { optimizeImageSize } from "../../../lib";
import request from "../../../services/request";
import OnboardingContainer from "../OnboardingContainer";
import WrapperSurveySite from "./WrapperSurveySite";

export const Preview = styled.div`
    width: 60px;
    height: 60px;
    margin-right: 2rem;
    margin-bottom: 1rem;
    position: relative;
    img {
        object-fit: cover;
        width: 100%;
        width: 60px;
        height: 60px;
    }
    button {
        padding: 2px;
        border: none;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 20px;
        outline: none;
        background-color: ${(props) => props.theme.color.white};
        :hover,
        :active,
        :focus {
            box-shadow: none;
            color: ${(props) => props.theme.color.error};
        }
        svg {
            font-size: 20px;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 70px;
        height: 70px;
        img {
            width: 70px;
            height: 70px;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        width: 80px;
        height: 80px;
        img {
            width: 80px;
            height: 80px;
        }
    }
`;

const AgentSurvey = () => {
    const { plantId } = useParams();
    const [getPlantId] = useState(plantId);
    const [siteList, setSiteList] = useState([]);
    const [lightsBills, setLightsBills] = useState([]);
    const [lightBillsError, setLightBillsError] = useState("");
    const [surveyStatus, setSurveyStatus] = useState("");
    const [removeSite, setRemoveSite] = useState(null);
    const [siteLoading, setSiteLoading] = useState(false);

    const {
        data,
        error: plantError,
        loading: plantLoading,
    } = useNetwork(`${GET_SURVEY_SITE_API_ENDPOINT}/${getPlantId}`);

    useEffect(() => {
        if (data && data?.data?.survey?.siteList) {
            setSiteList(data?.data?.survey?.siteList);
            setLightsBills(data?.data?.survey?.lightsBills);
            setSurveyStatus(data?.data?.survey?.surveyStatus);
        }
    }, [data]);

    const [error, setError] = useState("");
    const [uploadLoading, setUploadLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toUpload, setToUpload] = useState(null);
    const [siteUploadData, setSiteUploadData] = useState(null);

    const handleOnChange = useCallback(async (e) => {
        const selectedFile = e.target.files[0];
        const fileName = selectedFile.name;
        var extName = fileName
            .substring(fileName.lastIndexOf(".") + 1)
            .toUpperCase();
        if (validImageTypes.includes(extName)) {
            setToUpload(selectedFile);
            setError("");
        } else {
            setError(
                "Invalid File Types! Only supported types are JPEG, PNG, BMP, HEIF, GIF, and WebP"
            );
        }
    }, []);

    const addMoreSurvey = useCallback(async () => {
        if (plantId) {
            try {
                setLoading(true);
                const res = await request.authGet({
                    endpoint: `${AGENT_SITE_ENDPOINT}/${plantId}`,
                });
                setSiteList([...siteList, res?.data?.survey]);
                toast.success(res?.message || "New survey has been added");
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast.error(typeof err === "string" ? err : ERROR_MSG);
            }
        } else {
            toast.error("Plant Id is required");
        }
    }, [plantId, siteList]);

    const [formError, setFormError] = useState([]);

    const handleChangeSurveySite = (index, name, value) => {
        const newSiteLists = [...siteList];
        newSiteLists[index][name] = value;
        setSiteList(newSiteLists);
    };

    // @TODO ==>   upload survey files Wed Oct  11
    const handleUploadSurveyFiles = useCallback(
        async (e) => {
            e.preventDefault();
            setUploadLoading(true);
            const imageFile = await optimizeImageSize(toUpload);
            if (imageFile instanceof File) {
                try {
                    let formData = new FormData();
                    formData.append("document", imageFile);
                    formData.append("plantId", plantId);
                    formData.append("id", data?.data?.survey?.surveyId);

                    let res = await request.authPost({
                        body: formData,
                        endpoint: UPLOAD_SURVEY_IMAGE_ENDPOINT,
                        isFormData: true,
                    });

                    toast.success(res?.message || "Document has been uploaded");
                    setLightsBills([...lightsBills, res?.data?.document]);
                    setToUpload(null);
                    setUploadLoading(false);
                } catch (error) {
                    setUploadLoading(false);
                    toast.error(typeof error === "string" ? error : ERROR_MSG);
                }
            } else {
                setUploadLoading(false);
                toast.error("Something went wrong while compressing image");
            }
        },
        [data?.data?.survey?.surveyId, lightsBills, plantId, toUpload]
    );
    const handleUploadSiteFiles = useCallback(
        async (e) => {
            e.preventDefault();
            setUploadLoading(true);
            const imageFile = await optimizeImageSize(siteUploadData.document);
            if (imageFile instanceof File) {
                try {
                    let formData = new FormData();
                    formData.append("document", imageFile);
                    formData.append("plantId", plantId);
                    formData.append("id", siteUploadData.id);
                    formData.append("purpose", siteUploadData.purpose);

                    let res = await request.authPost({
                        body: formData,
                        endpoint: UPLOAD_SITE_IMAGE_ENDPOINT,
                        isFormData: true,
                    });
                    toast.success(res?.message || "Document has been uploaded");
                    const newSiteList = [...siteList];
                    newSiteList[siteUploadData.index].documents = [
                        ...newSiteList[siteUploadData.index].documents,
                        res?.data?.document,
                    ];
                    setSiteList(newSiteList);
                    // setLightsBills([...lightsBills, res?.data?.document]);
                    setSiteUploadData(null);
                    setUploadLoading(false);
                } catch (error) {
                    setUploadLoading(false);
                    toast.error(typeof error === "string" ? error : ERROR_MSG);
                }
            } else {
                setUploadLoading(false);
                toast.error("Something went wrong while compressing image");
            }
        },
        [
            plantId,
            siteList,
            siteUploadData?.document,
            siteUploadData?.id,
            siteUploadData?.index,
            siteUploadData?.purpose,
        ]
    );

    // @TODO ==>   Handle Submit final  Wed Oct  11
    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            const getValidData = validateSurveySite(siteList);

            setFormError(getValidData);

            if (lightsBills.length === 0) {
                setLightBillsError("Light bills images are required");
            } else if (
                Object.keys(getValidData).length === 0 &&
                lightsBills.length !== 0
            ) {
                setLightBillsError("");
                // upload
                let uploadData = [];
                siteList.forEach((site) => {
                    let newSites = {};
                    newSites.siteId = site?.siteId;
                    if (site.installationType === INSTALLATION_TYPE[0]) {
                        newSites.installationType = site.installationType
                            .trim()
                            .replaceAll(" ", "_");
                        newSites.roofTopType = site.roofTopType
                            .trim()
                            .replaceAll(" ", "_");
                        newSites.elevationHeight = site.elevationHeight;
                        newSites.shadeFreeArea = site.shadeFreeArea;
                        newSites.isSolarThermal =
                            typeof site?.isSolarThermal === "boolean"
                                ? site?.isSolarThermal
                                : site.isSolarThermal === BOOLEAN_VALUES[0]
                                ? true
                                : false;
                        newSites.isElevatedStructure =
                            typeof site?.isElevatedStructure === "boolean"
                                ? site?.isElevatedStructure
                                : site.isElevatedStructure === BOOLEAN_VALUES[0]
                                ? true
                                : false;
                        newSites.comment = site.comment;
                    } else {
                        newSites.installationType = site.installationType
                            .trim()
                            .replaceAll(" ", "_");
                        newSites.roofTopType = null;
                        newSites.elevationHeight = null;
                        newSites.shadeFreeArea = site.shadeFreeArea;
                        newSites.isSolarThermal = null;
                        newSites.isElevatedStructure =
                            typeof site?.isElevatedStructure === "boolean"
                                ? site?.isElevatedStructure
                                : site.isElevatedStructure === BOOLEAN_VALUES[0]
                                ? true
                                : false;
                        newSites.comment = site.comment;
                    }
                    uploadData.push(newSites);
                });

                let body = {
                    plantId: plantId,
                    site: [...uploadData],
                };

                setLoading(true);
                try {
                    await request.authPut({
                        endpoint: AGENT_SITE_ENDPOINT,
                        body,
                    });

                    toast.success("Updated successfully");
                    setSurveyStatus(SURVEY_STATUS.SURVEY_FILLED);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    toast.error(typeof error === "string" ? error : ERROR_MSG);
                }
            }
        },
        [lightsBills.length, plantId, siteList]
    );

    // @TODO ==>   Handle Remove a Site Fri Mar  22
    const handleRemoveSite = useCallback(
        async (e) => {
            e.preventDefault();
            if (plantId) {
                try {
                    setSiteLoading(true);
                    const res = await request.authDelete({
                        endpoint: `${AGENT_SITE_ENDPOINT}/${plantId}`,
                        body: { siteId: removeSite?.siteId },
                    });
                    let updatedSite = siteList.filter(
                        (site) => site.siteId !== res?.data?.survey?.siteId
                    );

                    setSiteList(updatedSite);
                    toast.success(res?.message || "Site has been removed");
                    setSiteLoading(false);
                    setRemoveSite(null);
                } catch (err) {
                    setSiteLoading(false);
                    toast.error(typeof err === "string" ? err : ERROR_MSG);
                }
            } else {
                toast.error("Plant Id is required");
            }
        },
        [plantId, removeSite?.siteId, siteList]
    );

    return (
        <>
            <Space
                xxsm={["5rem 0 3rem"]}
                stm={["6rem 0 4rem"]}
                lgm={["8rem  0 4rem"]}
            >
                <Section height="auto" style={{ overflow: "hidden" }}>
                    <Container>
                        {plantLoading ? (
                            <Section height="80vh"></Section>
                        ) : plantError ? (
                            <Flex.Container
                                justify="center"
                                style={{ minHeight: "30vh" }}
                            >
                                <Flex.Item xxs="100%" st="60%" xl="50%">
                                    <AlertNew
                                        backgroundColor="red200"
                                        borderColor="error"
                                        color="error"
                                    >
                                        {plantError}
                                    </AlertNew>
                                </Flex.Item>
                            </Flex.Container>
                        ) : data !== null ? (
                            <OnboardingContainer>
                                {data?.data?.survey?.surveyStatus ? (
                                    <>
                                        <WrapperSurveySite
                                            error={error}
                                            formError={formError}
                                            onChangeSurveySite={
                                                handleChangeSurveySite
                                            }
                                            onChange={handleOnChange}
                                            lightBillsError={lightBillsError}
                                            lightsBills={lightsBills}
                                            setSiteUploadData={
                                                setSiteUploadData
                                            }
                                            siteList={siteList}
                                            disabled={
                                                surveyStatus ===
                                                SURVEY_STATUS.SURVEY_FILLED
                                            }
                                            onRemove={setRemoveSite}
                                        />

                                        <Flex.Container justify="space-between">
                                            <Space
                                                xxsm={["1rem 0"]}
                                                stm={["0 0 0"]}
                                            >
                                                <Flex.Item
                                                    xxs="100%"
                                                    st="45%"
                                                    t="40%"
                                                >
                                                    <Button
                                                        onClick={addMoreSurvey}
                                                        fullWidth
                                                        isLoading={loading}
                                                        disabled={
                                                            loading ||
                                                            surveyStatus ===
                                                                SURVEY_STATUS.SURVEY_FILLED
                                                        }
                                                        backgroundColor="cyan500"
                                                    >
                                                        Add More Survey
                                                    </Button>
                                                </Flex.Item>
                                            </Space>

                                            <Flex.Item
                                                xxs="100%"
                                                st="45%"
                                                t="40%"
                                            >
                                                <Button
                                                    fullWidth
                                                    backgroundColor="primary"
                                                    onClick={handleSubmit}
                                                    disabled={
                                                        loading ||
                                                        surveyStatus ===
                                                            SURVEY_STATUS.SURVEY_FILLED
                                                    }
                                                    isLoading={loading}
                                                >
                                                    Submit
                                                </Button>
                                            </Flex.Item>
                                        </Flex.Container>
                                    </>
                                ) : (
                                    <div style={{ margin: "3rem 0" }}>
                                        <AlertNew
                                            backgroundColor="red200"
                                            borderColor="error"
                                            color="error"
                                        >
                                            Feasibility booking is required.
                                            Please come back later!
                                        </AlertNew>
                                    </div>
                                )}
                            </OnboardingContainer>
                        ) : null}
                    </Container>
                </Section>
            </Space>
            <ModalAlt
                isOpen={toUpload || siteUploadData}
                innerTimes={false}
                onClose={() =>
                    toUpload ? setToUpload(null) : setSiteUploadData(null)
                }
                width={["100%", "600px", "600px"]}
                overflowx="hidden"
                overflowy="auto"
                xxsp="0 1rem 2rem "
                xsp="0 1.8rem 2rem "
                stp="0  2rem 2.5rem"
                xlp="0 2rem 2.5rem "
            >
                <FloatingContainer justify="center">
                    <Flex.Item xxs="100%" xs="100%" st="100%">
                        <Space xxsm={["0 0 .5rem"]} stm={["0 0 1rem"]}>
                            <H4 align="center">Are you sure to upload?</H4>
                        </Space>
                    </Flex.Item>
                    <Space
                        xxsm={["0 0 1rem"]}
                        xsm={["0 2rem 0 0"]}
                        stm={["0 3rem 0 0"]}
                    >
                        <Flex.Item xxs="100%" xs="40%" st="20%">
                            <Button
                                fullWidth
                                size="s"
                                backgroundColor="green"
                                color="white"
                                onClick={
                                    toUpload
                                        ? handleUploadSurveyFiles
                                        : handleUploadSiteFiles
                                }
                                disabled={uploadLoading}
                                isLoading={uploadLoading}
                            >
                                Yes
                            </Button>
                        </Flex.Item>
                    </Space>
                    <Flex.Item xxs="100%" xs="40%" st="20%">
                        <Button
                            fullWidth
                            size="s"
                            backgroundColor="lightGrey"
                            color="black"
                            onClick={() => {
                                toUpload
                                    ? setToUpload(null)
                                    : setSiteUploadData(null);
                            }}
                        >
                            No
                        </Button>
                    </Flex.Item>
                </FloatingContainer>

                <div style={{ padding: ".5rem" }}>
                    <Flex.Container
                        direction="column"
                        align="center"
                        justify="center"
                    >
                        <Flex.Item xxs="100%" st="100%" xl="100%">
                            <div>
                                {toUpload && (
                                    <img
                                        style={{ width: "auto" }}
                                        alt="uploadImg"
                                        src={URL.createObjectURL(toUpload)}
                                    />
                                )}
                                {siteUploadData && (
                                    <img
                                        style={{ width: "auto" }}
                                        alt="siteUploadImg"
                                        src={URL.createObjectURL(
                                            siteUploadData.document
                                        )}
                                    />
                                )}
                            </div>
                        </Flex.Item>
                    </Flex.Container>
                </div>
            </ModalAlt>
            <ModalAlt
                isOpen={removeSite}
                innerTimes={false}
                onClose={() => setRemoveSite(null)}
                width={["100%", "600px", "600px"]}
                overflowx="hidden"
                overflowy="auto"
                xxsp="1rem 2rem "
                xsp="1.8rem 2rem "
                stp="2rem 2.5rem"
                xlp="2rem 2.5rem "
            >
                <Flex.Container justify="center">
                    <Flex.Item xxs="100%" xs="100%" st="100%">
                        <Space xxsm={["0 0 .5rem"]} stm={["0 0 1rem"]}>
                            <div>
                                <H4 align="center">
                                    Are you sure to remove site #id:
                                    {removeSite?.id}
                                </H4>
                            </div>
                        </Space>
                        <Space xxsm={["0 0 1rem"]} stm={["0 0 2rem"]}>
                            <P xxs="s" st="m" align="center">
                                Warning: There is no way to recover Site once
                                deleted
                            </P>
                        </Space>
                    </Flex.Item>
                    <Space
                        xxsm={["0 0 1rem"]}
                        xsm={["0 2rem 0 0"]}
                        stm={["0 3rem 0 0"]}
                    >
                        <Flex.Item xxs="100%" xs="40%" st="20%">
                            <Button
                                fullWidth
                                size="s"
                                backgroundColor="lightGrey"
                                color="black"
                                disabled={siteLoading}
                                onClick={() => {
                                    setRemoveSite(null);
                                }}
                            >
                                No
                            </Button>
                        </Flex.Item>
                    </Space>
                    <Flex.Item xxs="100%" xs="40%" st="20%">
                        <Button
                            fullWidth
                            size="s"
                            backgroundColor="green"
                            color="white"
                            onClick={handleRemoveSite}
                            disabled={siteLoading}
                            isLoading={siteLoading}
                        >
                            Yes
                        </Button>
                    </Flex.Item>
                </Flex.Container>
            </ModalAlt>
        </>
    );
};

export default AgentSurvey;

const FloatingContainer = styled(Flex.Container)`
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.color.white};
    padding: 1rem 0 0.5rem;
`;
