import React from "react";
import { contactUs } from "../../resources/js/images";

import Hero from "./Hero";
import HeroDetails from "./HeroDetails";
import { MapContactUs } from "./MapContact";
import MobileContact from "./MobileContact";

const ContactUS = () => {
    return (
        <div>
            <Hero
                url={contactUs}
                content={<HeroDetails />}
                wrapperClass={"min-h-[20vh] xl:min-h-[100vh]   "}
            />
            <MobileContact />
            <MapContactUs />
        </div>
    );
};

export default ContactUS;
