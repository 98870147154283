import React from "react";

import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";
import P from "../../shared/typography/P";
const OurVision = () => {
    return (
        <Section>
            <Container>
                <div
                    className={`flex justify-between gap-1.6 flex-col lg:flex-row  items-center   mb-3.2`}
                >
                    <div className=" w-full lg:w-[40%]">
                        <H2>Why we do it?</H2>
                    </div>

                    <P className={" lg:w-[50%] text-secondary"}>
                        Lorem ipsum dolor sit amet consectetur. Risus enim
                        nullam eget purus elementum mauris dictum. Sed in urna
                        odio et purus aliquam. Placerat.
                    </P>
                </div>
            </Container>
        </Section>
    );
};

export default OurVision;
