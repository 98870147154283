import React from "react";

const Tab = ({ text, active, onClick }) => {
    return (
        <div
            onClick={onClick}
            className={`inline-block  cursor-pointer border-0.5 border-primary rounded-full  transition-colors duration-300 py-0.6 px-1.2  xl:py-0.8 xl:px-1.6 ${
                active
                    ? "bg-gray-100 text-primary border-primary"
                    : "bg-white text-secondary border-secondary"
            }`}
        >
            <span className="text-xs sm:text-base  xl:text-base 2xl:text-btn-s font-regular text-nowrap">
                {text}
            </span>
        </div>
    );
};

export default Tab;
