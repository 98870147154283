import React from "react";
import terms from "../../assets/images/terms.png";
import Container from "../../shared/Container";
import H1 from "../../shared/typography/H1";
import Hero from "../home/Hero";
import Terms from "./terms";
const termContent = (
    <Container>
        <H1 className="text-white w-[80%] sm:w-[100%] lg:w-[80%] xl:w-[70%] 3xl:w-[60%]">
            Terms of Service
        </H1>
    </Container>
);
const TermsAndConditions = () => {
    return (
        <div>
            <Hero
                url={terms}
                content={termContent}
                wrapperClass=" h-[306px] sm:h-[306px] xl:h-[678px] mb-0"
            />
            <Terms />
        </div>
    );
};

export default TermsAndConditions;
