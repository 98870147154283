import React from "react";
import RadioInput from "../../../components/radio/RadioInput";
import Space from "../../../components/space/Space";
import { Flex } from "../../../components/styles/Elements.styles";
import { P } from "../../../components/styles/Typography.styles";
const BOOLEAN_VALUES = {
    yes: "Yes",
    no: "No",
};
const SidebarRadioInput = ({ type, title, onChange, value }) => {
    return (
        <>
            <Space
                xxsm={["0 0 1rem"]}
                stm={["0 0 1.2rem"]}
                lgm={["0 0 1.5rem"]}
            >
                <P>{title}</P>
            </Space>

            <Flex.Container alignItems="center">
                <Flex.Item xxs="45%" st="45%" lg="45%">
                    <RadioInput
                        checked={value === BOOLEAN_VALUES.yes}
                        label={"Yes"}
                        id={`${type}_yes`}
                        name={type}
                        color="black800"
                        activeColor="lightGreen"
                        value={BOOLEAN_VALUES.yes}
                        onChange={() => onChange(BOOLEAN_VALUES.yes)}
                    />
                </Flex.Item>

                <Flex.Item xxs="45%" st="45%" lg="45%">
                    <RadioInput
                        checked={value === BOOLEAN_VALUES.no}
                        label={BOOLEAN_VALUES.no}
                        id={`${type}_no`}
                        name={type}
                        color="black800"
                        activeColor="lightGreen"
                        value={BOOLEAN_VALUES.no}
                        onChange={() => onChange(BOOLEAN_VALUES.no)}
                    />
                </Flex.Item>
            </Flex.Container>
        </>
    );
};

export default SidebarRadioInput;
