import React from "react";
import { FaLink } from "react-icons/fa";
import Icon from "../../../components/Icon/Icon";
import Button from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import Link from "../../../components/link/Link";
import RadioInput from "../../../components/radio/RadioInput";
import Space from "../../../components/space/Space";
import HorizontalStepper from "../../../components/stepper/HorizontaStepper";
import Container from "../../../components/styles/Container.styles";
import { Flex, Section } from "../../../components/styles/Elements.styles";
import { H2, P } from "../../../components/styles/Typography.styles";
import Tables from "../../../components/table/Tables";
import { DASHBOARD } from "../../../constants/routes";

const cellStyles = {
    padding: "10px 0",
    borderLeft: "none",
    borderRight: "none",
};

const data = {
    large: [
        {
            index: 1,
            id: {
                value: null,
                component: () => (
                    <Link
                        color="primary"
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                        to={`${DASHBOARD}/${"SNN546"}`}
                    >
                        SNN546
                    </Link>
                ),
            },
            revisionDate: {
                value: "25/03/2024",
            },

            link: {
                value: "PR_56689",
            },
            revision: {
                value: "01",
            },
            status: {
                value: "Requested",
            },

            expandable: {
                component: () => (
                    <div style={{ padding: "0 40px" }}>
                        <HorizontalStepper
                            activeStep={2}
                            padding="50px 0px 20px"
                            data={steps}
                            completedSteps={2}
                        />
                        <Flex.Container justify="flex-end">
                            <Space xxsm={["0 1rem 0 0"]} stm={["0 2rem 0 0"]}>
                                <Button size="s" xxs="s" st="s">
                                    Audit
                                </Button>
                            </Space>

                            <Button size="s" xxs="s" st="s">
                                Proposal Details
                            </Button>
                        </Flex.Container>
                    </div>
                ),
            },
            viewForm: {
                component: () => <Icon component={FaLink} lg="l" />,
            },
        },

        {
            index: 2,
            id: {
                value: null,
                component: () => (
                    <Link
                        color="primary"
                        isInteractive={true}
                        style={{ display: "flex", justifyContent: "center" }}
                        to={`${DASHBOARD}/${"SNN245"}`}
                    >
                        SNN859
                    </Link>
                ),
            },
            revisionDate: {
                value: "05/04/2024",
            },

            link: {
                value: "PR_45859",
            },
            revision: {
                value: "03",
            },
            status: {
                value: "Requested",
            },

            expandable: {
                component: () => (
                    <div style={{ padding: "0 40px" }}>
                        <HorizontalStepper
                            activeStep={2}
                            padding="50px 0px 20px"
                            data={steps}
                            completedSteps={2}
                        />
                        <Flex.Container justify="flex-end">
                            <Space xxsm={["0 1rem 0 0"]} stm={["0 2rem 0 0"]}>
                                <Button size="s" xxs="s" st="s">
                                    Audit
                                </Button>
                            </Space>

                            <Button size="s" xxs="s" st="s">
                                Proposal Details
                            </Button>
                        </Flex.Container>
                    </div>
                ),
            },
            viewForm: {
                component: () => <Icon component={FaLink} lg="l" />,
            },
        },
    ],
    small: [
        {
            index: 1,
            id: {
                value: null,
                component: () => (
                    <>
                        <Link
                            color="primary"
                            isInteractive={true}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                            to={`${DASHBOARD}/${"SNN546"}`}
                        >
                            SNN546
                        </Link>
                    </>
                ),
            },
            revisionDate: {
                value: "25/03/2024",
            },
            link: {
                value: "PR_56689",
            },
            revision: {
                value: "01",
            },

            expandable: {
                component: () => (
                    <div style={{ padding: "0 10px" }}>
                        <HorizontalStepper
                            activeStep={2}
                            padding="50px 0px 20px"
                            data={steps}
                            completedSteps={2}
                        />
                        <Flex.Container justify="flex-end">
                            <Space xxsm={["0 1rem 0 0"]} stm={["0 2rem 0 0"]}>
                                <Button size="s" xxs="s" st="s">
                                    Audit
                                </Button>
                            </Space>

                            <Button size="s" xxs="s" st="s">
                                Proposal Details
                            </Button>
                        </Flex.Container>
                    </div>
                ),
            },
        },

        {
            index: 2,
            val: {
                value: null,
                component: () => (
                    <>
                        <Link
                            color="primary"
                            isInteractive={true}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                            to={`${DASHBOARD}/${"SNN245"}`}
                        >
                            SNN859
                        </Link>{" "}
                    </>
                ),
            },

            revisionDate: {
                value: "05/04/2024",
            },
            link: {
                value: "PR_45859",
            },
            revision: {
                value: "APPROVED",
            },

            expandable: {
                component: () => (
                    <div style={{ padding: "0 40px" }}>
                        <HorizontalStepper
                            activeStep={2}
                            padding="50px 0px 20px"
                            data={steps}
                            completedSteps={2}
                        />
                        <Flex.Container justify="flex-end">
                            <Space xxsm={["0 1rem 0 0"]} stm={["0 2rem 0 0"]}>
                                <Button size="s" xxs="s" st="s">
                                    Audit
                                </Button>
                            </Space>

                            <Button size="s" xxs="s" st="s">
                                Proposal Details
                            </Button>
                        </Flex.Container>
                    </div>
                ),
            },
        },
    ],
};

const Revision = () => {
    return (
        <Section height="auto">
            <Space xxsp={["10rem 0 0 0"]}>
                <Container>
                    <Flex.Container justify="between">
                        <Flex.Item xxs="100%" lg="50%">
                            <H2>Revision Request </H2>
                        </Flex.Item>

                        <Flex.Container justify="between" align="center">
                            <P>Revision Status:</P>
                            <Space xxsp={["0 0 0 20px"]}>
                                <Flex.Container
                                    justify="between"
                                    align="center"
                                >
                                    <Space xxsp={["0 0 0 20px"]}>
                                        <Flex.Container
                                            justify="between"
                                            align="center"
                                        >
                                            <RadioInput label={"All"} />
                                        </Flex.Container>
                                    </Space>
                                    <Space xxsp={["0 0 0 30px"]}>
                                        <Flex.Container
                                            justify="between"
                                            align="center"
                                        >
                                            <RadioInput label={"Rejected"} />
                                        </Flex.Container>
                                    </Space>
                                    <Space xxsp={["0 0 0 30px"]}>
                                        <Flex.Container
                                            justify="between"
                                            align="center"
                                        >
                                            <RadioInput label={"Approved"} />
                                        </Flex.Container>
                                    </Space>
                                </Flex.Container>
                            </Space>
                        </Flex.Container>
                    </Flex.Container>
                    <Tables
                        mobileTableHeader={mobileTableHeader}
                        tableHeader={tableHeader}
                        data={data}
                        cellStyles={cellStyles}
                        cellMobileStyles={cellStyles}
                    />
                </Container>
            </Space>
        </Section>
    );
};

export default Revision;

const tableHeader = [
    {
        id: 1,
        name: "Property ID",
    },
    {
        id: 2,
        name: "Revision Date",
    },
    {
        id: 3,
        name: "Proposal Link",
    },

    {
        id: 4,
        name: "Revision #",
    },
    {
        id: 5,
        name: "Status",
    },
    {
        id: 6,
        name: "View Form",
    },
];
const mobileTableHeader = [
    {
        id: 1,
        name: "Property ID",
    },
    {
        id: 2,
        name: "Revision Date",
    },
    {
        id: 3,
        name: "Proposal Link",
    },

    {
        id: 4,
        name: "Revision #",
    },
];
const steps = [
    {
        step: 1,
        bottom: "Paid",
        top: "02/12/22",
    },
    {
        step: 2,
        bottom: "Procuring",
        top: "02/12/22",
    },
    {
        step: 3,
        bottom: "Installing",
        top: "",
    },
    {
        step: 4,
        bottom: "Active",
        top: "",
    },
    {
        step: 5,
        bottom: "Closed",
        top: "",
    },
];

const formSteps = [
    {
        step: 1,
    },
    {
        step: 2,
    },
];

const FlexInput = ({ label, type = "input", value, ...rest }) => {
    return (
        <Space xxsm={["0 0 1rem"]} stm={["0 0 2rem"]}>
            <Flex.Container justify="between" align="center">
                <Flex.Item xxs="100%" lg="35%">
                    <P>{label}</P>
                </Flex.Item>
                <Flex.Item xxs="100%" lg="60%">
                    <Input
                        componentType={type}
                        disabled
                        value={value}
                        {...rest}
                    ></Input>
                </Flex.Item>
            </Flex.Container>
        </Space>
    );
};
