import React from "react";
import Circle from "../circle/Circle";

import Space from "../space/Space";
import { Flex } from "../styles/Elements.styles";
import Text from "../text/Text";

const BulletList = ({
    title,
    color = "lightGrey",
    margin = "2% 0",
    fontSize = "m",
    size = "7px",
}) => {
    return (
        <Flex.Container
            align="flex-start"
            style={{ flexWrap: "nowrap", margin: margin }}
        >
            <Space xxsm={[".7em 10px 0 10px"]}>
                <Circle
                    size={size}
                    backgroundColor={color}
                    style={{ flexShrink: 0 }}
                />
            </Space>
            <Text align="left" color={color} size={fontSize}>
                {title}
            </Text>
        </Flex.Container>
    );
};

export default BulletList;
