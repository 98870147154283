import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useRef } from "react";
import { Pie } from "react-chartjs-2";
import styled from "styled-components";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = (props) => {
    const chartRef = useRef(null);

    const data = {
        labels: props.data.labels,
        datasets: [
            {
                data: props.data.data,
                backgroundColor: props.data.backgroundColor,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    color: "black",
                    font: {
                        size: 15,
                    },
                },
            },
        },
    };

    return (
        <>
            <div className=" h-[350px] sm:h-[350px]  lg:h-[400px]">
                <Pie ref={chartRef} data={data} options={options} />
            </div>
        </>
    );
};

export default PieChart;

const ChartWrapper = styled.div`
    height: 450px;
    position: relative;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        height: 250px;
    }
`;
