import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ButtonAlt } from "../../components/button/Button";
import Input from "../../components/input/Input";
import Space from "../../components/space/Space";
import { Flex } from "../../components/styles/Elements.styles";
import { P } from "../../components/styles/Typography.styles";
import { InlineText } from "../../components/text/HeadingText";
import { ERROR_MSG } from "../../constants/common";
import {
    REMOVE_REDIRECT_PATH,
    SET_ERROR,
} from "../../constants/contexConstant";
import { emailRegEx, phoneRegEx } from "../../constants/regularExpression";
import { FORGET } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { handleRedirection } from "../../helpers/routingHelper";
import auth from "../../services/auth";

const TYPE_PASSWORD = "password";
const TYPE_TEXT = "text";

const LoginWithEmailPass = ({ onClose }) => {
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        password: "",
        userId: "",
    });
    const [passType, setPassType] = useState(TYPE_PASSWORD);
    const history = useHistory();

    const handleChangeFunction = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const { state, dispatch } = useContextState();
    const validate = (values) => {
        const errors = {};
        if (!values.userId) {
            errors.userId = "User Id is required!";
        } else if (
            !phoneRegEx.test(values.userId) &&
            !emailRegEx.test(values.userId)
        ) {
            errors.userId = "Enter valid email or phone number!";
        }

        if (!values.password) {
            errors.password = "Password is required!";
        }
        return errors;
    };

    const closeModalHandler = () => {
        setFormData({ userId: "", otp: "" });
        setFormError({});
        setLoading(false);
        onClose();
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const getValidFormData = validate(formData);

        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);

            try {
                let val = emailRegEx.test(formData.userId)
                    ? formData.userId
                    : `91${formData.userId}`;
                await auth.login({
                    username: val,
                    password: formData.password,
                    otpEnable: false,
                });

                let profile = await auth.getUserProfile(dispatch);
                let status = profile?.data?.investor_profile?.plant?.status;
                const roles = profile?.data?.investor_profile?.roles;
                handleRedirection(state, history, roles, status);
                dispatch({
                    type: REMOVE_REDIRECT_PATH,
                });
                setLoading(false);

                closeModalHandler();
            } catch (error) {
                setLoading(false);

                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        }
    };
    const updatePasswordFiledType = () => {
        setPassType(passType === TYPE_PASSWORD ? TYPE_TEXT : TYPE_PASSWORD);
    };

    const handleReset = () => {
        closeModalHandler();
        history.push(FORGET);
    };
    return (
        <div>
            <Space lgm={["2rem 0"]} stm={["1.5rem 0"]} xxsm={["1.5rem 0"]}>
                <Flex.Container justify="space-between" align="center">
                    <Flex.Item xxs="100%" st="100%" xl="25%">
                        <P color="white" weight="b" xxs="m" st="m">
                            User Id
                        </P>
                    </Flex.Item>
                    <Flex.Item xxs="100%" st="100%" xl="70%">
                        <Input
                            label="UserId"
                            placeholder="Enter your email or phone number"
                            name="userId"
                            errorMessage={formError.userId}
                            value={formData.userId}
                            onChange={handleChangeFunction}
                            backgroundColor="white"
                        />
                    </Flex.Item>
                </Flex.Container>
            </Space>
            <Space lgm={["2rem 0"]} stm={["1.5rem 0"]} xxsm={["1.5rem 0"]}>
                <Flex.Container justify="space-between" align="center">
                    <Flex.Item xxs="100%" st="100%" xl="25%">
                        <P color="white" weight="b" xxs="m" st="m">
                            Password
                        </P>
                    </Flex.Item>
                    <Flex.Item xxs="100%" st="100%" xl="70%">
                        <Input
                            label="Password"
                            placeholder="Enter your password"
                            name="password"
                            errorMessage={formError.password}
                            value={formData.password}
                            onChange={handleChangeFunction}
                            backgroundColor="white"
                            type={passType}
                            icon={
                                passType === TYPE_PASSWORD ? faEyeSlash : faEye
                            }
                            onIconClick={() =>
                                updatePasswordFiledType(TYPE_PASSWORD)
                            }
                        />
                    </Flex.Item>
                </Flex.Container>
            </Space>

            <Flex.Container justify="center">
                <ButtonAlt
                    onClick={handleSubmit}
                    xxs="m"
                    st="m"
                    weight="b"
                    color="white"
                    backgroundColor="white"
                    style={{ borderRadius: "50px", padding: "1rem 3rem" }}
                    disabled={loading}
                    isLoading={loading}
                    type="submit"
                >
                    Submit
                </ButtonAlt>
            </Flex.Container>

            <Flex.Container style={{ marginTop: "20px" }} justify="center">
                <P onClick={handleSubmit} xxs="s" st="s" color="white">
                    Forget Password? Click
                    <ResetBtn role="button" onClick={handleReset} weight="bold">
                        Reset
                    </ResetBtn>
                </P>
            </Flex.Container>
        </div>
    );
};

export default LoginWithEmailPass;

const ResetBtn = styled(InlineText)`
    margin-left: 4px;
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;
