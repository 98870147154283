import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    background-color: ${(props) =>
        props.backgroundColor
            ? props.theme.color[props.backgroundColor]
            : props.theme.color.grey100};
    border-radius: 1px;
    padding: 2rem;

    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    margin-top: 3rem;
    margin-bottom: 3rem;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        padding: 2rem 3rem;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 2rem 2rem;
    }
`;
const ProposalDeatilsContainer = ({ backgroundColor, children }) => {
    return <Wrapper backgroundColor={backgroundColor}>{children}</Wrapper>;
};

export default ProposalDeatilsContainer;
