import React from "react";
import styled from "styled-components";
import Space from "../../../components/space/Space";
import { H3 } from "../../../components/styles/Typography.styles";

const Backdrop = ({ title, children }) => {
    return (
        <BackdropWrapper>
            {title && (
                <Space xxsm={["0 0 2rem"]} stm={["0 0 3rem"]}>
                    <H3 weight="rg">{title}</H3>
                </Space>
            )}
            {children}
        </BackdropWrapper>
    );
};

export default Backdrop;

const BackdropWrapper = styled.div`
    box-sizing: border-box;
    background-color: ${(props) => props.theme.color.grey250};
    border: 1px solid ${(props) => props.theme.color.black800};
    padding: 1.5rem 2rem;
    border-radius: 0.8rem;
    height: 100%;
`;
