import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../components/button/Button";
import ModalAlt from "../../components/modal/ModalAlt";
import Space from "../../components/space/Space";
import { Flex } from "../../components/styles/Elements.styles";

import { P } from "../../components/styles/Typography.styles";
import {
    CLOSE_LOGIN_FORM,
    SHOW_SIGNUP_FORM,
} from "../../constants/contexConstant";
import { useContextState } from "../../context/ContextProvider";
import LoginWithEmailPass from "./LoginWithEmailPass";
import LoginWithOTP from "./LoginWithOTP";

const SignupButtonContainer = styled(Flex.Container)`
    position: absolute;
    top: 17px;
`;

const SignupButton = styled(Button)`
    border: solid 1px ${(props) => props.theme.color.white};
    border-radius: 4px;
    padding: 0.2rem 0.8em;
    margin-left: 0px;
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        margin-left: 10px;
        padding: 0.5rem 1em;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;

    .buttonWrapper {
        margin-bottom: 1rem;
        width: 100%;

        background: ${(props) => props.theme.color.grey200};
        border-radius: 10px;
        /* width: min-content; */
        padding: 5px;
        height: auto;
        display: flex;
        justify-content: space-between;
        border-radius: 50px;
        button {
            height: 100%;
            border-radius: 50px;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        .buttonWrapper {
            width: 80%;
        }
    }
`;
const LoginSliderBtn = styled(Button)`
    transition: ease-in-out 0.5s;
    padding: 0.5rem 2.8rem;
    border-color: ${(props) =>
        props.isActive ? props.theme.color.primary : props.theme.color.grey200};
    background-color: ${(props) =>
        props.isActive ? props.theme.color.primary : props.theme.color.grey200};
    color: ${(props) =>
        props.isActive ? props.theme.color.white : props.theme.color.black800};
    :hover {
        box-shadow: none;
    }
`;
const LOGIN_TYPE_OTP = "LOGIN_TYPE_OTP";
const LOGIN_TYPE_EMAIL = "LOGIN_TYPE_EMAIL";

const LoginModal = () => {
    const [activeLoginType, setActiveLoginType] = useState(LOGIN_TYPE_OTP);

    const { dispatch, state } = useContextState();
    const { loginForm } = state;

    const closeModalHandler = () => {
        setActiveLoginType(LOGIN_TYPE_OTP);
        dispatch({ type: CLOSE_LOGIN_FORM });
    };

    const handleSignupFunction = (e) => {
        e.preventDefault();
        closeModalHandler();
        dispatch({ type: SHOW_SIGNUP_FORM });
    };

    return (
        <ModalAlt
            times={false}
            onClose={closeModalHandler}
            isOpen={loginForm}
            backgroundColor="primary"
            width={["100%", "600px", "600px"]}
        >
            <Space xxsm={["0 0 9rem"]} xsm={["0 0 6rem"]}>
                <div>
                    <AuthenticationHead
                        onClick={handleSignupFunction}
                        type="New User"
                        actionText="Signup here"
                    />
                </div>
            </Space>
            <Space xxsm={["0 0 3rem"]} xsm={[" 0 0 3rem"]}>
                <P align="center" weight="b" xxs="l" st="xl" color="white">
                    Login With
                </P>
            </Space>

            <ButtonWrapper>
                <div className="buttonWrapper">
                    <LoginSliderBtn
                        type="solid"
                        xxs="m"
                        st="m"
                        weight="sb"
                        style={{ height: "auto" }}
                        backgroundColor="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            setActiveLoginType(LOGIN_TYPE_OTP);
                        }}
                        isActive={activeLoginType === LOGIN_TYPE_OTP}
                    >
                        OTP
                    </LoginSliderBtn>
                    <LoginSliderBtn
                        onClick={(e) => {
                            e.preventDefault();
                            setActiveLoginType(LOGIN_TYPE_EMAIL);
                        }}
                        isActive={activeLoginType === LOGIN_TYPE_EMAIL}
                        background="primary"
                        xxs="m"
                        st="m"
                        weight="sb"
                    >
                        Password
                    </LoginSliderBtn>
                </div>
            </ButtonWrapper>
            <Space>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    {activeLoginType === LOGIN_TYPE_OTP ? (
                        <LoginWithOTP onClose={closeModalHandler} />
                    ) : (
                        <LoginWithEmailPass onClose={closeModalHandler} />
                    )}
                </form>
            </Space>
        </ModalAlt>
    );
};

export default LoginModal;

export const AuthenticationHead = ({ onClick, type, actionText }) => {
    return (
        <SignupButtonContainer align="center">
            <P xxs="s" st="s" color="white" weight="b">
                {type}
            </P>
            <Flex.Item xxs="100%" xs="auto">
                <SignupButton
                    st="s"
                    xxs="s"
                    weight="b"
                    color="white"
                    onClick={onClick}
                    type="button"
                >
                    {actionText}
                </SignupButton>
            </Flex.Item>
        </SignupButtonContainer>
    );
};
