import React from "react";
import { IoLocationOutline } from "react-icons/io5";
import P from "../../../shared/typography/P";
import Tag from "../../../shared/ui/tag/Tag";
import H4 from "../../typography/H4";
const ProjectCard = ({
    industry,
    image,
    title,
    location,
    plantSize,
    label,
}) => {
    return (
        <div className="relative group mx-2 ">
            <div className="flex items-start ">
                <Tag text={industry} />
            </div>
            <div className="relative h-[413px]  w-max-[423px]  overflow-hidden rounded-m bg-rose-300 mt-1 lg:mt-1.5 xl:mt-1.6">
                <img
                    src={image}
                    alt=""
                    className="absolute inset-0 w-full h-full object-cover"
                />

                <div
                    className={`absolute bg-primary bg-opacity-50 bottom-0 left-0 right-0 py-1 px-2 group-hover:opacity-100 transition-opacity duration-300 ease-in-out`}
                >
                    <div className="flex justify-between px-0.5">
                        <div>
                            <H4 className="text-sm  sm:text-xl text-white mb-[4px] font-semibold">
                                {title}
                            </H4>
                            <P className="text-xs sm:text-base text-white  flex items-center">
                                <IoLocationOutline className="mr-0.5" />
                                {location}
                            </P>
                        </div>
                        <div>
                            <P className="text-sm sm:text-xl text-white mb-[4px] font-semibold">
                                {plantSize}
                            </P>
                            <P className="text-xs sm:text-base text-white ">
                                {label}
                            </P>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;
