import React from "react";
import TabButton from "../TabButton";

const SwitchingTabs = ({
    options,
    currentTab,
    onTabClick,
    border,
    bestlabel = false,
}) => {
    return (
        <div
            className={`flex gap-1.6 bg-gray-100 ${
                border ? border : ""
            }  shadow-lg p-0.4`}
        >
            {options.map((option, index) => (
                <TabButton
                    key={option}
                    className={`   ${
                        border ? border : "rounded-full"
                    } py-0.5 px-1.2   transition-colors duration-300 text-base font-normal flex items-start justify-center ${
                        currentTab === index
                            ? "bg-primary text-white"
                            : "bg-gray-100 text-secondary"
                    } ${option === "Lease" ? "flex items-center gap-0.5" : ""}`}
                    onClick={() => onTabClick(index)}
                    showHoverEffect={false}
                >
                    {option}
                    {bestlabel && option === "Lease" && (
                        <span className="py-0.4 h-[15px] flex items-center justify-center px-0.6 bg-orange text-[10px] leading-[1.5rem] text-primary rounded-full">
                            Best
                        </span>
                    )}
                </TabButton>
            ))}
        </div>
    );
};

export default SwitchingTabs;
