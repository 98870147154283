import React from "react";
import { aboutUsBg } from "../../resources/js/images";
import Container from "../../shared/Container";
import H1 from "../../shared/typography/H1";
import ContactUSForm from "../home/ContactUSForm";
import Hero from "../home/Hero";
import ProjectCarouselSection from "../home/Sections/ProjectCarouselSection";
import CarouselSection from "./CarouselSection";
import EnvironmentImpact from "./EnvironmentImpact";
import OurAim from "./OurAim";
import OurStory from "./OurStory";
import OurVision from "./OurVision";
import TeamSection from "./TeamSection";

const AboutUs = () => {
    return (
        <div>
            <Hero
                wrapperClass="h-[330px] sm:h-[403px] xl:h-[500px]"
                content={
                    <Container>
                        <H1 className={"text-white lg:ml-5"}>About Us</H1>
                    </Container>
                }
                url={aboutUsBg}
            />
            <OurStory />
            <OurAim />
            <CarouselSection />å
            <OurVision />
            <EnvironmentImpact />
            <ProjectCarouselSection />
            <TeamSection />
            <ContactUSForm />
        </div>
    );
};

export default AboutUs;
