import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Space = styled(({ children, ...props }) =>
    React.cloneElement(children, props)
)`
    &&& {
        margin: ${(props) => `${props?.xxsm}` || ""};
        padding: ${(props) => `${props?.xxsp}` || ""};

        @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
            margin: ${(props) => props?.xsm || props?.xxsm || ""};
            padding: ${(props) => props?.xsp || props?.xxsp || ""};
        }
        @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
            margin: ${(props) => props.sm || props.xsm || props.xxsm || ""};
            padding: ${(props) => props?.sp || props?.xsp || props?.xxsp || ""};
        }
        @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
            margin: ${(props) =>
                props?.stm || props?.sm || props?.xsm || props?.xxsm || ""};
            padding: ${(props) =>
                props?.stp || props?.sp || props?.xsp || props?.xxsp || ""};
        }
        @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
            margin: ${(props) =>
                props?.tm ||
                props?.stm ||
                props?.sm ||
                props?.xsm ||
                props?.xxsm ||
                ""};
            padding: ${(props) =>
                props?.tp ||
                props?.stp ||
                props?.sp ||
                props?.xsp ||
                props?.xxsp ||
                ""};
        }
        @media (min-width: ${(props) => props.theme.breakPoints.large}) {
            margin: ${(props) =>
                props?.lgm ||
                props?.tm ||
                props?.stm ||
                props?.sm ||
                props?.xsm ||
                props?.xxsm ||
                ""};
            padding: ${(props) =>
                props?.lgp ||
                props?.tp ||
                props?.stp ||
                props?.sp ||
                props?.xsp ||
                props?.xxsp ||
                ""};
        }
        @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
            margin: ${(props) =>
                props?.xlm ||
                props?.lgm ||
                props?.tm ||
                props?.stm ||
                props?.sm ||
                props?.xsm ||
                props?.xxsm ||
                ""};
            padding: ${(props) =>
                props?.xlp ||
                props?.lgp ||
                props?.tp ||
                props?.stp ||
                props?.sp ||
                props?.xsp ||
                props?.xxsp ||
                ""};
        }
    }
`;

Space.propTypes = {
    margin: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    padding: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
};

export default Space;
