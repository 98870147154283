import { motion } from "framer-motion";
import React from "react";
import P from "../../../shared/typography/P";
import { slideOpacityRight } from "../../animation/constants/landingAnimation";
import H4 from "../../typography/H4";
const FeatureCard = ({ feature, index }) => {
    return (
        <motion.div
            variants={slideOpacityRight}
            key={index}
            className={`flex flex-col sm:mb-0  ${
                index % 2 !== 0
                    ? "border-0 lg:border-r lg:pr-1.6  "
                    : "border-r pr-1.6"
            } border-black/40 lg:last:border-r-0 `}
        >
            <div className="mb-1.6 lg:mb-[2rem] xl:mb-2.4 2xl:mb-3.2">
                <motion.img
                    initial={{ opacity: 0, y: "100%" }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: {
                            duration: 0.8,
                            delay: 1.1,
                            type: "tween",
                        },
                    }}
                    className="h-4 sm:h-5 xl:h-5.6 2xl:h-8"
                    src={feature.icon}
                    alt={feature.title}
                />
            </div>
            <H4 className="text-base lg:text-2xl lg:font-semibold mb-0.8 lg:mb-1.6 xl:mb-2 2xl:mb-2.4">
                {feature.title}
            </H4>
            <P className="text-secondary text-xs ">{feature.description}</P>
        </motion.div>
    );
};

export default FeatureCard;
