import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";

import Space from "../../components/space/Space";
import Container from "../../components/styles/Container.styles";
import { Flex, Section } from "../../components/styles/Elements.styles";
import { InlineText } from "../../components/text/HeadingText";

import { motion } from "framer-motion";
import { slideRight } from "../../components/animation/landingAnimation";
import SlickCarousel from "../../components/carousel/SlickCarousel";
import { H2, H3, P } from "../../components/styles/Typography.styles";
import {
    pastProjects,
    projectsCarouselSettings,
} from "../../constants/landingConstants";

const { title, tags, cards } = pastProjects;

const TagItem = styled(Flex.Item)`
    box-sizing: border-box;
    background-color: ${(props) => props.theme.color.primary};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    border-radius: 10px;
    margin-bottom: 2rem;
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        margin: 0 1rem;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        margin: 0 2rem;
    }
`;

const PastProjects = () => {
    return (
        <Section
            style={{ overflow: "hidden" }}
            height="auto"
            backgroundColor="grey200"
        >
            <Container>
                <Space xxsp={["0 0 2rem "]}>
                    <H2
                        fontFamily="Montserrat"
                        align="center"
                        as={motion.h2}
                        variants={slideRight}
                        initial="hidden"
                        whileInView="visible"
                    >
                        {title}
                    </H2>
                </Space>

                <Space xxsm={["1rem 0 0"]}>
                    <Flex.Container justify="center" align="flex-start">
                        {tags.map((tag) => (
                            <TagItem
                                key={tag.id}
                                xxs="100%"
                                s="80%"
                                st="210px"
                                lg="28%"
                            >
                                <H3 align="center" color="white">
                                    <CountUp
                                        duration={2}
                                        end={tag.value}
                                        redraw={false}
                                        start={tag.value - 20}
                                    >
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor
                                                onChange={start}
                                                delayedCall
                                            >
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                    {"+ "}
                                    <InlineText>{tag.unit}</InlineText>
                                </H3>
                                <P color="white">{tag.name}</P>
                            </TagItem>
                        ))}
                    </Flex.Container>
                </Space>

                <div>
                    <SlickCarousel settings={projectsCarouselSettings}>
                        {cards.map((item) => (
                            <ProjectCard key={item.id} {...item} />
                        ))}
                    </SlickCarousel>
                </div>
            </Container>
        </Section>
    );
};

export default PastProjects;

export const ImgCard = styled.div`
    display: flex;
    justify-content: center;

    .card {
        width: 350px;
        margin-top: ${(props) => props.theme.sizes.xl4};
        min-height: 350px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23);
        transition: all linear 0.3s;
        background-color: ${(props) => props.theme.color.black800};
        :hover {
            transform: scale(1.05);
            box-shadow: 0 0 5px ${(props) => props.theme.color.primary};
            cursor: pointer;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all linear 0.4s;
        }
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        .card {
            width: 340px;
            margin-top: ${(props) => props.theme.sizes.xl5};
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        .card {
            width: 350px;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        .card {
            width: 360px;
            margin: ${(props) => props.theme.sizes.xl7} 0
                ${(props) => props.theme.sizes.xl5};
        }
    }
`;
const ProjectCard = (props) => {
    return (
        <ImgCard>
            <div className="card">
                <div className="overly">
                    <img src={props.img} alt={props.name} />
                </div>

                <Space xxsp={["1rem"]}>
                    <div>
                        <P weight="m" color="white">
                            {props.name}
                        </P>

                        <Flex.Container align="center">
                            <Circle
                                size="10px"
                                margin="0px 10px 0 0"
                                backgroundColor="white"
                            />
                            <P xxs="s" st="s" xlg="s" color="white">
                                {props.location}
                            </P>
                        </Flex.Container>

                        <Space xxsm={["20px 0"]}>
                            <Flex.Container
                                justify="space-between"
                                align="center"
                            >
                                {props.tags.map((tag) => (
                                    <CardTag
                                        key={tag.id}
                                        xxs="100%"
                                        xs="49%"
                                        st="48%"
                                    >
                                        <P
                                            align="center"
                                            color="white"
                                            weight="b"
                                        >
                                            {tag.value}{" "}
                                            <InlineText>{tag.unit}</InlineText>
                                        </P>
                                        <P align="center" color="white">
                                            {tag.name}
                                        </P>
                                    </CardTag>
                                ))}
                                {/* <Space xxsm={["1.8rem 0 .5rem"]}>
                                    <P weight="b" color="white">
                                        Project Details
                                    </P>
                                </Space>

                                <P lineHeight="1.55" color="grey300">
                                    {props.details}
                                </P> */}
                            </Flex.Container>
                        </Space>
                    </div>
                </Space>
            </div>
        </ImgCard>
    );
};

const CardTag = styled(Flex.Item)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem;
    box-sizing: border-box;
    border-radius: 10px;
    color: ${(props) => props.theme.color.white};
    background: ${(props) => props.theme.color.primary};
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.sizes.sm};
`;

const Circle = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 20px;
    background-color: ${(props) => props.theme.color.white};
    margin-right: 5px;
    display: inline-flex;
`;
