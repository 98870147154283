import React, { useState } from "react";
import Container from "../../../components/styles/Container.styles";
import { Flex, Section } from "../../../components/styles/Elements.styles";

import { FaRegEdit } from "react-icons/fa";
import styled from "styled-components";
import Icon from "../../../components/Icon/Icon";
import BulletList from "../../../components/bullet-list/BulletList";
import Button from "../../../components/button/Button";
import DropDown from "../../../components/dropdown/DropDown";
import Input from "../../../components/input/Input";
import ModalAlt from "../../../components/modal/ModalAlt";
import Space from "../../../components/space/Space";
import { H4, P } from "../../../components/styles/Typography.styles";
import Tables from "../../../components/table/Tables";
import ProposalDeatilsContainer from "../admin/proposal/ProposalDetailsContainer";
import {
    ProCheckBox,
    ProRadioInput,
} from "../admin/proposal/PropsoalRadioInput";
const cellStyles = {
    padding: "10px 0",
    borderLeft: "none",
    borderRight: "none",
};
export const StyledButton = styled(Button)`
    border: none;
    padding: 10px 100px;

    background-color: ${(props) =>
        props.isActive
            ? props.theme.color.primary
            : props.isCancel
            ? props.theme.color.error
            : props.theme.color.grey500};
    color: ${(props) => props.theme.white};

    font-size: 15px;
    :hover,
    :focus,
    :active {
        box-shadow: none;
    }
`;

function AdminUserUpdate() {
    const [showSummary, setShowSummary] = useState(false);
    const [userInfo, setUserInfo] = useState("");
    const [propertyId, setPropertyId] = useState("");
    const [intro, setIntro] = useState("Yes");
    const [checkboxes, setCheckboxes] = useState({
        Consumer: false,
        Investor: false,
        Operation: true,
        Sales: true,
    });
    const onChange = (name, checked) => {
        setCheckboxes((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };
    const [connectionType, setConnectionType] = useState("Residential Society");
    const OnChange = (value) => {
        setIntro(value);
    };
    const handleId = (event) => {
        setPropertyId(event.target.value);
    };
    const handleUserInfo = (event) => {
        setUserInfo(event.target.value);
    };
    const data = {
        large: [
            {
                index: 1,
                name: {
                    value: "Divyanshu",
                },
                emailId: {
                    value: "Divyanshu@hypersrot.com",
                },
                phoneNo: {
                    value: "9322589658",
                },
                PropertyId: {
                    value: "NA",
                },
                Role: {
                    value: null,
                    component: () => (
                        <ul>
                            <BulletList
                                title={"Admin"}
                                color="black"
                                fontSize="s"
                            />
                            <BulletList
                                title={"Consumer"}
                                color="black"
                                fontSize="s"
                            />
                            <BulletList
                                title={"Investor"}
                                color="black"
                                fontSize="s"
                            />
                            <BulletList
                                title={"Operation"}
                                color="black"
                                fontSize="s"
                            />
                            <BulletList
                                title={"Sales"}
                                color="black"
                                fontSize="s"
                            />
                        </ul>
                    ),
                },
                Action: {
                    value: null,
                    component: () => (
                        <Icon
                            onClick={() => setShowSummary(!showSummary)}
                            component={FaRegEdit}
                            lg="l"
                        />
                    ),
                },
            },
            {
                index: 2,
                name: {
                    value: "Aman",
                },
                emailId: {
                    value: "Divyanshu@hypersrot.com",
                },
                phoneNo: {
                    value: "9322589658",
                },
                PropertyId: {
                    value: "SNN458",
                },
                Role: {
                    value: null,
                    component: () => (
                        <ul>
                            <BulletList
                                title={"Consumer"}
                                color="black"
                                fontSize="s"
                            />
                        </ul>
                    ),
                },
                Action: {
                    value: null,
                    component: () => <Icon component={FaRegEdit} lg="l" />,
                },
            },
        ],
        small: [
            {
                index: 1,
                name: {
                    value: "Divyanshu",
                },
                emailId: {
                    value: "Divyanshu@hypersrot.com",
                },
                phoneNo: {
                    value: "9322589658",
                },
                PropertyId: {
                    value: "NA",
                },
                Role: {
                    value: null,
                    component: () => (
                        <ul>
                            <BulletList
                                title={"Admin"}
                                color="black"
                                fontSize="s"
                            />
                            <BulletList
                                title={"Consumer"}
                                color="black"
                                fontSize="s"
                            />
                            <BulletList
                                title={"Investor"}
                                color="black"
                                fontSize="s"
                            />
                            <BulletList
                                title={"Operation"}
                                color="black"
                                fontSize="s"
                            />
                        </ul>
                    ),
                },
                Action: {
                    value: null,
                    component: () => (
                        <Icon
                            onClick={() => setShowSummary(!showSummary)}
                            component={FaRegEdit}
                            lg="m"
                        />
                    ),
                },
            },
            {
                index: 2,
                name: {
                    value: "Aman",
                },
                emailId: {
                    value: "Divyanshu@hypersrot.com",
                },
                phoneNo: {
                    value: "9322589658",
                },
                PropertyId: {
                    value: "SNN458",
                },
                Role: {
                    value: null,
                    component: () => (
                        <ul>
                            <BulletList
                                title={"Consumer"}
                                color="black"
                                fontSize="s"
                            />
                        </ul>
                    ),
                },
                Action: {
                    value: null,
                    component: () => <Icon component={FaRegEdit} lg="m" />,
                },
            },
        ],
    };
    return (
        <Section height="auto">
            <Container>
                <ProposalDeatilsContainer backgroundColor={"smoke"}>
                    <Flex.Container justify="space-between">
                        <DetailsFiled
                            label={"Search User by Email Id / Phone No."}
                            value={userInfo}
                            xl={"50%"}
                            onChange={handleUserInfo}
                        />

                        <DetailsFiled
                            label={"Search by Property Id"}
                            value={propertyId}
                            xl={"40%"}
                            onChange={handleId}
                        />
                    </Flex.Container>
                    <Flex.Container justify="center" align="center">
                        <StyledButton isActive={true}>Go</StyledButton>
                    </Flex.Container>
                </ProposalDeatilsContainer>
                <Tables
                    mobileTableHeader={mobileTableHeader}
                    tableHeader={tableHeader}
                    data={data}
                    cellStyles={cellStyles}
                    cellMobileStyles={cellStyles}
                />
            </Container>
            <ModalAlt
                times={false}
                onClose={() => setShowSummary(false)}
                isOpen={showSummary}
                backgroundColor="limeYellow"
                width={["100%", "700px", "600"]}
            >
                <Container>
                    <H4>Update User Details</H4>
                    <Space xxsm={[" 1rem 0 2rem"]}>
                        <hr />
                    </Space>
                    <Flex.Container justify="center" direction="column">
                        <ProCheckBox
                            title={"Set New Roles"}
                            connectionType={"Residential Society"}
                            arr={["Consumer", "Investor", "Operation", "Sales"]}
                            onChange={onChange}
                            selected={checkboxes}
                            direction={"row"}
                            align="center"
                        />

                        <ProRadioInput
                            onChange={OnChange}
                            type={"disableUsers"}
                            title={"Disable Users"}
                            selected={intro}
                            data={["Yes", "No"]}
                            direction={"row"}
                            align={"center"}
                        />
                        <Space xxsm={["20px 0 0"]} stm={["20px 0"]}>
                            <StyledButton isActive={true}>Submit</StyledButton>
                        </Space>
                    </Flex.Container>
                </Container>
            </ModalAlt>
        </Section>
    );
}

export default AdminUserUpdate;

export const DetailsFiled = ({
    label,
    isSelect,
    data,
    selected,
    textAlign,
    xl,
    ...rest
}) => {
    return (
        <Space margin={["0 0 30px"]} tm={["0 0 25px"]} xsm={["0 0 10px"]}>
            <Flex.Container justify="space-between" align="center">
                <Space xxsm={["10px 0 0"]} stm={["0"]}>
                    <Flex.Item xl={xl} st="60%" s="100%" xxs="100%">
                        <P xxs="xs" st="s" lg="m" color="black">
                            {label}
                        </P>
                    </Flex.Item>
                </Space>
                {isSelect ? (
                    <FlexItem s="100%" xxs="100%" xl="100%">
                        <DropDown
                            data={data}
                            onChange={null}
                            selected={selected}
                            {...rest}
                        />
                    </FlexItem>
                ) : (
                    <FlexItem s="100%" xxs="100%" xl="30rem">
                        <Input
                            backgroundColor="white"
                            shadow={false}
                            style={{
                                paddingBottom: "7px",
                                paddingTop: "7px",
                                textAlign: textAlign,
                            }}
                            {...rest}
                        />
                    </FlexItem>
                )}
            </Flex.Container>
        </Space>
    );
};

const FlexItem = styled(Flex.Item)`
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        flex: 1;
    }
`;
const tableHeader = [
    {
        id: 1,
        name: "Name",
    },
    {
        id: 2,
        name: "Email Id",
    },
    {
        id: 3,
        name: "Phone No.",
    },

    {
        id: 4,
        name: "Property Id",
    },
    {
        id: 5,
        name: "Role",
    },
    {
        id: 6,
        name: "Action",
    },
];
const mobileTableHeader = [
    {
        id: 1,
        name: "Name",
    },
    {
        id: 2,
        name: "Email Id",
    },
    {
        id: 3,
        name: "Phone No.",
    },

    {
        id: 4,
        name: "Property Id",
    },
    {
        id: 5,
        name: "Role",
    },
    {
        id: 6,
        name: "Action",
    },
];
