import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/button/Button";
import { H1 } from "../../components/styles/Typography.styles";
import { LANDING } from "../../constants/routes";
import { Status } from "../notfound";
import NotAvailableContainer from "../notfound/NotAvailableContainer";

const ButtonStyled = styled(Button)`
    border-radius: 50px;
    margin-top: 100px;
    padding: 1rem 4rem;
`;
const UnAuthorized = () => {
    const history = useHistory();
    return (
        <NotAvailableContainer>
            <Status align="center" color="white" weight="b">
                401
            </Status>
            <H1 align="center" size="xl" weight="b" color="white">
                Sorry!! You Don't have any access to this page
            </H1>

            <ButtonStyled
                weight="bold"
                color="white"
                textTransform="uppercase"
                backgroundColor="secondary"
                type="solid"
                fontSize="xl"
                onClick={() => history.push(LANDING)}
            >
                Back to Home
            </ButtonStyled>
        </NotAvailableContainer>
    );
};

export default UnAuthorized;
