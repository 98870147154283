let desktopNewFont = {
    xxl: "4.2rem",
    xml: "3.6rem",
    xl: "2.4rem",
    l: "2rem",
    m: "1.8rem",
    s: "1.6rem",
    xs: "0.7rem",
};
let extraLargeNewFont = {
    xxl: "3.6rem",
    xml: "3rem",
    xl: "2.4rem",
    l: "2rem",
    m: "1.8rem",
    s: "1.6rem",
    xs: "0.7rem",
};

let largeNewFont = {
    xxl: "3.2rem",
    xml: "2.8rem",
    xl: "2.4rem",
    l: "2rem",
    m: "1.8rem",
    s: "1.4rem",
    xs: "0.7rem",
};

let tabletNewFont = {
    xxl: "3.6rem",
    xml: "2.8rem",
    xl: "2.2rem",
    l: "1.8rem",
    m: "1.6rem",
    s: "1.4rem",
    xs: "0.6rem",
};

let mobileNewFont = {
    xxl: "3.4rem",
    xml: "2.4rem",
    xl: "2rem",
    l: "1.8rem",
    m: "1.6rem",
    s: "1.4rem",
    xs: "0.5rem",
};

let smallMobile = {
    xxl: "2.8rem",
    xml: "2.4rem",
    xl: "2rem",
    l: "1.8rem",
    m: "1.6rem",
    s: "1.4rem",
    xs: "0.4rem",
};

let globalFont = {
    xxl: "4rem",
    xml: "3.6rem",
    xl: "3.2rem",
    l: "2.4rem",
    ml: "1.8rem",
    m: "1.6rem",
    s: "1.4rem",
    xs: "1.2rem",
    xxs: "1rem",
};
const sizes = {
    horizontal: "8%",
    n: 0,
    xxs: ".5rem",
    xs: ".8rem",
    sm: "1rem",
    s: "1.2rem",
    m: "1.6rem",
    l: "1.8rem",
    xl: "2rem",
    xl3: "3rem",
    xl4: "4rem",
    xl5: "5rem",
    xl6: "6rem",
    xl7: "7rem",
    xl8: "8rem",
    xl9: "9rem",
    xl10: "10rem",
};
const theme = {
    default: {
        color: {
            lightPrimary: "#fc9e17", //#03d1e0 //#18FFFF //#fc9e17
            primary: "#25689C", // "#00C853", // #f9a70f //#03d1e0 //#fc9e17
            main: "#171D39",
            secondary: "#01393e", // "#153e4c", //#005c63 //#01393e
            darkSecondary: "#002123", //"#1a3640", //#00393e //#002123
            error: "#c6002a",
            active: "#f24b6a",
            blue: "#153295",
            white: "#fff",
            whitesmoke: "#F9FAFC",
            darkGrey: "#3e4152", //"#ccc",
            lightGrey: "#ccc",
            black: "#000",
            lightGreen: "#5CB946",
            limeYellow: " #F6F8EB",
            green: "#3b8940",
            orange: "#F59120",
            orange200: "#FFFBF3",
            smoke: "#F9FAFC",
            grey100: "#ECEFF2",
            grey200: "#F6F6F6",
            grey250: "#F7F7F7",
            grey300: "#ededed",
            grey350: "#E8E9EB",
            grey400: "#D9D9D9",
            grey500: "#8a8a8c",
            grey600: "#b3b3b3",
            grey700: "#939292",
            grey800: "#424242",

            blue200: "#bed2e1",
            black800: "#3A4C5A",
            cyan500: "#18A99E",
            cyan200: "#d4f1f1",
            indigo: "#6B5EFF",
            cyan: "#71CFD1",

            red200: "#f8d7da",

            bgInfo: "#ebf5ff",
            bgDanger: "#fdf2f2",
            bgWarn: "#fdfdea",
            bgSuccess: "#f3faf7",

            // new colors
            background: "#FBFBFB",
        },
        fontSize: {
            smallMobile: smallMobile,
            mobile: mobileNewFont,
            smallTablet: tabletNewFont,
            tablet: tabletNewFont,
            large: largeNewFont,
            laptop: extraLargeNewFont,
            desktop: desktopNewFont,
            default: globalFont,
        },
        fontWeight: {
            th: "300",
            rg: "400",
            m: "500",
            sb: "600",
            b: "700",
        },
        // breakPoints: {
        //     smallMobile: "350px",
        //     mobile: "540px",
        //     tablet: "940px",
        //     large: "1030px",
        //     laptop: "1200px",
        //     desktop: "1500px",
        // },
        breakPoints: {
            smallMobile: "425px",
            mobile: "570px",
            smallTablet: "768px",
            tablet: "992px",
            large: "1024px",
            laptop: "1200px",
            desktop: "1600px",
        },
        sizes: sizes,
        gradient: {
            highlight:
                "linear-gradient(90deg, rgba(255, 255, 255, 0.102) 0px 77%, rgba(255, 255, 255, 0.502) 92%, transparent)",
        },
        transition: {
            xs: "0.25s ease",
            s: "0.5s ease",
            m: "1s ease",
            c1: "cubic-bezier(0.33, 0.85, 0.4, 0.96)",
        },
    },
};

export const SMALL_MOBILE_BREAKPOINT = 425;
export const MOBILE_BREAKPOINT = 570;
export const SMALL_TAB_BREAKPOINT = 768;
export const TAB_BREAKPOINT = 992;
export const BIG_TAB_BREAKPOINT = 1024;
export const SMALL_LAPTOP_BREAKPOINT = 1200;
export const XL_BREAKPOINT = 1280;

export default theme.default;

// container
// 2xs 375px   max-width: 375px
// xsm 425px   max-width: 4255px
// sm (640px)	max-width: 640px;
// md (768px)	max-width: 768px;
// lg (1024px)	max-width: 1024px;
// xl (1280px)	max-width: 1280px;
// 2xl (1536px)	max-width: 1536px;
