import React, { useState } from "react";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import ContactUSForm from "../../home/ContactUSForm";
import Faq from "../../home/Faq";
import Calculator from "../../home/Sections/Calculator";
import EnquiryPopUp from "../../home/Sections/EnquiryPopUp";
import PriceSection from "../../home/Sections/PriceSection";
import GovernmentSubsidies from "../GovernmentSubsidies";
import ListItem from "../ListItem";
import ContactEnquiry from "../sections/ContactEnquiry";
import InvestInsurance from "../sections/InvestInsurance";

const subsidyData = {
    summary: (
        <P className="text-secondary">
            Are you planning to make the switch and save money by installing
            solar panels? There are many schemes by the government to promote
            the adoption of solar panels. Need more information? Go through
            these to know which plan suits you the best.
        </P>
    ),
    data: [
        {
            title: "For Residential Households",
            list: [
                "A subsidy of ₹30,000 per kW is available for systems up to 2 kW.",
                "For additional capacity up to 3 kW, the subsidy is ₹18,000 per kW.",
            ],
        },
        {
            title: "For Housing Society/Resident Welfare Association (GHS/RWA)",
            list: [
                "A subsidy of ₹18,000 per kW is given for common facilities up to 500 kW capacity.",
            ],
        },
        {
            title: "For Larger Systems (More Than 3 kW)",
            list: [
                "The total subsidy for systems larger than 3 kW is capped at ₹78,000.",
            ],
        },
    ],
};

const contactData = {
    heading: {
        title: "Want to Know More About Us?",
        content:
            "Schedule a call with our experts to learn more about our products and plans.",
    },
    general: {
        title: "General Inquiry",
        content:
            "Simply fill in the form and our experts will assist you with any query you might have about our products and pricing to make an informed decision.",
    },
    investor: {
        title: "Investor Inquiry",
        content:
            "Do you want to be a part of Hypersrot? Get in touch with us to learn more about the opportunities and how you can join Hypersrot.",
    },
};

const PricingOverView = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>
            <GovernmentSubsidies
                summary={subsidyData.summary}
                content={
                    <SubsidyOverview
                        data={subsidyData.data}
                        onClick={() => setShowModal(true)}
                    />
                }
            />
            <InvestInsurance />
            <PriceSection />
            <Calculator
                heading="Estimate Your Savings"
                text="Finding an ideal plan can be challenging. Use our calculator to find the plan that is best for you."
                bottomHeading="Save on Your Current Bill Today!"
                bottomText="With Hypersrot's plans, you can significantly cut down your monthly electricity bill and help us in fighting climate change together."
            />
            <ContactUSForm data={contactData} enquiry={<ContactEnquiry />} />
            <Faq />
            <EnquiryPopUp
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                callback={() => setShowModal(false)}
            />
        </div>
    );
};

const SubsidyOverview = ({ data, onClick }) => {
    return (
        <div className="w-full ">
            <div className="flex flex-wrap items-start justify-between ">
                {data[0] && (
                    <div className="w-full md:w-[45%] xl:w-full">
                        <P className="mb-0.8 xl:mb-1.6 font-medium">
                            {data[0]?.title}
                        </P>
                        <div className="flex flex-wrap justify-between items-start mb-4">
                            {data[0].list.map((item, i) => (
                                <div
                                    key={i}
                                    className="w-full md:w-45%  xl:w-[45%] mb-1.2 xl:mb-0"
                                >
                                    <ListItem value={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {data[1] && (
                    <div className="w-full md:w-[45%] xl:w-full">
                        <P className="mb-0.8 xl:mb-1.6 font-medium">
                            {data[1].title}
                        </P>
                        {data[1].list.map((item, i) => (
                            <div
                                key={i}
                                className="mb-2.8 lg:mb-3 xl:mb-4 xl:w-[50%]"
                            >
                                <ListItem key={1} value={item} />
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {data[2] && (
                <div>
                    <P className="mb-0.8 xl:mb-1.6 font-medium">
                        {data[2].title}
                    </P>
                    {data[2].list.map((item, i) => (
                        <div
                            key={i}
                            className="mb-2.8 lg:mb-3.2 2xl:mb-4 xl:w-[50%]"
                        >
                            <ListItem value={item} />
                        </div>
                    ))}
                </div>
            )}

            <Button onClick={onClick}>Got an enquiry?</Button>
        </div>
    );
};

export default PricingOverView;
