import React from "react";
import cn from "../../lib/cn";

const baseStyles =
    "font-semibold font-urbanist text-xs sm:text-btn-s  lg:text-btn-s py-0.6 px-0.8 sm:py-0.8 sm:px-1.6  lg:py-1 lg:px-1.6  rounded-0.8 lg:rounded-sm xl:hover:drop-shadow-lg relative group overflow-hidden duration-500 ease transition-all flex justify-center";

const variants = {
    primary: "bg-primary text-white ",
    secondary: "bg-white text-primary ",
};

const TabButton = ({
    children,
    variant = "primary",
    className = "",
    isLoading = false,
    disabled = false,
    loaderClass,
    ...rest
}) => {
    const variantStyles = variants[variant];

    return (
        <button className={cn(baseStyles, variantStyles, className)} {...rest}>
            {children}
        </button>
    );
};

export default TabButton;
