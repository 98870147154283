import React from "react";
import { subsidyImage } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import H2 from "../../shared/typography/H2";

const GovernmentSubsidies = ({ content, summary }) => {
    return (
        <Section className="bg-white pb-2">
            <div>
                <Container>
                    <div className="flex flex-wrap justify-between items-center xl:mb-4.8 2xl:mb-5.6">
                        <div className="w-full xl:w-4/12 mb-1.6">
                            <H2>Government Subsidies</H2>
                        </div>
                        <div className="w-full xl:w-7/12 ">{summary}</div>
                        <div className="xl:hidden">
                            <img
                                src={subsidyImage}
                                className="w-full aspect-video my-2.4 "
                                alt="pricing_background"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-between">
                        <div className="w-full xl:w-5/12">{content}</div>
                        <div className=" hidden xl:block xl:w-7/12">
                            <img
                                src={subsidyImage}
                                className="w-full  my-2.4 "
                                alt="pricing_background"
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </Section>
    );
};

export default GovernmentSubsidies;
